import { useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";

// Material UI
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";

// components
import ExternalLayout from "../../components/layout/external/ExternalLayout";
import { resendEmail, resendEmailLink, userSelector } from "../user/userSlice";

// images
import Mail from "components/layout/external/img/MailBackground.svg";

// styles
import styles from "./onboardingStyles";
import { makeStyles } from "@material-ui/core/styles";
import toast from "react-hot-toast";

const useStyles = makeStyles(styles);

export default function CheckEmail() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { handleSubmit } = useForm();
  const { signUpEmail } = useSelector(userSelector);
  const [resendCount, setResendCount] = useState(0);

  const onSubmit = handleSubmit(() => {
    if (signUpEmail != null && resendCount < 3) {
      dispatch(resendEmailLink.Action(signUpEmail));
      dispatch(resendEmail);
      toast.success("Email link sent!");
      setResendCount(resendCount + 1);
    } else {
      toast.error("Failed to resend email link. Please contact support.");
    }
  });

  return (
    <ExternalLayout footerFull lowCurve>
      <div
        className={classes.backgroundMailSVG}
        style={{ backgroundImage: `url(${Mail})` }}
      />

      <Grid container justifyContent="center">
        <Grid item xs={8} md={6} lg={4}>
          <form onSubmit={onSubmit}>
            <Card>
              <Typography variant="h1">Check your email</Typography>
              <Typography variant="body1">
                Click the 'confirmation link'. If you did not receive it, try
                again.
              </Typography>
              <Button
                color="primary"
                type="submit"
                variant="contained"
                style={{ zIndex: 1000 }}
              >
                Resend Link
              </Button>
            </Card>
          </form>
        </Grid>
      </Grid>
      <div className={classes.radialGradient} />
    </ExternalLayout>
  );
}
