// Material UI
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import Divider from "@material-ui/core/Divider";

// images
import Logo from "../logo/REMagnetLogo.svg";
import Facebook from "../logo/facebookLogo.svg";
import Twitter from "../logo/twitterLogo.svg";
import LinkedIn from "../logo/linkedInLogo.svg";
import Vimeo from "../logo/vimeoLogo.svg";

// styles
import styles from "./footerStyles";
import { makeStyles } from "@material-ui/core/styles";
import commonStyles from "components/layout/commonStyles";

const useStyles = makeStyles((theme) => ({
  ...styles(theme),
  ...commonStyles(theme),
}));

export default function Footer() {
  const classes = useStyles();

  return (
    <footer className={classes.footerContainer}>
      <Grid container justifyContent="center" style={{ padding: "0 1em" }}>
        <Grid item container xs={12} md={2} lg={4} justifyContent="center">
          <Grid item>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="menu"
              className={classes.logo}
            >
              <img src={Logo} alt="logo" />
            </IconButton>
            <Typography variant="h6" className={classes.nameWrapper}>
              <span className={classes.headingMain}>Retention</span>
              <span className={classes.headingSub}>Engine</span>
            </Typography>
          </Grid>
        </Grid>
        <Grid item container xs={12} md={10} lg={8} justifyContent="center">
          <Grid item xs={6} sm={3} md={2}>
            <Typography variant="h6" className={classes.footerSectionTitle}>
              Products
            </Typography>
            <FooterLink
              destination="https://www.bellwethr.com/products/no-code-data-science-for-customer-success"
              text="No Code Data Science"
            />
            <FooterLink
              destination="https://www.retentionengine.com/"
              text="RetentionEngine"
            />
          </Grid>
          <Grid item xs={6} sm={3} md={2}>
            <Typography variant="h6" className={classes.footerSectionTitle}>
              Industries
            </Typography>
            <FooterLink
              destination="https://www.bellwethr.com/products/prospecting-ai-for-b2b-saas"
              text="B2B SaaS"
            />
            <FooterLink
              destination="https://www.retentionengine.com/"
              text="Subscription eCommerce"
            />
          </Grid>
          <Grid item xs={6} sm={3} md={2}>
            <Typography variant="h6" className={classes.footerSectionTitle}>
              Insights
            </Typography>
            <FooterLink
              destination="https://www.bellwethr.com/insights/blog"
              text="Research and Articles"
            />
            <FooterLink
              destination="https://www.bellwethr.com/insights/explain-it-like-im-5"
              text="Explain it like I'm 5"
            />
            <FooterLink
              destination="https://www.bellwethr.com/insights/free-resources"
              text="Free Resources"
            />
          </Grid>
          <Grid item xs={6} sm={3} md={2}>
            <Typography variant="h6" className={classes.footerSectionTitle}>
              Company
            </Typography>
            <FooterLink
              destination="https://www.bellwethr.com/company/about-bellwethr"
              text="About BellWethr"
            />
            <FooterLink
              destination="https://www.bellwethr.com/company/contact"
              text="Contact Us"
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid
        container
        justifyContent="center"
        className={classes.socialContainer}
      >
        <Grid item xs={6} sm={4} md={3} lg={2} xl={1}>
          <Grid container spacing={1}>
            <SocialButton
              destination="https://twitter.com/getretention"
              source={Twitter}
              altText="TwitterLogo"
            />
            <SocialButton
              destination="https://www.facebook.com/GetRetention"
              source={Facebook}
              altText="FacebookLogo"
            />
            <SocialButton
              destination="https://www.linkedin.com/company/retentionengine/"
              source={LinkedIn}
              altText="LinkedInLogo"
            />
            <SocialButton
              destination="https://vimeo.com/user114893219"
              source={Vimeo}
              altText="VimeoLogo"
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid container justifyContent="center">
        <Grid item xs={11}>
          <Divider variant="middle" className={classes.divider} />
        </Grid>
      </Grid>

      <Typography variant="body2" className={classes.copyright}>
        &copy; RetentionEngine {new Date().getFullYear()}. All rights reserved.
      </Typography>
    </footer>
  );
}

type SocialButtonProps = {
  destination: string;
  source: string;
  altText: string;
};

export const SocialButton: React.FC<SocialButtonProps> = ({
  destination,
  source,
  altText,
}) => {
  const classes = useStyles();

  return (
    <Grid item xs={3}>
      <div className={classes.altLogoBox}>
        <a href={destination} target="_blank" rel="noreferrer">
          <img src={source} alt={altText} className={classes.altLogo} />
        </a>
      </div>
    </Grid>
  );
};

type FooterLinkProps = {
  destination: string;
  text: string;
};

export const FooterLink: React.FC<FooterLinkProps> = ({
  destination,
  text,
}) => {
  const classes = useStyles();

  return (
    <a
      className={classes.grayLink}
      href={destination}
      target="_blank"
      rel="noreferrer"
    >
      {text}
    </a>
  );
};
