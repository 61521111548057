import { Theme } from "@material-ui/core";

export default function wizardLayoutStyles(theme: Theme) {
  return {
    smallAvatar: {
      width: theme.spacing(4),
      height: theme.spacing(4),
      fontSize: "1.5em",
      border: "4px solid #E6E6E6",
    },
    headerButton: {
      height: "2.5em",
      padding: "0 0.5em",
      margin: "0em 0.2em",
      borderRadius: "1em",
    },
    activeButton: {
      background: "#E4E4E4",
    },
    wizardNavbarWrapper: {
      height: 120,
    },
    wizardContent: {
      position: "relative" as "relative",
      display: "flex",
      alignItems: "stretch",
      flex: 1,
    },
  };
}
