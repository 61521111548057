import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

// Material UI
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import Typography from "@material-ui/core/Typography";

// components
import ExternalLayout from "../../components/layout/external/ExternalLayout";
import { confirmEmail } from "../user/userSlice";
import Loader from "../../components/loading/Loader";

// images
import Mail from "components/layout/external/img/MailBackground.svg";

// styles
import styles from "./onboardingStyles";
import { makeStyles } from "@material-ui/core/styles";
import commonStyles from "../../components/layout/commonStyles";

const useStyles = makeStyles((theme) => ({
  ...commonStyles(theme),
  ...styles(theme),
}));

interface EmailTokenParam {
  token: string;
}

export default function CheckEmail() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const params = useParams<EmailTokenParam>();
  const error = useSelector(confirmEmail.ErrorSelector);

  useEffect(() => {
    dispatch(confirmEmail.Action(params.token));
  }, [dispatch, params.token]);

  return (
    <ExternalLayout footerFull lowCurve>
      <div
        className={classes.backgroundMailSVG}
        style={{ backgroundImage: `url(${Mail})` }}
      />

      <Grid container justifyContent="center">
        <Grid item xs={8} md={6} lg={4}>
          <Card>
            <Typography variant="h1">Confirming your email...</Typography>
            <Typography variant="body1">
              {error ? (
                <span className={classes.errorMessage}>{error}</span>
              ) : (
                <>
                  This should just take a second!
                  <br />
                  <br />
                  <br />
                  <Loader />
                </>
              )}
            </Typography>
          </Card>
        </Grid>
      </Grid>
      <div className={classes.radialGradient} />
    </ExternalLayout>
  );
}
