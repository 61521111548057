import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import commonStyles from "../layout/commonStyles";

export interface LoaderProps {
  size?: number;
  padding?: number | string | boolean;
  reason?: string;
}
const useStyles = makeStyles(commonStyles);

const Loader: React.FC<LoaderProps> = ({ size, padding, reason }) => {
  const classes = useStyles();
  return (
    <div
      style={{
        padding: padding === true ? "2em" : padding || undefined,
        width: "100%",
        textAlign: "center",
      }}
    >
      {reason ? (
        <Typography variant="h6" className={classes.purpleGradient}>
          {reason}
        </Typography>
      ) : null}
      <CircularProgress size={size} />
    </div>
  );
};

export default Loader;
