import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

// Material UI
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";

// components
import MainTreeCard from "components/wizard/experience/MainTreeCard";
import SecondaryTreeCard from "components/wizard/experience/SecondaryTreeCard";
import EditableTreeCard from "components/wizard/experience/EditableTreeCard";
import { EXPERIENCE_URLS, MECHANICS_URLS, WIZARD_URLS } from "./slice/urls";
import { ExitSurveyResponse } from "./slice/types";
import TreeWrapper from "../../components/wizard/experience/TreeWrapper";

import {
  availableTreatmentsSelector,
  createExitSurveyResponse,
  currentEngineSlugSelector,
  currentSlugSelector,
  currentStepSelector,
  deleteExitSurveyResponse,
  displayDataSelector,
  exitSurveyOtherReasonResponseSlugSelector,
  exitSurveyResponsesSelector,
  stepNavigate,
  toggleCollapseExitSurvey,
  updateExitSurveyResponse,
  exitSurveyImmediateCancelReasonResponseSlugSelector,
} from "./slice/wizardSlice";

// images
import AddIcon from "./img/purpleAddIcon.svg";
import TrashIcon from "../../components/wizard/img/trashIcon.svg";
import PurpleCheck from "./img/purpleCheck.svg";
import GrayCheck from "../../components/wizard/img/grayCheck.svg";

// styles
import { makeStyles } from "@material-ui/core/styles";
import commonStyles from "components/layout/commonStyles";
import clsx from "clsx";
import wizardStyles from "./wizardStyles";

const useStyles = makeStyles((theme) => ({
  ...commonStyles(theme),
  ...wizardStyles(theme),
}));

export function ExitSurveyTree() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const responses = useSelector(exitSurveyResponsesSelector);
  const collapsed = useSelector(
    (state) => displayDataSelector(state).exitSurveyCollapsed
  );

  const currentStep = useSelector(currentStepSelector);
  const currentSlug = useSelector(currentSlugSelector);
  const currentEngineSlug = useSelector(currentEngineSlugSelector);
  const otherReasonSlug = useSelector(
    exitSurveyOtherReasonResponseSlugSelector
  );
  const immediateCancelSlug = useSelector(
    exitSurveyImmediateCancelReasonResponseSlugSelector
  );
  const availableTreatments = useSelector(availableTreatmentsSelector);

  const [showTextField, setShowTextField] = useState(false);

  const mainPanelSelected =
    currentStep?.path === EXPERIENCE_URLS.exit_survey ||
    currentStep?.path === WIZARD_URLS.experience;

  const onClickCollapseOptions = () => dispatch(toggleCollapseExitSurvey());

  const handleOnClickAdd = (reason_text: string) => {
    dispatch(
      createExitSurveyResponse.Action({
        offboard_slug: currentEngineSlug,
        reason_text,
        active: true,
        show_pauses: true,
        offer_ids: Object.keys(availableTreatments).map(
          (key) => availableTreatments[key].id
        ),
        slug: reason_text,
      } as ExitSurveyResponse)
    );
    setShowTextField(false);
  };

  const isMechanics = currentStep?.path.indexOf(WIZARD_URLS.mechanics) !== -1;
  const isSettings = currentStep?.path.indexOf(WIZARD_URLS.settings) !== -1;

  const showSlugs = Object.keys(responses).filter((slug: string) => {
    if (isMechanics && slug === immediateCancelSlug) return false;
    return mainPanelSelected || responses[slug].active;
  });

  return (
    <TreeWrapper
      header={
        <MainTreeCard
          title="Exit Survey"
          number={showSlugs.length}
          collapsed={collapsed}
          onClick={onClickCollapseOptions}
          onCardClick={
            isMechanics
              ? undefined
              : () =>
                  dispatch(stepNavigate({ path: EXPERIENCE_URLS.exit_survey }))
          }
        />
      }
      after={
        <>
          {showTextField ? (
            <Grid item>
              <EditableTreeCard
                onClickCancel={() => setShowTextField(false)}
                onClickSave={handleOnClickAdd}
              />
            </Grid>
          ) : null}
          {!isMechanics && !isSettings ? (
            <Tooltip title="Add Exit Survey Response" arrow>
              <IconButton
                aria-label="add option"
                onClick={() => setShowTextField(true)}
              >
                <img src={AddIcon} alt="add" />
              </IconButton>
            </Tooltip>
          ) : null}
        </>
      }
    >
      {collapsed
        ? null
        : showSlugs.map((slug: string) => {
            const option = responses[slug];
            return (
              <Grid
                item
                key={option.reason_text}
                className={clsx(
                  option.reason_text.length < 10 ? classes.secondarySmall : null
                )}
              >
                <SecondaryTreeCard
                  description={option.reason_text}
                  active={option.active}
                  selected={
                    option.slug === currentSlug &&
                    currentStep?.path === MECHANICS_URLS.exit_survey_response
                  }
                  onClickSelect={
                    isMechanics
                      ? () =>
                          dispatch(
                            stepNavigate({
                              path: MECHANICS_URLS.exit_survey_response,
                              slug: option.slug,
                            })
                          )
                      : undefined
                  }
                  leftIcon={
                    <Tooltip
                      title={option.active ? "Activated" : "Deactivated"}
                      placement="left"
                      arrow
                    >
                      <img
                        src={option.active ? PurpleCheck : GrayCheck}
                        className={classes.hoverPointer}
                        onClick={
                          isMechanics || isSettings
                            ? undefined
                            : () =>
                                dispatch(
                                  updateExitSurveyResponse.Action({
                                    slug: option.slug,
                                    active: !option.active,
                                    offboard_slug: currentEngineSlug,
                                  } as ExitSurveyResponse)
                                )
                        }
                        alt="activate_or_deactivate"
                      />
                    </Tooltip>
                  }
                  rightIcon={
                    currentStep?.path === EXPERIENCE_URLS.exit_survey &&
                    ![otherReasonSlug, immediateCancelSlug].includes(
                      option.slug
                    ) ? (
                      <>
                        <Tooltip
                          title="Delete Exit Survey Response"
                          placement="right"
                          arrow
                        >
                          <img
                            src={TrashIcon}
                            alt="delete"
                            className={classes.hoverPointer}
                            onClick={() =>
                              dispatch(
                                deleteExitSurveyResponse.Action({
                                  slug: option.slug,
                                  offboard_slug: currentEngineSlug,
                                } as ExitSurveyResponse)
                              )
                            }
                          />
                        </Tooltip>
                      </>
                    ) : (
                      <></>
                    ) // If the right icon is undefined, we experience weird vertical spacing issues between cards!
                  }
                />
              </Grid>
            );
          })}
    </TreeWrapper>
  );
}

export default ExitSurveyTree;
