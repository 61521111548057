import React from "react";
import Grid from "@material-ui/core/Grid";
import BuilderView from "./BuilderView";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import commonStyles from "../../components/layout/commonStyles";
import clsx from "clsx";
import wizardStyles from "./wizardStyles";
import WizardPreviewContainer from "./preview/WizardPreviewContainer";
import { displayDataSelector } from "./slice/wizardSlice";
import { useSelector } from "react-redux";

interface BuilderSidebarWrapperProps {}

const useStyles = makeStyles((theme) => ({
  ...commonStyles(theme),
  ...wizardStyles(theme),
}));

export const BuilderSidebarWrapper: React.FC<BuilderSidebarWrapperProps> = ({
  children,
}) => {
  const classes = useStyles();
  const { showPreviewStep } = useSelector(displayDataSelector);
  return (
    <Grid
      container
      style={{ height: "100%", position: "absolute" }}
      alignItems="stretch"
    >
      <Grid item sm={6} md={7} lg={8} style={{ position: "relative" }}>
        {showPreviewStep ? <WizardPreviewContainer /> : <BuilderView />}
      </Grid>
      <Grid item sm={6} md={5} lg={4}>
        <Paper
          square
          className={clsx(
            classes.alignLeft,
            classes.fullHeight,
            classes.sidebarWrapper
          )}
        >
          {children}
        </Paper>
      </Grid>
    </Grid>
  );
};

export default BuilderSidebarWrapper;
