import { FieldErrors, FieldValues } from "react-hook-form";

export function mergeErrors(
  frontend: FieldErrors<FieldValues>,
  backend: Map<string, string[]>
) {
  let errors = new Map<string, string>();

  for (const [field, value] of Object.entries(frontend || {})) {
    if (value.message.length) errors.set(field, value.message);
    else if (value.type === "required")
      errors.set(field, "This field is required.");
  }
  for (const [field, value] of Object.entries(backend || {})) {
    if (Array.isArray(value)) {
      errors.set(field, value[0]);
    } else {
      errors.set(field, String(value));
    }
  }

  return errors;
}
