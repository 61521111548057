import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import client, { APIStatus, createAPICallThunk } from "../../app/api";
import toast from "react-hot-toast";

export interface BaseSignupFields {
  password1: string;
  password2: string;
  first_name: string;
  last_name: string;
}
export interface SignupFields extends BaseSignupFields {
  email: string;
}

export const signupUser = createAsyncThunk(
  "users/signupUser",
  async (data: SignupFields, thunkAPI) => {
    try {
      const { http, data: reply } = await client.post(
        "/authentication/registration/",
        data
      );

      if (http.status === 201 || http.status === 200) {
        return reply;
      } else {
        return thunkAPI.rejectWithValue(reply);
      }
    } catch (e) {
      return thunkAPI.rejectWithValue(e.reason);
    }
  }
);

export const resendEmailLink = createAPICallThunk(
  "user",
  "resendLink",
  async (email: string) => {
    return await client.post("/authentication/registration/resend-email/", {
      email: email,
    });
  },
  (state, call) => {
    toast.success("Email link sent!");
  }
);

export const forgotPassword = createAPICallThunk(
  "user",
  "forgotPassword",
  async (email: string) => {
    return await client.post("/authentication/password/reset/", {
      email: email,
    });
  },
  (state, call) => {
    toast.success("Password reset email sent!");
    setTimeout(() => (window.location.pathname = "/login"), 1000);
  }
);

export const confirmEmail = createAPICallThunk(
  "user",
  "confirmEmail",
  async (token: string) => {
    return await client.post("/authentication/registration/verify-email/", {
      key: token,
    });
  },
  (state, call) => {
    toast.success("Email verified! Sending you to login...");
    setTimeout(() => (window.location.pathname = "/login"), 3000);
  }
);

export const getUserInvite = createAPICallThunk(
  "user",
  "getUserInvite",
  async (token: string) => {
    return await client.get(`/invite/${token}/`);
  },
  (state, call) => {
    state.userInviteEmail = call.reply.email;
  },
  (state, call) => {
    toast.error(call.error || "Invalid invite link");
    setTimeout(() => (window.location.pathname = "/login"), 3000);
  },
  [200, 201, 202, 204, 205],
  false
);

export interface SendUserInviteFields {
  email: string;
  clientSlug: string;
}

export const sendUserInvite = createAPICallThunk(
  "user",
  "sendUserInvite",
  async (data: SendUserInviteFields) => {
    return await client.post(`/client-user-invite/`, {
      email: data.email,
      client: data.clientSlug,
    });
  },
  (state, call) => {
    toast.success(`Invite Successfully Sent to ${call.reply?.email}!`, {
      duration: 3500,
    });
  }
);

export interface UserInviteSignUpFields extends BaseSignupFields {
  token: string;
}

export const userInviteSignUp = createAPICallThunk(
  "user",
  "userInviteSignUp",
  async (data: UserInviteSignUpFields) => {
    return await client.post(`/invite/${data.token}/`, data);
  },
  (state, call) => {
    toast.success("Success! You can now login.");
    setTimeout(() => (window.location.pathname = "/login"), 3000);
  }
);

export interface PasswordChangeFields {
  old_password: string;
  new_password1: string;
  new_password2: string;
}

export const changePassword = createAPICallThunk(
  "user",
  "changePassword",
  async (input: PasswordChangeFields) => {
    return await client.post("/authentication/password/change/", {
      old_password: input.old_password,
      new_password1: input.new_password1,
      new_password2: input.new_password2,
    });
  },
  (state, call) => {
    toast.success("Password Successfully Updated!");
  }
);

export const updateClient = createAPICallThunk(
  "users",
  "updateClient",
  async (data: UserCompanyClient) => {
    return await client.patch("/client/", data);
  },
  (state, call) => {
    if (state.user && state.user.auth) state.user.auth.user.client = call.reply;
    toast.success("Client Successfully Updated!");
  }
);

interface LoginFields {
  email: string;
  password: string;
}

export const getProfile = createAsyncThunk(
  "users/getProfile",
  async (data: void, thunkAPI) => {
    try {
      const { http, data: reply } = await client.get(
        "/authentication/profile/"
      );

      if (http.status === 200 && reply.email) {
        return { user: reply };
      } else {
        return thunkAPI.rejectWithValue(reply);
      }
    } catch (e) {
      return thunkAPI.rejectWithValue(e.reason);
    }
  }
);

export const refreshToken = createAPICallThunk(
  "users",
  "refreshToken",
  async (data: any) => {
    console.log("Refreshing login token...");
    const userCache = JSON.parse(
      localStorage.getItem("RE_auth_user") || "{}"
    ) as { [key: string]: string };
    const refresh = userCache["refresh_token"];
    return await client.post("/authentication/token/refresh/", { refresh });
  },
  (state, call) => {
    const userCache = JSON.parse(
      localStorage.getItem("RE_auth_user") || "{}"
    ) as { [key: string]: string };

    state.auth.access_token = call.reply.access;
    state.auth.access_token_expiration = call.reply.access_expiration;
    state.auth.refresh_token = userCache["refresh"];
    localStorage.setItem("RE_auth_user", JSON.stringify(state.auth));
  },
  (state, call) => {
    state.errorMessage = call.reply?.non_field_errors || "An error occurred";
    state.auth = { user: {} };
    localStorage.removeItem("RE_auth_user");
    if (typeof call.reply === "object") {
      state.backendFieldErrors = call.reply;
    }
  }
);

export const loginUser = createAPICallThunk(
  "users",
  "login",
  async (data: LoginFields) => {
    return await client.post("/authentication/login/", data);
  },
  (state, call) => {
    state.auth.user = call.reply.user;
    localStorage.setItem("RE_auth_user", JSON.stringify(call.reply));
    toast.success("Client Successfully Updated!");
  },
  (state, call) => {
    state.errorMessage = call.reply?.non_field_errors || "An error occurred";
    localStorage.removeItem("RE_auth_user");
    if (typeof call.reply === "object") {
      state.backendFieldErrors = call.reply;
    }
  }
);

export const logoutUser = createAsyncThunk(
  "users/logout",
  async (data: void, thunkAPI) => {
    try {
      console.log("Log out");
      localStorage.clear();
      const { http, data: reply } = await client.post(
        "/authentication/logout/"
      );

      console.log("Logged out");
      if (http.status === 200) {
        return true;
      } else {
        return thunkAPI.rejectWithValue(reply);
      }
    } catch (e) {
      return thunkAPI.rejectWithValue(e.reason);
    }
  }
);

interface CreateClientFields {
  organization_name: string;
}

export const createClient = createAsyncThunk(
  "users/createClient",
  async (data: CreateClientFields, thunkAPI) => {
    try {
      const { http, data: reply } = await client.post("/client/", {
        organization_name: data.organization_name,
      });

      if (http.status === 201 || http.status === 200) {
        return reply;
      } else {
        return thunkAPI.rejectWithValue(reply);
      }
    } catch (e) {
      return thunkAPI.rejectWithValue(e.reason);
    }
  }
);

interface ResetPasswordFields {
  new_password1: string;
  new_password2: string;
  uid: string;
  token: string;
}

export const resetPassword = createAPICallThunk(
  "user",
  "resetPassword",
  async (data: ResetPasswordFields) => {
    return await client.post(
      `/authentication/password/reset/confirm/${data.uid}/${data.token}/`,
      {
        ...data,
      }
    );
  },
  (state, call) => {
    toast.success("Your password has been changed.");
    setTimeout(() => (window.location.pathname = "/login"), 1500);
  }
);

export const getClient = createAsyncThunk(
  "users/getClient",
  async (data: void, thunkAPI) => {
    try {
      const { http, data: reply } = await client.get("/client/");

      if (http.status === 200) {
        return reply;
      } else {
        return thunkAPI.rejectWithValue(reply);
      }
    } catch (e) {
      return thunkAPI.rejectWithValue(e.reason);
    }
  }
);

export const getClientSettings = createAsyncThunk(
  "users/getClientSettings",
  async (data: void, thunkAPI) => {
    try {
      const { http, data: reply } = await client.get(
        "/static-values/client-settings/"
      );

      if (http.status === 200) {
        return reply;
      } else {
        return thunkAPI.rejectWithValue(reply);
      }
    } catch (e) {
      return thunkAPI.rejectWithValue(e.reason);
    }
  }
);

export interface UserCompanyClient {
  active: boolean;
  organization_name: string;
  slug: string;
  timezone: string;
}

export interface UserProfile {
  email?: string;
  client?: UserCompanyClient;
}

interface AuthUser {
  user?: UserProfile;
  access_token?: string;
  access_token_expiration?: string;
  refresh_token?: string;
  failed?: boolean;
}

export const userSlice = createSlice({
  name: "user",
  initialState: {
    loggedIn: false,
    auth: {} as AuthUser,
    isFetching: false,
    isSuccess: false,
    isError: false,
    errorMessage: "",
    backendFieldErrors: {},
    clientSettings: {},
    signUpEmail: "",
    userInviteEmail: "",
    getClientSettingsStatus: "NONE" as APIStatus,
    getClientStatus: "NONE" as APIStatus,
    apiCalls: {
      ...loginUser.State,
      ...refreshToken.State,
      ...confirmEmail.State,
      ...changePassword.State,
      ...updateClient.State,

      ...forgotPassword.State,
      ...resetPassword.State,
      ...resendEmailLink.State,
      ...getUserInvite.State,
      ...userInviteSignUp.State,
      ...sendUserInvite.State,
    },
  },
  reducers: {
    clearFormState: (state) => {
      state.isError = false;
      state.isSuccess = false;
      state.isFetching = false;
      state.backendFieldErrors = {};
      return state;
    },
    resendEmail: (state) => {
      state.isError = false;
      state.isSuccess = false;
      state.isFetching = false;
      return state;
    },
    setEmail: (state, action) => {
      state.signUpEmail = action.payload;
      return state;
    },
  },
  extraReducers: {
    [logoutUser.fulfilled as any]: (state) => {
      state.isFetching = false;
      state.isError = false;
      state.loggedIn = false;
      state.auth = {};
      localStorage.setItem("RE_auth_user", "");
    },
    [logoutUser.pending as any]: (state) => {
      state.isFetching = true;
    },
    [signupUser.fulfilled as any]: (state) => {
      state.backendFieldErrors = {};
      state.isFetching = false;
      state.isError = false;
      state.isSuccess = true;
    },
    [signupUser.pending as any]: (state) => {
      state.isFetching = true;
      state.isError = false;
      state.errorMessage = "";
    },
    [signupUser.rejected as any]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload?.non_field_errors || "An error occurred";
      if (typeof payload === "object") {
        state.backendFieldErrors = payload;
      }
    },
    [createClient.fulfilled as any]: (state) => {
      state.backendFieldErrors = {};
      state.isFetching = false;
      state.isError = false;
      state.isSuccess = true;
    },
    [createClient.pending as any]: (state) => {
      state.isFetching = true;
      state.isError = false;
      state.errorMessage = "";
    },
    [createClient.rejected as any]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload?.non_field_errors || "An error occurred";
      if (typeof payload === "object") {
        state.backendFieldErrors = payload;
      }
    },
    [getProfile.fulfilled as any]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = false;
      state.loggedIn = true;
      state.auth = payload;
    },
    [getProfile.pending as any]: (state) => {
      state.isFetching = true;
      state.isError = false;
    },
    [getProfile.rejected as any]: (state) => {
      state.isFetching = false;
      state.loggedIn = false;
      state.auth = { failed: true };
      localStorage.removeItem("RE_auth_user");
    },
    [getClient.fulfilled as any]: (state) => {
      state.getClientStatus = "SUCCESS";
    },
    [getClient.pending as any]: (state) => {
      state.getClientStatus = "FETCHING";
    },
    [getClient.rejected as any]: (state) => {
      state.getClientStatus = "ERROR";
    },
    [getClientSettings.fulfilled as any]: (state, { payload }) => {
      state.getClientSettingsStatus = "SUCCESS";
      state.isSuccess = true;
      state.clientSettings = payload;
    },
    [getClientSettings.pending as any]: (state) => {
      state.getClientSettingsStatus = "FETCHING";
    },
    [getClientSettings.rejected as any]: (state) => {
      state.getClientSettingsStatus = "ERROR";
    },
    ...loginUser.Reducers,
    ...refreshToken.Reducers,
    ...confirmEmail.Reducers,
    ...changePassword.Reducers,
    ...updateClient.Reducers,
    ...forgotPassword.Reducers,
    ...resetPassword.Reducers,
    ...getUserInvite.Reducers,
    ...userInviteSignUp.Reducers,
    ...sendUserInvite.Reducers,
  },
});

export const { clearFormState, resendEmail, setEmail } = userSlice.actions;

export const userSelector = (state: any) => state.user;
export const clientSettingsSelector = (state: any) =>
  userSelector(state).clientSettings;
export const authSelector = (state: any) => userSelector(state).auth;
export const authUserSelector = (state: any) => authSelector(state).user;
export const clientSelector = (state: any) => authUserSelector(state)?.client;
export const clientSettingsReadySelector = (state: any) => {
  return (
    state.user.getClientSettingsStatus === "SUCCESS" &&
    state.user.getClientStatus === "SUCCESS"
  );
};
export const userInviteEmailSelector = (state: any) =>
  state.user.userInviteEmail;

export default userSlice.reducer;
