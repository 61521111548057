import { Theme } from "@material-ui/core";

export default function commonStyles(theme: Theme) {
  return {
    // CONTENT
    mainContent: {
      position: "relative" as "relative",
      height: "100%",
      minHeight: "100vh",
    },
    externalMainShowFooter: {
      minHeight: "75vh", // override the default min-height so that on tall screens, the footer peeks out a little
    },
    middleContainer: {
      position: "relative" as "relative",
      flex: 1,
      zIndex: 5, // Need to be above onboarding "curve" vector zIndex: 1
      padding: "2rem 0",
      [theme.breakpoints.up("md")]: {
        textAlign: "center" as "center",
      },
    },
    lightGrayBackground: {
      background: "#F4F4F4",
    },
    grayBackground: {
      background: "#E2E2E2",
    },
    offWhiteBackground: {
      background: "#FBFBFB",
    },
    darkPurpleGradient: {
      background: "linear-gradient(#3A1569, #281243, #200A3B)",
    },
    fullHeight: {
      height: "100%",
    },
    relative: {
      position: "relative" as "relative",
    },
    marginTop: {
      marginTop: "1em",
    },
    marginBottom: {
      marginBottom: "1em",
    },
    marginRight: {
      marginRight: "1em",
    },
    marginTopMd: {
      marginTop: "3em",
    },
    marginBottomMd: {
      marginBottom: "3em",
    },
    zeroPadding: {
      padding: 0,
    },
    zeroMargin: {
      margin: 0,
    },
    alignLeft: {
      textAlign: "left" as "left",
    },
    alignRight: {
      textAlign: "right" as "right",
    },
    lgPadding: {
      padding: "4em",
    },
    mdPadding: {
      padding: "2em",
    },
    smPadding: {
      padding: "1em",
    },
    smMargin: {
      margin: "1em",
    },
    flexTrue: {
      flex: 1,
    },
    // COLORS
    lightGray: {
      color: "#F4F4F4",
    },
    midGray: {
      color: "#A0A0A0",
    },
    gray: {
      color: "#787878",
    },
    black: {
      color: "black",
    },
    purple: {
      color: "#341241",
    },
    lightPurple: {
      color: "#8E509F",
    },
    offWhite: {
      color: "#FBFBFB",
    },
    // TEXT
    bold: {
      fontWeight: 500,
    },
    uppercase: {
      textTransform: "uppercase" as "uppercase",
    },
    underline: {
      textDecoration: "underline",
    },
    purpleGradient: {
      background: "-webkit-linear-gradient(#341241, #4F2260, #6D3781, #9D68B0)",
      "-webkit-background-clip": "text",
      "-webkit-text-fill-color": "transparent",
    },
    errorMessage: {
      color: "#f44336",
      padding: "1rem 0",
      fontSize: "large",
      display: "block",
    },
    inputBorder: {
      border: "1px solid #C9C9C9",
      borderRadius: "4px",
      padding: "0.5em",
    },
    whiteText: {
      color: "white",
    },
    code: {
      fontSize: "12px",
      textAlign: "left" as "left",
      color: "#787878",
      overflow: "scroll",
    },
    warningText: {
      textDecoration: "underline",
      color: "red",
    },
    textLink: {
      textDecoration: "none",
      fontWeight: 500,
    },
    purpleTextLink: {
      color: "#341241",
      textDecoration: "none",
    },
    navBarMenuItemText: {
      margin: 0,
      padding: "0 0.5em",
      fontFamily: "Poppins",
      fontSize: 14,
      fontWeight: 500,
    },
    // COMPONENTS
    button: {
      background: "#4644A4",
      border: "3px solid #E9E9E9",
      borderRadius: "5px",
      marginTop: "2em",
      boxShadow: "none",
      color: "white",
      "&:hover": {
        background: "#4644A4",
      },
    },
    copyButton: {
      background: "rgba(196, 196, 196, 0.2)",
      borderRadius: "5px",
      float: "right" as "right",
      border: "none",
      fontSize: "10px",
    },
    lightPurpleButton: {
      background: "#8E509F",
      border: "3px solid #EDD2FD",
      borderRadius: "5px",
      color: "white",
    },
    roundedCard: {
      borderRadius: "10px",
    },
    codeSnippetCard: {
      background: "#F1F1F1",
      border: "1px solid #D2D2D2",
      boxSizing: "border-box" as "border-box",
      borderRadius: "10px",
      padding: "0.5em",
    },
    attentionBubble: {
      background: "#F1EDFC",
      borderRadius: "10px",
      boxShadow: "none",
    },
    panelHeader: {
      background: "-webkit-linear-gradient(#341241, #4F2260, #6D3781, #9D68B0)",
      "-webkit-background-clip": "text",
      "-webkit-text-fill-color": "transparent",
      fontWeight: 500,
    },
    panelSubheader: {
      background: "-webkit-linear-gradient(#341241, #4F2260, #6D3781, #9D68B0)",
      "-webkit-background-clip": "text",
      "-webkit-text-fill-color": "transparent",
      marginTop: "1em",
    },
    panelCaption: {
      textTransform: "uppercase" as "uppercase",
      color: "#787878",
    },
  };
}
