import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

// components
import PanelHeader from "components/wizard/PanelHeader";
import Loader from "../../../components/loading/Loader";
import { States } from "../../offboarding/containers/StateViewRouter/StateViewRouter";

import {
  closePreview,
  currentEngineSlugSelector,
  engineStyleSelector,
  getEngineStyle,
  openPreviewStep,
  updateEngineStyle,
  displayDataSelector,
} from "../slice/wizardSlice";

// Material UI
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import Card from "@material-ui/core/Card";
import Button from "@material-ui/core/Button";
import InputBase from "@material-ui/core/InputBase";

// styles
import { makeStyles } from "@material-ui/core/styles";
import commonStyles from "../../../components/layout/commonStyles";
import wizardStyles from "../wizardStyles";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  ...commonStyles(theme),
  ...wizardStyles(theme),
}));

export default function ResponseLogicPanel() {
  const classes = useStyles();
  const currentEngineSlug = useSelector(currentEngineSlugSelector);
  const ready = useSelector(getEngineStyle.isReadySelector);
  const dispatch = useDispatch();
  const style = useSelector(engineStyleSelector);
  const [value, setValue] = useState("");
  const inPreview = useSelector(displayDataSelector).showPreviewStep;
  const isFetchingUpdateStyle = useSelector(
    updateEngineStyle.isFetchingSelector
  );

  useEffect(() => {
    if (currentEngineSlug) {
      dispatch(getEngineStyle.Action(currentEngineSlug));
      dispatch(openPreviewStep({ step: States.REASONS }));
    }
  }, [dispatch, currentEngineSlug]);

  useEffect(() => {
    if (ready) {
      setValue(style.custom_css);
    }
  }, [ready, style.custom_css]);

  const saveCSS = () => {
    dispatch(
      updateEngineStyle.Action({
        slug: currentEngineSlug,
        custom_css: value,
      })
    );
  };

  if (!ready) {
    return <Loader padding reason="Fetching custom CSS..." />;
  }

  return (
    <div className={classes.mdPadding}>
      <PanelHeader
        title="CSS Editor"
        onClick={() =>
          dispatch(
            inPreview
              ? closePreview()
              : openPreviewStep({ step: States.REASONS })
          )
        }
      />
      <Divider className={classes.headingDivider} />
      <Typography variant="h6" className={classes.purpleGradient}>
        Add Custom CSS (Optional)
      </Typography>
      <Typography variant="body2" className={classes.gray}>
        Add custom styling to this specific screen or all screens.
        <br />
        Need help?{" "}
        <a
          className={classes.purpleTextLink}
          href="https://docs.bellwethr.com/docs/custom-css"
          target="_blank"
          rel="noreferrer"
        >
          Click here
        </a>
      </Typography>
      <Card className={classes.codeSnippetCard} style={{ maxWidth: "50vw" }}>
        <InputBase
          fullWidth
          multiline
          className={classes.code}
          value={value}
          onChange={(e) => setValue(e.target.value)}
        />

        <style
          dangerouslySetInnerHTML={{
            __html: value,
          }}
        />
      </Card>
      <Button
        className={clsx(classes.panelButton, classes.marginTop)}
        onClick={saveCSS}
        disabled={isFetchingUpdateStyle}
      >
        Apply
      </Button>
    </div>
  );
}
