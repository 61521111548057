import { Theme } from "@material-ui/core";

export default function footerStyles(theme: Theme) {
  return {
    // FOOTER
    footerContainer: {
      flexShrink: 0,
      background: "white",
      width: "100%",
      minHeight: "6rem",
      textAlign: "left" as "left",
      padding: "2em 0 0",
      color: "black", // TODO: Changes with dark and light theme
      zIndex: 1000,
    },
    footerSectionTitle: {
      marginBottom: "1em",
    },
    grayLink: {
      color: "#6f6e70",
      textDecoration: "none" as "none",
      display: "block",
      fontSize: "14px",
      fontWeight: 300,
      margin: "1em 0",
    },
    logo: {
      display: "inline-block",
    },
    nameWrapper: {
      display: "inline-block",
    },
    headingMain: {
      display: "inline-block",
      fontWeight: 500,
    },
    headingSub: {
      display: "inline-block",
      fontWeight: 300,
    },
    socialContainer: {
      textAlign: "center" as "center",
      paddingTop: "2em",
    },
    altLogoBox: {
      border: "2px solid #f0f0f0",
      borderRadius: "5px",
      height: "30px",
      width: "30px",
      opacity: "0.8",
    },
    altLogo: {
      maxHeight: "18px",
      maxWidth: "18px",
      marginTop: "6px",
    },
    divider: {
      backgroundColor: "#f0f0f0",
      height: "0.1em",
      marginTop: "2em",
    },
    // MINI FOOTER
    miniFooterContainer: {
      flexShrink: 0,
      background: "white",
      width: "100%",
      textAlign: "left" as "left",
      padding: "0.3em 0",
    },
    miniDivider: {
      backgroundColor: "#f8f8f8",
      height: "0.1em",
    },
    copyright: {
      paddingLeft: "7em",
      color: "#787878",
    },
  };
}
