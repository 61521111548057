import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

//components
import Loader from "../../../components/loading/Loader";
import { IntermediateConditional, ContextNameData } from "../slice/types";
import { ConditionalForm } from "./ConditionalForm";
import PanelHeader from "components/wizard/PanelHeader";

import {
  wizardEngineSelector,
  currentEngineSlugSelector,
  getPauseOptions,
  pauseOptionsSelector,
  updatePauseOptions,
} from "../slice/wizardSlice";

// Material UI
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import FormControl from "@material-ui/core/FormControl";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";

//images
import AddIcon from "../img/purpleAddIcon.svg";

// styles
import { makeStyles } from "@material-ui/core/styles";
import commonStyles from "components/layout/commonStyles";
import clsx from "clsx";
import wizardStyles from "../wizardStyles";

const useStyles = makeStyles((theme) => ({
  ...commonStyles(theme),
  ...wizardStyles(theme),
}));

export default function PauseMechanics() {
  const classes = useStyles();
  const dispatch = useDispatch();

  const pauseOptions = useSelector(pauseOptionsSelector);
  const ready = useSelector(getPauseOptions.isReadySelector);
  const currentEngineSlug = useSelector(currentEngineSlugSelector);
  const engine = useSelector(wizardEngineSelector);

  useEffect(() => {
    dispatch(getPauseOptions.Action(currentEngineSlug));
  }, [currentEngineSlug, dispatch]);

  const [toggleConditionals, setToggleConditionals] = useState<boolean>(false);
  const [currentConditionals, setCurrentConditionals] = useState<
    IntermediateConditional[]
  >([]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const show = (event.target as HTMLInputElement).value === "conditions";
    setToggleConditionals(show);
    if (show && !currentConditionals?.length) addConditional();
    if (!show)
      dispatch(
        updatePauseOptions.Action({
          engine: currentEngineSlug,
          conditionals: [],
        })
      );
  };

  useEffect(() => {
    if (ready) {
      setToggleConditionals(pauseOptions.conditionals?.length >= 1);
      setCurrentConditionals(
        pauseOptions.conditionals as IntermediateConditional[]
      );
    }
  }, [pauseOptions, pauseOptions.conditionals, ready]);

  const context_names: ContextNameData = engine?.conditionals_data?.context_names;
  const conditions = engine?.conditionals_data?.conditions;

  const isFetchingUpdatePauseOptions = useSelector(
    updatePauseOptions.isFetchingSelector
  );

  const saveConditionals = () => {
    dispatch(
      updatePauseOptions.Action({
        engine: currentEngineSlug,
        conditionals: currentConditionals,
      })
    );
  };

  const addConditional = () => {
    setCurrentConditionals((current) =>
      (current || []).concat({
        context_type: "CONTEXT",
        context_name: Object.keys(context_names)[0],
        condition: Object.keys(conditions)[0],
        values: [""],
        id: Math.random(),
      } as IntermediateConditional)
    );
  };
  const removeConditional = (index: number) => {
    setCurrentConditionals((current) => {
      return Array.from(current.filter((c, idx) => idx !== index));
    });
  };
  const handleConditionalUpdate = (
    updated: IntermediateConditional,
    index: number
  ) => {
    const newData = Array.from(currentConditionals);
    newData[index] = updated;
    setCurrentConditionals(newData);
  };

  if (!ready) return <Loader padding reason="Fetching pause flow..." />;

  return (
    <div className={classes.mdPadding}>
      <PanelHeader title="Pause Rules" caption="For ALL PAUSES" />
      <Divider className={classes.headingDivider} />
      <Typography variant="h6" className={classes.panelSubheader}>
        Rules
      </Typography>
      <FormControl component="fieldset">
        <RadioGroup
          aria-label="activate_treatment"
          name="activate_treatment"
          value={toggleConditionals}
          onChange={handleChange}
        >
          <FormControlLabel
            value="everyone"
            checked={!toggleConditionals}
            control={<Radio />}
            label="Activate For Everyone"
          />
          <FormControlLabel
            value="conditions"
            checked={toggleConditionals}
            control={<Radio />}
            label="Activate With Conditions"
          />
        </RadioGroup>
      </FormControl>
      {toggleConditionals ? (
        <>
          {currentConditionals.map((c, idx) => (
            <ConditionalForm
              key={c.id}
              index={idx}
              conditional={c}
              conditionalType={context_names[c.context_name].data_type}
              onChange={handleConditionalUpdate}
              onRemove={() => removeConditional(idx)}
            />
          ))}
          <Grid container alignItems="center">
            <Grid item>
              <IconButton
                aria-label="add option"
                className={classes.zeroPadding}
                onClick={addConditional}
              >
                <img src={AddIcon} alt="add" />
              </IconButton>
            </Grid>
            <Grid item>
              <Typography variant="body1">
                &nbsp; Add another user type
              </Typography>
            </Grid>
          </Grid>
          <Grid container alignItems="flex-start">
            <Button
              className={clsx(classes.blueButton, classes.marginTop)}
              onClick={saveConditionals}
              disabled={isFetchingUpdatePauseOptions}
            >
              Save Rules
            </Button>
          </Grid>
        </>
      ) : null}

      <Grid container xs={12}>
        <Typography variant="body2" className={classes.gray}>
          Need help with Rules?
          <a
            href="https://docs.bellwethr.com/docs/cancel-resolutions-getting-started-1"
            className={classes.textLink}
            target="_blank"
            rel="noreferrer"
          >
            <span className={classes.purple}>&nbsp; See our docs</span>
          </a>
        </Typography>
      </Grid>
    </div>
  );
}
