import {ResponsiveBar} from "@nivo/bar";
import {CustomTooltip} from "components/dashboard/CustomTooltip";
import {OrdinalColorScaleConfig} from "@nivo/colors";
import {ComputedDatum} from "@nivo/bar/dist/types/types";
import {Tooltip} from "@material-ui/core";

type BarItem = { [key: string]: number };
export type BarItems = { [key: string]: BarItem };

interface BarChartProps {
    barItems: BarItems;
    order?: string[];
    colors?: OrdinalColorScaleConfig<ComputedDatum<any>>;
}

export const HorizontalBarChart = (props: BarChartProps) => {
    let defaultColors = ["#8E509F", "#F3D2FF", "#CACACA", "#250542", "#5B1E71"];

    // Orders by bar size, then sets to BarDatum type
    let sizeOfBar = (bar: BarItem) => Object.values(bar).reduce((a, b) => a + b, 0);
    const data = Array.from(Object.entries(props.barItems))
        .sort(([k1, bar1], [k2, bar2]) => {
            return sizeOfBar(bar1) - sizeOfBar(bar2);
        })
        // Limits to top 10
        .filter((value, idx) => idx >= Object.keys(props.barItems).length - 10)
        .map(([key, bar]) => ({_key: key, ...bar}));

    // Used for ordering the bar items (the categories of each bar)
    let barKeys: string[];
    if (props.order !== undefined)
        barKeys = props.order;
    else {
        // Prepares for ordering
        let barItemSizes: { [key: string]: number } = {};
        Object.values(props.barItems).forEach((bar) => {
            Object.entries(bar).forEach(([key, barItemSize]) => {
                if (barItemSizes[key] === undefined)
                    barItemSizes[key] = barItemSize;
                else
                    barItemSizes[key] += barItemSize;
            });
        });
        // Orders by total bar item size
        barKeys = Object.keys(barItemSizes).sort((key1, key2) => (
            barItemSizes[key2] - barItemSizes[key1]
        ))
    }

    // Fail safe
    if (data.length === 0) return (<div></div>);

    return (
        <ResponsiveBar
            data={data}
            keys={barKeys}
            indexBy="_key"
            layout="horizontal"
            margin={{top: 0, right: 30, bottom: 40, left: 150}}
            padding={0.6}
            valueScale={{type: "linear"}}
            indexScale={{type: "band", round: true}}
            colors={(props.colors === undefined)? defaultColors : props.colors}
            borderColor={{from: "color", modifiers: [["darker", 1.6]]}}
            enableLabel={false}
            enableGridY={false}
            axisTop={null}
            axisBottom={null}
            axisLeft={{
                renderTick: (tick) => {
                    const SUMMARY_LENGTH = 18;
                    return (
                        <g transform={`translate(${tick.x},${tick.y})`}>
                            <Tooltip title={tick.value} aria-label={tick.value}>
                                <text
                                    textAnchor="end"
                                    dominantBaseline="middle"
                                    dx={-5}
                                    style={{
                                        fill: "#333",
                                        fontSize: 10,
                                    }}
                                >
                                    {tick.value.slice(0, SUMMARY_LENGTH) +
                                        (tick.value.length >= SUMMARY_LENGTH ? "..." : "")}
                                </text>
                            </Tooltip>
                        </g>
                    );
                },
            }}
            tooltip={(props) => {
                return <CustomTooltip datum={props}/>;
            }}
            legends={(barKeys.length > 4)? [] : [
                {
                    dataFrom: "keys",
                    anchor: "bottom-right",
                    direction: "row",
                    justify: false,
                    translateY: 20,
                    itemsSpacing: 2,
                    itemWidth: 100,
                    itemHeight: 20,
                    itemDirection: "left-to-right",
                    itemOpacity: 0.85,
                    symbolSize: 20,
                    effects: [
                        {
                            on: "hover",
                            style: {
                                itemOpacity: 1,
                            },
                        },
                    ],
                },
            ]}
        />
    );
}