import React from 'react';
import styled from '@emotion/styled';

interface AlignProps {
  children: any;
  horizontal?: boolean;
  vertical?: boolean;
}

const VerticalCenterWrapper = styled.div`
  display: table;
  height: 100%;
  width: 100%;
`;

const VerticalCenterInner = styled('div')<AlignProps>`
  display: table-cell;
  vertical-align: middle;
  ${(p: AlignProps) => p.horizontal && 'text-align: center;'}
`;

export const Center = styled('div')<AlignProps>`
  height: 100%;
  width: 100%;

  display: flex;
  flex-direction: column;
  ${(props: AlignProps) => props.vertical && 'justify-content: center;'}
  ${(props: AlignProps) => props.horizontal && 'align-items: center;'}
`;

export default class VerticalCenter extends React.Component<AlignProps> {
  render() {
    return (
      <VerticalCenterWrapper>
        <VerticalCenterInner horizontal={this.props.horizontal}>
          {this.props.children}
        </VerticalCenterInner>
      </VerticalCenterWrapper>
    );
  }
}
