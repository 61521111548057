import React from "react";
import styled from "@emotion/styled";
import Layout, {
  BottomWhite,
} from "features/offboarding/components/Layout/Layout";
import { compareIgnoringCase } from "features/offboarding/utils";
import { useTheme } from "emotion-theming";
import { ThemeAttributeProps } from "features/offboarding/theme";

interface OtherReasonScreenProps {
  placeholder?: string;
  onReasonChange(input: string): void;
}

const Controls = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;

  height: 72px;
  width: 100%;

  @media (max-width: 768px) {
    padding-top: 24px;
    justify-content: space-between;
  }
`;

const CancelButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;

  margin-right: 64px;
  width: 6.5rem;

  @media (max-width: 768px) {
    margin-right: 0px;
    margin-left: 3rem;
  }
`;

const CancelText = styled.div`
  font-family: ${(props: any) => props.theme.font};
  font-size: 16px;
  font-weight: 500;
  line-height: 27px;
  color: #080f18;
  margin-left: 0.5rem;
`;

const ReasonWrapper = styled.div`
  width: 70%;
  padding: 3rem 0 1rem;
  margin: auto;
  margin-top: 1rem;
`;

const ReasonText = styled.textarea`
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 24px;
  padding: 1rem;
  width: 100%;
  color: #080f18;
  border: 1px solid
    ${(props: any) =>
      props.theme.primary_color.toUpperCase() === "#FFFFFF"
        ? "#000000"
        : props.theme.primary_color};
  font-family: ${(props: any) => props.theme.font};
  @media (max-width: 768px) {
    padding: 0;
  }
`;

const ButtonWrapper = styled.div`
  width: 6rem;
  @media (max-width: 768px) {
    float: right;
    margin-right: 2rem;
  }
`;

const SubmitButton = styled.div`
  font-family: ${(props: any) => props.theme.font};
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  padding: 0.25rem;
  line-height: 27px;
  text-align: center;
  background: ${(props: any) =>
    compareIgnoringCase(props.theme.primary_color, "#FFFFFF")
      ? "#E5E5E5"
      : props.theme.primary_color};
  color: ${(props: any) => props.theme.font_color};
  border-radius: 6px;

  @media (max-width: 768px) {
    margin: 0;
  }
  :hover {
    background: ${(props: any) => props.theme.secondary_color};
    color: ${(props: any) => props.theme.secondary_font_color};
    cursor: pointer;
  }

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const CancelIconWrapper = styled.div`
  :hover {
    cursor: pointer;
  }
`;

const OnlyMobile = styled.div`
  @media (min-width: 768px) {
    display: none;
  }
`;

const DesktopOnlyButton = styled(ButtonWrapper)`
  margin-top: 24px;
  @media (max-width: 768px) {
    display: none;
  }
`;

const CancelIcon: React.FC<OtherReasonScreenProps> = (
  props: OtherReasonScreenProps
) => {
  return (
    <svg
      onClick={() => props.onReasonChange("_back")}
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="experience-svg"
    >
      <rect opacity="0.2" width="30" height="30" rx="6" fill="#F26B6B" />
      <path
        d="M19.875 18.7C19.9538 18.7782 19.9982 18.8847 19.9982 18.9958C19.9982 19.1069 19.9538 19.2134 19.875 19.2916L19.2916 19.875C19.2134 19.9538 19.1069 19.9982 18.9958 19.9982C18.8847 19.9982 18.7782 19.9538 18.7 19.875L15 16.175L11.3 19.875C11.2217 19.9538 11.1152 19.9982 11.0041 19.9982C10.893 19.9982 10.7865 19.9538 10.7083 19.875L10.125 19.2916C10.0461 19.2134 10.0017 19.1069 10.0017 18.9958C10.0017 18.8847 10.0461 18.7782 10.125 18.7L13.825 15L10.125 11.3C10.0461 11.2217 10.0017 11.1152 10.0017 11.0041C10.0017 10.893 10.0461 10.7865 10.125 10.7083L10.7083 10.125C10.7865 10.0461 10.893 10.0017 11.0041 10.0017C11.1152 10.0017 11.2217 10.0461 11.3 10.125L15 13.825L18.7 10.125C18.7782 10.0461 18.8847 10.0017 18.9958 10.0017C19.1069 10.0017 19.2134 10.0461 19.2916 10.125L19.875 10.7083C19.9538 10.7865 19.9982 10.893 19.9982 11.0041C19.9982 11.1152 19.9538 11.2217 19.875 11.3L16.175 15L19.875 18.7Z"
        fill="#F26B6B"
      />
    </svg>
  );
};

let OtherReasonScreen: React.FC<OtherReasonScreenProps> = (props) => {
  const theme: ThemeAttributeProps = useTheme();
  const title = theme.other_reason_headline;
  const cancelButtonText = theme.go_back_button;
  const SubmitText = theme.submit_button;
  const [reason, setReason] = React.useState("");

  return (
    <Layout show_banner title={title}>
      <BottomWhite className="bottom-content-container">
        <Controls>
          <CancelButtonWrapper>
            <CancelIconWrapper>
              <CancelIcon {...props} />
            </CancelIconWrapper>
            <CancelText>{cancelButtonText}</CancelText>
          </CancelButtonWrapper>
          <OnlyMobile>
            <ButtonWrapper>
              <SubmitButton
                onClick={() => props.onReasonChange(reason)}
                className="other-reason-button"
              >
                {SubmitText}
              </SubmitButton>
            </ButtonWrapper>
          </OnlyMobile>
        </Controls>

        <ReasonWrapper>
          <ReasonText
            rows={6}
            onChange={(e: any) => setReason(e.target.value)}
            value={reason}
            className="other-reason-textarea"
            maxLength={250}
            placeholder={theme.other_reason_placeholder || ""}
          ></ReasonText>
          <DesktopOnlyButton>
            <SubmitButton
              onClick={() => props.onReasonChange(reason)}
              className="other-reason-button"
            >
              {SubmitText}
            </SubmitButton>
          </DesktopOnlyButton>
        </ReasonWrapper>
      </BottomWhite>
    </Layout>
  );
};

export default OtherReasonScreen;
