import { history } from "app/history";
import { useDispatch, useSelector } from "react-redux";
import { generatePath, Route, Router, Switch } from "react-router-dom";

// components
import BuilderSidebarWrapper from "../BuilderSidebarWrapper";
import { MECHANICS_URLS } from "../slice/urls";
import { MechanicsIcons, MechanicsIconOrder } from "../slice/tour";
import ResponseLogicPanel from "./ResponseLogicPanel";
import TreatmentPanel from "./TreatmentPanel";
import PauseMechanics from "./PauseMechanics";

import {
  currentEngineSlugSelector,
  currentStepPathSelector,
  navigateTo,
} from "../slice/wizardSlice";

// Material UI
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Tooltip from "@material-ui/core/Tooltip";
import Button from "@material-ui/core/Button";

//styles
import { makeStyles } from "@material-ui/core/styles";
import commonStyles from "../../../components/layout/commonStyles";
import clsx from "clsx";
import wizardStyles from "../wizardStyles";

const useStyles = makeStyles((theme) => ({
  ...commonStyles(theme),
  ...wizardStyles(theme),
}));

export const MechanicsContainer = () => {
  const classes = useStyles();
  const currentStepPath = useSelector(currentStepPathSelector);
  const engine = useSelector(currentEngineSlugSelector);
  const dispatch = useDispatch();
  return (
    <BuilderSidebarWrapper>
      <Grid
        container
        justifyContent="flex-start"
        className={classes.fullHeight}
      >
        <Grid
          item
          className={clsx(classes.fullHeight, classes.sidebarNavigation)}
        >
          <Grid
            container
            direction="column"
            alignItems="center"
            style={{ paddingTop: "0.5em" }}
          >
            {MechanicsIconOrder.map((name: string) => {
              const icon = MechanicsIcons[name];
              return (
                <Grid item key={icon.alt}>
                  <Button
                    onClick={() =>
                      dispatch(
                        navigateTo(
                          generatePath(icon.target, { engine, slug: ":slug" })
                        )
                      )
                    }
                    className={classes.sidebarNavIcon}
                  >
                    <Tooltip title={icon.title || ""} placement="left" arrow>
                      <img
                        src={
                          currentStepPath === icon.target
                            ? icon.selected
                            : icon.normal
                        }
                        alt={icon.alt}
                      />
                    </Tooltip>
                  </Button>
                </Grid>
              );
            })}
          </Grid>
        </Grid>
        <Grid item className={clsx(classes.fullHeight, classes.flexTrue)}>
          <Paper
            square
            className={clsx(classes.sidebarContent, classes.scrollY)}
          >
            <Router history={history}>
              <Switch>
                <Route
                  exact
                  component={ResponseLogicPanel}
                  path={MECHANICS_URLS.exit_survey_response}
                />
                <Route
                  exact
                  component={PauseMechanics}
                  path={MECHANICS_URLS.pause_mechanics}
                />
                <Route
                  exact
                  component={TreatmentPanel}
                  path={MECHANICS_URLS.treatment_mechanics}
                />
              </Switch>
            </Router>
          </Paper>
        </Grid>
      </Grid>
    </BuilderSidebarWrapper>
  );
};
