// Material UI
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import Typography from "@material-ui/core/Typography";
import Tooltip from "@material-ui/core/Tooltip";

// styles
import styles from "features/dashboard/dashboardStyles";
import { makeStyles } from "@material-ui/core/styles";
import commonStyles from "components/layout/commonStyles";
import IconButton from "@material-ui/core/IconButton";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";

const useStyles = makeStyles((theme) => ({
  ...commonStyles(theme),
  ...styles(theme),
}));

interface OverviewInfoCardProps {
  title: string;
  amount: string;
  tooltip: string;
}

export const OverviewInfoCard: React.FC<OverviewInfoCardProps> = ({
  title,
  amount,
  tooltip,
}) => {
  const classes = useStyles();

  return (
    <Card className={classes.overviewInfoCard}>
      <Grid container direction="column">
        <Grid item xs={12} style={{minHeight: "65px"}}>
          <Typography variant="h5" style={{textAlign: "start"}}>
            {title}

            <Tooltip title={tooltip} placement="top">
              <IconButton aria-label="delete" size="small" style={{paddingTop: 0, paddingBottom: "5px"}}>
                <HelpOutlineIcon style={{fontSize: "18px"}}></HelpOutlineIcon>
              </IconButton>
            </Tooltip>
          </Typography>
        </Grid>

        <Grid item>
          <span className={classes.infoCardText} style={{textAlign: "center"}}>{amount}</span>
        </Grid>
      </Grid>
    </Card>
  );
};

export default OverviewInfoCard;
