import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import { mergeErrors } from "app/forms";

// components
import Loader from "components/loading/Loader";
import { States } from "../../offboarding/containers/StateViewRouter/StateViewRouter";
import PanelHeader from "components/wizard/PanelHeader";
import ContextVariableList from "components/wizard/ContextVariableList";
import FormSectionHeader from "components/wizard/FormSectionHeader";
import Switch from "@material-ui/core/Switch";

import {
  currentEngineSlugSelector,
  openPreviewStep,
  displayDataSelector,
  getEngineStyle,
  engineStyleSelector,
  updateEngineStyle,
  removeMedia,
  updateEngine,
  getEngines,
  wizardEngineSelector,
  staticValuesSelector,
} from "../slice/wizardSlice";

// Material UI
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import FormGroup from "@material-ui/core/FormGroup";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

import IconButton from "@material-ui/core/IconButton";

// icons
import Cancel from "@material-ui/icons/Cancel";

// styles
import { makeStyles } from "@material-ui/core/styles";
import commonStyles from "../../../components/layout/commonStyles";
import wizardStyles from "../wizardStyles";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  ...commonStyles(theme),
  ...wizardStyles(theme),
}));

export default function FinalExitPanel() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const currentEngineSlug = useSelector(currentEngineSlugSelector);
  const engineStylesReady = useSelector(getEngineStyle.isReadySelector);
  const updateEngineStyleErrors = useSelector(
    updateEngineStyle.ExtraErrorsSelector
  );
  const isFetchingUpdateStyle = useSelector(
    updateEngineStyle.isFetchingSelector
  );

  const engine = useSelector(wizardEngineSelector);
  const isFetchingUpdateEngine = useSelector(updateEngine.isFetchingSelector);
  const engineReady = useSelector(getEngines.isReadySelector);

  const staticValues = useSelector(staticValuesSelector);
  const style = useSelector(engineStyleSelector);
  const inPreview = useSelector(displayDataSelector).showPreviewStep;
  const inLostPreview = inPreview && inPreview.step === States.LOST;
  const inSavedPreview = inPreview && inPreview.step === States.SAVED;

  const exitImageRef = useRef<HTMLInputElement | null>(null);
  const [exitImage, setExitImage] = useState<File>();

  const savedImageRef = useRef<HTMLInputElement | null>(null);
  const [savedImage, setSavedImage] = useState<File>();

  const [savedImageType, setSavedImageType] = useState("");
  const [lostImageType, setLostImageType] = useState("");

  const {
    handleSubmit,
    control,
    formState: { errors: frontendErrors },
  } = useForm();

  const onSubmit = handleSubmit((data) => {
    dispatch(
      updateEngineStyle.Action({
        slug: currentEngineSlug,
        ...data,
        show_customer_lost_image: lostImageType,
        show_customer_saved_image: savedImageType,

        ...(exitImage?.name
          ? { customer_lost_image: lostImageType === "CUSTOM" ? exitImage : "" }
          : undefined),

        ...(savedImage?.name
          ? {
              customer_saved_image:
                savedImageType === "CUSTOM" ? savedImage : "",
            }
          : undefined),
      })
    );
  });

  const errors = mergeErrors(frontendErrors, updateEngineStyleErrors);

  useEffect(() => {
    if (currentEngineSlug) {
      dispatch(getEngineStyle.Action(currentEngineSlug));
      dispatch(
        openPreviewStep({
          step: States.LOST,
        })
      );
    }
  }, [dispatch, currentEngineSlug]);

  useEffect(() => {
    if (engineStylesReady) {
      setLostImageType(style.show_customer_lost_image);
      setSavedImageType(style.show_customer_saved_image);
    }
  }, [
    engineStylesReady,
    style.show_customer_lost_image,
    style.show_customer_saved_image,
  ]);

  if (!engineStylesReady) {
    return <Loader reason="Fetching final exit data..." padding />;
  }

  return (
    <div className={classes.mdPadding}>
      <PanelHeader title="Final Exit Screen" />
      <Divider className={classes.headingDivider} />

      <form onSubmit={onSubmit}>
        <FormGroup>
          <FormSectionHeader
            title="Lost a Customer"
            showPreview
            headerInPreview={inLostPreview}
            previewStep={States.LOST}
          />

          <ContextVariableList
            contextVariables={style.context_vars}
            textType="FINAL EXIT DESCRITPION"
          />

          <Controller
            control={control}
            name="customer_lost_headline"
            rules={{ required: true }}
            defaultValue={style.customer_lost_headline}
            render={({ field }) => (
              <TextField
                {...field}
                InputLabelProps={{ shrink: true }}
                label="FINAL EXIT HEADLINE"
                variant="outlined"
                error={Boolean(errors.get("customer_lost_headline"))}
                helperText={errors.get("customer_lost_headline")}
              />
            )}
          />

          <Controller
            control={control}
            name="customer_lost_description"
            rules={{ required: true }}
            defaultValue={style.customer_lost_description}
            render={({ field }) => (
              <TextField
                {...field}
                InputLabelProps={{ shrink: true }}
                label="FINAL EXIT DESCRIPTION"
                variant="outlined"
                error={Boolean(errors.get("customer_lost_description"))}
                helperText={errors.get("customer_lost_description")}
              />
            )}
          />

          <Controller
            control={control}
            name="customer_lost_button_text"
            defaultValue={style.customer_lost_button_text}
            render={({ field }) => (
              <TextField
                {...field}
                InputLabelProps={{ shrink: true }}
                label="FINAL EXIT BUTTON TEXT"
                variant="outlined"
                error={Boolean(errors.get("customer_lost_button_text"))}
                helperText={errors.get("customer_lost_button_text")}
              />
            )}
          />

          <FormControl variant="outlined">
            <InputLabel id="lost_image_label">LOST IMAGE</InputLabel>

            <Select
              labelId="lost_image_label"
              label="LOST IMAGE"
              value={lostImageType}
              onChange={(e) => {
                setLostImageType(e.target.value as string);
              }}
              className={classes.gray}
            >
              <MenuItem value="DEFAULT">Default</MenuItem>
              <MenuItem value="CUSTOM">Custom</MenuItem>
              <MenuItem value="NO_IMAGE">No Image</MenuItem>
            </Select>
          </FormControl>

          {lostImageType === "CUSTOM" ? (
            <Grid container alignItems="center" spacing={2}>
              {style?.customer_lost_image ? (
                <Grid item xs={2} xl={1}>
                  <IconButton
                    onClick={() => {
                      setExitImage(undefined);
                      dispatch(
                        removeMedia.Action({
                          slug: currentEngineSlug,
                          media_type: "customer_lost_image",
                        })
                      );
                    }}
                  >
                    <Cancel />
                  </IconButton>
                </Grid>
              ) : null}
              <Grid item xs={10} lg={5}>
                <TextField
                  value={
                    exitImage?.name ||
                    style.customer_lost_image ||
                    "no image selected"
                  }
                  variant="outlined"
                  size="small"
                  disabled
                />
              </Grid>

              <Grid item xs={12} lg={5}>
                <input
                  accept={
                    staticValues
                      ? staticValues.allowed_image_types.join(",")
                      : "image/*"
                  }
                  style={{ display: "none" }}
                  type="file"
                  ref={exitImageRef}
                  onChange={(e) => {
                    if (
                      staticValues &&
                      e.target?.files &&
                      e.target?.files[0].size > staticValues.max_image_size
                    )
                      return alert(
                        `Max file size is ${
                          staticValues.max_image_size / 1024 / 1024
                        }MB`
                      );
                    setExitImage(
                      e.target.files ? e.target?.files[0] : undefined
                    );
                  }}
                />
                <Button
                  className={classes.panelButton}
                  onClick={() =>
                    exitImageRef.current && exitImageRef.current.click()
                  }
                >
                  Upload Image
                </Button>
              </Grid>
            </Grid>
          ) : null}
        </FormGroup>
        <Button
          type="submit"
          className={clsx(classes.panelButton, classes.marginTop)}
          disabled={isFetchingUpdateStyle}
        >
          Save Changes
        </Button>

        <Divider className={classes.headingDivider} />

        <FormGroup>
          <FormSectionHeader
            title="Lost a Prepaid Customer"
            showPreview
            headerInPreview={inLostPreview}
            previewStep={States.LOST}
            extraPreviewProps={{ is_prepaid: true }}
          />
          <Controller
            control={control}
            name="customer_lost_description_for_prepaid"
            rules={{ required: true }}
            defaultValue={style.customer_lost_description_for_prepaid}
            render={({ field }) => (
              <TextField
                {...field}
                InputLabelProps={{ shrink: true }}
                label="FINAL EXIT DESCRIPTION FOR PREPAIDS"
                variant="outlined"
                error={Boolean(
                  errors.get("customer_lost_description_for_prepaid")
                )}
                helperText={errors.get("customer_lost_description_for_prepaid")}
              />
            )}
          />
        </FormGroup>
        <Button
          type="submit"
          className={clsx(classes.panelButton, classes.marginTop)}
          disabled={isFetchingUpdateStyle}
        >
          Save Changes
        </Button>

        <Divider className={classes.headingDivider} />

        <FormGroup>
          <FormSectionHeader
            title="Saved a Customer"
            showPreview
            headerInPreview={inSavedPreview}
            previewStep={States.SAVED}
          />

          <Controller
            control={control}
            name="back_home_button"
            defaultValue={style.back_home_button}
            render={({ field }) => (
              <TextField
                {...field}
                InputLabelProps={{ shrink: true }}
                label="BACK TO HOME BUTTON"
                variant="outlined"
                error={Boolean(errors.get("back_home_button"))}
                helperText={errors.get("back_home_button")}
              />
            )}
          />

          <FormControl variant="outlined">
            <InputLabel id="saved_image_label">SAVED IMAGE</InputLabel>

            <Select
              labelId="saved_image_label"
              label="SAVED IMAGE"
              value={savedImageType}
              onChange={(e) => {
                setSavedImageType(e.target.value as string);
              }}
              className={classes.gray}
            >
              <MenuItem value="DEFAULT">Default</MenuItem>
              <MenuItem value="CUSTOM">Custom</MenuItem>
              <MenuItem value="NO_IMAGE">No Image</MenuItem>
            </Select>
          </FormControl>

          {savedImageType === "CUSTOM" ? (
            <Grid container alignItems="center" spacing={2}>
              {style?.customer_saved_image ? (
                <Grid item xs={2} xl={1}>
                  <IconButton
                    onClick={() => {
                      setSavedImage(undefined);
                      dispatch(
                        removeMedia.Action({
                          slug: currentEngineSlug,
                          media_type: "customer_saved_image",
                        })
                      );
                    }}
                  >
                    <Cancel />
                  </IconButton>
                </Grid>
              ) : null}

              <Grid item xs={10} lg={5}>
                <TextField
                  value={
                    savedImage?.name ||
                    style.customer_saved_image ||
                    "no image selected"
                  }
                  variant="outlined"
                  size="small"
                  disabled
                />
              </Grid>
              <Grid item xs={12} lg={5}>
                <input
                  accept={
                    staticValues
                      ? staticValues.allowed_image_types.join(",")
                      : "image/*"
                  }
                  style={{ display: "none" }}
                  type="file"
                  ref={savedImageRef}
                  onChange={(e) => {
                    if (
                      staticValues &&
                      e.target?.files &&
                      e.target?.files[0].size > staticValues.max_image_size
                    )
                      return alert(
                        `Max file size is ${
                          staticValues.max_image_size / 1024 / 1024
                        }MB`
                      );
                    setSavedImage(
                      e.target.files ? e.target?.files[0] : undefined
                    );
                  }}
                />
                <Button
                  className={classes.panelButton}
                  onClick={() =>
                    savedImageRef.current && savedImageRef.current.click()
                  }
                >
                  Upload Image
                </Button>
              </Grid>
            </Grid>
          ) : null}
        </FormGroup>
        <Button
          type="submit"
          className={clsx(classes.panelButton, classes.marginTop)}
          disabled={isFetchingUpdateStyle}
        >
          Save Changes
        </Button>
      </form>

      <Divider className={classes.headingDivider} />

      <FormSectionHeader
        title="Contact Support For Cancel"
        showPreview
        headerInPreview={inLostPreview}
        previewStep={States.LOST}
      />
      <Typography variant="body2" className={classes.gray}>
        Toggling this to active will force customers to cancel by contacting
        support at the end of the experience. RetentionEngine will not take the
        steps to cancel the account in your payment integration.
      </Typography>
      <Switch
        checked={engine.contact_support_for_cancel}
        disabled={!engineReady && isFetchingUpdateEngine}
        onChange={() =>
          dispatch(
            updateEngine.Action({
              contact_support_for_cancel: !engine.contact_support_for_cancel,
              slug: engine.slug,
            })
          )
        }
        name="contact_support_for_cancel"
        inputProps={{ "aria-label": "contact support for cancel" }}
      />
      {engine.contact_support_for_cancel ? (
        <form onSubmit={onSubmit} className={classes.marginBottomMd}>
          <Typography variant="body2" className={classes.gray}>
            The fields below will be displayed to the customer at the end of the
            experience. Enter the text you want them to see so that they may
            contact support.
          </Typography>
          <FormGroup>
            <Controller
              control={control}
              name="contact_support_for_cancel_heading"
              rules={{ required: true }}
              defaultValue={style.contact_support_for_cancel_heading}
              render={({ field }) => (
                <TextField
                  {...field}
                  InputLabelProps={{ shrink: true }}
                  label="CONTACT SUPPORT HEADING"
                  variant="outlined"
                  error={Boolean(
                    errors.get("contact_support_for_cancel_heading")
                  )}
                  helperText={errors.get("contact_support_for_cancel_heading")}
                />
              )}
            />
            <Controller
              control={control}
              name="contact_support_for_cancel_description"
              rules={{ required: true }}
              defaultValue={style.contact_support_for_cancel_description}
              render={({ field }) => (
                <TextField
                  {...field}
                  InputLabelProps={{ shrink: true }}
                  label="CONTACT SUPPORT DESCRIPTION"
                  variant="outlined"
                  error={Boolean(
                    errors.get("contact_support_for_cancel_description")
                  )}
                  helperText={errors.get(
                    "contact_support_for_cancel_description"
                  )}
                />
              )}
            />
          </FormGroup>
          <Button
            type="submit"
            className={clsx(classes.panelButton, classes.marginTop)}
            disabled={isFetchingUpdateStyle}
          >
            Save Changes
          </Button>
        </form>
      ) : null}
    </div>
  );
}
