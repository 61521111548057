import { Theme } from "@material-ui/core";

export default function wizardStyles(theme: Theme) {
  return {
    wizardMain: {
      overflow: "hidden" as "hidden",
    },
    builderView: {
      width: "100%",
      height: "100%",
    },
    sidebarWrapper: {
      borderLeft: "2px solid #E8E8E8",
      boxShadow: "none",
      background: "#FCFCFC",
      position: "relative" as "relative",
    },
    sidebarContent: {
      position: "absolute" as "absolute",
      top: 0,
      left: 52,
      right: 0,
      bottom: 0,
      paddingBottom: 100,
    },
    scrollY: {
      overflowY: "scroll" as "scroll",
      scrollbarWidth: "none" as "none",
      "&::-webkit-scrollbar": {
        display: "none" as "none",
      },
    },
    sidebarNavigation: {
      width: "52px",
      // borderLeft: "2px solid #E8E8E8",
      position: "relative" as "relative",
      background: "#FCFCFC",
      top: 0,
      right: 0,
      bottom: 0,
    },
    sidebarNavIcon: {
      padding: "5 0",
      minWidth: 0,
    },
    headingDivider: {
      margin: "1em -2em",
    },
    stepRow: {
      position: "relative" as "relative",
    },
    stepRowTitle: {
      float: "left" as "left",
    },
    treeWrapper: {
      width: "28em",
    },
    bigTreeChildWrapper: {
      maxHeight: "35em",
      paddingRight: "0em",
      overflowY: "scroll" as "scroll",
      "&::-webkit-scrollbar": {
        width: 24,
      },
      "&::-webkit-scrollbar-track": {
        backgroundColor: "transparent",
      },
      "&::-webkit-scrollbar-thumb": {
        background:
          "-webkit-linear-gradient(#341241, #4F2260, #6D3781, #9D68B0)",
        border: "8px solid transparent",
        backgroundClip: "content-box" as "content-box",
        borderRadius: 4,
      },
    },
    mainTreeCard: {
      padding: "0.5em 0.6em",
      color: "#F4F4F4",
      background: "#4e408a",
      border: "1px solid rgba(255, 255, 255, 0.26)",
      boxSizing: "border-box" as "border-box",
      boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.2)",
      borderRadius: "5px",
      position: "relative" as "relative",
      overflow: "visible",
      width: "80%",
      margin: "0 auto",
      "&:hover": {
        cursor: "pointer" as "pointer",
      },
    },
    mainTreeCardSelected: {
      boxShadow: "0 0 0.5em 0.5em #EAC43588",
    },
    mainTreeCardDisabled: {
      background: "#aaaa",
    },
    expandOptions: {
      position: "absolute" as "absolute",
      bottom: "-10px",
      left: "46%",
    },
    secondarySmall: {},
    secondaryFontWrapper: { minWidth: 200 },
    hoverPointer: {
      "&:hover": {
        cursor: "pointer" as "pointer",
      },
    },
    mainCardItem: {
      height: 64,
    },
    secondaryTreeCard: {
      background: "#FBFBFB",
      borderRadius: "10px",
      padding: "0 0.5em",
      margin: "0.5em 0",
      boxShadow: "none",
      minHeight: 40,
    },
    selectedOptionCard: {
      border: "2px solid #8E509F",
    },
    emptyTreeCard: {
      background: "#FBFBFB",
      border: "2px solid rgba(142, 80, 159, 0.6)",
      boxSizing: "border-box" as "border-box",
      boxShadow: "0px 2px 2px rgba(184, 88, 220, 0.2)",
      borderRadius: "5px",
      padding: "0 0.5em",
    },
    optionTextField: {
      fontSize: "14px",
      margin: 0,
      padding: "4px 0",
      zIndex: 1,
    },
    numberCard: {
      background: "rgba(0, 0, 0, 0.1)",
      borderRadius: "5px",
      boxShadow: "none",
      padding: "0 1em",
    },
    whiteButton: {
      backgroundColor: "white",
      boxShadow: "none",
    },
    dragComponent: {
      borderRadius: "10px",
      padding: "10px",
      background: "linear-gradient(90deg, #D1D1D1 0%, #EAEAEA 100%)",
      margin: "1em 0 0",
    },
    dropzoneOverlay: {
      position: "absolute" as "absolute",
      height: "100%",
      width: "100%",
      zIndex: 1000,
      top: 0,
      left: 0,
      background: "rgba(0,0,0,0.4)",
      pointerEvents: "none" as "none",
    },
    formSection: {
      margin: "2em 0",
    },
    basicDialog: {
      minWidth: "max(400px, 50vw)",
    },
    panelButton: {
      background: "rgba(235, 235, 253, 0.5)",
      border: "1px solid #8E509F",
      boxSizing: "border-box" as "border-box",
      borderRadius: "5px",
      boxShadow: "none",
      color: "#8E509F",
    },
    activePanelCard: {
      background: "#F4F4F4",
      bordeRadius: "10px",
    },
    blueButton: {
      background: theme.palette.secondary.main,
      color: "#FBFBFB",
      border: "3px solid rgba(70, 68, 164, 0.3)",
      borderRadius: "5px",
      marginRight: 5,
      float: "right" as "right",
      "&:hover": {
        background: theme.palette.primary.main,
      },
    },
    nextButton: {
      background: "#000",
      border: "3px solid rgba(70, 68, 164, 0.9)",
      borderRadius: "5px",
      color: "#FBFBFB",
    },
    swatch: {
      padding: "5px",
      background: "#fff",
      borderRadius: "1px",
      boxShadow: "0 0 0 1px rgba(0,0,0,.1)",
      display: "inline-block" as "inline-block",
      cursor: "pointer" as "pointer",
    },
    popover: {
      position: "absolute" as "absolute",
      zIndex: 2,
    },
    cover: {
      position: "fixed" as "fixed",
      top: "0px",
      right: "0px",
      bottom: "0px",
      left: "0px",
    },
    colorCard: {
      padding: "0 0 0 0.5em",
      background: "#F4F4F4",
    },
    contextChip: {
      color: "#8E509F",
      fontSize: "0.7rem",
      borderColor: "#341241",
      margin: "0.2rem",
    },
  };
}
