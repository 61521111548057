import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { generatePath } from "react-router-dom";
import { history } from "app/history";
import { formatDateString } from "../../app/utils";
import moment from "moment";

// Material UI
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import Dialog from "@material-ui/core/Dialog";
import TextField from "@material-ui/core/TextField";

// components
import { PrimarySelect } from "./input/SelectBoxes";
import { DASHBOARD_URLS } from "features/dashboard/urls";

import {
  currentEngineSlugSelector,
  dashboardEndDateSelector,
  dashboardStartDateSelector,
  displayDataSelector,
  exitSurveyResponsesSelector,
  getDashboardStatistics,
  setDashboardFilterResponses,
  setDashboardDates,
} from "features/wizard/slice/wizardSlice";

// images
import Filter from "./img/filterGraphic.svg";
import Calendar from "./img/calendarGraphic.svg";

// styles
import { makeStyles } from "@material-ui/core/styles";
import styles from "features/dashboard/dashboardStyles";
import headerStyles from "components/header/headerStyles";
import commonStyles from "components/layout/commonStyles";
import { Tooltip } from "@material-ui/core";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  ...styles(theme),
  ...headerStyles(theme),
  ...commonStyles(theme),
}));

interface DateOption {
  label: string;
  start: string;
  end?: string;
}

export const DashboardNavBar = () => {
  const classes = useStyles();
  const engine = useSelector(currentEngineSlugSelector);
  const dispatch = useDispatch();
  const allResponses = useSelector(exitSurveyResponsesSelector);
  const activeResponseSlugs = Object.keys(allResponses);
  const activeResponseIds = activeResponseSlugs.map(
    (key) => allResponses[key].id
  );
  const start_date = useSelector(dashboardStartDateSelector);
  const end_date = useSelector(dashboardEndDateSelector);
  const [customDates, setCustomDates] = useState({
    start_date,
    end_date,
  });
  const [statDates, setStatDates] = useState({ ...customDates });
  const reason_ids = useSelector(
    (state) => displayDataSelector(state).dashboardResponsesFilter
  );
  const [customModal, setCustomModal] = useState(false);
  const [currentView, setCurrentView] = useState("");
  const fetchingStats = useSelector(getDashboardStatistics.isFetchingSelector);

  const datesFormatted = `${start_date} to ${end_date}`;

  const dateOptions = {
    "1_month": {
      label: "Past Month",
      start: formatDateString(moment().subtract("1", "month")),
    },
    "3_month": {
      label: "Past 3 Months",
      start: formatDateString(moment().subtract("3", "months")),
    },
    year: {
      label: "Past year",
      start: formatDateString(moment().subtract("1", "year")),
    },
    wtd: {
      label: "Week To Date",
      start: formatDateString(moment().startOf("week")),
    },
    mtd: {
      label: "Month To Date",
      start: formatDateString(moment().startOf("month")),
    },
    ytd: {
      label: "Year To Date",
      start: formatDateString(moment().startOf("year")),
    },
  } as { [key: string]: DateOption };

  const dashboardViews = {
    overview: {
      name: "Overview",
      url: DASHBOARD_URLS.overview,
      pathname: "overview",
    },
    exit_survey: {
      name: "Exit Survey",
      url: DASHBOARD_URLS.exit_survey,
      pathname: "exit-survey",
    },
    experiences: {
      name: "Experiences",
      url: DASHBOARD_URLS.experiences,
      pathname: "experiences",
    },
    risk_forecasts: {
      name: "Risk Forecasts",
      url: DASHBOARD_URLS.risk_forecasts,
      pathname: "risk-forecasts",
    },
  } as { [key: string]: { name: string; url: string; pathname: string } };

  const dateSelect = (key: string) => {
    if (key === "current") {
      // Do nothing, didn't change selection
      return;
    } else if (key === "custom") {
      setCustomModal(true);
      setCustomDates({ ...statDates });
      return;
    }
    const chosen = dateOptions[key];
    if (chosen !== undefined) {
      if (chosen.start) {
        setStatDates({ ...statDates, start_date: chosen.start });
      }
    }
  };

  useEffect(() => {
    const historyPath = history.location.pathname;
    if (
      currentView &&
      !historyPath.includes(dashboardViews[currentView].pathname)
    ) {
      history.push(
        generatePath(dashboardViews[currentView].url, {
          engine: engine,
        })
      );
      return;
    }

    Object.keys(dashboardViews).forEach((keys) => {
      if (
        historyPath.includes(dashboardViews[keys].pathname) &&
        currentView !== keys
      ) {
        setCurrentView(keys);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentView, history.location.pathname, engine]);

  useEffect(() => {
    const reasons_exist = activeResponseIds.length > 0;
    const chosen_reasons_are_valid =
      !reasons_exist ||
      (reason_ids &&
        reason_ids.filter((id: string) =>
          activeResponseIds.includes(parseInt(id))
        ).length > 0);

    const no_reasons_selected = reasons_exist && !reason_ids?.length;
    if (no_reasons_selected || !chosen_reasons_are_valid) {
      const selectAllReasons = activeResponseIds.map((id: number) =>
        id.toString()
      );
      dispatch(setDashboardFilterResponses(selectAllReasons));
    }
  }, [reason_ids, activeResponseIds, dispatch]);

  useEffect(() => {
    dispatch(
      setDashboardDates({
        start: statDates.start_date,
        end: statDates.end_date,
      })
    );
  }, [statDates]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Grid
      container
      spacing={2}
      alignItems="center"
      justifyContent="space-between"
    >
      <Grid item>
        <Grid container spacing={2} alignItems="center">
          <Grid item>
            <Typography variant="h4" className={classes.dashboardNavHeader}>
              Dashboard
            </Typography>
          </Grid>
          <Grid item>
            <Select
              variant="outlined"
              value={currentView}
              input={<PrimarySelect />}
            >
              {Object.keys(dashboardViews).map((key) => (
                <MenuItem
                  value={key}
                  key={key}
                  onClick={() => {
                    setCurrentView(key);
                  }}
                >
                  <Grid
                    container
                    alignItems="center"
                    justifyContent="flex-start"
                  >
                    <Grid item>
                      <Typography
                        variant="body1"
                        className={classes.navBarMenuItemText}
                      >
                        {dashboardViews[key].name}
                      </Typography>
                    </Grid>
                  </Grid>
                </MenuItem>
              ))}
            </Select>
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <Grid container spacing={2} alignItems="center">
          <Grid item>
            <Select value="all" variant="outlined" input={<PrimarySelect />}>
              <MenuItem value="all" disabled>
                <Grid container alignItems="center" justifyContent="flex-start">
                  <Grid item>
                    <img src={Filter} alt="dashboard" />
                  </Grid>
                  <Tooltip title={"Responses Filtering"}>
                    <Grid item>
                      <Typography
                        variant="body1"
                        className={classes.navBarMenuItemText}
                      >
                        {activeResponseIds.length === reason_ids.length
                          ? "All"
                          : reason_ids.length}{" "}
                      </Typography>
                    </Grid>
                  </Tooltip>
                </Grid>
              </MenuItem>
              {activeResponseSlugs.map((slug) => (
                <MenuItem value={allResponses[slug].reason_text} key={slug}>
                  <Grid
                    container
                    alignItems="center"
                    justifyContent="flex-start"
                  >
                    <Grid item>
                      <Checkbox
                        checked={reason_ids.includes(
                          allResponses[slug].id.toString()
                        )}
                        onChange={(e) => {
                          if (e.target.checked)
                            dispatch(
                              setDashboardFilterResponses(
                                reason_ids.concat(
                                  allResponses[slug].id.toString()
                                )
                              )
                            );
                          else
                            dispatch(
                              setDashboardFilterResponses(
                                reason_ids.filter(
                                  (elem: number) =>
                                    elem !== allResponses[slug].id.toString()
                                )
                              )
                            );
                        }}
                      />
                    </Grid>
                    <Grid item>
                      <Typography
                        variant="body1"
                        className={classes.navBarMenuItemText}
                      >
                        {allResponses[slug].reason_text}
                      </Typography>
                    </Grid>
                  </Grid>
                </MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid item>
            <Select
              value="current"
              variant="outlined"
              input={<PrimarySelect />}
              onChange={(e) => dateSelect(e.target.value as string)}
            >
              <MenuItem value="current">
                <Grid container alignItems="center" justifyContent="flex-start">
                  <Grid item>
                    <img src={Calendar} alt="dashboard" />
                  </Grid>
                  <Grid item>
                    <Typography
                      variant="body1"
                      className={classes.navBarMenuItemText}
                    >
                      {datesFormatted}
                    </Typography>
                  </Grid>
                </Grid>
              </MenuItem>
              {Object.keys(dateOptions).map((key) => (
                <MenuItem value={key} key={key}>
                  {dateOptions[key].label}
                </MenuItem>
              ))}
              <MenuItem value={"custom"}>Custom Date Range</MenuItem>
            </Select>
            <Dialog open={customModal} onClose={() => setCustomModal(false)}>
              <TextField
                id="startDate"
                label="Start Date"
                type="date"
                variant="outlined"
                value={moment(customDates.start_date).format("YYYY-MM-DD")}
                onChange={(e) =>
                  setCustomDates({
                    start_date: formatDateString(moment(e.target.value)),
                    end_date: customDates.end_date,
                  })
                }
                InputLabelProps={{
                  shrink: true,
                }}
                className={classes.dialogTextfield}
              />
              <TextField
                id="endDate"
                label="End Date"
                type="date"
                variant="outlined"
                value={moment(customDates.end_date).format("YYYY-MM-DD")}
                onChange={(e) =>
                  setCustomDates({
                    start_date: customDates.start_date,
                    end_date: formatDateString(moment(e.target.value)),
                  })
                }
                InputLabelProps={{
                  shrink: true,
                }}
                className={classes.dialogTextfield}
              />
              <Button
                className={clsx(classes.marginTop)}
                onClick={() => {
                  setStatDates({ ...customDates });
                }}
                disabled={fetchingStats}
                variant="contained"
                color="primary"
              >
                {fetchingStats ? "Fetching" : "Get Data"}
              </Button>
            </Dialog>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default DashboardNavBar;
