import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

// Material UI
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";

//components
import DashboardNavBar from "components/dashboard/DashboardNavBar";
import ExperiencesTable from "components/dashboard/ExperiencesTable";
import Loader from "components/loading/Loader";

import {
  dashboardFiltersSelector,
  wizardEngineSelector,
  getDashboardExperiencesData,
  dashboardExperiencesSelector,
} from "features/wizard/slice/wizardSlice";

// images
import DownloadGraphic from "./img/downloadGraphic.svg";

// styles
import styles from "./dashboardStyles";
import { makeStyles } from "@material-ui/core/styles";
import commonStyles from "components/layout/commonStyles";
import { ThemeProvider } from "@material-ui/core";
import { darkTheme, lightTheme } from "MUITheme";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  ...commonStyles(theme),
  ...styles(theme),
}));

export default function ExperiencesDash() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const isLoadingExperiences = useSelector(
    getDashboardExperiencesData.isFetchingSelector
  );
  const experiencesObj = useSelector(dashboardExperiencesSelector);
  const experiences = experiencesObj.experiences || [];

  const { start_date, end_date, reason_ids, engine } = useSelector(
    dashboardFiltersSelector
  );

  useEffect(() => {
    dispatch(
      getDashboardExperiencesData.Action({
        engine,
        reason_ids,
        start_date,
        end_date,
      })
    );
  }, [dispatch, engine, start_date, end_date, reason_ids]);

  const currentEngine = useSelector(wizardEngineSelector);

  const DownloadURL = `/offboard/${engine}/download-experiences/?start_date=${start_date}&end_date=${end_date}&reason_ids=${reason_ids
    .filter((id: number | string) => id !== "other")
    .join("&reason_ids=")}`;

  return (
    <div style={{ margin: "0 3em" }}>
      <ThemeProvider theme={darkTheme}>
        <DashboardNavBar />
      </ThemeProvider>
      <ThemeProvider theme={lightTheme}>
        <Grid
          container
          alignItems="center"
          justifyContent="space-between"
          style={{ paddingTop: "2em" }}
        >
          <Grid item>
            <Typography variant="h5" className={clsx(classes.gray)}>
              Experience Outcomes
            </Typography>
            <Typography
              variant="body1"
              className={clsx(
                classes.gray,
                classes.zeroMargin,
                classes.alignLeft
              )}
            >
              {experiences.length} Total
            </Typography>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              onClick={() => window.open(DownloadURL)}
            >
              <Grid container alignItems="center">
                <Grid item>
                  <img src={DownloadGraphic} alt="download" />
                </Grid>
                <Grid item>
                  <Typography
                    variant="body1"
                    className={classes.navBarMenuItemText}
                  >
                    Download .CSV
                  </Typography>
                </Grid>
              </Grid>
            </Button>
          </Grid>
        </Grid>
        <Card className={classes.barChartCard}>
          {isLoadingExperiences ? (
            <Loader reason="Retrieving experiences..." />
          ) : (
            <ExperiencesTable
              data={experiences}
              currentEngine={currentEngine}
            />
          )}
        </Card>
      </ThemeProvider>
    </div>
  );
}
