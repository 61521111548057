import { Action, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-hot-toast";

function getCookie(name: string) {
  let cookieValue = null;
  if (document.cookie && document.cookie !== "") {
    const cookies = document.cookie.split(";");
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();
      // Does this cookie string begin with the name we want?
      if (cookie.substring(0, name.length + 1) === name + "=") {
        cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
        break;
      }
    }
  }
  return cookieValue;
}

export async function apiFetch(
  method: string,
  endpoint: string,
  body: any,
  customConfig = {},
  customHeaders = {}
) {
  const csrftoken = getCookie("csrftoken") || "";
  const headers = {
    "X-CSRFToken": csrftoken,
  };

  const config = {
    method,
    mode: "same-origin" as "same-origin",
    ...customConfig,
    headers: {
      ...headers,
      ...customHeaders,
    },
    body: body || "",
  };

  if (method === "GET") config.body = null;

  let http;
  try {
    return await window.fetch(endpoint, config).then((http) => {
      if (http.ok) {
        return { http, ok: true, error: null };
      }
      return { http, ok: false, error: "An unknown error occurred" };
    });
  } catch (err) {
    return Promise.reject({
      error: (err.message ? err.message : null) || "An unknown error occurred",
      ok: false,
      http,
    });
  }
}

export async function handleJSONreply(http: Response, ok: Boolean, error: any) {
  let data;
  try {
    data = await http.json();
  } catch (e) {
    data = null;
  }
  return {
    http,
    ok,
    error,
    data,
  };
}

export async function apiFetchJSON(
  method: string,
  endpoint: string,
  body: any,
  customConfig = {},
  customHeaders = {}
) {
  const { http, ok, error } = await apiFetch(
    method,
    endpoint,
    body,
    customConfig,
    {
      ...customHeaders,
      "Content-Type": "application/json",
    }
  );
  return await handleJSONreply(http, ok, error);
}

export const get = async function (
  endpoint: string,
  customConfig = {},
  customHeaders = {}
) {
  return await apiFetchJSON("GET", endpoint, null, customConfig, customHeaders);
};

export const post = async function (
  endpoint: string,
  body = {},
  customConfig = {},
  customHeaders = {}
) {
  return await apiFetchJSON(
    "POST",
    endpoint,
    JSON.stringify(body),
    customConfig,
    customHeaders
  );
};

export const multipart_post = async function (
  endpoint: string,
  fields: { [key: string]: any },
  customConfig = {},
  customHeaders = {}
) {
  const formData = new FormData();

  Object.keys(fields).map((name) => formData.append(name, fields[name]));

  const { http, ok, error } = await apiFetch(
    "POST",
    endpoint,
    formData,
    customConfig,
    customHeaders
  );

  return handleJSONreply(http, ok, error);
};

export const multipart_patch = async function (
  endpoint: string,
  fields: { [key: string]: any },
  customConfig = {},
  customHeaders = {}
) {
  const formData = new FormData();

  Object.keys(fields).map((name) => formData.append(name, fields[name]));

  const { http, ok, error } = await apiFetch(
    "PATCH",
    endpoint,
    formData,
    customConfig,
    customHeaders
  );

  return handleJSONreply(http, ok, error);
};

export const patch = async function (
  endpoint: string,
  body = {},
  customConfig = {},
  customHeaders = {}
) {
  return await apiFetchJSON(
    "PATCH",
    endpoint,
    JSON.stringify(body),
    customConfig,
    customHeaders
  );
};

export const rest_delete = async function (
  endpoint: string,
  customConfig = {},
  customHeaders = {}
) {
  return await apiFetchJSON(
    "DELETE",
    endpoint,
    null,
    customConfig,
    customHeaders
  );
};

export const client = {
  get,
  post,
  multipart_post,
  multipart_patch,
  patch,
  rest_delete,
};

export default client;
export type APIStatus = "NONE" | "FETCHING" | "SUCCESS" | "ERROR";

export interface APICall {
  status: APIStatus;
  reply: any;
  input?: any;
  error?: string;
  extra_errors: { [key: string]: string[] };
}

export type APICallMap = { [key: string]: APICall };

export function createAPICallThunk(
  namespace: string,
  name: string,
  getReply: (input: any) => Promise<{ http: Response; data: any }>,
  onFulfilled?: (state: any, call: APICall) => void,
  onRejected?: (state: any, call: APICall) => void,
  successCodes: number[] = [201, 200, 204, 205],
  toastOnError: boolean = true
) {
  /* Creates an AsyncThunk and some helpers to automatically handle fetch-compatible Responses */
  const FetchInputActionName = `${namespace}/${name}/apiCallInputData`;
  const ResetActionName = `${namespace}/${name}/reset`;
  const Action = createAsyncThunk(
    `${namespace}/${name}`,
    async (input: any, thunkAPI) => {
      thunkAPI.dispatch({
        type: FetchInputActionName,
        payload: input,
      } as Action);
      try {
        const { http, data: reply } = await getReply(input);
        const output = {
          status: http.status,
          statusText: http.statusText,
          reply,
          input,
        };
        if (successCodes.includes(http.status)) {
          return output;
        } else {
          return thunkAPI.rejectWithValue(output);
        }
      } catch (e) {
        return thunkAPI.rejectWithValue({ error: e.reason, input });
      }
    }
  );
  const Selector = (state: any) => state[namespace].apiCalls[name];
  const ReplySelector = (state: any) => Selector(state).reply;
  const ErrorSelector = (state: any) => Selector(state).error;
  const ExtraErrorsSelector = (state: any) => Selector(state).extra_errors;
  const StatusSelector = (state: any) => Selector(state).status;
  const isRequestedSelector = (state: any) => StatusSelector(state) !== "NONE";
  const isReadySelector = (state: any) =>
    ReplySelector(state) || StatusSelector(state) === "SUCCESS";
  const isErrorSelector = (state: any) => StatusSelector(state) === "ERROR";

  const isFetchingSelector = (state: any) =>
    StatusSelector(state) === "FETCHING";

  const Reducers = {
    [ResetActionName]: (state: any) => {
      state.apiCalls[name].input = null;
      state.apiCalls[name].error = null;
      state.apiCalls[name].extra_errors = null;
      state.apiCalls[name].reply = null;
      state.apiCalls[name].status = "NONE";
    },
    [FetchInputActionName]: (state: any, action: any) => {
      state.apiCalls[name].input = action?.payload;
    },
    [Action.pending as any]: (state: any) => {
      state.apiCalls[name].status = "FETCHING";
    },
    [Action.rejected as any]: (state: any, action: any) => {
      state.apiCalls[name].status = "ERROR";
      state.apiCalls[name].input = action?.payload?.input;
      console.error("API Error occurred in action", name, action);
      if (typeof action?.payload?.reply === "string") {
        state.apiCalls[name].error = action.payload.reply;
      } else if (typeof action?.payload?.reply?.errors === "string") {
        state.apiCalls[name].error = action.payload.errors;
      } else if (typeof action?.payload?.reply?.errors?.error === "string") {
        state.apiCalls[name].error = action.payload.errors.error;
      } else if (typeof action?.payload?.reply === "object") {
        const errors = action.payload.reply;
        state.apiCalls[name].extra_errors = errors;
        if (errors && Object.keys(errors).length !== 0) {
          const next_field_with_error = Object.keys(errors)[0];
          let next_error = errors[next_field_with_error];
          if (Array.isArray(next_error)) next_error = next_error[0];
          state.apiCalls[name].error = `${next_field_with_error
            .replace("non_field_errors", "error")
            .replaceAll("_", " ")
            .toUpperCase()}: ${next_error}` as string;
        }
        if (!state.apiCalls[name].error)
          state.apiCalls[name].error =
            "An unknown error occurred" +
            (action?.payload?.statusText
              ? ` (${action.payload.statusText})`
              : "");
      }
      if (toastOnError)
        toast.error(state.apiCalls[name].error || "An unknown error occurred");
      if (onRejected) onRejected(state, state.apiCalls[name]);
    },
    [Action.fulfilled as any]: (state: any, action: any) => {
      state.apiCalls[name].status = "SUCCESS";
      state.apiCalls[name].input = action?.payload?.input;
      state.apiCalls[name].reply = action?.payload?.reply;
      state.apiCalls[name].error = null;
      state.apiCalls[name].extra_errors = null;
      if (onFulfilled) onFulfilled(state, state.apiCalls[name]);
    },
  };

  const Reset = () => ({
    type: ResetActionName,
  });

  return {
    Action,
    Reset,
    Reducers,
    State: {
      [name]: { status: "NONE" } as APICall,
    },
    Selector,
    StatusSelector,
    ReplySelector,
    ErrorSelector,
    ExtraErrorsSelector,
    isReadySelector,
    isRequestedSelector,
    isFetchingSelector,
    isErrorSelector,
  };
}
