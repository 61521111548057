import React from 'react';
import styled from '@emotion/styled';
import AcceptOfferButton from '../AcceptOfferButton/index';

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  text-align: center;
  max-width: 50%;
  @media (max-width: 676px) {
    width: 100%;
  }

  @media (max-height: 620px) {
    margin-bottom: 1rem;
  }

  a {
    text-decoration: none;
    color: #000000;
  }
`;

const ContactWrapper = styled.div`
  display: flex;
  align-items: left;

  font-size: 20px;
  font-family: ${(props: any) => props.theme.font};
  word-break: break-word;

  margin-top: 1rem;
  margin-bottom: 1rem;
  width: fit-content;

  flex-wrap: wrap;

  @media (max-width: 676px) {
    width: 100%;
    justify-content: center;
  }
`;

const TextWrapper = styled.div`
  text-align: center;
  word-wrap: break-word;
  word-break: break-all;
  text-align-last: left;
  @media (max-width: 676px) {
    text-align-last: unset;
    max-width: 90%;
  }
`;

interface BaseContactButtonProps {
  link?: string;
}

// eslint-disable-next-line
const BaseContactButton: React.FC<BaseContactButtonProps> = props => {
  return (
    <ButtonWrapper>
      {props.link ? (
        <a href={props.link} rel="noopener noreferrer" target="_blank">
          {props.children}
        </a>
      ) : (
        props.children
      )}
    </ButtonWrapper>
  );
};
/* eslint-enable no-unused-vars */

interface SpecificContactButtonProps {
  contactValue: string;
  buttonText: string;
}

const PhoneContactButton: React.FC<SpecificContactButtonProps> = props => {
  return (
    <>
      <ContactWrapper>
        Phone:&nbsp;<TextWrapper> {props.contactValue}</TextWrapper>
      </ContactWrapper>
    </>
  );
};

const MailContactButton: React.FC<SpecificContactButtonProps> = props => {
  return (
    <ContactWrapper>
      <a
        href={`mailto:${props.contactValue}`}
        style={{ textDecoration: 'none' }}
        target="_blank"
        rel="noopener noreferrer"
      >
        <AcceptOfferButton buttonText={props.buttonText} />
      </a>
    </ContactWrapper>
  );
};

export { PhoneContactButton, MailContactButton };
