import { FC, useEffect, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { mergeErrors } from "app/forms";
import { useParams } from "react-router-dom";

// components
import Loader from "../../../components/loading/Loader";
import PanelHeader from "components/wizard/PanelHeader";
import { States } from "features/offboarding/containers/StateViewRouter/StateViewRouter";

import {
  availableTreatmentsSelector,
  closePreview,
  createIntermediate,
  currentEngineSlugSelector,
  displayDataSelector,
  engineStyleSelector,
  livePreviewTreatment,
  openPreviewStep,
  updateTreatment,
  wizardEngineSelector,
  staticValuesSelector,
} from "features/wizard/slice/wizardSlice";

import {
  UpsertIntermediateFields,
  IntermediatePreviewBase,
  EngineCustomerPaymentPlan,
  EnginePredefinedCoupon,
  IntermediateTypes as TreatmentType,
} from "features/wizard/slice/types";

// images
import OpenPreview from "../img/previewLeftArrowGray.svg";
import ClosePreview from "../img/previewRightArrowPurple.svg";

// Material UI
import FormGroup from "@material-ui/core/FormGroup";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputBase from "@material-ui/core/InputBase";
import InputAdornment from "@material-ui/core/InputAdornment";
import Divider from "@material-ui/core/Divider";
import Tooltip from "@material-ui/core/Tooltip";

// styles
import { makeStyles } from "@material-ui/core/styles";
import commonStyles from "components/layout/commonStyles";
import clsx from "clsx";
import wizardStyles from "features/wizard/wizardStyles";
import ContextVariableList from "components/wizard/ContextVariableList";

const useStyles = makeStyles((theme) => ({
  ...commonStyles(theme),
  ...wizardStyles(theme),
}));

const SWAP_HELP_LINK =
  "https://docs.bellwethr.com/docs/recharge-product-swap-cancel-resolution";

type FormTreatmentType = Exclude<TreatmentType, "PAUSE">;

export const IntermediateTypes: {
  [key in FormTreatmentType]: string;
} = {
  PERCENT_DISCOUNT: "Percent Discount",
  AMOUNT_DISCOUNT: "Amount Discount",
  GIFT: "Gift",
  RELEVANT_INFORMATION: "Relevant Information",
  CONTACT_SUPPORT: "Contact Support",
  COUPON: "Coupon",
  SWAP_PRODUCT: "Swap Product",
  PAYMENT_PLAN: "Switch Plan",
  PREDEF_COUPON: "Coupon",
  FREQUENCY_CHANGE: "Frequency Change",
  SERVICE_CREDITS: "Service Credits",
  MULTIPLE_PRODUCTS_SWAP_OPTIONS: "Multiple Products Swap Options",
  AUTOMATED_GIFT: "Automated Gift",
};

interface TreatmentFormParams {
  engine: string;
  slug?: string;
  duplicate?: string;
}

export const TreatmentFormPanel: FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const currentEngineSlug = useSelector(currentEngineSlugSelector);
  const params = useParams<TreatmentFormParams>();
  const existing = useSelector((state) =>
    params.slug !== undefined
      ? availableTreatmentsSelector(state)[params.slug]
      : null
  );
  const duplicate = useSelector((state) =>
    params.duplicate !== undefined
      ? availableTreatmentsSelector(state)[params.duplicate]
      : null
  );

  const staticValues = useSelector(staticValuesSelector);
  const styling = useSelector(engineStyleSelector);
  const engine = useSelector(wizardEngineSelector);
  const availableTypes = engine.allowed_intermediates as FormTreatmentType[];
  const selectableTypes = (
    existing ? staticValues.all_intermediate_types : availableTypes
  ).filter((key: string) => key !== "PAUSE") as FormTreatmentType[];
  const [chosenType, setChosenType] = useState(availableTypes[0]);
  const [removeDuplicateFileUrlOnSubmit, setRemoveDuplicateFileUrlOnSubmit] =
    useState(false);
  const [image, setImage] = useState<File | null>(null);
  const [video, setVideo] = useState<File | null>(null);
  const [orderIntervalUnit, setOrderIntervalUnit] = useState("MONTHS");
  const imageRef = useRef<HTMLInputElement | null>(null);
  const videoRef = useRef<HTMLInputElement | null>(null);
  const firstUpdate = useRef(true);
  const formContainerRef = useRef<HTMLDivElement | null>(null);
  const [localPreviewData, setLocalPreviewData] =
    useState<IntermediatePreviewBase>({} as IntermediatePreviewBase);

  const inPreview = useSelector(displayDataSelector).showPreviewStep;
  const inThisPreview =
    inPreview && inPreview.treatment
      ? inPreview.treatment.slug === params.slug
      : false;

  const [offerPreviewStepState, setOfferPreviewStepState] = useState<
    States.OFFER | States.MULTI_PRODUCT_SWAP_EXISTING
  >(States.OFFER);

  const inThisOfferPreview =
    inThisPreview &&
    [States.OFFER, States.MULTI_PRODUCT_SWAP_EXISTING].includes(inPreview.step);
  const inThisSavedPreview = inThisPreview && inPreview.step === States.SAVED;
  const inSecondScreenPreview =
    inThisPreview && inPreview.step === States.MULTI_PRODUCT_SWAP_NEW;

  const showAppliedOfferCount =
    engine.allows_continuous_offer &&
    (chosenType === "AMOUNT_DISCOUNT" || chosenType === "PERCENT_DISCOUNT");

  const createIntermediateErrors = useSelector(
    createIntermediate.ExtraErrorsSelector
  );

  const isFetchingUpdateTreatment = useSelector(
    updateTreatment.isFetchingSelector
  );
  const isCreatingNewTreatment = useSelector(
    createIntermediate.isFetchingSelector
  );

  const hasAPIErrors = useSelector(
    (state) =>
      updateTreatment.isErrorSelector(state) ||
      createIntermediate.isErrorSelector(state)
  );

  const {
    handleSubmit,
    control,
    formState: { errors: frontendErrors },
    setValue,
    reset,
    watch,
  } = useForm();

  useEffect(() => {
    const subscription = watch((data) => {
      let currentVideo = video;
      let currentImage = image;

      if (firstUpdate.current) {
        const loadDetails = existing || duplicate;
        currentImage = loadDetails ? loadDetails.image : currentImage;
        currentVideo = loadDetails ? loadDetails.video : currentVideo;
        if (existing) firstUpdate.current = false;
      }

      setLocalPreviewData((d) => {
        const newPreview = { ...d, ...data };
        if (currentImage) {
          newPreview.image =
            typeof currentImage === "string"
              ? currentImage
              : URL.createObjectURL(currentImage);
          if (!firstUpdate.current) setRemoveDuplicateFileUrlOnSubmit(true);
        }
        if (currentVideo) {
          newPreview.video =
            typeof currentVideo === "string"
              ? currentVideo
              : URL.createObjectURL(currentVideo);
          if (!firstUpdate.current) setRemoveDuplicateFileUrlOnSubmit(true);
        }
        return newPreview;
      });

      if (firstUpdate.current) firstUpdate.current = false;
    });
    return () => subscription.unsubscribe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch, image, video]);

  useEffect(() => {
    // We use localPreviewData as a state variable to ensure we don't dispatch livePreviewTreatment
    // every time watch() is updated -- sometimes watch() gets called 15+ times PER KEYSTROKE!
    dispatch(livePreviewTreatment(localPreviewData));
  }, [localPreviewData, dispatch]);

  useEffect(() => {
    // Used to close preview and switch out between different preview screens
    return function () {
      dispatch(closePreview());
    };
  }, [dispatch]);

  useEffect(() => {
    const loadDetails = existing || duplicate;
    if (loadDetails) {
      reset({
        // Required fields - fill from existing
        button_text: loadDetails.button_text,
        display_title: loadDetails.display_title,
        headline: loadDetails.headline,
        name: existing ? loadDetails.name : `Copy of ${loadDetails.name}`,
        offer_claimed_button_text: loadDetails.offer_claimed_button_text,
        offer_claimed_heading: loadDetails.offer_claimed_heading,

        // Optional fields - fill from existing
        apply_offer_count: loadDetails.apply_offer_count || undefined,
        discount_percent: loadDetails.discount_percent || undefined,
        price: loadDetails.price || undefined,
        description: loadDetails.description || "",
        contact_email: loadDetails.contact_email || "",
        url: loadDetails.url || "",
        swap_product_id: loadDetails.swap_product_id || "",
        coupon: loadDetails.coupon || "",
        order_interval_value: loadDetails.order_interval_value || undefined,
        secondary_button_text: loadDetails.secondary_button_text || "",
        secondary_display_title: loadDetails.secondary_display_title || "",
      });

      if (loadDetails.order_interval_unit)
        setOrderIntervalUnit(loadDetails.order_interval_unit as string);

      const chosenTreatmentType = existing
        ? loadDetails.type
        : availableTypes[0];
      setChosenType(chosenTreatmentType);
      handleOfferPreviewStepStateChange(chosenTreatmentType);
      if (!loadDetails.button_text) setValue("button_text", "Accept Offer");
    } else {
      const emptyForm = {
        name: "",
        display_title: "",
        description: "",
        discount_percent: "",
        price: "",
        url: "",
        contact_email: "",
        image: "",
        video: "",
        coupon: "",
        headline: "Wait! Before you go, we think you'd like a...",
        button_text: "Accept Offer",
        apply_offer_count: 1,
        offer_claimed_heading: "Success",
        offer_claimed_button_text: "Back to Home",
        order_interval_value: "",
        swap_product_id: "",
        type: availableTypes[0],
        secondary_button_text: "",
        secondary_display_title: "",
      };
      reset(emptyForm);
      dispatch(livePreviewTreatment(emptyForm as IntermediatePreviewBase));
      setChosenType(emptyForm.type);
    }
    dispatch(
      openPreviewStep({
        step: offerPreviewStepState,
        treatment: existing || localPreviewData,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [existing, duplicate, dispatch, reset, setValue, availableTypes]);

  const errors = mergeErrors(frontendErrors, createIntermediateErrors);

  useEffect(() => {
    if (hasAPIErrors) onFormError(null, null);
  }, [hasAPIErrors]);

  const onFormError = (errors: any, e: any) => {
    formContainerRef?.current?.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "nearest",
    });
  };

  const onSubmit = handleSubmit((data) => {
    if (!data.discount_percent) delete data.discount_percent;
    if (!data.url) delete data.url;
    if (typeof data.image === "string" || typeof data.video === "string") {
      /* data.image came from a duplicate, it's a s3 URL - delete it from the File field */
      delete data.image;
      delete data.video;
    }

    const upsertData = {
      ...data,
      offboard_slug: currentEngineSlug,
      type: chosenType,
      ...(image && typeof image !== "string" ? { image } : null),
      ...(video && typeof video !== "string" ? { video } : null),
      /* If there's no other selected image/video, and a duplicate image exists */
      /* send it as a string in image_url field */
      ...(!image &&
      !video &&
      typeof duplicate?.image === "string" &&
      !removeDuplicateFileUrlOnSubmit
        ? { image_url: duplicate.image }
        : undefined),

      ...(!video &&
      !image &&
      typeof duplicate?.video === "string" &&
      !removeDuplicateFileUrlOnSubmit
        ? { video_url: duplicate.video }
        : undefined),
    } as UpsertIntermediateFields;

    if (upsertData.image === null) delete upsertData.image;
    if (upsertData.video === null) delete upsertData.video;
    if (!["AMOUNT_DISCOUNT", "SERVICE_CREDITS"].includes(upsertData.type))
      delete upsertData.price;
    if (upsertData.type !== "PERCENT_DISCOUNT")
      delete upsertData.discount_percent;
    if (upsertData.type !== "COUPON" && upsertData.type !== "PREDEF_COUPON")
      delete upsertData.coupon;
    if (!["SWAP_PRODUCT", "AUTOMATED_GIFT"].includes(upsertData.type))
      delete upsertData.swap_product_id;
    if (upsertData.type === "FREQUENCY_CHANGE")
      upsertData.order_interval_unit = orderIntervalUnit;
    if (upsertData.type !== "FREQUENCY_CHANGE")
      delete upsertData.order_interval_value;

    if (existing) {
      upsertData.slug = upsertData.slug || existing.slug;
      dispatch(updateTreatment.Action(upsertData));
    } else {
      dispatch(createIntermediate.Action(upsertData));
    }
  }, onFormError);

  const handleChangeTreatmentType = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    const treatmentType = event.target.value as FormTreatmentType;
    setChosenType(treatmentType);
    handleOfferPreviewStepStateChange(treatmentType);
  };

  const handleOfferPreviewStepStateChange = (treatmentType: string) => {
    if (treatmentType === "MULTIPLE_PRODUCTS_SWAP_OPTIONS")
      return setOfferPreviewStepState(States.MULTI_PRODUCT_SWAP_EXISTING);
    setOfferPreviewStepState(States.OFFER);
  };

  const handleChangeFile = (
    e: React.ChangeEvent<HTMLInputElement>,
    isVideo: boolean = false
  ) => {
    const file = e.target.files ? e.target?.files[0] : null;
    setImage(null);
    setVideo(null);
    let newPreview = { ...localPreviewData };
    if (isVideo) {
      newPreview = {
        ...newPreview,
        video: file ? URL.createObjectURL(file) : undefined,
        image: undefined,
      };
      setVideo(file);
    } else {
      newPreview = {
        ...newPreview,
        image: file ? URL.createObjectURL(file) : undefined,
        video: undefined,
      };
      setImage(file);
    }

    setLocalPreviewData(newPreview);
    dispatch(livePreviewTreatment(newPreview));
  };

  const handleChangeTreatmentIntervalUnit = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    setOrderIntervalUnit(event.target.value as string);
  };

  useEffect(() => {
    dispatch(
      openPreviewStep({
        step: offerPreviewStepState,
        treatment: existing || localPreviewData,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offerPreviewStepState]);

  if (params.slug && !existing) {
    return <Loader padding reason="Fetching treatment details..." />;
  }

  return (
    <div className={classes.mdPadding} ref={formContainerRef}>
      <PanelHeader
        title="Treatment Form"
        onClick={() =>
          dispatch(
            inThisOfferPreview
              ? closePreview()
              : openPreviewStep({
                  step: offerPreviewStepState,
                  treatment: existing || localPreviewData,
                })
          )
        }
        headerInPreview={inThisOfferPreview}
      />

      <form onSubmit={onSubmit}>
        <Typography variant="h6" className={classes.panelSubheader}>
          Treatment Type
        </Typography>
        <FormControl>
          <Select
            id="treatment-type"
            value={chosenType}
            onChange={handleChangeTreatmentType}
            variant="outlined"
            disabled={Boolean(existing)}
          >
            {selectableTypes.map((type: FormTreatmentType) => (
              <MenuItem value={type} key={type}>
                {IntermediateTypes[type]}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Typography variant="h6" className={classes.panelSubheader}>
          Treatment Internal Name*
        </Typography>
        <Typography variant="body2" className={classes.gray}>
          The internal name used to identify this treatment.
        </Typography>
        <FormGroup>
          <Controller
            control={control}
            name="name"
            rules={{ required: true }}
            render={({ field }) => (
              <TextField
                {...field}
                InputLabelProps={{ shrink: true }}
                label="255 CHARACTERS MAX"
                variant="outlined"
                placeholder="10% Discount"
                error={Boolean(errors.get("name"))}
                helperText={errors.get("name")}
              />
            )}
          />
        </FormGroup>

        {chosenType !== "MULTIPLE_PRODUCTS_SWAP_OPTIONS" ? (
          <>
            <Typography variant="h6" className={classes.panelSubheader}>
              Treatment Headline
            </Typography>
            <ContextVariableList contextVariables={styling.context_vars} />
            <Typography variant="body2" className={classes.gray}>
              Personalize the greeting for this cancel screen.
            </Typography>
            <FormGroup>
              <Controller
                control={control}
                name="headline"
                rules={{ required: Boolean(!existing) }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    InputLabelProps={{ shrink: true }}
                    label="500 CHARACTERS MAX"
                    variant="outlined"
                    disabled={Boolean(existing)}
                    error={Boolean(errors.get("headline"))}
                    helperText={errors.get("headline")}
                  />
                )}
              />
            </FormGroup>
          </>
        ) : null}

        <Typography variant="h6" className={classes.panelSubheader}>
          Display Title*
        </Typography>
        <FormGroup>
          <Controller
            control={control}
            name="display_title"
            rules={{ required: Boolean(!existing) }}
            render={({ field }) => (
              <TextField
                {...field}
                InputLabelProps={{ shrink: true }}
                label="500 CHARACTERS MAX"
                variant="outlined"
                placeholder="10% discount"
                disabled={Boolean(existing)}
                error={Boolean(errors.get("display_title"))}
                helperText={errors.get("display_title")}
              />
            )}
          />
        </FormGroup>

        {chosenType !== "MULTIPLE_PRODUCTS_SWAP_OPTIONS" ? (
          <>
            <Typography variant="h6" className={classes.panelSubheader}>
              Treatment Description
            </Typography>
            <ContextVariableList contextVariables={styling.context_vars} />
            <FormGroup>
              <Controller
                control={control}
                name="description"
                render={({ field }) => (
                  <TextField
                    {...field}
                    InputLabelProps={{ shrink: true }}
                    label="500 CHARACTERS MAX"
                    variant="outlined"
                    disabled={Boolean(existing)}
                    placeholder="We'll take 10% off your next bill."
                    error={Boolean(errors.get("description"))}
                    helperText={errors.get("description")}
                  />
                )}
              />
            </FormGroup>
          </>
        ) : null}

        {chosenType === "PERCENT_DISCOUNT" ? (
          <div>
            <Typography variant="h6" className={classes.panelSubheader}>
              Treatment Value*
            </Typography>
            <Grid container alignItems="center" spacing={2}>
              <Grid item xs={3}>
                <FormGroup>
                  <Controller
                    control={control}
                    rules={{ required: Boolean(!existing) }}
                    name="discount_percent"
                    render={({ field }) => (
                      <InputBase
                        {...field}
                        placeholder="10"
                        disabled={Boolean(existing)}
                        className={classes.inputBorder}
                        error={Boolean(errors.get("discount_percent"))}
                      />
                    )}
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="body1">% Discount</Typography>
              </Grid>
              {Boolean(errors.get("discount_percent")) ? (
                <Typography variant="caption" className={classes.errorMessage}>
                  {errors.get("discount_percent")}
                </Typography>
              ) : null}
            </Grid>
          </div>
        ) : null}

        {["AMOUNT_DISCOUNT", "SERVICE_CREDITS"].includes(chosenType) ? (
          <div>
            <Typography variant="h6" className={classes.panelSubheader}>
              Treatment Value*
            </Typography>
            <Grid container alignItems="center" spacing={2}>
              <Grid item xs={4}>
                <FormGroup>
                  <Controller
                    control={control}
                    rules={{ required: Boolean(!existing) }}
                    name="price"
                    render={({ field }) => (
                      <InputBase
                        {...field}
                        placeholder="10"
                        className={classes.inputBorder}
                        disabled={Boolean(existing)}
                        error={Boolean(errors.get("price"))}
                        startAdornment={
                          <InputAdornment position="start">$</InputAdornment>
                        }
                      />
                    )}
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="body1">Off</Typography>
              </Grid>
            </Grid>
          </div>
        ) : null}

        {chosenType === "COUPON" ? (
          <div>
            <Typography variant="h6" className={classes.panelSubheader}>
              Coupon ID*
            </Typography>
            {engine.payment_engine === "CRATEJOY" ? (
              // TODO: add this
              <Typography variant="body2">
                You can find the Coupon ID in the URL of your CrateJoy coupon.
                <br />
                <a
                  href={
                    "https://docs.bellwethr.com/docs/cancel-resolutions-cratejoy-coupons"
                  }
                  rel="noreferrer"
                  target="_blank"
                  className={clsx(classes.purpleTextLink, classes.bold)}
                >
                  Need Help?
                </a>
              </Typography>
            ) : null}
            <Grid container alignItems="center" spacing={2}>
              <Grid item>
                <FormGroup>
                  <Controller
                    control={control}
                    rules={{ required: Boolean(!existing) }}
                    name="coupon"
                    render={({ field }) => (
                      <InputBase
                        {...field}
                        placeholder="YOUR_DISCOUNT_CODE"
                        disabled={Boolean(existing)}
                        className={classes.inputBorder}
                        error={Boolean(errors.get("coupon"))}
                      />
                    )}
                  />
                </FormGroup>
              </Grid>
              {Boolean(errors.get("coupon")) ? (
                <Typography variant="caption" className={classes.errorMessage}>
                  {errors.get("coupon")}
                </Typography>
              ) : null}
            </Grid>
          </div>
        ) : null}

        {chosenType === "PREDEF_COUPON" ? (
          <div>
            <Typography variant="h6" className={classes.panelSubheader}>
              Coupon*
            </Typography>
            <Grid container alignItems="center" spacing={2}>
              <Grid item>
                <FormGroup>
                  <Controller
                    control={control}
                    rules={{ required: Boolean(!existing) }}
                    name="coupon"
                    render={({ field }) => (
                      <Select
                        {...field}
                        label="Coupon"
                        variant="outlined"
                        disabled={Boolean(existing)}
                      >
                        {engine.predefined_coupons?.map(
                          (coupon: EnginePredefinedCoupon) => (
                            <MenuItem value={coupon.id}>{coupon.name}</MenuItem>
                          )
                        )}
                      </Select>
                    )}
                  />
                </FormGroup>
              </Grid>
              {Boolean(errors.get("coupon")) ? (
                <Typography variant="caption" className={classes.errorMessage}>
                  {errors.get("coupon")}
                </Typography>
              ) : null}
            </Grid>
          </div>
        ) : null}

        {["SWAP_PRODUCT", "AUTOMATED_GIFT"].includes(chosenType) ? (
          <div>
            <Typography variant="h6" className={classes.panelSubheader}>
              Shopify Product Variant ID*
            </Typography>
            {chosenType === "SWAP_PRODUCT" ? (
              <Typography variant="body2">
                Where can I find the Shopify Product Variant ID?{" "}
                <a
                  href={SWAP_HELP_LINK}
                  target="_blank"
                  rel="noreferrer"
                  className={clsx(classes.purpleTextLink, classes.bold)}
                >
                  Click Here
                </a>
              </Typography>
            ) : null}
            <Grid container alignItems="center" spacing={2}>
              <Grid item>
                <FormGroup>
                  <Controller
                    control={control}
                    rules={{ required: Boolean(!existing) }}
                    name="swap_product_id"
                    render={({ field }) => (
                      <InputBase
                        {...field}
                        placeholder="36808451532287"
                        disabled={Boolean(existing)}
                        className={classes.inputBorder}
                        error={Boolean(errors.get("swap_product_id"))}
                      />
                    )}
                  />
                </FormGroup>
                {chosenType === "SWAP_PRODUCT" ? (
                  <Typography variant="body2">
                    This treatment will not be shown to customers that are
                    currently subscribed to the product selected.
                  </Typography>
                ) : null}
              </Grid>
              {Boolean(errors.get("swap_product_id")) ? (
                <Typography variant="caption" className={classes.errorMessage}>
                  {errors.get("swap_product_id")}
                </Typography>
              ) : null}
            </Grid>
          </div>
        ) : null}

        {chosenType === "PAYMENT_PLAN" ? (
          <div>
            <Typography variant="h6" className={classes.panelSubheader}>
              Select a Payment Plan
            </Typography>
            <Typography variant="body2">
              This treatment will not be shown to customers that are currently
              on the selected plan
            </Typography>
            <Grid container alignItems="center" spacing={2}>
              <Grid item>
                <FormGroup>
                  <Controller
                    control={control}
                    rules={{ required: Boolean(!existing) }}
                    name="payment_plan"
                    defaultValue={
                      existing?.payment_plan || engine.payment_plans?.[0]?.id
                    }
                    render={({ field, fieldState, formState }) => (
                      <Select
                        {...field}
                        label="Plan"
                        variant="outlined"
                        disabled={Boolean(existing)}
                      >
                        {engine.payment_plans.map(
                          (plan: EngineCustomerPaymentPlan) => (
                            <MenuItem value={plan.id}>
                              {plan.name} - {plan.amount} {plan.currency} /{" "}
                              {plan.interval_count} {plan.interval_unit}
                            </MenuItem>
                          )
                        )}
                      </Select>
                    )}
                  />
                </FormGroup>
              </Grid>
              {Boolean(errors.get("payment_plan")) ? (
                <Typography variant="caption" className={classes.errorMessage}>
                  {errors.get("payment_plan")}
                </Typography>
              ) : null}
            </Grid>
          </div>
        ) : null}

        {chosenType === "FREQUENCY_CHANGE" ? (
          <div>
            <Typography variant="h6" className={classes.panelSubheader}>
              Subscription Period *
            </Typography>
            <Typography variant="body2" className={classes.gray}>
              *applies to both the billing and delivery periods/intervals.
            </Typography>
            <Grid container alignItems="center" spacing={2}>
              <Grid item xs={4}>
                <FormGroup>
                  <Controller
                    control={control}
                    rules={{ required: Boolean(!existing) }}
                    name="order_interval_value"
                    render={({ field }) => (
                      <InputBase
                        {...field}
                        placeholder="1"
                        className={classes.inputBorder}
                        disabled={Boolean(existing)}
                        error={Boolean(errors.get("order_interval_value"))}
                      />
                    )}
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={4}>
                <FormGroup>
                  <FormControl>
                    <Select
                      id="order_interval_units"
                      value={orderIntervalUnit}
                      onChange={handleChangeTreatmentIntervalUnit}
                      variant="outlined"
                      disabled={Boolean(existing)}
                    >
                      <MenuItem value={"MONTHS"}>MONTH(S)</MenuItem>
                      <MenuItem value={"WEEKS"}>WEEK(S)</MenuItem>
                      <MenuItem value={"DAYS"}>DAY(S)</MenuItem>
                    </Select>
                  </FormControl>
                </FormGroup>
              </Grid>
            </Grid>
          </div>
        ) : null}

        <Typography variant="h6" className={classes.panelSubheader}>
          Button Text*
        </Typography>
        <FormGroup>
          <Controller
            control={control}
            name="button_text"
            rules={{ required: Boolean(!existing) }}
            render={({ field }) => (
              <TextField
                {...field}
                InputLabelProps={{ shrink: true }}
                label="100 CHARACTERS MAX"
                variant="outlined"
                disabled={Boolean(existing)}
                error={Boolean(errors.get("button_text"))}
                helperText={errors.get("button_text")}
              />
            )}
          />
        </FormGroup>

        {chosenType === "RELEVANT_INFORMATION" ? (
          <div>
            <Typography variant="h6" className={classes.panelSubheader}>
              Button Link
            </Typography>
            <ContextVariableList
              contextVariables={styling.context_vars}
              textType="URL"
              isUrl
            />
            <FormGroup>
              <Controller
                control={control}
                name="url"
                render={({ field }) => (
                  <TextField
                    {...field}
                    InputLabelProps={{ shrink: true }}
                    label="255 CHARACTERS MAX"
                    variant="outlined"
                    placeholder="https://www.companyname.com/more-info"
                    disabled={Boolean(existing)}
                    error={Boolean(errors.get("url"))}
                    helperText={errors.get("url")}
                  />
                )}
              />
            </FormGroup>
          </div>
        ) : null}

        {chosenType === "CONTACT_SUPPORT" ? (
          <div>
            <Typography variant="h6" className={classes.panelSubheader}>
              Contact Support Email Address*
            </Typography>
            <FormGroup>
              <Controller
                control={control}
                name="contact_email"
                render={({ field }) => (
                  <TextField
                    {...field}
                    InputLabelProps={{ shrink: true }}
                    label="320 CHARACTERS MAX"
                    variant="outlined"
                    placeholder="name@company.com"
                    error={Boolean(errors.get("contact_email"))}
                    helperText={errors.get("contact_email")}
                  />
                )}
              />
            </FormGroup>
          </div>
        ) : null}

        {showAppliedOfferCount ? (
          <>
            <Typography variant="h6" className={classes.panelSubheader}>
              How many times can this discount be applied?
            </Typography>
            <Typography variant="body2" className={classes.gray}>
              This coupon can be applied to a subscription
            </Typography>
            <Grid container alignItems="center" spacing={2}>
              <Grid item xs={2}>
                <FormGroup>
                  <Controller
                    control={control}
                    name="apply_offer_count"
                    render={({ field }) => (
                      <InputBase
                        {...field}
                        className={classes.inputBorder}
                        type="number"
                        disabled={Boolean(existing)}
                        error={Boolean(errors.get("apply_offer_count"))}
                      />
                    )}
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={2}>
                <Typography variant="body1">time(s)</Typography>
              </Grid>
            </Grid>
            <Typography variant="body2" className={classes.gray}>
              Make this field value zero if the discount should be applied
              infinite times.
            </Typography>
          </>
        ) : null}

        {chosenType !== "MULTIPLE_PRODUCTS_SWAP_OPTIONS" ? (
          <>
            <Typography variant="h6" className={classes.panelSubheader}>
              Treatment Image
            </Typography>

            <Grid container alignItems="center" spacing={2}>
              <Grid item>
                <input
                  accept={
                    staticValues
                      ? staticValues.allowed_image_types.join(",")
                      : "image/*"
                  }
                  style={{ display: "none" }}
                  type="file"
                  ref={imageRef}
                  disabled={Boolean(existing)}
                  onChange={(e) => {
                    if (
                      staticValues &&
                      e.target?.files &&
                      e.target?.files[0].size > staticValues.max_image_size
                    )
                      return alert(
                        `Max file size is ${
                          staticValues.max_image_size / 1024 / 1024
                        }MB`
                      );
                    handleChangeFile(e);
                  }}
                />
                <Button
                  className={classes.panelButton}
                  onClick={() => imageRef.current && imageRef.current.click()}
                  disabled={Boolean(existing)}
                >
                  Choose File
                </Button>
              </Grid>
              <Grid item>
                <TextField
                  value={
                    existing
                      ? "Can't change active Treatment image"
                      : image?.name || "no image selected"
                  }
                  variant="outlined"
                  size="small"
                  disabled
                />
              </Grid>
            </Grid>

            <Typography variant="h6" className={classes.panelSubheader}>
              Treatment Video
            </Typography>

            <Grid container alignItems="center" spacing={2}>
              <Grid item>
                <input
                  accept={
                    staticValues
                      ? staticValues.allowed_video_types.join(",")
                      : "image/*"
                  }
                  style={{ display: "none" }}
                  type="file"
                  ref={videoRef}
                  disabled={Boolean(existing)}
                  onChange={(e) => {
                    if (
                      staticValues &&
                      e.target?.files &&
                      e.target?.files[0].size > staticValues.max_video_size
                    )
                      return alert(
                        `Max file size is ${
                          staticValues.max_video_size / 1024 / 1024
                        }MB`
                      );
                    handleChangeFile(e, true);
                  }}
                />
                <Button
                  className={classes.panelButton}
                  onClick={() => videoRef.current && videoRef.current.click()}
                  disabled={Boolean(existing)}
                >
                  Choose File
                </Button>
              </Grid>
              <Grid item>
                <TextField
                  value={
                    existing
                      ? "Can't change active Treatment video"
                      : video?.name || "no video selected"
                  }
                  variant="outlined"
                  size="small"
                  disabled
                />
              </Grid>
            </Grid>
          </>
        ) : null}

        {chosenType === "MULTIPLE_PRODUCTS_SWAP_OPTIONS" ? (
          <>
            <Divider className={classes.headingDivider} />
            <Grid container alignItems="center" justifyContent="space-between">
              <Grid item>
                <Typography variant="h5" className={classes.panelHeader}>
                  New Swap Products Screen
                </Typography>
              </Grid>
              <Grid item>
                <Tooltip
                  title={inThisSavedPreview ? "Close Preview" : "Open Preview"}
                  placement="left"
                  arrow
                >
                  <img
                    src={inSecondScreenPreview ? ClosePreview : OpenPreview}
                    alt="toggle_preview"
                    onClick={() =>
                      dispatch(
                        inSecondScreenPreview
                          ? closePreview()
                          : openPreviewStep({
                              step: States.MULTI_PRODUCT_SWAP_NEW,
                              treatment: existing || localPreviewData,
                            })
                      )
                    }
                  />
                </Tooltip>
              </Grid>
            </Grid>

            <Typography variant="h6" className={classes.panelSubheader}>
              Display Title*
            </Typography>
            <FormGroup>
              <Controller
                control={control}
                name="secondary_display_title"
                rules={{ required: Boolean(!existing) }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    InputLabelProps={{ shrink: true }}
                    label="500 CHARACTERS MAX"
                    variant="outlined"
                    placeholder="10% discount"
                    disabled={Boolean(existing)}
                    error={Boolean(errors.get("secondary_display_title"))}
                    helperText={errors.get("secondary_display_title")}
                  />
                )}
              />
            </FormGroup>

            <Typography variant="h6" className={classes.panelSubheader}>
              Button Text*
            </Typography>
            <FormGroup>
              <Controller
                control={control}
                name="secondary_button_text"
                rules={{ required: Boolean(!existing) }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    InputLabelProps={{ shrink: true }}
                    label="100 CHARACTERS MAX"
                    variant="outlined"
                    disabled={Boolean(existing)}
                    error={Boolean(errors.get("secondary_button_text"))}
                    helperText={errors.get("secondary_button_text")}
                  />
                )}
              />
            </FormGroup>
          </>
        ) : null}

        <Divider className={classes.headingDivider} />

        <Grid container alignItems="center" justifyContent="space-between">
          <Grid item>
            <Typography variant="h5" className={classes.panelHeader}>
              Saved a Customer
            </Typography>
          </Grid>
          <Grid item>
            <Tooltip
              title={inThisSavedPreview ? "Close Preview" : "Open Preview"}
              placement="left"
              arrow
            >
              <img
                src={inThisSavedPreview ? ClosePreview : OpenPreview}
                alt="toggle_preview"
                onClick={() =>
                  dispatch(
                    inThisSavedPreview
                      ? closePreview()
                      : openPreviewStep({
                          step: States.SAVED,
                          treatment: existing || localPreviewData,
                        })
                  )
                }
              />
            </Tooltip>
          </Grid>
        </Grid>

        <Typography variant="h6" className={classes.panelSubheader}>
          Treatment Claimed Headline*
        </Typography>
        <Typography variant="body2" className={classes.gray}>
          The headline on the screen shown after a user claims this treatment.
        </Typography>
        <FormGroup>
          <Controller
            control={control}
            name="offer_claimed_heading"
            rules={{ required: true }}
            render={({ field }) => (
              <TextField
                {...field}
                InputLabelProps={{ shrink: true }}
                label="255 CHARACTERS MAX"
                variant="outlined"
                error={Boolean(errors.get("offer_claimed_heading"))}
                helperText={errors.get("offer_claimed_heading")}
              />
            )}
          />
        </FormGroup>

        <Typography variant="h6" className={classes.panelSubheader}>
          Treatment Claimed Button Text*
        </Typography>
        <Typography variant="body2" className={classes.gray}>
          The text inside the button that redirects to your website after a user
          claims treatment.
        </Typography>
        <FormGroup>
          <Controller
            control={control}
            name="offer_claimed_button_text"
            rules={{ required: true }}
            render={({ field }) => (
              <TextField
                {...field}
                InputLabelProps={{ shrink: true }}
                label="255 CHARACTERS MAX"
                variant="outlined"
                error={Boolean(errors.get("offer_claimed_button_text"))}
                helperText={errors.get("offer_claimed_button_text")}
              />
            )}
          />
        </FormGroup>
        <Button
          type="submit"
          className={clsx(classes.panelButton, classes.marginTop)}
          disabled={isFetchingUpdateTreatment || isCreatingNewTreatment}
        >
          {existing ? "Save Changes" : "Create Treatment"}
        </Button>
      </form>
    </div>
  );
};

export default TreatmentFormPanel;
