import React from "react";
import { ThemeProvider } from "emotion-theming";
import { getFontColor } from "./utils";
import deepEqual from "fast-deep-equal";

export interface ThemeAttributeProps {
  logo?: string;
  font: string;
  custom_font_url?: string;
  primary_color: string;
  secondary_color: string;
  experience_tab_title: string;
  announcement_banner_text?: string;
  announcement_banner_active: boolean;
  exit_survey_heading: string;
  customer_lost_button_text: string;
  customer_lost_description: string;
  customer_lost_description_for_prepaid: string;
  confirm_cancel_heading: string;
  confirm_cancel_description: string;
  pause_disclaimer_text: string;
  website_url: string;
  font_color?: string;
  secondary_font_color?: string;
  custom_css?: string;
  support_email?: string;
  hide_powered_by_bellwethr_link?: boolean;
  confirm_cancel_image?: string;
  customer_saved_image?: string;
  customer_lost_image?: string;
  show_customer_saved_image: boolean;
  show_customer_lost_image: boolean;
  is_embedded?: boolean;
  resolution_claimed_redirect_url?: string;
  pause_claimed_redirect_url?: string;
  customer_canceled_redirect_url?: string;
  show_cancel_bundle?: boolean;
  show_cancel_alternative_action?: boolean;
  cancel_alternative_action_text?: string;
  cancel_alternative_action_url?: string;

  cancel_after_error_heading?: string;
  cancel_after_error_description?: string;

  customer_lost_headline: string;
  confirm_cancel_button: string;
  other_reason_button: string;
  other_reason_headline: string;
  other_reason_placeholder?: string;

  pause_select_text?: string;
  pause_success_headline: string;
  pause_headline: string;
  pause_success_subtext?: string;

  additional_comments_headline: string;
  additional_comments_placeholder?: string;
  additional_comments_reason_selected_text: string;

  bundle_subscription_disclaimer: string;
  go_back_button: string;
  continue_to_cancel: string;
  submit_button: string;
  back_home_button: string;
  force_cancel_button_text: string;
  show_force_cancel_button?: boolean;

  contact_support_for_cancel_description: string;
  contact_support_for_cancel_heading: string;
  contact_support_for_cancel?: boolean;

  immediate_cancel_reason_id?: number;
  immediate_cancel_text: string;

  error_heading: string;
  default_error_description: string;
  embedded_experience_refresh_page_text: string;
}

export const defaultTheme: ThemeAttributeProps = {
  primary_color: "#8500b7",
  secondary_color: "#EDE9FA",
  font: "Poppins",
  // logo: 'https://cdn.bellwethr.com/belllogo.svg',
  experience_tab_title: "Goodbye",
  announcement_banner_text: "",
  announcement_banner_active: false,
  exit_survey_heading: "Why do you want to close your account?",
  customer_lost_button_text: "Exit Experience",
  customer_lost_description:
    "Your subscription is now cancelled. We'll miss you!",
  customer_lost_description_for_prepaid:
    "Your prepaid subscription will be cancelled after all orders are delivered",
  confirm_cancel_heading: "Are you sure you're ready to say goodbye?",
  confirm_cancel_description:
    "We're sad we couldn't resolve your problem. We hope to see you again one day.",
  pause_disclaimer_text: "We will automatically renew",
  website_url: "https://bellwethr.com",

  support_email: "sample@mail.com",
  show_customer_saved_image: true,
  show_customer_lost_image: true,
  customer_lost_headline: "Sample Customer lost headline",
  confirm_cancel_button: "Sample confirm cancel button",
  other_reason_button: "Sample other reason button",
  other_reason_headline: "Sample reason headline",

  pause_success_headline: "Sample pause success headline",
  pause_headline: "Sample pause page headline",

  additional_comments_headline: "Sample Customer lost headline",

  additional_comments_reason_selected_text: "Sample reason selected",

  bundle_subscription_disclaimer: "Sample Disclaimer",
  go_back_button: "GO BACK",
  continue_to_cancel: "GO to Cancel",
  submit_button: "Sample submit",
  back_home_button: "go back home",
  force_cancel_button_text: "Still want to cancel? Click Here",
  show_force_cancel_button: true,

  contact_support_for_cancel_description:
    "Contact support via your subscription page",
  contact_support_for_cancel_heading:
    "Follow these instructions to cancel you account",
  immediate_cancel_text: "Cancel Now",
  error_heading: "Error Text Heading",
  default_error_description: "Error Text Description",
  embedded_experience_refresh_page_text: "Refresh Page To See Changes",
};

/**
 * Silk and Sonder theme
 */
export const defaultTheme1: ThemeAttributeProps = {
  primary_color: "#ffffff",
  secondary_color: "#ffffff",
  font: "Roboto",
  // logo:
  //   '//bellwethr-uploaded-files.s3.amazonaws.com/offboards/20200227-0105472102fe99-022d-4086-8c64-488a59130cd5',
  experience_tab_title: "Silk and Sonder Offboarding",
  announcement_banner_text: "",
  announcement_banner_active: false,
  exit_survey_heading: "Why do you want to close your account?",
  customer_lost_button_text: "Exit Experience",
  customer_lost_description:
    "Your subscription is now cancelled. We'll miss you!",
  customer_lost_description_for_prepaid:
    "Your prepaid subscription will be cancelled after all orders are delivered",
  confirm_cancel_heading: "Are you sure you're ready to say goodbye?",
  confirm_cancel_description: "",
  website_url: "https://www.silkandsonder.com",
  pause_disclaimer_text: "",
  support_email: "sample@mail.com",
  show_customer_saved_image: true,
  show_customer_lost_image: true,
  customer_lost_headline: "Sample Customer lost headline",
  confirm_cancel_button: "Sample confirm cancel button",
  other_reason_button: "Sample other reason button",
  other_reason_headline: "Sample reason headline",

  pause_success_headline: "Sample pause success headline",
  pause_headline: "Sample pause page headline",

  additional_comments_headline: "Sample Customer lost headline",

  additional_comments_reason_selected_text: "Sample reason selected",

  bundle_subscription_disclaimer: "Sample Disclaimer",
  go_back_button: "GO BACK",
  continue_to_cancel: "GO to Cancel",
  submit_button: "Sample submit",
  back_home_button: "go back home",
  contact_support_for_cancel_description:
    "Contact support via your subscription page",
  contact_support_for_cancel_heading:
    "Follow these instructions to cancel you account",
  force_cancel_button_text: "Still want to cancel? Click Here",
  immediate_cancel_text: "Cancel Now",
  error_heading: "Error Text Heading",
  default_error_description: "Error Text Description",
  embedded_experience_refresh_page_text: "Refresh Page To See Changes",
};

/**
 * Therabox Theme
 */
export const defaultTheme2: ThemeAttributeProps = {
  primary_color: "#FFD479",
  secondary_color: "#2ec0d1",
  font: "Courier New",
  logo: "//bellwethr-uploaded-files.s3.amazonaws.com/offboards/20190910-2142489277a2e8-7ee3-4a25-bebe-f84e7a9d5b33",
  experience_tab_title: "TheraBox Offboarding",
  announcement_banner_text: "",
  announcement_banner_active: false,
  exit_survey_heading: "Why do you want to close your account?",
  customer_lost_button_text: "Exit Experience",
  customer_lost_description:
    "Your subscription is now cancelled. We'll miss you!",
  customer_lost_description_for_prepaid:
    "Your prepaid subscription will be cancelled after all orders are delivered",
  confirm_cancel_heading: "Are you sure you're ready to say goodbye?",
  confirm_cancel_description: "",
  website_url: "https://wwww.mytherabox.com",
  pause_disclaimer_text: "We will automatically renew",
  support_email: "sample@mail.com",
  show_customer_saved_image: true,
  show_customer_lost_image: true,
  customer_lost_headline: "Sample Customer lost headline",
  confirm_cancel_button: "Sample confirm cancel button",
  other_reason_button: "Sample other reason button",
  other_reason_headline: "Sample reason headline",

  pause_success_headline: "Sample pause success headline",
  pause_headline: "Sample pause page headline",

  additional_comments_headline: "Sample Customer lost headline",

  additional_comments_reason_selected_text: "Sample reason selected",

  bundle_subscription_disclaimer: "Sample Disclaimer",
  go_back_button: "GO BACK",
  continue_to_cancel: "GO to Cancel",
  submit_button: "Sample submit",
  back_home_button: "go back home",
  contact_support_for_cancel_description:
    "Contact support via your subscription page",
  contact_support_for_cancel_heading:
    "Follow these instructions to cancel you account",
  force_cancel_button_text: "Still want to cancel? Click Here",
  immediate_cancel_text: "Cancel Now",
  error_heading: "Error Text Heading",
  default_error_description: "Error Text Description",
  embedded_experience_refresh_page_text: "Refresh Page To See Changes",
};

/**
 * KetoKrate Theme
 */
export const defaultTheme3: ThemeAttributeProps = {
  primary_color: "#ffecD7",
  secondary_color: "#F5911C",
  font: "Roboto",
  logo: "//bellwethr-uploaded-files.s3.amazonaws.com/offboards/20200120-19004991923718-6490-4b7d-b156-35c93361d51b",
  experience_tab_title: "Stick with us!",
  announcement_banner_text:
    "We're operating normally during COVID. Ivraj wanted to show off the drop shadow.",
  announcement_banner_active: true,
  exit_survey_heading: "Why do you want to close your account?",
  customer_lost_button_text: "Exit Experience",
  customer_lost_description:
    "Your subscription is now cancelled. We'll miss you!",
  customer_lost_description_for_prepaid:
    "Your prepaid subscription will be cancelled after all orders are delivered",
  confirm_cancel_heading: "Are you sure you're ready to say goodbye?",
  confirm_cancel_description: "",
  website_url: "https://ketokrate.com",
  pause_disclaimer_text: "",
  show_customer_saved_image: true,
  show_customer_lost_image: true,
  customer_lost_headline: "Sample Customer lost headline",
  confirm_cancel_button: "Sample confirm cancel button",
  other_reason_button: "Sample other reason button",
  other_reason_headline: "Sample reason headline",

  pause_success_headline: "Sample pause success headline",
  pause_headline: "Sample pause page headline",

  additional_comments_headline: "Sample Customer lost headline",

  additional_comments_reason_selected_text: "Sample reason selected",

  bundle_subscription_disclaimer: "Sample Disclaimer",
  go_back_button: "GO BACK",
  continue_to_cancel: "GO to Cancel",
  submit_button: "Sample submit",
  back_home_button: "go back home",
  contact_support_for_cancel_description:
    "Contact support via your subscription page",
  contact_support_for_cancel_heading:
    "Follow these instructions to cancel you account",
  force_cancel_button_text: "Still want to cancel? Click Here",
  immediate_cancel_text: "Cancel Now",
  error_heading: "Error Text Heading",
  default_error_description: "Error Text Description",
  embedded_experience_refresh_page_text: "Refresh Page To See Changes",
};

/**
 * BreoBox Theme
 */
export const defaultTheme4: ThemeAttributeProps = {
  primary_color: "#FFFFFF",
  secondary_color: "#73B1C3",
  font: "Verdana",
  logo: "//bellwethr-uploaded-files.s3.amazonaws.com/offboards/20191205-153538708085cc-e868-4133-b1f8-576336787c01",
  experience_tab_title: "BREO BOX Offboarding",
  announcement_banner_text: "",
  announcement_banner_active: false,
  exit_survey_heading: "Why do you want to close your account?",
  customer_lost_button_text: "Exit Experience",
  customer_lost_description:
    "Your subscription is now cancelled. We'll miss you!",
  customer_lost_description_for_prepaid:
    "Your prepaid subscription will be cancelled after all orders are delivered",
  confirm_cancel_heading: "Are you sure you're ready to say goodbye?",
  confirm_cancel_description: "",
  website_url: "https://www.breobox.com",

  pause_disclaimer_text: "We will automatically renew",
  show_customer_saved_image: true,
  show_customer_lost_image: true,
  customer_lost_headline: "Sample Customer lost headline",
  confirm_cancel_button: "Sample confirm cancel button",
  other_reason_button: "Sample other reason button",
  other_reason_headline: "Sample reason headline",

  pause_success_headline: "Sample pause success headline",
  pause_headline: "Sample pause page headline",

  additional_comments_headline: "Sample Customer lost headline",

  additional_comments_reason_selected_text: "Sample reason selected",

  bundle_subscription_disclaimer: "Sample Disclaimer",
  go_back_button: "GO BACK",
  continue_to_cancel: "GO to Cancel",
  submit_button: "Sample submit",
  back_home_button: "go back home",
  contact_support_for_cancel_description:
    "Contact support via your subscription page",
  contact_support_for_cancel_heading:
    "Follow these instructions to cancel you account",
  force_cancel_button_text: "Still want to cancel? Click Here",
  immediate_cancel_text: "Cancel Now",
  error_heading: "Error Text Heading",
  default_error_description: "Error Text Description",
  embedded_experience_refresh_page_text: "Refresh Page To See Changes",
};

/**
 * Sticker Savages Theme
 */
export const defaultTheme5: ThemeAttributeProps = {
  primary_color: "#000000",
  secondary_color: "#F6E652",
  font: "Tahoma",
  logo: "//bellwethr-uploaded-files.s3.amazonaws.com/offboards/20200204-184658ce3e7534-b2ee-42e5-a5ed-b5a99bf34364",
  experience_tab_title: "Sticker Savages Unsubscribing",
  announcement_banner_text:
    "COVID-19 Update | Rest Assured our deliveries are still up and running this month",
  announcement_banner_active: true,
  exit_survey_heading: "Why do you want to cancel your sticker subscription?",
  customer_lost_button_text: "Exit Experience",
  customer_lost_description:
    "Your subscription is now cancelled. We'll miss you!",
  customer_lost_description_for_prepaid:
    "Your prepaid subscription will be cancelled after all orders are delivered",
  confirm_cancel_heading: "Are you sure you're ready to say goodbye?",
  confirm_cancel_description: "",
  website_url: "https://www.stickersavages.com",
  pause_disclaimer_text: "",
  show_customer_saved_image: true,
  show_customer_lost_image: true,
  customer_lost_headline: "Sample Customer lost headline",
  confirm_cancel_button: "Sample confirm cancel button",
  other_reason_button: "Sample other reason button",
  other_reason_headline: "Sample reason headline",

  pause_success_headline: "Sample pause success headline",
  pause_headline: "Sample pause page headline",

  additional_comments_headline: "Sample Customer lost headline",

  additional_comments_reason_selected_text: "Sample reason selected",

  bundle_subscription_disclaimer: "Sample Disclaimer",
  go_back_button: "GO BACK",
  continue_to_cancel: "GO to Cancel",
  submit_button: "Sample submit",
  back_home_button: "go back home",
  contact_support_for_cancel_description:
    "Contact support via your subscription page",
  contact_support_for_cancel_heading:
    "Follow these instructions to cancel you account",
  force_cancel_button_text: "Still want to cancel? Click Here",
  immediate_cancel_text: "Cancel Now",
  error_heading: "Error Text Heading",
  default_error_description: "Error Text Description",
  embedded_experience_refresh_page_text: "Refresh Page To See Changes",
};

export const ThemeContext = React.createContext(defaultTheme);
export const ThemeContext1 = React.createContext(defaultTheme1);
export const ThemeContext2 = React.createContext(defaultTheme2);
export const ThemeContext3 = React.createContext(defaultTheme3);
export const ThemeContext4 = React.createContext(defaultTheme4);
export const ThemeContext5 = React.createContext(defaultTheme5);

interface DynamicThemeProps {
  theme?: ThemeAttributeProps;
}

interface DynamicThemeState {
  theme: ThemeAttributeProps;
}

/*
The purpose of this wrapper is *hopefully* to support
storing the Theme data in local state, so we can re-theme
dynamically (e.g. using @storybook/action-knobs) in development
However, while many implementations of this have worked-in-theory,
it seems that @emotion/themes/ThemeProvider is not re-rendering
when we recieve our updates, and I can't yet figure out how to fix it
*/
export class DynamicThemeProvider extends React.Component<
  DynamicThemeProps,
  DynamicThemeState
> {
  static context = ThemeContext;
  constructor(props: any) {
    super(props);

    // State also contains the updater function so it will
    // be passed down into the context provider
    this.state = {
      theme: props.theme || defaultTheme,
    };
  }

  componentDidUpdate(
    prevProps: DynamicThemeProps,
    prevState: DynamicThemeState
  ) {
    if (this.props.theme && !deepEqual(this.props.theme, this.state.theme))
      this.setTheme(this.props.theme);
  }

  setTheme(newTheme: ThemeAttributeProps) {
    this.context = newTheme;

    this.setState((state) => ({
      theme: newTheme,
    }));
  }

  render() {
    return (
      <ThemeProvider theme={this.state.theme}>
        {this.props.children}
      </ThemeProvider>
    );
  }
}

export class DynamicThemeProvider1 extends React.Component<
  DynamicThemeProps,
  DynamicThemeState
> {
  static context = ThemeContext1;
  constructor(props: any) {
    super(props);

    // State also contains the updater function so it will
    // be passed down into the context provider
    this.state = {
      theme: props.theme || defaultTheme1,
    };
  }
  componentDidMount() {
    this.setState((prevState) => ({
      theme: {
        ...prevState.theme,
        font_color: getFontColor(this.state.theme.primary_color),
        secondary_font_color: getFontColor(this.state.theme.secondary_color),
      },
    }));
  }

  setTheme(newTheme: ThemeAttributeProps) {
    this.context = newTheme;

    this.setState((state) => ({
      theme: newTheme,
    }));
  }

  render() {
    return (
      <div
        style={{
          opacity: 1,
          transform: "scale(1) translateY(0%)",
          position: "absolute",
          top: "0px",
          width: "100%",
          minHeight: "100vh",
        }}
      >
        <ThemeProvider theme={this.state.theme}>
          {this.props.children}
        </ThemeProvider>
      </div>
    );
  }
}

export class DynamicThemeProvider2 extends React.Component<
  DynamicThemeProps,
  DynamicThemeState
> {
  static context = ThemeContext2;
  constructor(props: any) {
    super(props);

    // State also contains the updater function so it will
    // be passed down into the context provider
    this.state = {
      theme: props.theme || defaultTheme2,
    };
  }
  componentDidMount() {
    this.setState((prevState) => ({
      theme: {
        ...prevState.theme,
        font_color: getFontColor(this.state.theme.primary_color),
        secondary_font_color: getFontColor(this.state.theme.secondary_color),
      },
    }));
  }

  setTheme(newTheme: ThemeAttributeProps) {
    this.context = newTheme;

    this.setState((state) => ({
      theme: newTheme,
    }));
  }

  render() {
    return (
      <div
        style={{
          opacity: 1,
          transform: "scale(1) translateY(0%)",
          position: "absolute",
          top: "0px",
          width: "100%",
          minHeight: "100vh",
        }}
      >
        <ThemeProvider theme={this.state.theme}>
          {this.props.children}
        </ThemeProvider>
      </div>
    );
  }
}

export class DynamicThemeProvider3 extends React.Component<
  DynamicThemeProps,
  DynamicThemeState
> {
  static context = ThemeContext3;
  constructor(props: any) {
    super(props);

    // State also contains the updater function so it will
    // be passed down into the context provider
    this.state = {
      theme: props.theme || defaultTheme3,
    };
  }

  setTheme(newTheme: ThemeAttributeProps) {
    this.context = newTheme;

    this.setState((state) => ({
      theme: newTheme,
    }));
  }
  componentDidMount() {
    this.setState((prevState) => ({
      theme: {
        ...prevState.theme,
        font_color: getFontColor(this.state.theme.primary_color),
        secondary_font_color: getFontColor(this.state.theme.secondary_color),
      },
    }));
  }

  render() {
    return (
      <div
        style={{
          opacity: 1,
          transform: "scale(1) translateY(0%)",
          position: "absolute",
          top: "0px",
          width: "100%",
          minHeight: "100vh",
        }}
      >
        <ThemeProvider theme={this.state.theme}>
          {this.props.children}
        </ThemeProvider>
      </div>
    );
  }
}

export class DynamicThemeProvider4 extends React.Component<
  DynamicThemeProps,
  DynamicThemeState
> {
  static context = ThemeContext4;
  constructor(props: any) {
    super(props);

    // State also contains the updater function so it will
    // be passed down into the context provider
    this.state = {
      theme: props.theme || defaultTheme4,
    };

    this.setState({
      theme: {
        ...this.state.theme,
        font_color: getFontColor(this.state.theme.primary_color),
        secondary_font_color: getFontColor(this.state.theme.secondary_color),
      },
    });
  }
  componentDidMount() {
    this.setState((prevState) => ({
      theme: {
        ...prevState.theme,
        font_color: getFontColor(this.state.theme.primary_color),
        secondary_font_color: getFontColor(this.state.theme.secondary_color),
      },
    }));
  }

  setTheme(newTheme: ThemeAttributeProps) {
    this.context = newTheme;

    this.setState((state) => ({
      theme: newTheme,
    }));
  }

  render() {
    return (
      <div
        style={{
          opacity: 1,
          transform: "scale(1) translateY(0%)",
          position: "absolute",
          top: "0px",
          width: "100%",
          minHeight: "100vh",
        }}
      >
        <ThemeProvider theme={this.state.theme}>
          {this.props.children}
        </ThemeProvider>
      </div>
    );
  }
}

export class DynamicThemeProvider5 extends React.Component<
  DynamicThemeProps,
  DynamicThemeState
> {
  static context = ThemeContext5;
  constructor(props: any) {
    super(props);

    // State also contains the updater function so it will
    // be passed down into the context provider
    this.state = {
      theme: props.theme || defaultTheme5,
    };
  }

  componentDidMount() {
    this.setState((prevState) => ({
      theme: {
        ...prevState.theme,
        font_color: getFontColor(this.state.theme.primary_color),
        secondary_font_color: getFontColor(this.state.theme.secondary_color),
      },
    }));
  }

  setTheme(newTheme: ThemeAttributeProps) {
    this.context = newTheme;
    this.setState((state) => ({
      theme: newTheme,
    }));
  }

  render() {
    return (
      <div
        style={{
          opacity: 1,
          transform: "scale(1) translateY(0%)",
          position: "absolute",
          top: "0px",
          width: "100%",
          minHeight: "100vh",
        }}
      >
        <ThemeProvider theme={this.state.theme}>
          {this.props.children}
        </ThemeProvider>
      </div>
    );
  }
}

export const StorybookThemeDecorator = (storyFn: any) => (
  <DynamicThemeProvider>{storyFn()}</DynamicThemeProvider>
);
export const StorybookThemeDecorator1 = (storyFn: any) => (
  <DynamicThemeProvider1>{storyFn()}</DynamicThemeProvider1>
);
export const StorybookThemeDecorator2 = (storyFn: any) => (
  <DynamicThemeProvider2>{storyFn()}</DynamicThemeProvider2>
);
export const StorybookThemeDecorator3 = (storyFn: any) => (
  <DynamicThemeProvider3>{storyFn()}</DynamicThemeProvider3>
);
export const StorybookThemeDecorator4 = (storyFn: any) => (
  <DynamicThemeProvider4>{storyFn()}</DynamicThemeProvider4>
);
export const StorybookThemeDecorator5 = (storyFn: any) => (
  <DynamicThemeProvider5>{storyFn()}</DynamicThemeProvider5>
);
