import React from 'react';
import styled from '@emotion/styled';
import { keyframes } from '@emotion/core';

interface LoaderProps {
  loading: boolean;
  theme?: object;
}

const LoaderDiv = styled.div`
  background: ${(props: any) => props.theme.primary_color};
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: 50;
  opacity: 0.85;
  cursor: wait;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Animate = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const LoaderRing = styled.div`
  width: 100px;
  height: 100px;

  div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 84px;
    height: 84px;
    margin: 8px;
    border: 8px solid ${(props: any) => props.theme.secondary_color};
    border-radius: 50%;
    animation: ${Animate} 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: ${(props: any) => props.theme.secondary_color} transparent
      transparent transparent;
    &:nth-of-type(1) {
      animation-delay: -0.45s;
    }
    &:nth-of-type(2) {
      animation-delay: -0.3s;
    }
    &:nth-of-type(3) {
      animation-delay: -0.15s;
    }
  }
`;

const Loader: React.FunctionComponent<LoaderProps> = props => {
  return (
    <LoaderDiv style={{ display: props.loading ? 'flex' : 'none' }}>
      <LoaderRing>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </LoaderRing>
    </LoaderDiv>
  );
};

export default Loader;
