import React from "react";
import styled from "@emotion/styled";
import Layout, { BottomWhite } from "../../components/Layout/Layout";
import { compareIgnoringCase } from "features/offboarding/utils";
import { useTheme } from "emotion-theming";
import { ThemeAttributeProps } from "../../theme";
import Footer from "../../components/Footer/Footer";

interface AdditionalCommentsProps {
  reason_text?: string;
  callUpdate(input: string): void;
}

const ReasonWrapper = styled.div`
  width: 70%;
  padding: 3rem 0 1rem;
  margin: auto;
  margin-top: 1rem;
`;

const ReasonText = styled.textarea`
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 24px;
  padding: 1rem;
  width: 100%;
  color: #080f18;
  border: 1px solid
    ${(props: any) =>
      props.theme.primary_color.toUpperCase() === "#FFFFFF"
        ? "#000000"
        : props.theme.primary_color};
  font-family: ${(props: any) => props.theme.font};
  @media (max-width: 768px) {
    padding: 0;
  }
`;

const ButtonWrapper = styled.div`
  width: 6rem;
  margin-top: 24px;
  @media (max-width: 768px) {
    float: right;
  }
`;
const ReasonSelected = styled.div`
  font-family: ${(props: any) => props.theme.font};
  font-style: normal;
  font-weight: 500;
  font-size: 1.2rem;

  margin-bottom: 1rem;
`;

const SubmitButton = styled.div`
  font-family: ${(props: any) => props.theme.font};
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  padding: 0.25rem;
  line-height: 27px;
  text-align: center;
  background: ${(props: any) =>
    compareIgnoringCase(props.theme.primary_color, "#FFFFFF")
      ? "#E5E5E5"
      : props.theme.primary_color};
  color: ${(props: any) => props.theme.font_color};
  border-radius: 6px;

  @media (max-width: 768px) {
    margin: 0;
  }
  :hover {
    background: ${(props: any) => props.theme.secondary_color};
    color: ${(props: any) => props.theme.secondary_font_color};
    cursor: pointer;
  }

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

let AdditionalCommentsScreen: React.FC<AdditionalCommentsProps> = (props) => {
  const theme: ThemeAttributeProps = useTheme();

  const title = theme.additional_comments_headline;
  const anyExtraCommentsPlaceholder = theme.additional_comments_placeholder;
  const reasonSelectedText = theme.additional_comments_reason_selected_text;
  const SubmitText = theme.submit_button;
  const [reason, setReason] = React.useState("");

  const submitReason = (reason: string) => {
    if (reason.length < 1) {
      props.callUpdate("continue");
      return;
    }
    props.callUpdate(reason);
  };

  return (
    <>
      <Layout show_banner title={title}>
        <BottomWhite className="bottom-content-container">
          <ReasonWrapper>
            {props.reason_text ? (
              <ReasonSelected>
                {reasonSelectedText}: {props.reason_text}
              </ReasonSelected>
            ) : null}
            <ReasonText
              rows={6}
              onChange={(e: any) => setReason(e.target.value)}
              placeholder={anyExtraCommentsPlaceholder}
              className="other-reason-textarea"
              maxLength={250}
            ></ReasonText>
            <ButtonWrapper>
              <SubmitButton
                onClick={() => submitReason(reason)}
                className="other-reason-button"
              >
                {SubmitText}
              </SubmitButton>
            </ButtonWrapper>
          </ReasonWrapper>
        </BottomWhite>
      </Layout>
      <Footer
        onClickContinue={(e) => props.callUpdate("continue")}
        onClickBack={(e) => props.callUpdate("_back")}
      />
    </>
  );
};

export default AdditionalCommentsScreen;
