import { createTheme, ThemeOptions } from "@material-ui/core/styles";

export const commonTheme: ThemeOptions = {
  typography: {
    fontFamily: "Poppins, sans-serif",
    h1: {
      fontSize: "2.6rem",
      fontWeight: 500,
    },
    body1: {
      margin: "1em 0",
      fontWeight: 300,
      fontSize: "18px",
    },
    body2: {
      margin: "0.7em 0",
      fontWeight: 300,
    },
  },
  overrides: {
    MuiFormLabel: {
      root: {
        fontWeight: 400,
      },
    },
    MuiButton: {
      root: {
        textTransform: "none",
        // border: "3px solid rgba(70, 68, 164, 0.2)",
      },
    },
    /* MuiFormControlLabel: {
      root: {
        background:
          "-webkit-linear-gradient(#341241, #4F2260, #6D3781, #9D68B0)",
        "-webkit-background-clip": "text",
        "-webkit-text-fill-color": "transparent",
        
      },
    }, */
  },
};

export const lightTheme = createTheme({
  ...commonTheme,
  palette: {
    type: "light",
    primary: {
      main: "#4644a4",
    },
    secondary: {
      main: "#54BCD1",
    },
  },
});

export const darkTheme = createTheme({
  ...commonTheme,
  palette: {
    type: "dark",
    primary: {
      main: "#4644a4",
    },
    secondary: {
      main: "#54BCD1",
    },
  },
});

export const externalTheme = createTheme({
  ...commonTheme,
  palette: {
    type: "dark",
    primary: {
      main: "#4644a4",
    },
    secondary: {
      main: "#442f86",
    },
  },
  overrides: {
    ...commonTheme.overrides,
    MuiCard: {
      root: {
        background: "rgba(255, 255, 255, 0.26)",
        borderRadius: "10px",
        padding: "40px",
      },
    },
  },
});
