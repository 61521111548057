import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import moment from "moment";

import {
  ArchiveTreatmentParams,
  AvailableEngineMap,
  AvailableExitSurveyResponseMap,
  AvailablePauseMap,
  AvailableTreatmentMap,
  UpsertIntermediateFields,
  DeleteEngineFields,
  DeleteTreatmentParams,
  DisplayData,
  EngineStyle,
  ExitSurveyResponse,
  Intermediate,
  NotificationSettings,
  OffboardEngine,
  OpenPreview,
  Pause,
  PauseOptions,
  StepNavigation,
  UpdateEngineFields,
  UpdatePauseOptionsParams,
  UpdatePauseParams,
  IntermediatePreviewBase,
  DashboardStatistics,
  StartEndDate,
  SurvivalAnalysis,
  OffboardStaticValues,
  StatisticData,
  ExperienceStatData,
  ActivateEngineFields,
  UpdateNotificationSettings,
} from "./types";

import { WizardStepOrder, WizardSteps } from "./tour";
import { history } from "app/history";
import { EXPERIENCE_URLS, MECHANICS_URLS, SETTINGS_URLS } from "./urls";
import client, {
  APICall,
  APICallMap,
  APIStatus,
  createAPICallThunk,
} from "../../../app/api";
import { generatePath } from "react-router-dom";
import { toast } from "react-hot-toast";
import { formatDateString } from "../../../app/utils";

/*************************************/
/* API Thunk Creation */
/*************************************/

// Engine
export const getEngines = createAPICallThunk(
  "wizard",
  "getEngines",
  async (input: void) => {
    return await client.get("/offboard/");
  },
  (state, call) => {
    call.reply.map((engine: OffboardEngine) => {
      state.availableEngines[engine.slug] = engine as OffboardEngine;
      return engine;
    });
    unpackDeepEngineData(state);
  }
);

export const getEngine = createAPICallThunk(
  "wizard",
  "getEngine",
  async (slug: string) => {
    return await client.get(`/offboard/${slug}/`);
  },
  (state, call) => {
    state.availableEngines[call.reply.slug] = call.reply;
    unpackDeepEngineData(state);
  }
);

export const createEngine = createAPICallThunk(
  "wizard",
  "createEngine",
  async (input: UpdateEngineFields) => {
    return await client.post("/offboard/create/", input);
  },
  (state, call) => {
    window.location.pathname = generatePath(EXPERIENCE_URLS.exit_survey, {
      engine: call.reply.slug,
    });
  }
);

export const updateEngine = createAPICallThunk(
  "wizard",
  "updateEngine",
  async (input: UpdateEngineFields) => {
    const slug = input.slug;
    const data: UpdateEngineFields = {
      ...input,
      slug: input.updateSlug ? input.updateSlug : undefined,
    };
    return await client.patch(`/offboard/${slug}/`, data);
  },
  (state, call) => {
    if (call.input.slug !== call.reply.slug)
      window.location.pathname = generatePath(SETTINGS_URLS.advanced, {
        engine: call.reply.slug,
      });
    state.availableEngines[call.input.slug] = call.reply as OffboardEngine;
    toast.success("Engine updated!");
  }
);

export const deleteEngine = createAPICallThunk(
  "wizard",
  "deleteEngine",
  async (input: DeleteEngineFields) => {
    return await client.post(`/offboard/${input.slug}/delete/`, {
      password: input.data.password,
    });
  },
  (state, call) => {
    delete state.availableEngines[call.input.slug];
    toast.success("Successfully Deleted Engine");
    window.location.pathname = "/";
  }
);

export const activateEngine = createAPICallThunk(
  "wizard",
  "activateEngine",
  async (input: ActivateEngineFields) => {
    return await client.patch(
      `/offboard/${input.slug}/activate-auto-integration/`,
      {
        active: input.active,
      }
    );
  },
  (state, call) => {
    toast.success(
      `Successfully ${call.input.active ? "Activated" : "Deactivated"} Engine`
    );
    state.availableEngines[call.input.slug] = call.reply as OffboardEngine;
  }
);

// Styling
export const getEngineStyle = createAPICallThunk(
  "wizard",
  "getEngineStyle",
  async (slug: string) => {
    return await client.get(`/offboard/${slug}/style/`);
  },
  (state, call) => (state.style = call.reply as EngineStyle)
);

export const updateEngineStyle = createAPICallThunk(
  "wizard",
  "updateEngineStyle",
  async (input: EngineStyle) => {
    return await client.multipart_patch(
      `/offboard/${input.slug}/style/`,
      input
    );
  },
  (state, call) => {
    state.style = call.reply as EngineStyle;
    toast.success("Style updated!");
  }
);

export interface RemoveMediaParams {
  media_type:
    | "logo"
    | "confirm_cancel_image"
    | "customer_saved_image"
    | "customer_lost_image";
  slug: string;
}

export const removeMedia = createAPICallThunk(
  "wizard",
  "removeMedia",
  async (input: RemoveMediaParams) => {
    return await client.post(`/offboard/${input.slug}/media/delete/`, input);
  },
  (state, call) => {
    if (call.input.media_type === "logo") state.style.logo = "";
    if (call.input.media_type === "confirm_cancel_image")
      state.style.confirm_cancel_image = "";
    if (call.input.media_type === "customer_saved_image")
      state.style.customer_saved_image = "";
    if (call.input.media_type === "customer_lost_image")
      state.style.customer_lost_image = "";
    const displayName = call.input.media_type.replaceAll("_", " ");
    toast.success(`Removed ${displayName}!`);
  }
);

// Exit Responses

export const createExitSurveyResponse = createAPICallThunk(
  "wizard",
  "createExitSurveyResponse",
  async (input: ExitSurveyResponse) => {
    return await client.post(
      `/offboard/${input.offboard_slug}/exit-survey-create/`,
      input
    );
  },
  (state, call) => {
    call.reply.offer_ids = call.reply.offer_ids.map((n: number) => `${n}`);
    state.availableExitSurveyResponses[call.reply.slug] =
      call.reply as ExitSurveyResponse;
  }
);

export const updateExitSurveyResponse = createAPICallThunk(
  "wizard",
  "updateExitSurveyResponse",
  async (input: ExitSurveyResponse) => {
    return await client.patch(
      `/offboard/${input.offboard_slug}/exit-survey/${input.slug}/`,
      input
    );
  },
  (state: any, call: APICall) => {
    state.availableExitSurveyResponses[call.reply.slug] =
      call.reply as ExitSurveyResponse;
  }
);

export const deleteExitSurveyResponse = createAPICallThunk(
  "wizard",
  "deleteExitSurveyResponse",
  async (input: ExitSurveyResponse) => {
    return await client.rest_delete(
      `/offboard/${input.offboard_slug}/exit-survey/${input.slug}/`
    );
  },
  (state, call) => {
    delete state.availableExitSurveyResponses[call.input.slug];
    toast.success("Successfully Deleted Exit Survey Response");
  },
  undefined,
  [204]
);

// Treatments

export const createIntermediate = createAPICallThunk(
  "wizard",
  "createIntermediate",
  async (input: UpsertIntermediateFields) => {
    return await client.multipart_post(
      `/offboard/${input.offboard_slug}/intermediate-create/`,
      input
    );
  },
  (state, call) => {
    state.availableTreatments[call.reply.slug] = call.reply as Intermediate;
    const path = generatePath(EXPERIENCE_URLS.treatments, {
      engine: state.displayData.currentEngine,
    });
    window.location.pathname = path;
  }
);

export const updateTreatment = createAPICallThunk(
  "wizard",
  "updateTreatment",
  async (input: UpsertIntermediateFields) => {
    return await client.patch(
      `/offboard/${input.offboard_slug}/intermediates/${input.slug}/`,
      input
    );
  },
  (state: any, call: APICall) => {
    const treatment = call.reply as Intermediate;
    state.availableTreatments[treatment.slug] = treatment;
    toast.success(`Treatment updated!`);
  }
);

export const archiveTreatment = createAPICallThunk(
  "wizard",
  "archiveTreatment",
  async (input: ArchiveTreatmentParams) => {
    return await client.patch(
      `/offboard/${input.engine}/intermediates/${input.slug}/archive/`,
      input
    );
  },
  (state: any, call: APICall) => {
    const treatment = call.reply as Intermediate;
    state.availableTreatments[treatment.slug] = treatment;
  },
  (state: any, call: APICall) => {
    toast.error(`Error archiving Treatment - ${call.error}`);
  }
);

export const unarchiveTreatment = createAPICallThunk(
  "wizard",
  "unarchiveTreatment",
  async (input: ArchiveTreatmentParams) => {
    return await client.patch(
      `/offboard/${input.engine}/intermediates/${input.slug}/archive/`,
      input
    );
  },
  (state: any, call: APICall) => {
    const treatment = call.reply as Intermediate;
    state.availableTreatments[treatment.slug] = treatment;
  },
  (state: any, call: APICall) => {
    toast.error(`Error unarchiving Treatment - ${call.error}`);
  }
);

export const deleteTreatment = createAPICallThunk(
  "wizard",
  "deleteTreatment",
  async (input: DeleteTreatmentParams) => {
    return await client.rest_delete(
      `/offboard/${input.engine}/intermediates/${input.slug}/`
    );
  },
  (state, call) => {
    delete state.availableTreatments[call.input.slug];
    toast.success("Successfully Deleted Treatment");
  },
  undefined,
  [204]
);

// Pauses

export const updatePause = createAPICallThunk(
  "wizard",
  "updatePause",
  async (input: UpdatePauseParams) => {
    return await client.patch(
      `/offboard/${input.engine}/pauses/${input.slug}/`,
      input
    );
  },
  (state: any, call: APICall) => {
    const pause = call.reply as Pause;
    state.availablePauses[pause.slug] = pause;
  },
  (state: any, call: APICall) => {
    toast.error(`Error updating Pause - ${call.error}`);
  }
);

export const getPauseOptions = createAPICallThunk(
  "wizard",
  "getPauseOptions",
  async (engine: string) => {
    return await client.get(`/offboard/${engine}/pause-options/`);
  },
  (state: any, call: APICall) => {
    state.pauseOptions = call.reply as PauseOptions;
  }
);

export const updatePauseOptions = createAPICallThunk(
  "wizard",
  "updatePauseOptions",
  async (input: UpdatePauseOptionsParams) => {
    return await client.patch(
      `/offboard/${input.engine}/pause-options/`,
      input
    );
  },
  (state: any, call: APICall) => {
    state.pauseOptions = call.reply as PauseOptions;
    toast.success("Pause Options updated!");
  }
);

export interface DashboardStatisticsParams {
  engine: string;
  start_date: string;
  end_date: string;
  reason_ids: number[];
}

export const getDashboardExperiencesData = createAPICallThunk(
  "wizard",
  "getDashboardExperiencesData",
  async (data: DashboardStatisticsParams) => {
    return await client.post(`/offboard/${data.engine}/experiences/`, {
      start_date: moment(data.start_date).format("YYYY-MM-DD"),
      end_date: moment(data.end_date).format("YYYY-MM-DD"),
      reason_ids: data.reason_ids,
    });
  },
  (state: any, call: APICall) => {
    state.dashboardExperiences = call.reply as ExperienceStatData;
  }
);

export const getDashboardStatistics = createAPICallThunk(
  "wizard",
  "getDashboardStatistics",
  async (data: DashboardStatisticsParams) => {
    return await client.post(`/offboard/${data.engine}/statistics/`, {
      start_date: moment(data.start_date).format("YYYY-MM-DD"),
      end_date: moment(data.end_date).format("YYYY-MM-DD"),
      reason_ids: data.reason_ids,
    });
  },
  (state: any, call: APICall) => {
    state.dashboardStatistics = call.reply as DashboardStatistics;
    state.displayData.dashboardStartDate = call.reply.start_date;
    state.displayData.dashboardEndDate = call.reply.end_date;
  }
);

export const getSurvivalAnalysisData = createAPICallThunk(
  "wizard",
  "getSurvivalAnalysisData",
  async (slug: string) => {
    return await client.get(`/offboard/${slug}/survival-analysis/`);
  },
  (state: any, call: APICall) => {
    state.survivalAnalysis = call.reply as SurvivalAnalysis;
  }
);

export interface PreviewEmail {
  email_type:
    | "send_client_reactivate_pause"
    | "send_client_pause_claimed"
    | "send_client_subscription_canceled"
    | "send_client_resolution_claimed"
    | "send_customer_pause_claimed"
    | "send_customer_reactivate_pause"
    | "send_customer_resolution_claimed"
    | "send_customer_subscription_canceled";
  send_to_email: string;
  slug: string;
}

export const previewNotificationEmail = createAPICallThunk(
  "wizard",
  "previewNotificationEmail",
  async (data: PreviewEmail) => {
    return await client.post(
      `/offboard/${data.slug}/preview-notification-email/`,
      data
    );
  },
  (state, call) => {
    toast.success(call.reply.message);
  }
);

export type OAuthParams = {
  website_url: string;
};

export const getOAuthURL = createAPICallThunk(
  "wizard",
  "getOAuthURL",
  async (data: OAuthParams) => {
    return await client.get(
      `/offboard/stripe-oauth/start/?website_url=${data.website_url}`
    );
  },
  (state, call) => {
    const URL = call.reply.oauth_url;
    console.log("Sending you to OAuth URL:", URL);
    setTimeout(() => (window.location = URL), 2 * 1000);
  }
);

export const adminSearchEngine = createAPICallThunk(
  "wizard",
  "adminSearchEngine",
  async (terms: string) => {
    return await client.get(`/offboard/search/?terms=${terms}`);
  }
);

interface SingleExperienceParams {
  alid: string;
  offboardSlug: string;
}

export const getSingleExperienceDetails = createAPICallThunk(
  "wizard",
  "getSingleExperienceDetails",
  async (data: SingleExperienceParams) => {
    return await client.get(
      `/offboard/${data.offboardSlug}/experience-details/${data.alid}/`
    );
  }
);

export const getStaticOffboardValues = createAPICallThunk(
  "wizard",
  "getStaticOffboardValues",
  async () => {
    return await client.get("/static-values/offboards/");
  },
  (state, call) => {
    state.offboardStaticValues = call.reply as OffboardStaticValues;
  }
);

interface ToggleTestExperienceParams extends SingleExperienceParams {
  is_test: boolean;
}

export const toggleIsTestExperience = createAPICallThunk(
  "wizard",
  "toggleIsTestExperience",
  async (data: ToggleTestExperienceParams) => {
    return await client.patch(
      `/offboard/${data.offboardSlug}/experience-details/${data.alid}/`,
      { test_experience: data.is_test }
    );
  },
  (state, call) => {
    state.dashboardExperiences.experiences =
      state.dashboardExperiences.experiences.map((val: any) => {
        if (val.alid === call.reply.alid)
          val.test_experience = call.reply.test_experience;
        return val;
      });
  }
);

const DEFAULT_STATE = {
  getNotificationSettingsStatus: "NONE" as APIStatus,
  updateNotificationSettingsStatus: "NONE" as APIStatus,
  apiCalls: {
    ...getEngines.State,
    ...getEngine.State,
    ...createEngine.State,
    ...updateEngine.State,
    ...deleteEngine.State,
    ...activateEngine.State,

    ...getEngineStyle.State,
    ...updateEngineStyle.State,
    ...removeMedia.State,

    ...createExitSurveyResponse.State,
    ...updateExitSurveyResponse.State,
    ...deleteExitSurveyResponse.State,

    ...createIntermediate.State,
    ...updateTreatment.State,
    ...archiveTreatment.State,
    ...unarchiveTreatment.State,
    ...deleteTreatment.State,

    ...updatePause.State,
    ...getPauseOptions.State,
    ...updatePauseOptions.State,

    ...getDashboardStatistics.State,
    ...getSurvivalAnalysisData.State,
    ...previewNotificationEmail.State,

    ...getOAuthURL.State,
    ...adminSearchEngine.State,
    ...getSingleExperienceDetails.State,
    ...toggleIsTestExperience.State,

    ...getStaticOffboardValues.State,
    ...getDashboardExperiencesData.State,
  } as APICallMap,
  updateNotificationSettingsErrors: {},
  errorMessage: "",
  backendFieldErrors: {},
  displayData: { dashboardResponsesFilter: [] } as DisplayData,
  pauseOptions: {} as PauseOptions,
  dashboardStatistics: {} as DashboardStatistics,
  survivalAnalysis: {} as SurvivalAnalysis,
  availableTreatments: {} as AvailableTreatmentMap,
  availablePauses: {} as AvailablePauseMap,
  availableExitSurveyResponses: {} as AvailableExitSurveyResponseMap,
  availableEngines: {} as AvailableEngineMap,
  notificationSettings: {} as NotificationSettings,
  style: {} as EngineStyle,

  dashboardExperiences: {} as ExperienceStatData,
};

function nextMatch(collection: any[], filterCallback: (item: any) => boolean) {
  const matches = collection.filter(filterCallback);
  if (matches.length === 0) {
    return null;
  } else {
    const index = collection.indexOf(matches[0]);
    if (collection.length >= index + 1) return collection[index + 1];
  }
}

const unpackDeepEngineData = (state: any) => {
  const currentEngine =
    state.availableEngines[state.displayData.currentEngine as string];
  if (currentEngine) {
    state.availableTreatments = currentEngine.available_offers;
    state.availablePauses = currentEngine.available_pauses;
    state.availableExitSurveyResponses = currentEngine.exit_responses;
  }
};

export const getNotificationSettings = createAsyncThunk(
  "wizard/getNotificationSettings",
  async (slug: string, thunkAPI) => {
    try {
      const { http, data: reply } = await client.get(
        `/offboard/${slug}/notifications/`
      );

      if (http.status === 200) {
        return reply;
      } else {
        return thunkAPI.rejectWithValue(reply);
      }
    } catch (e) {
      return thunkAPI.rejectWithValue(e.reason);
    }
  }
);

export const updateNotificationSettings = createAsyncThunk(
  "wizard/updateNotificationSettings",
  async (data: UpdateNotificationSettings, thunkAPI) => {
    try {
      const { http, data: reply } = await client.patch(
        `/offboard/${data.slug}/notifications/`,
        data
      );

      if (http.status === 201 || http.status === 200) {
        return reply;
      } else {
        return thunkAPI.rejectWithValue(reply);
      }
    } catch (e) {
      return thunkAPI.rejectWithValue(e.reason);
    }
  }
);

export const wizardSlice = createSlice({
  name: "wizard",
  initialState: DEFAULT_STATE,
  reducers: {
    setWizardStepPath: (state, action: PayloadAction<string>) => {
      state.displayData.currentStepPath = action.payload;
    },
    setWizardSlug: (state, action: PayloadAction<string>) => {
      state.displayData.currentSlug = action.payload;
    },
    setWizardEngine: (state, action: PayloadAction<string>) => {
      state.displayData.currentEngine = action.payload;
      unpackDeepEngineData(state);
    },
    updatePreviewStyle: (state, action: PayloadAction<any>) => {
      state.style = { ...state.style, ...action.payload };
    },
    toggleCollapseExitSurvey: (state) => {
      state.displayData.exitSurveyCollapsed =
        !state.displayData.exitSurveyCollapsed;
      return state;
    },
    toggleCollapseTreatments: (state) => {
      state.displayData.treatmentsCollapsed =
        !state.displayData.treatmentsCollapsed;
      return state;
    },
    toggleCollapsePauses: (state) => {
      state.displayData.pausesCollapsed = !state.displayData.pausesCollapsed;
      return state;
    },
    livePreviewTreatment: (
      state,
      action: PayloadAction<IntermediatePreviewBase>
    ) => {
      if (state.displayData.showPreviewStep)
        state.displayData.showPreviewStep.treatment = action.payload;
      return state;
    },
    openPreviewStep: (state, action: PayloadAction<OpenPreview>) => {
      state.displayData.showPreviewStep = action.payload;
      return state;
    },
    closePreview: (state) => {
      state.displayData.showPreviewStep = undefined;
      return state;
    },
    toggleExitSurveyResponseTreatment: (
      state,
      action: PayloadAction<string>
    ) => {
      const response = selectedExitResponseSelector({ wizard: state });

      if (response.offer_ids) {
        const treatmentIndex = response.offer_ids.indexOf(action.payload);

        if (treatmentIndex === -1) {
          response.offer_ids.push(action.payload);
        } else {
          response.offer_ids.splice(treatmentIndex, 1);
        }
      }
    },
    togglePauseActive: (state, action: PayloadAction<string>) => {
      const pause = state.availablePauses[action.payload];
      if (pause) pause.active = !pause.active;
    },
    toggleTreatmentActive: (state, action: PayloadAction<string>) => {
      const treatment = state.availableTreatments[action.payload];
      if (treatment) treatment.active = !treatment.active;
    },
    toggleShowCancelAlternative: (state) => {
      state.style.show_cancel_alternative_action =
        !state.style.show_cancel_alternative_action;
      return state;
    },
    setDashboardDates: (state, action: PayloadAction<StartEndDate>) => {
      state.displayData.dashboardStartDate = action.payload.start;
      state.displayData.dashboardEndDate =
        action.payload.end || formatDateString(moment());
    },
    setDashboardFilterResponses: (state, action: PayloadAction<string[]>) => {
      state.displayData.dashboardResponsesFilter = action.payload;
    },
    stepNavigate: (state, action: PayloadAction<StepNavigation>) => {
      const nav = action.payload;
      const newLocation = generatePath(nav.path, {
        engine: nav.engine || state.displayData.currentEngine,
        slug: nav.slug,
      });
      console.log("Redirecting:", newLocation, nav);
      history.push(newLocation);
      state.displayData.showPreviewStep = undefined;
    },
    navigateTo: (state, action: PayloadAction<string>) => {
      history.push(action.payload);
      state.displayData.showPreviewStep = undefined;
    },
    nextStep: (state) => {
      let currentStepIndex = WizardStepOrder.indexOf(
        state.displayData.currentStepPath || ""
      );

      let nextStep = WizardStepOrder[0];

      const thisStepHasSlug =
        state.displayData.currentStepPath?.endsWith(":slug") === true;

      if (currentStepIndex === -1 || !state.displayData.currentStepPath) {
        // If not set, next step is the first step!
        nextStep = WizardStepOrder[0];
        console.debug("First step requested");
      } else if (!thisStepHasSlug) {
        // If this is a normal step, next step
        nextStep = WizardStepOrder[currentStepIndex + 1];
        console.debug("Next step requested", currentStepIndex, WizardStepOrder);
      } else if (thisStepHasSlug) {
        // If we have slugs, get the next available slug
        let nextSlug = "";
        console.debug("Next step requested (by slug)");
        if (
          state.displayData.currentStepPath ===
          MECHANICS_URLS.exit_survey_response
        ) {
          /* Get the next Exit Response */
          const responses = Object.values(
            state.availableExitSurveyResponses
          ).filter((response) => response.active);
          const nextResponse = nextMatch(
            responses,
            (resp: ExitSurveyResponse) =>
              resp.slug === state.displayData.currentSlug
          );
          nextSlug = nextResponse?.slug;
        } else if (
          state.displayData.currentStepPath ===
          MECHANICS_URLS.treatment_mechanics
        ) {
          /* Get the next Pause */
          const treatments = availableTreatmentsSelector({ wizard: state });
          const enabled = treatmentsEnabledSlugsSelector({ wizard: state });
          nextSlug = nextMatch(
            enabled,
            (enabled: string) =>
              treatments[enabled].slug === state.displayData.currentSlug
          );
        }

        console.debug("Next slug found:", nextSlug);

        if (nextSlug)
          nextStep = state.displayData.currentStepPath.replace(
            ":slug",
            nextSlug
          );
        else nextStep = WizardStepOrder[currentStepIndex + 1];
      }
      if (!nextStep) nextStep = "/dashboard";

      if (nextStep?.endsWith(":slug")) {
        if (nextStep === MECHANICS_URLS.exit_survey_response) {
          console.debug(
            "Next step ends in ':slug', using the first ExitSurveyResponse"
          );
          nextStep = nextStep.replace(
            ":slug",
            exitSurveyResponsesEnabledSlugsSelector({ wizard: state })[0]
          );
        }
      }

      if (state.displayData.currentEngine)
        nextStep = nextStep.replace(":engine", state.displayData.currentEngine);

      history.push(nextStep);
      state.displayData.showPreviewStep = undefined;
    },
  },
  extraReducers: {
    [getNotificationSettings.pending as any]: (state) => {
      state.getNotificationSettingsStatus = "FETCHING";
    },
    [getNotificationSettings.fulfilled as any]: (state, { payload }) => {
      state.getNotificationSettingsStatus = "SUCCESS";
      state.notificationSettings = payload;
    },
    [updateNotificationSettings.pending as any]: (state) => {
      state.updateNotificationSettingsStatus = "FETCHING";
      state.updateNotificationSettingsErrors = {};
    },
    [updateNotificationSettings.fulfilled as any]: (state, { payload }) => {
      state.updateNotificationSettingsStatus = "SUCCESS";
      toast.success("Notification Setttings Updated Successfully!");
      state.notificationSettings = payload;
    },
    [updateNotificationSettings.rejected as any]: (state, { payload }) => {
      state.updateNotificationSettingsStatus = "ERROR";
      state.errorMessage = payload?.non_field_errors || "An error occurred";
      if (typeof payload === "object") {
        state.updateNotificationSettingsErrors = payload;
      }
      toast.error(
        `Error updating notification settings: ${state.errorMessage}`
      );
    },
    ...getEngines.Reducers,
    ...getEngine.Reducers,
    ...createEngine.Reducers,
    ...updateEngine.Reducers,
    ...deleteEngine.Reducers,
    ...activateEngine.Reducers,

    ...getEngineStyle.Reducers,
    ...updateEngineStyle.Reducers,
    ...removeMedia.Reducers,

    ...createExitSurveyResponse.Reducers,
    ...updateExitSurveyResponse.Reducers,
    ...deleteExitSurveyResponse.Reducers,

    ...createIntermediate.Reducers,
    ...updateTreatment.Reducers,
    ...archiveTreatment.Reducers,
    ...unarchiveTreatment.Reducers,
    ...deleteTreatment.Reducers,

    ...updatePause.Reducers,
    ...updatePauseOptions.Reducers,
    ...getPauseOptions.Reducers,

    ...getDashboardStatistics.Reducers,
    ...getSurvivalAnalysisData.Reducers,
    ...previewNotificationEmail.Reducers,

    ...getOAuthURL.Reducers,
    ...adminSearchEngine.Reducers,
    ...getSingleExperienceDetails.Reducers,
    ...toggleIsTestExperience.Reducers,

    ...getStaticOffboardValues.Reducers,
    ...getDashboardExperiencesData.Reducers,
  },
});

export const {
  livePreviewTreatment,
  openPreviewStep,
  closePreview,
  updatePreviewStyle,
  setWizardStepPath,
  setWizardSlug,
  setWizardEngine,
  toggleCollapseExitSurvey,
  toggleCollapseTreatments,
  toggleCollapsePauses,
  togglePauseActive,
  toggleShowCancelAlternative,
  toggleTreatmentActive,
  toggleExitSurveyResponseTreatment,
  setDashboardDates,
  setDashboardFilterResponses,
  stepNavigate,
  navigateTo,
  nextStep,
} = wizardSlice.actions;

export const wizardSelector = (state: any) => state.wizard;

export const staticValuesSelector = (state: any) =>
  wizardSelector(state).offboardStaticValues as OffboardStaticValues;

export const availableTreatmentsSelector = (state: any) =>
  wizardSelector(state).availableTreatments || ({} as AvailableTreatmentMap);

export const pauseOptionsSelector = (state: any) =>
  wizardSelector(state).pauseOptions;

export const availablePausesSelector = (state: any) =>
  wizardSelector(state).availablePauses;

export const availableEnginesSelector = (state: any) =>
  wizardSelector(state).availableEngines;

export const notificationSettingsSelector = (state: any) =>
  wizardSelector(state).notificationSettings;

export const engineStyleSelector = (state: any) => wizardSelector(state).style;

export const dashboardExperiencesSelector = (state: any) =>
  wizardSelector(state).dashboardExperiences;

export const displayDataSelector = (state: any) =>
  wizardSelector(state).displayData;

export const currentEngineSlugSelector = (state: any) => {
  return displayDataSelector(state).currentEngine;
};

export const wizardEngineSelector = (state: any) => {
  const slug = currentEngineSlugSelector(state);
  return slug ? wizardSelector(state).availableEngines[slug] : null;
};

export const builderSelector = (state: any) =>
  wizardSelector(state).builderData;

export const exitSurveyResponsesSelector = (state: any) =>
  wizardSelector(state).availableExitSurveyResponses;

export const exitSurveyOtherReasonResponseSlugSelector = (state: any) => {
  const find = Object.keys(
    wizardSelector(state).availableExitSurveyResponses
  ).filter(
    (key: string) =>
      wizardSelector(state).availableExitSurveyResponses[key].reason_text ===
      "Other Reason"
  );
  if (find.length) return find[0];
};
export const exitSurveyOtherReasonResponseSelector = (state: any) => {
  const slug = exitSurveyOtherReasonResponseSlugSelector(state);
  if (slug) return wizardSelector(state).availableExitSurveyResponses[slug];
};

export const exitSurveyImmediateCancelReasonResponseSlugSelector = (
  state: any
) => {
  const find = Object.keys(
    wizardSelector(state).availableExitSurveyResponses
  ).filter(
    (key: string) =>
      wizardSelector(state).availableExitSurveyResponses[key].reason_text ===
      "Immediate Cancel"
  );
  if (find.length) return find[0];
};

export const exitSurveyResponsesEnabledSlugsSelector = (state: any) => {
  const responses = exitSurveyResponsesSelector(state);
  return Object.keys(responses).filter(
    (slug: string) => responses[slug].active
  );
};

export const pausesEnabledSlugsSelector = (state: any) => {
  const pauses = availablePausesSelector(state);
  return Object.keys(pauses).filter((slug: string) => pauses[slug].active);
};

export const treatmentsEnabledSlugsSelector = (state: any) => {
  const treatments = availableTreatmentsSelector(state);
  const slugs = Object.keys(treatments);
  return slugs.filter((s: string) => treatments[s].active);
};

export const treatmentsNotArchivedSlugsSelector = (state: any) => {
  const treatments = availableTreatmentsSelector(state);
  const slugs = Object.keys(treatments);
  return slugs.filter((s: string) => treatments[s].archived === false);
};

export const treatmentsArchivedSlugsSelector = (state: any) => {
  const treatments = availableTreatmentsSelector(state);
  const slugs = Object.keys(treatments);
  return slugs.filter((s: string) => treatments[s].archived);
};

export const currentStepPathSelector = (state: any) =>
  displayDataSelector(state).currentStepPath;

export const currentStepSelector = (state: any) =>
  WizardSteps[displayDataSelector(state).currentStepPath];

export const currentSlugSelector = (state: any) =>
  displayDataSelector(state).currentSlug;

export const selectedExitResponseSelector = (state: any) =>
  state.wizard.displayData.currentStepPath ===
  MECHANICS_URLS.exit_survey_response
    ? exitSurveyResponsesSelector(state)[currentSlugSelector(state)]
    : null;

export const dashboardStatisticsSelector = (state: any) =>
  wizardSelector(state).dashboardStatistics;

export const survivalAnalysisSelector = (state: any) =>
  wizardSelector(state).survivalAnalysis;

export const dashboardFilterResponseIdsSelector = (state: any) =>
  displayDataSelector(state).dashboardResponsesFilter || [];

export const dashboardStatsDataSelector = (state: any) =>
  dashboardStatisticsSelector(state).data || ({} as StatisticData);

export const dashboardStartDateSelector = (state: any) => {
  return displayDataSelector(state).dashboardStartDate;
};

export const dashboardEndDateSelector = (state: any) => {
  return (
    displayDataSelector(state).dashboardEndDate || formatDateString(moment())
  );
};

export const dashboardFiltersSelector = (state: any) => {
  return {
    start_date: dashboardStartDateSelector(state),
    end_date: dashboardEndDateSelector(state),
    reason_ids: dashboardFilterResponseIdsSelector(state),
    engine: displayDataSelector(state).currentEngine,
  };
};

export default wizardSlice.reducer;
