import { makeStyles } from "@material-ui/core/styles";
import TextField, { TextFieldProps } from "@material-ui/core/TextField";
import { forwardRef } from "react";

// Helpful pointers for styling TextField element and dealing with border colors
// https://codeburst.io/my-journey-to-make-styling-with-material-ui-right-6a44f7c68113

const useStyles = makeStyles((theme) => ({
  outlinedRoot: {
    background: "rgba(255,255,255,0.18)",
    "&:hover $notchedOutline, &:active $notchedOutline": {
      borderColor: "#fff",
    },
    "&$focused $notchedOutline": {
      borderColor: "#fff",
      borderWidth: 1,
    },
  },
  labelRoot: {
    color: "#fff !important",
  },
  notchedOutline: {},
  focused: {},
}));

const TransparentTextField = forwardRef((props: TextFieldProps, ref) => {
  const classes = useStyles();
  const InputProps = {
    ...props.InputProps,
    classes: {
      root: classes.outlinedRoot,
      notchedOutline: classes.notchedOutline,
      focused: classes.focused,
    },
  };

  return (
    <TextField
      {...props}
      inputRef={ref}
      InputProps={InputProps}
      InputLabelProps={{ classes: { root: classes.labelRoot } }}
    />
  );
});
export default TransparentTextField;
