import Typography from "@material-ui/core/Typography";
import {Tooltip} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";

export interface GraphCardHeaderProps {
    text: string;
    tooltip?: string;
}

export const GraphCardHeader = (props: GraphCardHeaderProps) => {
    return (
        <Typography variant="h5">
            {props.text}
            {props.tooltip && (
                <Tooltip title={props.tooltip} placement="top">
                    <IconButton aria-label="delete" size="small" style={{paddingTop: 0, paddingBottom: "5px"}}>
                        <HelpOutlineIcon style={{fontSize: "18px"}}></HelpOutlineIcon>
                    </IconButton>
                </Tooltip>
            )}
        </Typography>
    )
}