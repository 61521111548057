import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import { mergeErrors } from "app/forms";

// Material UI
import Switch from "@material-ui/core/Switch";

// components
import Loader from "components/loading/Loader";
import { States } from "../../offboarding/containers/StateViewRouter/StateViewRouter";
import PanelHeader from "components/wizard/PanelHeader";
import ContextVariableList from "components/wizard/ContextVariableList";
import FormSectionHeader from "components/wizard/FormSectionHeader";

import {
  currentEngineSlugSelector,
  openPreviewStep,
  displayDataSelector,
  closePreview,
  getEngineStyle,
  engineStyleSelector,
  wizardEngineSelector,
  updateEngineStyle,
  getEngines,
  updateEngine,
  removeMedia,
  toggleShowCancelAlternative,
  staticValuesSelector,
} from "../slice/wizardSlice";

// Material UI
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import FormGroup from "@material-ui/core/FormGroup";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";

// styles
import { makeStyles } from "@material-ui/core/styles";
import commonStyles from "../../../components/layout/commonStyles";
import wizardStyles from "../wizardStyles";
import clsx from "clsx";
import IconButton from "@material-ui/core/IconButton";
import Cancel from "@material-ui/icons/Cancel";

const useStyles = makeStyles((theme) => ({
  ...commonStyles(theme),
  ...wizardStyles(theme),
}));

export default function CancelledPanel() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const currentEngineSlug = useSelector(currentEngineSlugSelector);
  const engineStylesReady = useSelector(getEngineStyle.isReadySelector);
  const updateEngineStyleErrors = useSelector(
    updateEngineStyle.ExtraErrorsSelector
  );
  const isFetchingUpdateStyle = useSelector(
    updateEngineStyle.isFetchingSelector
  );
  const isFetchingUpdateEngine = useSelector(updateEngine.isFetchingSelector);
  const engineReady = useSelector(getEngines.isReadySelector);
  const style = useSelector(engineStyleSelector);
  const engine = useSelector(wizardEngineSelector);
  const inPreview = useSelector(displayDataSelector).showPreviewStep;
  const staticValues = useSelector(staticValuesSelector);
  const inCancelPreview = inPreview && inPreview.step === States.CANCEL;
  const inCancelAfterErrorPreview =
    inPreview && inPreview.step === States.CANCEL_AFTER_ERROR;
  const cancelImageRef = useRef<HTMLInputElement | null>(null);
  const [cancelImage, setCancelImage] = useState<File>();
  const [supportEmail, setSupportEmail] = useState("");

  const {
    handleSubmit,
    control,
    formState: { errors: frontendErrors },
  } = useForm();

  const onSubmit = handleSubmit((data) => {
    data["show_cancel_alternative_action"] =
      style.show_cancel_alternative_action;
    dispatch(
      updateEngineStyle.Action({
        slug: currentEngineSlug,
        ...data,
        ...(cancelImage?.name
          ? { confirm_cancel_image: cancelImage }
          : undefined),
      })
    );
  });

  const handleSubmitEngineChanges = () => {
    dispatch(
      updateEngine.Action({
        slug: currentEngineSlug,
        support_email: supportEmail,
      })
    );
  };

  const handleToggleCancelAlternative = () => {
    dispatch(toggleShowCancelAlternative());
  };

  const errors = mergeErrors(frontendErrors, updateEngineStyleErrors);

  useEffect(() => {
    if (currentEngineSlug) {
      dispatch(getEngineStyle.Action(currentEngineSlug));
      dispatch(
        openPreviewStep({
          step: States.CANCEL,
        })
      );
    }
  }, [dispatch, currentEngineSlug]);

  useEffect(() => {
    if (engineReady) {
      setSupportEmail(engine.support_email);
    }
  }, [engineReady, engine.support_email]);

  if (!engineStylesReady || !engineReady) {
    return <Loader reason="Fetching engine style data..." padding />;
  }

  return (
    <div className={classes.mdPadding}>
      <PanelHeader
        title="Confirm Cancellation Screen"
        onClick={() =>
          dispatch(
            inCancelPreview
              ? closePreview()
              : openPreviewStep({ step: States.CANCEL })
          )
        }
        headerInPreview={inCancelPreview}
      />
      <Divider className={classes.headingDivider} />

      <form onSubmit={onSubmit}>
        <Typography variant="h6" className={classes.panelSubheader}>
          Exit Confirmation
        </Typography>
        <Typography variant="body2" className={classes.gray}>
          This screen is shown after a user has seen the Cancel Treatments and
          Pause options.
        </Typography>
        <ContextVariableList
          contextVariables={style.context_vars}
          textType="confirm cancellation header and description"
        />
        <FormGroup>
          <Controller
            control={control}
            name="confirm_cancel_heading"
            rules={{ required: true }}
            defaultValue={style.confirm_cancel_heading}
            render={({ field }) => (
              <TextField
                {...field}
                InputLabelProps={{ shrink: true }}
                label="HEADER"
                variant="outlined"
                error={Boolean(errors.get("confirm_cancel_heading"))}
                helperText={errors.get("confirm_cancel_heading")}
              />
            )}
          />
          <Controller
            control={control}
            name="confirm_cancel_description"
            defaultValue={style.confirm_cancel_description}
            render={({ field }) => (
              <TextField
                {...field}
                InputLabelProps={{ shrink: true }}
                label="DESCRIPTION"
                variant="outlined"
                error={Boolean(errors.get("confirm_cancel_description"))}
                helperText={errors.get("confirm_cancel_description")}
                placeholder="We would love to see you stay."
              />
            )}
          />

          <Controller
            control={control}
            name="confirm_cancel_button"
            defaultValue={style.confirm_cancel_button}
            render={({ field }) => (
              <TextField
                {...field}
                InputLabelProps={{ shrink: true }}
                label="BUTTON TEXT"
                variant="outlined"
                error={Boolean(errors.get("confirm_cancel_button"))}
                helperText={errors.get("confirm_cancel_button")}
                placeholder="Confirm Cancel"
              />
            )}
          />
          <Grid container alignItems="center" spacing={2}>
            {style?.confirm_cancel_image ? (
              <Grid item xs={2} xl={1}>
                <IconButton
                  onClick={() => {
                    setCancelImage(undefined);
                    dispatch(
                      removeMedia.Action({
                        slug: currentEngineSlug,
                        media_type: "confirm_cancel_image",
                      })
                    );
                  }}
                >
                  <Cancel />
                </IconButton>
              </Grid>
            ) : null}
            <Grid item xs={10} lg={5}>
              <TextField
                value={
                  cancelImage?.name ||
                  style.confirm_cancel_image ||
                  "no image selected"
                }
                variant="outlined"
                size="small"
                disabled
              />
            </Grid>
            <Grid item xs={12} lg={5}>
              <input
                accept={
                  staticValues
                    ? staticValues.allowed_image_types.join(",")
                    : "image/*"
                }
                style={{ display: "none" }}
                type="file"
                ref={cancelImageRef}
                onChange={(e) => {
                  if (
                    staticValues &&
                    e.target?.files &&
                    e.target?.files[0].size > staticValues.max_image_size
                  )
                    return alert(
                      `Max file size is ${
                        staticValues.max_image_size / 1024 / 1024
                      }MB`
                    );
                  setCancelImage(
                    e.target.files ? e.target?.files[0] : undefined
                  );
                }}
              />
              <Button
                className={classes.panelButton}
                onClick={() =>
                  cancelImageRef.current && cancelImageRef.current.click()
                }
              >
                Upload Image
              </Button>
            </Grid>
          </Grid>

          <Typography variant="h6" className={classes.panelSubheader}>
            Add Alternative Action
          </Typography>
          <Typography variant="body2" className={classes.gray}>
            Offer customers an additional webpage redirect as an alternative to
            cancellation
          </Typography>
          <Switch
            checked={style.show_cancel_alternative_action}
            onChange={() => handleToggleCancelAlternative()}
            name="show_alternative_cancel_action"
            inputProps={{ "aria-label": "alternative cancel action" }}
          />
          {style?.show_cancel_alternative_action ? (
            <FormGroup>
              <Controller
                control={control}
                name="cancel_alternative_action_text"
                defaultValue={style.cancel_alternative_action_text}
                render={({ field }) => (
                  <TextField
                    {...field}
                    InputLabelProps={{ shrink: true }}
                    label="ACTION TEXT"
                    variant="outlined"
                    error={Boolean(
                      errors.get("cancel_alternative_action_text")
                    )}
                    helperText={errors.get("cancel_alternative_action_text")}
                  />
                )}
              />
              <Controller
                control={control}
                name="cancel_alternative_action_url"
                defaultValue={style.cancel_alternative_action_url}
                render={({ field }) => (
                  <TextField
                    {...field}
                    InputLabelProps={{ shrink: true }}
                    label="ACTION URL"
                    variant="outlined"
                    error={Boolean(errors.get("cancel_alternative_action_url"))}
                    helperText={errors.get("cancel_alternative_action_url")}
                  />
                )}
              />
            </FormGroup>
          ) : null}
        </FormGroup>

        {engine.allows_cancel_bundle ? (
          <>
            <FormSectionHeader
              title="Bundle Subscription Disclaimer"
              showPreview
              headerInPreview={inCancelPreview}
              previewStep={States.CANCEL}
              extraPreviewProps={{ style: { show_cancel_bundle: true } }}
            />
            <FormGroup>
              <Controller
                control={control}
                name="bundle_subscription_disclaimer"
                defaultValue={style.bundle_subscription_disclaimer}
                render={({ field }) => (
                  <TextField
                    {...field}
                    InputLabelProps={{ shrink: true }}
                    label="ACTION URL"
                    variant="outlined"
                    error={Boolean(
                      errors.get("bundle_subscription_disclaimer")
                    )}
                    helperText={errors.get("bundle_subscription_disclaimer")}
                  />
                )}
              />
            </FormGroup>
          </>
        ) : null}

        <FormSectionHeader title="Allow Cancel After Save" />
        <Typography variant="body2" className={classes.gray}>
          Toggling this to active will allow customers to cancel their
          subscription even after they have been saved by an an experience.
        </Typography>
        <FormGroup>
          <Switch
            checked={style.show_force_cancel_button}
            disabled={!engineReady && isFetchingUpdateEngine}
            onChange={() =>
              dispatch(
                updateEngineStyle.Action({
                  show_force_cancel_button: !style.show_force_cancel_button,
                  slug: engine.slug,
                })
              )
            }
            name="show_force_cancel_button"
            inputProps={{
              "aria-label": "display force cancel after save toggle",
            }}
          />
          {style.show_force_cancel_button ? (
            <Controller
              control={control}
              name="force_cancel_button_text"
              rules={{ required: true }}
              defaultValue={style.force_cancel_button_text}
              render={({ field }) => (
                <TextField
                  {...field}
                  InputLabelProps={{ shrink: true }}
                  label="FORCE CANCEL BUTTON TEXT"
                  variant="outlined"
                  error={Boolean(errors.get("force_cancel_button_text"))}
                  helperText={errors.get("force_cancel_button_text")}
                />
              )}
            />
          ) : null}
        </FormGroup>

        <Button
          type="submit"
          className={clsx(classes.panelButton, classes.marginTop)}
          disabled={isFetchingUpdateStyle}
        >
          Save Changes
        </Button>
      </form>

      {engine.allows_cancel_fail_safe ? (
        <>
          <Divider className={classes.headingDivider} />
          <form onSubmit={onSubmit}>
            <FormSectionHeader
              title="Errored Experience Cancel Confirmation"
              showPreview
              headerInPreview={inCancelAfterErrorPreview}
              previewStep={States.CANCEL_AFTER_ERROR}
            />

            <Typography variant="body2" className={classes.gray}>
              This screen is shown if an error occurs while trying to save a
              customer. They will still have the option to cancel their
              subscription.
            </Typography>
            <FormGroup>
              <Controller
                control={control}
                name="cancel_after_error_heading"
                rules={{ required: true }}
                defaultValue={style.cancel_after_error_heading}
                render={({ field }) => (
                  <TextField
                    {...field}
                    InputLabelProps={{ shrink: true }}
                    label="HEADER"
                    variant="outlined"
                    error={Boolean(errors.get("cancel_after_error_heading"))}
                    helperText={errors.get("cancel_after_error_heading")}
                    placeholder="We encountered an error"
                  />
                )}
              />
              <Controller
                control={control}
                name="cancel_after_error_description"
                defaultValue={style.cancel_after_error_description}
                render={({ field }) => (
                  <TextField
                    {...field}
                    InputLabelProps={{ shrink: true }}
                    label="DESCRIPTION"
                    variant="outlined"
                    error={Boolean(
                      errors.get("cancel_after_error_description")
                    )}
                    helperText={errors.get("cancel_after_error_description")}
                    placeholder="You can still cancel"
                  />
                )}
              />
            </FormGroup>

            <Button
              type="submit"
              className={clsx(classes.panelButton, classes.marginTop)}
              disabled={isFetchingUpdateStyle}
            >
              Save Changes
            </Button>
          </form>
        </>
      ) : null}

      <Divider className={classes.headingDivider} />
      <Typography variant="h6" className={classes.panelSubheader}>
        Contact Support Email
      </Typography>
      <Typography variant="body2" className={classes.gray}>
        If there is an error, we will display this email to your customers to
        reach out to for support.
      </Typography>
      <FormGroup>
        <TextField
          InputLabelProps={{ shrink: true }}
          label="255 CHARACTERS MAX"
          variant="outlined"
          type="email"
          value={supportEmail}
          onChange={(e) => setSupportEmail(e.target.value)}
          error={Boolean(errors.get("support_email"))}
          helperText={errors.get("support_email")}
        />
      </FormGroup>

      <Button
        className={clsx(classes.panelButton, classes.marginTop)}
        onClick={handleSubmitEngineChanges}
        disabled={isFetchingUpdateEngine}
      >
        Save Changes
      </Button>
    </div>
  );
}
