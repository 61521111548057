import { useForm, Controller } from "react-hook-form";
import { changePassword, PasswordChangeFields } from "../userSlice";
import { useDispatch, useSelector } from "react-redux";

// components
import UserSettingsLayout from "./UserSettingsLayout";
import { mergeErrors } from "app/forms";

// Material UI
import Typography from "@material-ui/core/Typography";
import FormGroup from "@material-ui/core/FormGroup";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";

// styles
import commonStyles from "components/layout/commonStyles";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import styles from "./userSettingsStyles";
import { useEffect } from "react";

const useStyles = makeStyles((theme) => ({
  ...commonStyles(theme),
  ...styles(theme),
}));

export default function Password() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const changePasswordErrors = useSelector(changePassword.ExtraErrorsSelector);
  const changePasswordSuccess = useSelector(changePassword.isReadySelector);

  const {
    handleSubmit,
    control,
    formState: { errors: frontendErrors },
    reset,
  } = useForm();

  const onSubmit = handleSubmit((data) => {
    dispatch(changePassword.Action(data as PasswordChangeFields));
  });

  const errors = mergeErrors(frontendErrors, changePasswordErrors);

  useEffect(() => {
    if (changePasswordSuccess?.detail) {
      reset({ old_password: "", new_password1: "", new_password2: "" });
      dispatch(changePassword.Reset());
    }
  }, [changePasswordSuccess?.detail, dispatch, reset]);

  return (
    <UserSettingsLayout>
      <Typography variant="h4" className={classes.panelHeader}>
        Change Password
      </Typography>
      <form onSubmit={onSubmit}>
        <FormGroup className={classes.marginTop}>
          <Controller
            control={control}
            name="old_password"
            rules={{ required: true }}
            defaultValue=""
            render={({ field }) => (
              <TextField
                {...field}
                InputLabelProps={{ shrink: true }}
                label="OLD PASSWORD"
                variant="outlined"
                type="password"
                error={Boolean(errors.get("old_password"))}
                helperText={errors.get("old_password")}
              />
            )}
          />

          <Controller
            control={control}
            name="new_password1"
            rules={{ required: true }}
            defaultValue=""
            render={({ field }) => (
              <TextField
                {...field}
                InputLabelProps={{ shrink: true }}
                label="NEW PASSWORD"
                variant="outlined"
                type="password"
                error={Boolean(errors.get("new_password1"))}
                helperText={errors.get("new_password1")}
              />
            )}
          />

          <Controller
            control={control}
            name="new_password2"
            rules={{ required: true }}
            defaultValue=""
            render={({ field }) => (
              <TextField
                {...field}
                InputLabelProps={{ shrink: true }}
                label="CONFIRM NEW PASSWORD"
                variant="outlined"
                type="password"
                error={Boolean(errors.get("new_password2"))}
                helperText={errors.get("new_password2")}
              />
            )}
          />
        </FormGroup>
        <Button color="primary" type="submit" variant="contained">
          Save
        </Button>
      </form>
      <Typography
        variant="body2"
        className={clsx(classes.marginTop, classes.gray)}
      >
        If you're having trouble changing your password, you can email us at
        {"  "}
        <a href="mailto:hello@bellwethr.com" className={classes.purpleTextLink}>
          hello@bellwethr.com
        </a>
        .
      </Typography>
    </UserSettingsLayout>
  );
}
