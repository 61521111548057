import { EndScreenProps } from "./../../offboarding/views/EndScreen/EndScreen";
import { States } from "../../offboarding/containers/StateViewRouter/StateViewRouter";

export enum AppEnvironmentEnums {
  PROD = "PROD",
  STAGING = "STAGING",
  RE = "RE",
}

export interface AvailableExitSurveyResponseMap {
  [key: string]: ExitSurveyResponse;
}

export interface ExitSurveyResponse {
  id: number;
  slug: string;
  reason_text: string;
  show_pauses: boolean;
  offer_ids: string[];
  active: boolean;
  allow_additional_comment_on_reason_select: boolean;
  offboard_slug?: string;
}

export interface AvailableTreatmentMap {
  [key: string]: Intermediate;
}

export interface PauseOptions {
  conditionals: [IntermediateConditional];
}

export interface UpdatePauseOptionsParams extends PauseOptions {
  engine: string;
}

export interface Pause extends Intermediate {
  resume_pause_text: string;
  display_title: string;
  pause_term: string;
  pause_term_display_name: string;
}

export interface AvailablePauseMap {
  [key: string]: Pause;
}

export interface OffboardSnippetData {
  embedded_js: string[];
  embedded_js_instructions: string;
  embedded_link: string[];
  embedded_link_instructions: string;
  snippet_code: string[];
  snippet_epilogue: string;
  snippet_prologue: string;
}

export interface EngineCustomerPaymentPlan {
  id: number;
  plan_id: string;
  name: string;
  product: string;
  amount: string;
  currency: string;
  interval_count: string;
  interval_unit: string;
}
export interface EnginePredefinedCoupon {
  id: number;
  name: string;
}

export type ConditionalDataType = "string" | "number" | "date";

export enum EndScreenImageEnum {
  NO_IMAGE = "NO_IMAGE",
  DEFAULT = "DEFAULT",
  CUSTOM = "CUSTOM",
}

export interface ContextNameData {
  [k: string]: {
    human_name: string;
    data_type: ConditionalDataType;
  };
}

export interface OffboardEngine {
  slug: string;
  automation_slug: string;
  payment_engine: string;
  website_url: string;
  support_phone: string;
  support_email: string;
  name: string;
  locale_code: string;

  allow_additional_comment: boolean;
  allowed_intermediates: string[];
  allows_continuous_offer: boolean;
  available_offers: AvailableTreatmentMap;
  available_pauses: AvailablePauseMap;
  exit_responses: AvailableExitSurveyResponseMap;
  snippet_data: OffboardSnippetData;
  payment_plans: EngineCustomerPaymentPlan[];
  predefined_coupons: EnginePredefinedCoupon[];
  resolution_claimed_redirect_url: string;
  pause_claimed_redirect_url: string;
  customer_canceled_redirect_url: string;
  context_names: object;
  conditionals_data: {
    allowed_conditions_for_data_type: { [k: string]: string[] };
    context_names: ContextNameData;
    conditions: object;
  };
  apply_discount_to_address: boolean;
  show_cancel_bundle: boolean;
  days_before_new_gift: number;
  days_before_new_discount: number;
  replace_payment_engine?: string;
  custom_subscription_url?: string;
  allows_cancel_bundle: boolean;
  allows_cancel_fail_safe: boolean;
  customisable_texts?: {
    styling: object;
    intermediates: object;
  };
  contact_support_for_cancel: boolean;
  allow_global_immediate_cancel: boolean;
  allow_california_immediate_cancel: boolean;
  client: {
    slug: string;
    organization_name: string;
  };
}

export interface DashboardStatistics {
  slug: string;
  start_date: string;
  end_date: string;
  download_experiences_endpoint: string;
  reason_ids?: number[] | null;
  data: StatisticData;
}

interface SurvivalAnalysisGraphs {
  display_name: string;
  graph_name: string;
  x_axis_type: string;
  data: {
    x: number[];
    y: number[];
    col_val?: string[];
  };
}

interface SurvivalAnalysisGraphGroup {
  group_id: { graph_cohort: string; month: string };
  graph_data: SurvivalAnalysisGraphs;
}

interface SurvivalAnalysisGraphType {
  display_name: string;
  groups: SurvivalAnalysisGraphGroup[];
}

export interface SurvivalAnalysis {
  offboard_slug: string;
  graph_types: { [key: string]: SurvivalAnalysisGraphType };
}

interface MainStats<dType> {
  save_rate: dType;
  mrr_saved: dType;
  mrr_lost: dType;
  n_saved: dType;
  n_lost: dType;
  n_unique_experiences: dType;
  n_duplicate_experiences: dType;
  n_all_experiences: dType;
  n_invalid_experiences: dType;
  n_in_progress_experiences: dType;
}

interface CatMainStats<dType> extends MainStats<dType> {
  n_saved_by_this: dType;
}

interface AggregateStats {
  sum: number;
  mean: number;
  min: number;
  max: number;
}

interface CatValStats<dtype> {
  [val: string]: CatMainStats<dtype>;
}

interface CatStats<dtype> {
  [col: string]: CatValStats<dtype>;
}

interface DateItem {
  date: string;
  stats: MainStats<number>;
  cat: CatStats<number>;
}

interface OverallStats {
  stats: MainStats<AggregateStats>;
  cat: CatStats<AggregateStats>;
}

export interface OverviewStats {
  dates: DateItem[];
  overall: OverallStats;
}

interface CategoryValStats {
  cat_val_1: string;
  cat_val_2: string;
  n_unique_experiences: number;
}

interface CategoryStats {
  cat_1: string;
  cat_2: string;
  val_stats: CategoryValStats[];
}

export interface ExitSurveyData {
  overall: OverallStats;
  cat_overlap: CategoryStats[];
}

export interface StatisticData {
  overview: OverviewStats;
  exit_survey: ExitSurveyData;
}

export interface TopIntermediateStatistics {
  name: string;
  shown: number;
  selected: number;
  percent_selected: string;
  headline?: string;
  display_title?: string;
  description?: string;
  slug?: string;
}

export interface ExperienceStat {
  id: string;
  timestamp: string;
  additional_comment: string | null;
  receipts: number;
  bulk_subscriptions: null;
  customer_id: string;
  user_reason: string;
  plan_before: string;
  subscription_id: string;
  customer_email: string;
  offer_shown_details: ExperienceStatOffer;
  offer_shown: string;
  offer_claimed: string;
  outcome: string;
  alid: string;
  other_experiences: string[];
  test_experience: boolean;
  subscription_signup_date: string;
  num_orders: number | null;
}

export interface ExperienceStatData {
  experiences: ExperienceStat[];
}

export interface SingleExperienceDetail {
  alid: string;
  test_experience: boolean;

  created: string;
  customer_id: string;
  subscription_id: string;
  customer_email: string;
  user_reason: string;
  plan_before: string;
  option_selected: string;
  additional_comment: null | string;
  action: string;
  bulk_subscriptions: string[];

  error_occurred: boolean;
  error_message: string;
  offers_shown: { name: string; type: string }[];
  outcome: string;
  context_variables_values: object;

  other_experiences: {
    outcome: string;
    chosen_offer: string;
    created: string;
    reason: string;
  }[];
}

export interface ExperienceStatOffer {
  slug: string;
  display_title: string;
  headline: string;
  description: string;
  name: string;
}

export interface CustomerInterview {
  timestamp: string;
  interviewee: string;
  reason: string;
  ltv: string;
  segment: string;
}

export interface AvailableEngineMap {
  [key: string]: OffboardEngine;
}

export interface UpdateEngineFields {
  website_url: string;
  resolution_claimed_redirect_url?: string;
  pause_claimed_redirect_url?: string;
  customer_canceled_redirect_url?: string;
  support_phone?: string;
  payment_api_key?: string;
  payment_api_secret?: string;
  payment_api_domain?: string;
  name?: string;
  custom_subscription_url?: string;
  replace_payment_engine?: string;
  support_email?: string;
  allow_additional_comment?: boolean;

  days_before_new_gift?: number;
  days_before_new_discount?: number;
  slug?: string;
  updateSlug?: string;
  show_cancel_bundle?: boolean;
  apply_discount_to_address?: boolean;
  contact_support_for_cancel?: boolean;
  payment_engine:
    | "STRIPE"
    | "CRATEJOY"
    | "RECHARGE"
    | "CHARGEBEE"
    | "CHARGIFY"
    | "BOLD_SUBSCRIPTIONS"
    | "WOOCOMMERCE"
    | "RECURLY"
    | "BRAINTREE"
    | "RETEXTION"
    | "NONE";
  active: boolean;
  allow_global_immediate_cancel?: boolean;
  allow_california_immediate_cancel?: boolean;
  cool_down_period?: boolean;
}

export interface DeleteEngineFields {
  data: {
    password: string;
  };
  slug: string;
}

export interface ActivateEngineFields {
  active: boolean;
  slug: string;
}

export enum NotificationPeriod {
  INSTANT = "INSTANT",
  DAILY = "DAILY",
  WEEKLY = "WEEKLY",
  MONTHLY = "MONTHLY",
  NEVER = "NEVER",
}

export interface NotificationSettings {
  team_notification_emails: string[];
  customer_email_sender: string;
  customer_email_signature: string;
  instant_gift_notifications: boolean;
  notification_period: NotificationPeriod;
  send_customer_resolution_claimed: boolean;
  send_customer_pause_claimed: boolean;
  send_customer_subscription_canceled: boolean;
  send_customer_reactivate_pause: boolean;
  send_client_pause_claimed: boolean;
  send_client_reactivate_pause: boolean;
  send_client_resolution_claimed: boolean;
  send_client_subscription_canceled: boolean;
  field_descriptions: { [key: string]: string };
  offboard: string;
  slug: string;
}

export interface UpdateNotificationSettings
  extends Partial<NotificationSettings> {
  slug: string;
}

export interface EngineStyle {
  experience_tab_title: string;
  font: string;
  logo?: File | string;
  show_customer_lost_image?: "DEFAULT" | "CUSTOM" | "NO_IMAGE";
  show_customer_saved_image?: "DEFAULT" | "CUSTOM" | "NO_IMAGE";
  primary_color: string;
  secondary_color: string;
  announcement_banner_text?: string;
  announcement_banner_active: boolean;
  customer_lost_button_text: string;
  customer_lost_description: string;
  customer_lost_description_for_prepaid: string;
  exit_survey_heading: string;
  confirm_cancel_heading: string;
  confirm_cancel_description?: string;
  confirm_cancel_image?: string;
  customer_saved_image?: string;
  customer_lost_image?: string;
  pause_disclaimer_text?: string;
  custom_font_url?: string;
  custom_font_family?: string;
  slug: string;
  show_cancel_alternative_action: boolean;
  cancel_alternative_action_text?: string;
  cancel_alternative_action_url?: string;
  custom_css?: string;
  customer_lost_headline: string;
  confirm_cancel_button: string;
  other_reason_button: string;
  other_reason_headline: string;
  other_reason_placeholder?: string;

  pause_select_text?: string;
  pause_success_headline: string;
  pause_headline: string;
  pause_success_subtext?: string;

  additional_comments_headline: string;
  additional_comments_placeholder?: string;
  additional_comments_reason_selected_text: string;

  bundle_subscription_disclaimer: string;
  go_back_button: string;
  continue_to_cancel: string;
  submit_button: string;
  back_home_button: string;

  cancel_after_error_heading?: string;
  cancel_after_error_description?: string;
  immediate_cancel_text: string;

  contact_support_for_cancel_description: string;
  contact_support_for_cancel_heading: string;

  error_heading: string;
  default_error_description: string;
  already_cancelled_description: string;

  cool_down_period: boolean;
  embedded_experience_refresh_page_text: string;
  force_cancel_button_text: string;
}

export interface Fonts {
  name: string;
}

export type IntermediateTypes =
  | "AMOUNT_DISCOUNT"
  | "PERCENT_DISCOUNT"
  | "GIFT"
  | "RELEVANT_INFORMATION"
  | "PAUSE"
  | "PAYMENT_PLAN"
  | "SWAP_PRODUCT"
  | "COUPON"
  | "PREDEF_COUPON"
  | "CONTACT_SUPPORT"
  | "FREQUENCY_CHANGE"
  | "SERVICE_CREDITS"
  | "MULTIPLE_PRODUCTS_SWAP_OPTIONS"
  | "AUTOMATED_GIFT";

export interface UpsertIntermediateFields {
  type: IntermediateTypes;
  name: string;
  display_title: string;
  button_text: string;
  headline?: string;
  description?: string;
  offboard_slug: string;
  coupon?: string;
  swap_product_id?: string;
  payment_plan?: string;
  price?: string;
  discount_percent?: number;
  contact_email?: string;
  url?: string;
  apply_offer_count?: number;
  image?: File;
  image_url?: string;
  video?: File;
  video_url?: string;
  slug: string;
  active?: boolean;
  archived?: boolean;
  order_interval_value?: number;
  order_interval_unit?: string;
  secondary_display_title?: string;
  secondary_button_text?: string;
}

export interface Intermediate extends IntermediatePreviewBase {
  id: number;
  active: boolean;
  apply_offer_count: number;
  conditionals: IntermediateConditional[];
  slug: string;
  archived: boolean;
  swap_product_id?: string;
  payment_plan?: string;
  coupon?: string;
}

export interface IntermediatePreviewBase {
  name: string;
  type: IntermediateTypes;
  display_title: string;
  headline?: string;
  image?: string;
  video?: string;
  button_text: string;
  description?: string;
  offer_claimed_heading?: string;
  offer_claimed_button_text?: string;
  secondary_button_text?: string;
  secondary_display_title?: string;
}

export interface DeleteTreatmentParams {
  engine: string;
  slug: string;
}

export interface ArchiveTreatmentParams {
  engine: string;
  slug: string;
  archived: boolean;
}

export interface UpdatePauseParams {
  engine: string;
  slug: string;
  active?: boolean;
  display_title?: string;
}

export interface StartEndDate {
  start: string;
  end?: string;
}

export interface DisplayData {
  showPreviewStep?: OpenPreview;
  currentStepPath?: string;
  currentSlug?: string;
  currentEngine?: string;
  exitSurveyCollapsed?: boolean;
  treatmentsCollapsed?: boolean;
  pausesCollapsed?: boolean;
  dashboardStartDate?: string;
  dashboardEndDate?: string;
  dashboardResponsesFilter: string[];
}

export interface IntermediateConditional {
  id: number;
  context_type: "CONTEXT";
  context_name: string;
  condition: "in" | "not in" | "gt" | "lt" | "gte" | "lte";
  values: string[];
}

export interface StepNavigation {
  path: string;
  engine?: string;
  slug?: string;
}

export interface OpenPreview {
  step: States;
  treatment?: IntermediatePreviewBase;
}

export interface OffboardSearchResult {
  name: string;
  client: string;
  slug: string;
  updated: string;
  timestamp: string;
  payment_engine: string;
}

export interface PlatformIntegrationOption {
  integration_name: string;
  image_url: string;
  key?: string;
  payment_api_key?: string;
  payment_api_secret?: string;
  payment_api_domain?: string;
}

export interface AvailablePlatformsMap {
  [key: string]: PlatformIntegrationOption;
}

export interface AvailableCountries {
  code_2: string;
  code_3: string;
  name: string;
}
export interface OffboardStaticValues {
  integration_options: AvailablePlatformsMap;
  end_screen_image_options: string[];
  fonts: string[];
  fonts_import_url: string;
  notification_periods: string[];
  countries: AvailableCountries[];
  locales: { [key: string]: string };
  allowed_image_types: string[];
  allowed_video_types: string[];
  max_image_size: number;
  max_video_size: number;
  all_intermediate_types: IntermediateTypes[];
}

export interface IframePreviewType {
  state?: States;
  style: EngineStyle | null;
  engine: OffboardEngine | null;
  reasons: AvailableExitSurveyResponseMap | null;
  pauses: AvailablePauseMap | null;
  treatments: AvailableTreatmentMap | null;
  activeTreatment: Intermediate | null;
  saved: EndScreenProps | null;
  is_prepaid?: boolean;
}
