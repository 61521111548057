import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";

// components
import { UpdateEngineFields } from "../slice/types";
import PanelHeader from "components/wizard/PanelHeader";
import { wizardEngineSelector, updateEngine } from "../slice/wizardSlice";
import { AttentionBubble } from "components/wizard/AttentionBubble";
import FormSectionHeader from "components/wizard/FormSectionHeader";

// Material UI
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import InputBase from "@material-ui/core/InputBase";
import Grid from "@material-ui/core/Grid";
import FormGroup from "@material-ui/core/FormGroup";
import Button from "@material-ui/core/Button";
import Switch from "@material-ui/core/Switch";

// styles
import { makeStyles } from "@material-ui/core/styles";
import commonStyles from "../../../components/layout/commonStyles";
import wizardStyles from "../wizardStyles";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  ...commonStyles(theme),
  ...wizardStyles(theme),
}));

export default function GamingPreventionPanel() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const currentEngine = useSelector(wizardEngineSelector);

  const { handleSubmit, control } = useForm();
  const isFetchingUpdateEngine = useSelector(updateEngine.isFetchingSelector);

  const onSubmit = handleSubmit((data) => {
    dispatch(
      updateEngine.Action({
        ...data,
        slug: currentEngine.slug,
      } as UpdateEngineFields)
    );
  });

  return (
    <div className={classes.mdPadding}>
      <PanelHeader title="Gaming Prevention" caption="Settings" />
      <Divider className={classes.headingDivider} />
      <form onSubmit={onSubmit}>
        <Typography variant="body2" className={classes.gray}>
          Prevent customers from gaming your RetentionEngine and claiming
          discounts and/or gifts every billing cycle.
          <br />
          <br />
          Choose how frequently you would like for Gift and Discount treatments
          to show for customers returning to cancel.
        </Typography>
        <Typography variant="h6" className={classes.panelSubheader}>
          If a customer claims a Discount, how long should we wait before we
          show them another discount?
        </Typography>
        <Grid container alignItems="center" spacing={2}>
          <Grid item xs={4}>
            <Typography variant="body1" className={classes.gray}>
              Show after
            </Typography>
          </Grid>
          <Grid item xs={4} md={3} lg={2}>
            <FormGroup>
              <Controller
                control={control}
                rules={{ required: true }}
                defaultValue={currentEngine.days_before_new_discount}
                name="days_before_new_discount"
                render={({ field }) => (
                  <InputBase {...field} className={classes.inputBorder} />
                )}
              />
            </FormGroup>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="body1" className={classes.gray}>
              day(s)
            </Typography>
          </Grid>
        </Grid>
        <Typography variant="h6" className={classes.panelSubheader}>
          If a customer claims a Gift, how long should we wait before we show
          them another gift?
        </Typography>
        <Grid container alignItems="center" spacing={2}>
          <Grid item xs={4}>
            <Typography variant="body1" className={classes.gray}>
              Show after
            </Typography>
          </Grid>
          <Grid item xs={4} md={3} lg={2}>
            <FormGroup>
              <Controller
                control={control}
                rules={{ required: true }}
                name="days_before_new_gift"
                defaultValue={currentEngine.days_before_new_gift}
                render={({ field }) => (
                  <InputBase {...field} className={classes.inputBorder} />
                )}
              />
            </FormGroup>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="body1" className={classes.gray}>
              day(s)
            </Typography>
          </Grid>
        </Grid>
        <AttentionBubble
          text="If you set the days
                to zero it means that type of treatment will never be shown
                again to that customer once they have claimed it."
        />
        <Button
          type="submit"
          className={clsx(classes.panelButton, classes.marginTop)}
          disabled={isFetchingUpdateEngine}
        >
          Save Changes
        </Button>

        <Divider className={classes.headingDivider} />
        <FormSectionHeader title="One Hour Cooldown Period" />
        <Typography variant="body2" className={classes.gray}>
          Once a customer goes through an experience there is a one
          hour cool down period before they can go through another experience.
        </Typography>
        <Switch
          checked={currentEngine.cool_down_period}
          disabled={isFetchingUpdateEngine}
          onChange={() =>
            dispatch(
              updateEngine.Action({
                cool_down_period:
                  !currentEngine.cool_down_period,
                slug: currentEngine.slug,
              })
            )
          }
          name="cool_down_period"
          inputProps={{ "aria-label": "one hour cool down period toggle" }}
        />

        {currentEngine.payment_engine === "RECHARGE" ? (
          <>
            <FormSectionHeader title="Override An Existing Discount" />
            <Typography variant="body2" className={classes.gray}>
              If a subscription already has a discount and a customer selects a
              new one during an experience, the existing one will be removed and
              the new one applied.
            </Typography>
            <Switch
              checked={currentEngine.override_existing_discount}
              disabled={isFetchingUpdateEngine}
              onChange={() =>
                dispatch(
                  updateEngine.Action({
                    override_existing_discount:
                      !currentEngine.override_existing_discount,
                    slug: currentEngine.slug,
                  })
                )
              }
              name="override_existing_discount"
              inputProps={{ "aria-label": "override existing discount" }}
            />
          </>
        ) : null}
      </form>
    </div>
  );
}
