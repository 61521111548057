import { Theme } from "@material-ui/core";

export default function externalLayoutStyles(theme: Theme) {
  return {
    purpleBackground: {
      background:
        "linear-gradient(180deg, #4644a4 0%, #3c1563 30%, #1c0e4a 100%)",
      width: "100%",
      minHeight: "100vh",
      display: "flex" as "flex",
      flexDirection: "column" as "column",
      zIndex: 0,
    },
    curveOverlay: {
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "bottom" as "bottom",
      position: "absolute" as "absolute",
      bottom: "0vh",
      width: "100%",
      height: "100%",
      zIndex: 1,
      opacity: 0.8,
    },
    centerMiddleContainer: {
      position: "relative" as "relative",
      flex: 1,
      zIndex: 5, // Need to be above onboarding "curve" vector zIndex: 1
      height: "100%",
      width: "100%",
      paddingTop: "4em",
      [theme.breakpoints.up("md")]: {
        textAlign: "center" as "center",
        paddingTop: "4em",
        paddingBottom: "8em",
      },
      [theme.breakpoints.up("xl")]: {
        paddingTop: "6em",
      },
    },
  };
}
