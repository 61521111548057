import React from "react";
import {LineChart, LineChartProps} from "../compact/LineChart";
import {HalfPieChart, HalfPieChartProps} from "../compact/HalfPieChart";
import {HorizontalBarChart, HorizontalBarChartProps} from "../compact/HorizontalBarChart";
import {SankeyChart, SankeyChartProps} from "../compact/SankeyChart";

export declare type GraphCardGraphProps = LineChartProps | HalfPieChartProps | HorizontalBarChartProps | SankeyChartProps;

export const GraphCardGraph = (props: GraphCardGraphProps) => {
    let _props = {...props};
    if (_props.colors === undefined)
        _props.colors = ["#8E509F", "#F3D2FF", "#CACACA", "#250542", "#5B1E71"];
    return (
        <>
            {_props.type === "line" && (<LineChart {..._props}/>)}
            {_props.type === "halfPie" && (<HalfPieChart {..._props}/>)}
            {_props.type === "hBar" && (<HorizontalBarChart {..._props}/>)}
            {_props.type === "sankey" && (<SankeyChart {..._props}/>)}
        </>
    )
}