import { FC, useState } from "react";

// Material UI
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TablePagination from "@material-ui/core/TablePagination";

// components
import { TopIntermediateStatistics } from "features/wizard/slice/types";

interface TopPausesTableProps {
  data: TopIntermediateStatistics[];
}

export const TopPausesTable: FC<TopPausesTableProps> = ({ data }) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const start = page * rowsPerPage;

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <div>
      <Table aria-label="top offers table">
        <TableHead>
          <TableRow>
            <TableCell>Pause Period</TableCell>
            <TableCell align="left"># Collected</TableCell>
            <TableCell align="left"># Shown</TableCell>
            <TableCell align="left">Save %</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.slice(start, start + rowsPerPage).map((row) => (
            <TableRow key={row.name}>
              <TableCell component="th" scope="row">
                {row.name}
              </TableCell>
              <TableCell align="left">{row.selected}</TableCell>
              <TableCell align="left">{row.shown}</TableCell>
              <TableCell align="left">{row.percent_selected}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={data.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </div>
  );
};

export default TopPausesTable;
