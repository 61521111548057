import { useEffect } from "react";

export default function SentryTesting() {
  useEffect(() => {
    // eslint-disable-next-line
    throw "Test error for sentry";
  });

  return <>Returning an error to sentry</>;
}
