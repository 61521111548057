import { useEffect, useState, useRef } from "react";
import { history } from "app/history";
import { useDispatch, useSelector } from "react-redux";

import {
  generatePath,
  Route,
  Router,
  Switch,
  useParams,
} from "react-router-dom";

// components
import Loader from "components/loading/Loader";
import { SETUP_URLS, ONBOARDING_URLS } from "../wizard/slice/urls";
import DashboardLayout from "./DashboardLayout";
import { DASHBOARD_URLS } from "./urls";
import Overview from "./Overview";
import SurvivalAnalysis from "./SurvivalAnalysis";
import ExitSurveyDash from "./ExitSurveyDash";
import ExperiencesDash from "./ExperiencesDash";

import {
  availableEnginesSelector,
  getEngines,
  setWizardEngine,
  stepNavigate,
  currentEngineSlugSelector,
  getDashboardStatistics,
  getEngine,
  dashboardStartDateSelector,
  dashboardEndDateSelector,
  displayDataSelector,
  exitSurveyResponsesSelector,
  setDashboardDates,
  dashboardStatisticsSelector,
} from "../wizard/slice/wizardSlice";
import moment from "moment";
import { userSelector } from "../user/userSlice";

interface DashboardParams {
  engine: string;
}

export const DashboardContainer = () => {
  const dispatch = useDispatch();
  const params = useParams<DashboardParams>();
  const availableEngines = useSelector(availableEnginesSelector);
  const availableEnginesCount = Object.keys(availableEngines).length;
  const enginesRequested = useSelector(getEngines.isRequestedSelector);
  const enginesReady = useSelector(getEngines.isReadySelector);
  const getEngineStarted = useSelector(getEngine.isRequestedSelector);
  const reduxEngineSlug = useSelector(currentEngineSlugSelector);
  const loadingStatistics = useSelector(
    getDashboardStatistics.isFetchingSelector
  );
  const DashboardStats = useSelector(getDashboardStatistics.ReplySelector);
  const showingOverviewInfoCards =
    !loadingStatistics &&
    DashboardStats?.data?.overview !== undefined &&
    DashboardStats.data.overview.overall.stats.n_unique_experiences.sum > 0;
  const { auth } = useSelector(userSelector);
  const isStaff = auth?.user?.is_staff;

  const start_date = useSelector(dashboardStartDateSelector);
  const end_date = useSelector(dashboardEndDateSelector);
  const fetchingStats = loadingStatistics;
  const engine = useSelector(currentEngineSlugSelector);
  const [currentReasonsCount, setCurrentReasonsCount] = useState(0);

  const [statDates, setStatDates] = useState({
    start_date,
    end_date,
  });
  const reason_ids = useSelector(
    (state) => displayDataSelector(state).dashboardResponsesFilter
  );
  const allResponses = useSelector(exitSurveyResponsesSelector);
  const activeResponseSlugs = Object.keys(allResponses);
  const activeResponseIds = activeResponseSlugs.map(
    (key) => allResponses[key].id
  );
  const currentStatsEngineSlug = useSelector(dashboardStatisticsSelector).slug;

  useEffect(() => {
    /* On every page load, check the URL to see if redux state is different */
    if (params.engine === undefined || params.engine !== reduxEngineSlug) {
      if (!params.engine || params.engine === ":engine") {
        if (availableEnginesCount) {
          history.push(
            generatePath(DASHBOARD_URLS.overview, {
              engine: Object.keys(availableEngines)[0],
            })
          );
        }
      } else {
        console.debug("Read Engine from url path...", params.engine);
        dispatch(setWizardEngine(params.engine));
      }
    }
    // eslint-disable-next-line
  }, [params.engine, availableEnginesCount]);

  useEffect(() => {
    if (!enginesRequested) {
      dispatch(getEngines.Action(null));
    }
  }, [dispatch, enginesRequested]);

  useEffect(() => {
    // No engines setup, must redirect to create engine
    if (enginesReady) {
      const engineSlugs = Object.keys(availableEngines);
      console.debug("Engines loaded: ", engineSlugs);
      const viewClientAsStaff = isStaff && !engineSlugs.includes(params.engine);

      if (availableEnginesCount === 0) {
        const path = !!auth?.user?.client
          ? SETUP_URLS.platform_options
          : ONBOARDING_URLS.company_signup;
        dispatch(stepNavigate({ path }));
      } else if (viewClientAsStaff) {
        if (!getEngineStarted && params.engine) {
          dispatch(getEngine.Action(params.engine));
        }
      }
    }
    // eslint-disable-next-line
  }, [availableEngines, params.engine, enginesReady]);

  const retrieveStatistics = () => {
    dispatch(
      getDashboardStatistics.Action({
        start_date: moment(start_date).format("YYYY-MM-DD"),
        end_date: moment(end_date).format("YYYY-MM-DD"),
        reason_ids: reason_ids.filter((id: string) =>
          activeResponseIds.includes(parseInt(id))
        ),
        engine,
      })
    );
  };

  const firstUpdate = useRef(true);
  useEffect(() => {
    const reasonOrEngineChange =
      (currentReasonsCount !== reason_ids.length ||
        currentStatsEngineSlug !== params.engine) &&
      !fetchingStats &&
      reason_ids?.length;

    const dateChange =
      (start_date !== statDates.start_date ||
        end_date !== statDates.end_date) &&
      !fetchingStats &&
      engine &&
      reason_ids?.length;

    if (firstUpdate.current) {
      // Initial load sets one month ago as start date
      dispatch(
        setDashboardDates({
          start: moment().subtract(1, "months").format("YYYY-MM-DD"),
          end: moment().format("YYYY-MM-DD"),
        })
      );
      firstUpdate.current = false;
    } else if (dateChange) {
      retrieveStatistics();
      setStatDates({
        start_date,
        end_date,
      });
    } else if (reasonOrEngineChange) retrieveStatistics();

    if (reason_ids.length !== currentReasonsCount)
      // update current reasons count
      setCurrentReasonsCount(reason_ids.length);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [start_date, end_date, reason_ids?.length, engine, currentReasonsCount]);

  if (!enginesReady) {
    return <Loader padding reason="Fetching your engines..." />;
  }

  return (
    <DashboardLayout smallPurpleCard={!showingOverviewInfoCards}>
      <Router history={history}>
        <Switch>
          <Route exact component={Overview} path={DASHBOARD_URLS.overview} />
          <Route
            exact
            component={ExitSurveyDash}
            path={DASHBOARD_URLS.exit_survey}
          />
          <Route
            exact
            component={ExperiencesDash}
            path={DASHBOARD_URLS.experiences}
          />
          <Route
            exact
            component={SurvivalAnalysis}
            path={DASHBOARD_URLS.risk_forecasts}
          />
        </Switch>
      </Router>
    </DashboardLayout>
  );
};

export default DashboardContainer;
