import React from "react";
import styled from "@emotion/styled";
import VerticalCenter from "../../components/AlignItems";
import { ThemeAttributeProps } from "../../theme";
import { useTheme } from "emotion-theming";
export interface GetStartedProps {
  onGetStartedClicked(data?: any): void;
}

const FullBackground = styled.div`
  height: 100%;
  min-height: 100vh;
  width: 100%;
  background: ${(props: any) => props.theme.primary_color};
`;

const PageWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  text-align: center;
  height: 100vh;
  width: 100%;

  @media (max-width: 768px) {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
`;

const Logo = styled.img`
  max-width: 360px;
  max-height: 160px;
  margin: 20vh auto 1rem;
  padding: 1.2rem 2rem;
  border-radius: 0.5rem;
  @media (max-width: 768px) {
    width: 200px;
  }
`;

const SomethingWrong = styled.h2`
  font-family: ${(props: any) => props.theme.font};
  font-size: 26pt;
  font-weight: 300;
  font-style: normal;
  color: ${(props: any) => props.theme.font_color};
  @media (max-width: 768px) {
    font-size: 29px;
    width: 90%;
  }
`;

const LetsSee = styled.h1`
  font-family: ${(props: any) => props.theme.font};
  font-size: 22pt;
  font-weight: 400;
  font-style: normal;
  color: ${(props: any) => props.theme.font_color};
  padding-bottom: 0.3rem;
  @media (max-width: 768px) {
    font-size: 20px;
    padding-bottom: 1.7rem;
  }
`;
const GetStartedButton = styled.div`
  height: 70px;
  width: 310px;
  font-size: 24px;

  @media (max-width: 768px) {
    font-size: 16px;
    height: 3.5rem;
    width: 17rem;
  }

  background: white;
  font-family: ${(props: any) => props.theme.font};
  font-style: normal;
  display: block;

  color: #000000;
  text-align: center;
  border-style: solid;
  border-radius: 10px;
  border-color: ${(props: any) => props.theme.secondary_color};
  &:hover {
    cursor: pointer;
  }
`;

const GoBackButton = styled.div`
  height: 60px;
  width: 200px;
  font-size: 24px;
  margin: 1rem auto 0;
  padding-top: 0.5rem;
  @media (max-width: 768px) {
    ewidth: 200px;
    font-size: 16px;
    height: 40px;
    width: 17rem;
  }
  font-family: ${(props: any) => props.theme.font};
  font-style: normal;

  color: ${(props: any) => props.theme.font_color};
  text-align: center;
  border-radius: 10px;
  &:hover {
    cursor: pointer;
  }
`;

const GetStarted: React.FC<GetStartedProps> = (props: GetStartedProps) => {
  const theme: ThemeAttributeProps = useTheme();
  return (
    <FullBackground>
      <PageWrap>
        {theme && theme.logo && <Logo src={theme.logo} />}
        <SomethingWrong>Something wrong with your account?</SomethingWrong>
        <LetsSee>Let's see what we can do.</LetsSee>
        <GetStartedButton onClick={() => props.onGetStartedClicked()}>
          <VerticalCenter>Get Started</VerticalCenter>
        </GetStartedButton>
        <GoBackButton onClick={() => window.history.back()}>
          <VerticalCenter>Go Back</VerticalCenter>
        </GoBackButton>
      </PageWrap>
    </FullBackground>
  );
};
export default GetStarted;
