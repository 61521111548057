import React from "react";
import styled from "@emotion/styled";
import Layout, { BottomWhite } from "../../components/Layout/Layout";
import Footer from "../../components/Footer";
import HorizontalCardSelectable from "../../components/HorizontalCardSelectable";
import { compareIgnoringCase } from "features/offboarding/utils";

export interface SingleProduct {
  id: string;
  name: string;
  sub_title?: string;
  image_url: string;
  price: number;
  currency: string;
  discount: number;
}
export interface SwapProductInterface {
  line_items: SingleProduct[];
  headline: string;
  button_text: string;
}

export interface MultiProductSwapProps {
  swapProductsData: SwapProductInterface;
  callUpdate(action: string): void;
}

const SubmitButton = styled.div`
  font-family: ${(props: any) => props.theme.font};
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  padding: 0.25rem;
  line-height: 27px;
  text-align: center;
  background: ${(props: any) =>
    compareIgnoringCase(props.theme.primary_color, "#FFFFFF")
      ? "#E5E5E5"
      : props.theme.primary_color};
  color: ${(props: any) => props.theme.font_color};
  border-radius: 6px;

  @media (max-width: 768px) {
    margin: 0;
  }
  :hover {
    background: ${(props: any) => props.theme.secondary_color};
    color: ${(props: any) => props.theme.secondary_font_color};
    cursor: pointer;
  }

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Controls = styled.div`
  display: flex;
  flex-direction: row;
  align-self: flex-end
  justify-content: flex-end;

  height: 72px;

  @media (max-width: 768px) {
    padding-top: 24px;
  }
`;

const LineItemsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-around;
  width: 100%;
`;

const ButtonWrapper = styled.div`
  width: 6rem;
`;

const MultipleProductsSwap: React.FC<MultiProductSwapProps> = (
  props: MultiProductSwapProps
) => {
  const [selectedProductId, setSelectedProductId] = React.useState<
    null | string
  >(null);

  const submitProductId = () => {
    if (!!selectedProductId) return props.callUpdate(selectedProductId!);
    alert("Please select a product");
  };

  return (
    <>
      <Layout title={props.swapProductsData.headline}>
        <BottomWhite className="bottom-content-container">
          <Controls>
            <ButtonWrapper>
              <SubmitButton
                onClick={submitProductId}
                className="submit-swap-product-button"
              >
                {props.swapProductsData.button_text}
              </SubmitButton>
            </ButtonWrapper>
          </Controls>

          <LineItemsContainer className="swap-products-line-items">
            {props.swapProductsData.line_items.map(
              (lineItem: SingleProduct, index: number) => {
                const originalPrice = lineItem.discount ?
                  `${(lineItem.price).toFixed(2)} ${lineItem.currency}`:
                  null;
                const discountedPrice =
                  `${(lineItem.price - lineItem.discount).toFixed(2)} ${lineItem.currency}`;
                return (
                  <HorizontalCardSelectable
                    key={index}
                    onClick={() => setSelectedProductId(lineItem.id)}
                    selected={selectedProductId === lineItem.id}
                    name={lineItem.name}
                    subTitle={lineItem.sub_title}
                    newPriceText={discountedPrice}
                    previousPriceText={originalPrice}
                    imageUrl={lineItem.image_url}
                  />
              )}
            )}
          </LineItemsContainer>
        </BottomWhite>
      </Layout>
      <Footer
        onClickContinue={(e) => props.callUpdate("continue")}
        onClickBack={(e) => props.callUpdate("_back")}
      />
    </>
  );
};
export default MultipleProductsSwap;
