/**
 * Takes a hex value and returns the rga equivalent.
 * If hex is not valid, we return the rgb for black.
 * Reference: https://stackoverflow.com/questions/5623838/rgb-to-hex-and-hex-to-rgb
 */
const hexToRgb = (colorInHex: string): object => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(colorInHex);
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : { r: 0, g: 0, b: 0 };
};

/**
 * Takes backgroundColor as a hex string and returns the font color as hex.
 */
const getFontColor = (backgroundColor: string): string => {
  const { r, g, b }: any = hexToRgb(backgroundColor);
  const backgroundIsDark = r * 0.299 + g * 0.587 + b * 0.114 > 186;

  return backgroundIsDark ? "#000000" : "#ffffff";
};

/**
 * Compare two strings while ignoring case sensitivity
 */
const compareIgnoringCase = (string1: string, string2: string): boolean => {
  if (!string1 || !string2) return false;
  return (
    string1.localeCompare(string2, undefined, { sensitivity: "accent" }) === 0
  );
};

/**
 * Takes string length and returns optimal font size
 * @param  {Number} textLength Length of a string as an integer
 * @param  {Number} textType Where the text is. Options are heading, title, description, button (default)
 * @return {Number} Font size as an integer
 */
const getFontSize = (textLength: number, textType: string = "button") => {
  let buttonTextSizesToCharacterLength = { 18: 30, 16: 60, 12: 75, 10: 120 };
  let headingTextSizesToCharacterLength = {
    28: 200,
    26: 300,
    24: 400,
  };
  let titleTextSizesToCharacterLength = {
    22: 200,
    20: 400,
  };
  let spotlightTextSizesToCharacterLength = {
    28: 50,
    26: 75,
    24: 100,
    22: 120,
    20: 140,
  };

  let fontSize = 24;
  let textMapping: any = {};
  if (textType === "button") {
    textMapping = buttonTextSizesToCharacterLength;
  } else if (textType === "header") {
    fontSize = 30;
    textMapping = headingTextSizesToCharacterLength;
  } else if (textType === "title") {
    fontSize = 24;
    textMapping = titleTextSizesToCharacterLength;
  } else if (textType === "spotlight") {
    fontSize = 30;
    textMapping = spotlightTextSizesToCharacterLength;
  }

  Object.keys(textMapping)
    .reverse()
    .forEach((key) => {
      if (textLength > textMapping[key]) {
        fontSize = Number.parseInt(key);
      }
    });

  return fontSize;
};

export { hexToRgb, getFontColor, getFontSize, compareIgnoringCase };
