import React from "react";
import styled from "@emotion/styled";
import AnnouncementBanner from "../AnnoucementBanner/AnnouncementBanner";
import { ThemeAttributeProps } from "features/offboarding/theme";
import { useTheme } from "emotion-theming";
import { getFontColor } from "features/offboarding/utils";

interface LayoutProps {
  styles?: object;
  title: string;
  children: any;
  negativeTop?: number;
  logo?: boolean;
  show_banner?: boolean;
}

/**
 * Container responsible for everything in the view.
 */
const PageWrapper = styled.div`
  position: relative;
  width: 100%;

  display: flex;
  flex-direction: column;
  flex: 1;

  background: ${(props: any) => props.theme.primary_color};
`;

/**
 * Wrapper for any header text and logo. Responsible for aligning header
 * content.
 */
const HeaderWrapper = styled.div`
  min-height: 25vh;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

/**
 * Wrapper that's responsible for spacing around HeaderText.
 */
const HeaderTextWrapper = styled.div`
  margin-top: 2rem;
  margin-bottom: 2rem;
  width: 80%;
`;

/**
 * Div for the header text. Responsible for size and styling of the header.
 */
const HeaderText = styled.div`
  font-family: ${(props: any) => props.theme.font};
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  text-align: center;
  color: ${(props: any) =>
    props.theme.secondary_color === props.theme.primary_color
      ? getFontColor(props.theme.secondary_color)
      : props.theme.secondary_color};

  @media (max-width: 768px) {
    font-size: 22px;
    line-height: 36px;
    padding: 2% 5% 0 5%;
  }
`;

/**
 * img element for the logo. Responsible for size and spacing around logo.
 */
const HeaderLogo = styled.img`
  max-width: 360px;
  max-height: 120px;
  margin-top: 24px;
  border-radius: 0.5rem;

  @media (max-width: 768px) {
    max-width: 200px;
  }
`;

type BottomWhiteProps = {
  negativeTop?: number;
  negativeTopMobile?: number;
};

/* Defined here and used in every other view */
export const BottomWhite = styled("div")<BottomWhiteProps>`
  width: 100%;
  margin-top: ${(props: BottomWhiteProps) => -1 * (props.negativeTop || 0)}px;
  border-radius: 4rem 4rem 0px 0px;
  background: #ffffff;
  flex-grow: 1;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media (max-width: 768px) {
    border-radius: 1rem 1rem 0px 0px;
    padding-bottom: 3rem;
    margin-top: ${(props: BottomWhiteProps) => -1 * (props.negativeTop || 0)}px;
  }
`;

/**
 * Page layout that supplies the split screen view.
 *
 * `BottomWhite` div should be supplied to Layout as a child by the parent
 * component.
 */
const Layout: React.FunctionComponent<LayoutProps> = (props) => {
  const theme: ThemeAttributeProps = useTheme();
  return (
    <PageWrapper className="header-background">
      {props.show_banner &&
        theme.announcement_banner_active &&
        theme.announcement_banner_text && (
          <AnnouncementBanner
            announcementText={theme.announcement_banner_text}
          />
        )}
      <HeaderWrapper>
        {props.logo && theme && theme.logo && (
          <HeaderLogo src={theme.logo} className="exit-survey-logo" />
        )}
        <HeaderTextWrapper>
          <HeaderText className="header-text">{props.title}</HeaderText>
        </HeaderTextWrapper>
      </HeaderWrapper>
      {props.children}
    </PageWrapper>
  );
};

export default Layout;
