import {ChartProps} from "./chartProps";
import {CustomTooltip} from "../../dashboard/CustomTooltip";
import {ResponsivePie} from "@nivo/pie";
import { animated } from '@react-spring/web';
import {OrdinalColorScaleConfig} from "@nivo/colors/dist/types/ordinalColorScale";

export type PieChartData = {id: string, value: number}[];

export interface HalfPieChartProps extends ChartProps {
    type: "halfPie";
    data: PieChartData;
}

export const HalfPieChart = (props: HalfPieChartProps) => {
    let colorConfig = (props.colors || {scheme: "category10"}) as OrdinalColorScaleConfig;
    // let colors = useOrdinalColorScale(colorConfig, "id");

    let yFmt = (props.yFmt !== undefined) ? props.yFmt : (v: number) => v.toString();

    return (
        <ResponsivePie
            data={props.data}
            margin={{
                top: 10,
                right: 10,
                bottom: 10,
                left: 10,
            }}
            startAngle={-90}
            endAngle={90}
            innerRadius={0.5}
            padAngle={0.7}
            cornerRadius={3}
            activeOuterRadiusOffset={8}
            borderWidth={1}
            // colors={["#F3D2FF", "#8E509F", "#5B1E71", "#250542", "#CACACA"]}
            colors={colorConfig}
            borderColor={{
                from: "color",
                modifiers: [["darker", 0.2]],
            }}
            arcLabelsSkipAngle={20}
            arcLabelsTextColor={{ from: 'color', modifiers: [["darker", 2]] }}
            // enableArcLabels={false}
            enableArcLinkLabels={false}
            arcLabelsComponent={({ datum, label, style }) => (
                <animated.g transform={style.transform} style={{ pointerEvents: 'none' }}>
                    <circle fill="#ffffff" stroke={datum.color} strokeWidth={2} r={16} />
                    <text
                        textAnchor="middle"
                        dominantBaseline="central"
                        fill={style.textColor}
                        style={{
                            fontSize: 10,
                            fontWeight: 800,
                        }}
                    >
                        {yFmt(Number.parseFloat(label))}
                    </text>
                </animated.g>
            )}
            tooltip={({ datum }) => <CustomTooltip datum={datum} />}
            legends={[
                {
                anchor: "bottom",
                direction: "row",
                justify: false,
                translateX: 0,
                translateY: 56,
                itemsSpacing: 0,
                itemWidth: 130,
                itemHeight: 18,
                itemTextColor: "#fff",
                itemDirection: "left-to-right",
                itemOpacity: 1,
                symbolSize: 18,
                symbolShape: "square",
                },
            ]}
        />
    );
}