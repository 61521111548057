import Card from "@material-ui/core/Card";
import Grid from "@material-ui/core/Grid";

import styles from "./graphStyles";
import {makeStyles} from "@material-ui/core/styles";
import commonStyles from "components/layout/commonStyles";
import React from "react";
import {GraphCardHeader, GraphCardHeaderProps} from "./GraphCardHeader";
import {GraphCardMetrics, GraphCardMetricsProps} from "./GraphCardMetrics";
import {GraphCardGroupInfo, GraphCardGroupInfoProps} from "./GraphCardGroupInfo";
import {GraphCardGraph, GraphCardGraphProps} from "./GraphCardGraph";


const useStyles = makeStyles((theme) => ({
    ...commonStyles(theme),
    ...styles(theme),
}));

interface GraphCardProps {
    fmt: (v: number) => string;
    hoveredX?: any;
    header?: GraphCardHeaderProps;
    metrics?: GraphCardMetricsProps;
    groupBy?: GraphCardGroupInfoProps;
    graph?: GraphCardGraphProps;
}

export const GraphCard = (props: GraphCardProps) => {
    const classes = useStyles();

    return (
        <Card className={classes.graphCard}>
            {/* Component Container */}
            <Grid container direction="column">
                {/* Header */}
                {props.header !== undefined && (
                    <Grid item className={classes.graphItem}>
                        <GraphCardHeader {...props.header}/>
                    </Grid>
                )}

                {/* Group by Info */}
                {props.groupBy !== undefined && (
                    <Grid item className={classes.graphItem} style={{paddingTop: "0px"}}>
                        <GraphCardGroupInfo {...props.groupBy}/>
                    </Grid>
                )}

                {/* Metrics */}
                {props.metrics !== undefined && (
                    <Grid container direction="row" item className={classes.graphItem} style={{paddingTop: "10px"}}>
                        <GraphCardMetrics {...props.metrics} fmt={props.fmt}/>
                    </Grid>
                )}

                {/* Graph */}
                {props.graph !== undefined && (
                    <Grid item xs={12} className={classes.graphItem} style={{padding: "20px"}}>
                        <div style={{height: props.graph.height || 200}}>
                            <GraphCardGraph {...props.graph} yFmt={props.fmt}/>
                        </div>
                    </Grid>
                )}
            </Grid>

        </Card>
    )
}