import { useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
import { clearFormState, logoutUser, resetPassword } from "./userSlice";
import { Link, useParams } from "react-router-dom";
import { mergeErrors } from "app/forms";

// Material UI
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import FormGroup from "@material-ui/core/FormGroup";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";

// components
import TransparentTextField from "components/input/TransparentTextField";
import ExternalLayout from "components/layout/external/ExternalLayout";
import Loader from "components/loading/Loader";

// styles
import styles from "./userStyles";
import { makeStyles } from "@material-ui/core/styles";
import commonStyles from "components/layout/commonStyles";

const useStyles = makeStyles((theme) => ({
  ...styles(theme),
  ...commonStyles(theme),
}));

interface ResetPasswordParam {
  token: string;
  uid: string;
}

export default function ResetPassword() {
  const dispatch = useDispatch();
  const params = useParams<ResetPasswordParam>();
  const {
    handleSubmit,
    control,
    formState: { errors: frontendErrors },
  } = useForm();
  const classes = useStyles();

  const onSubmit = handleSubmit(({ new_password1, new_password2 }) => {
    dispatch(
      resetPassword.Action({
        new_password1,
        new_password2,
        uid: params.uid,
        token: params.token,
      })
    );
  });

  const isLoading = useSelector(resetPassword.isFetchingSelector);
  const isError = useSelector(resetPassword.isErrorSelector);
  const errors = mergeErrors(
    frontendErrors,
    useSelector(resetPassword.ExtraErrorsSelector)
  );

  // On component load
  useEffect(() => {
    return () => {
      dispatch(clearFormState());
      dispatch(logoutUser());
    };
  }, [dispatch]);

  // after ajax response
  useEffect(() => {
    if (!isError) {
      dispatch(clearFormState());
    }
  }, [isError, dispatch]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <ExternalLayout footerFull>
      <Grid container justifyContent="center">
        <Grid item xs={10} md={6} lg={4}>
          <form onSubmit={onSubmit}>
            <Card>
              <Typography variant="h1">Reset Your Password</Typography>
              <Typography variant="body1">
                Enter the new password you want to use
              </Typography>
              <FormGroup>
                <Controller
                  control={control}
                  name="new_password1"
                  rules={{ required: true }}
                  defaultValue=""
                  render={({ field }) => (
                    <TransparentTextField
                      {...field}
                      error={Boolean(errors.get("new_password1"))}
                      helperText={errors.get("new_password1")}
                      label="NEW PASSWORD"
                      variant="outlined"
                      type="password"
                    />
                  )}
                />
              </FormGroup>

              <FormGroup>
                <Controller
                  control={control}
                  name="new_password2"
                  rules={{ required: true }}
                  defaultValue=""
                  render={({ field }) => (
                    <TransparentTextField
                      {...field}
                      error={Boolean(errors.get("new_password2"))}
                      helperText={errors.get("new_password2")}
                      label="CONFIRM NEW PASSWORD"
                      variant="outlined"
                      type="password"
                    />
                  )}
                />
              </FormGroup>

              {errors.get("token") ? (
                <div className={classes.errorMessage}>
                  Password reset link expired. Please request another one.
                </div>
              ) : null}

              {isLoading ? (
                <Loader />
              ) : (
                <Button
                  color="primary"
                  onClick={onSubmit}
                  variant="contained"
                  disabled={isLoading}
                  type="submit"
                >
                  Submit
                </Button>
              )}

              <Link className={classes.registerLink} to="/login">
                Back Home
              </Link>
            </Card>
          </form>
        </Grid>
      </Grid>
    </ExternalLayout>
  );
}
