import { FC } from "react";
import { Card } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { capitalizeFirstLetter } from "../../app/utils";

export interface CustomTooltipProps {
  datum: any;
}
export const CustomTooltip: FC<CustomTooltipProps> = ({ datum }) => {
  return (
    <Card style={{ padding: "6px", fontSize: "10px" }}>
      <Grid container alignItems="center">
        <Grid item>
          <div
            style={{
              height: 16,
              width: 16,
              background: datum.color,
              marginTop: 4,
              marginRight: 8,
              borderRadius: 2,
              display: "inline-block",
            }}
          />
        </Grid>
        <Grid item>
          <b style={{ fontSize: "12px" }}>{datum.value}</b>{" "}
          {capitalizeFirstLetter(datum.label)}
        </Grid>
      </Grid>
    </Card>
  );
};
