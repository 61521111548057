import { SingleProduct } from "features/offboarding/views/MultipleProductsSwap/MultipleProductsSwap";

const multiSwapLineItems = [
  ...Array.from({ length: 3 }, (_, i) => {
    return {
      id: `${i}`,
      name: `Sample Product ${i + 1}`,
      sub_title: i === 1 ? null : `Variant ${i + 1}`,
      image_url:
        "https://bellwethragents.s3.us-west-1.amazonaws.com/img/placeholder_image.png",
      price: Math.floor(Math.random() * 16) + 15,
      currency: "USD",
      discount: i === 0 ? null : Math.floor(Math.random() * 10) + 1,
    };
  }),
] as SingleProduct[];

export default multiSwapLineItems;
