import React from "react";

// components
import PrivateNavBar from "components/header/PrivateNavBar";
import { Toaster } from "react-hot-toast";

// Material UI
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";

// styles
import styles from "./dashboardLayoutStyles";
import footerStyles from "components/footer/footerStyles";
import commonStyles from "components/layout/commonStyles";
import { darkTheme } from "MUITheme";
import { makeStyles, ThemeProvider } from "@material-ui/core/styles";

interface DashboardLayoutProps {
  smallPurpleCard: boolean;
}

const useStyles = makeStyles((theme) => ({
  ...styles(theme),
  ...commonStyles(theme),
  ...footerStyles(theme),
}));

export const DashboardLayout: React.FC<DashboardLayoutProps> = ({
  children,
  smallPurpleCard,
}) => {
  const classes = useStyles();

  return (
    <Paper square className={classes.offWhiteBackground}>
      <Toaster position="bottom-center" />
      <Paper
        square
        className={
          window.location.pathname.includes("overview") && !smallPurpleCard
            ? classes.largePurpleBackground
            : classes.smallPurpleBackground
        }
      />
      <Grid
        container
        justifyContent="center"
        direction="column"
        className={classes.mainContent}
      >
        <Grid item>
          <ThemeProvider theme={darkTheme}>
            <PrivateNavBar />
          </ThemeProvider>
        </Grid>
        <Grid item className={classes.middleContainer}>
          {children}
        </Grid>
      </Grid>
    </Paper>
  );
};

export default DashboardLayout;
