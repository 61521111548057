import { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";

import { wizardEngineSelector } from "../slice/wizardSlice";
import { sendUserInvite, SendUserInviteFields } from "features/user/userSlice";

// Material UI
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import FormGroup from "@material-ui/core/FormGroup";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";

// components
import { mergeErrors } from "../../../app/forms";
import PanelHeader from "components/wizard/PanelHeader";
import { AttentionBubble } from "components/wizard/AttentionBubble";

// styles
import { makeStyles } from "@material-ui/core/styles";
import commonStyles from "../../../components/layout/commonStyles";
import wizardStyles from "../wizardStyles";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  ...commonStyles(theme),
  ...wizardStyles(theme),
}));

export default function UserAccessManagement() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const currentEngine = useSelector(wizardEngineSelector);
  const backendErrors = useSelector(sendUserInvite.ExtraErrorsSelector);
  const isLoading = useSelector(sendUserInvite.isFetchingSelector);
  const isSuccess = useSelector(sendUserInvite.isReadySelector);

  const {
    handleSubmit,
    control,
    formState: { errors: frontendErrors },
    reset,
  } = useForm();

  const onSubmit = handleSubmit((data) => {
    dispatch(
      sendUserInvite.Action({
        email: data.email,
        clientSlug: currentEngine.client.slug,
      } as SendUserInviteFields)
    );
  });

  useEffect(() => {
    if (isSuccess?.email) reset({ email: "" });
  }, [isSuccess, reset]);

  const errors = mergeErrors(frontendErrors, backendErrors);

  return (
    <div className={classes.mdPadding}>
      <form onSubmit={onSubmit}>
        <PanelHeader title="User Access Management" caption="Settings" />

        <Divider className={classes.headingDivider} />
        <Typography variant="h6" className={classes.purpleGradient}>
          User Invitation
        </Typography>
        <Typography variant="body2" className={classes.gray}>
          Grant a user access to your RetentionEngine by entering their email,
          and we’ll send them an invitation.
        </Typography>
        <AttentionBubble text="Invites are only valid for 3 days." />

        <FormGroup>
          <Controller
            control={control}
            name="email"
            defaultValue=""
            render={({ field }) => (
              <TextField
                {...field}
                InputLabelProps={{ shrink: true }}
                label="250 CHARACTERS MAX"
                variant="outlined"
                error={Boolean(errors.get("email"))}
                helperText={errors.get("email")}
              />
            )}
          />
        </FormGroup>

        <Button
          type="submit"
          className={clsx(classes.panelButton, classes.marginTop)}
          disabled={isLoading}
        >
          Send Invite
        </Button>
      </form>
    </div>
  );
}
