import {ResponsiveSankey} from '@nivo/sankey';
import {OrdinalColorScaleConfig, useOrdinalColorScale} from "@nivo/colors";
import {BarItems} from "./HorizontalBarChart";

interface ChartProps  {
    barItems: BarItems;
    sourceOrder?: string[];
    targetOrder?: string[];
    sourceColors?: OrdinalColorScaleConfig;
    targetColors?: OrdinalColorScaleConfig;
}

export const SankeyChart = (props: ChartProps) => {
    // Sets colors
    let defaultColors = ["#8E509F", "#F3D2FF", "#CACACA", "#250542", "#5B1E71"];
    let sourceColorConfig: OrdinalColorScaleConfig = props.sourceColors || defaultColors;
    let targetColorConfig: OrdinalColorScaleConfig = props.targetColors || defaultColors;
    let sourceColors = useOrdinalColorScale(sourceColorConfig, "id");
    let targetColors = useOrdinalColorScale(targetColorConfig, "id");

    // Prepares for ordering
    let barSizes: { [key: string]: number } = {};  // AKA sourceSizes
    let barItemSizes: { [key: string]: number } = {};  // AKA targetSizes
    Object.entries(props.barItems).forEach(([source, bar]) => {
        barSizes[source] = 0;
        Object.entries(bar).forEach(([key, barItemSize]) => {
            barSizes[source] += barItemSize;
            if (barItemSizes[key] === undefined)
                barItemSizes[key] = barItemSize;
            else
                barItemSizes[key] += barItemSize;
        });
    });

    // Creates nodes and links
    let nodes: {id: string, nodeColor: string}[] = [];
    let links: { source: string, target: string, value: number }[] = [];
    let sourceIds = new Set(),
        targetIds = new Set();
    Object.entries(props.barItems)
        // Orders by props.sourceOrder, if defined, else by total size
        .sort(([source1], [source2]) => (
            (props.sourceOrder === undefined)?
                barSizes[source2] - barSizes[source1]
                : props.sourceOrder.indexOf(source1) - props.sourceOrder.indexOf(source2)
        ))
        // Limit to top 10
        .filter((value, idx) => idx < 10)
        .forEach((([source, barItem]) => {
            // Creates source node
            sourceIds.add(source);
            let sourceNode = nodes.find(_node => _node.id === source);
            if (sourceNode === undefined)
                nodes = [...nodes, {id: source, nodeColor: sourceColors({id: source})}];

            Object.entries(barItem)
                // Orders by props.targetOrder, if defined, else by total size
                .sort(([target1], [target2]) => (
                    (props.targetOrder === undefined)?
                        barItemSizes[target2] - barItemSizes[target1]
                        : props.targetOrder.indexOf(target1) - props.targetOrder.indexOf(target2)
                ))
                .forEach(([target, value]) => {
                    // Creates link
                    links = [...links, {source: source, target: target, value: value}]

                    // Creates Target Node
                    targetIds.add(target);
                    let targetNode = nodes.find(_node => _node.id === target);
                    if (targetNode === undefined)
                        nodes = [...nodes, {id: target, nodeColor: targetColors({id: target})}];
                });
        }));

    // Fail safe
    if ((nodes.length === 0) || (links.length === 0)) return (<div></div>);

    return (
        <ResponsiveSankey
            data={{
                nodes: nodes,
                links: links,
            }}
            margin={{top: 0, right: 30, bottom: 30, left: 30}}
            colors={(node: {nodeColor: string}) => node.nodeColor}
            sort="input"
            nodeHoverOthersOpacity={0.05}
            linkHoverOthersOpacity={0.05}
            enableLinkGradient
            // linkTooltip has some visualization problems, default tooltip may be good enough for now
            // linkTooltip={(props: any) => (
            //     <CustomTooltip datum={{color: "#ff0000", value: props.value, label: `${props.source.id} → ${props.target.id}`}} />
            // )}
        />
    )
};