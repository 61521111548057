import { FC, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

// components
import MainTreeCard from "components/wizard/experience/MainTreeCard";
import SecondaryTreeCard from "components/wizard/experience/SecondaryTreeCard";
import { EXPERIENCE_URLS, MECHANICS_URLS, WIZARD_URLS } from "./slice/urls";
import { ExitSurveyResponse, Intermediate, Pause } from "./slice/types";
import Loader from "../../components/loading/Loader";
import TreeWrapper from "../../components/wizard/experience/TreeWrapper";

import {
  currentStepSelector,
  displayDataSelector,
  selectedExitResponseSelector,
  stepNavigate,
  toggleCollapsePauses,
  availablePausesSelector,
  currentEngineSlugSelector,
  updatePause,
} from "./slice/wizardSlice";

// Material UI
import Grid from "@material-ui/core/Grid";
import Tooltip from "@material-ui/core/Tooltip";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";

// images
import PurpleCheck from "./img/purpleCheck.svg";
import GrayCheck from "../../components/wizard/img/grayCheck.svg";
import EditIcon from "./img/editPurpleGraphic.svg";

// styles
import { makeStyles } from "@material-ui/core/styles";
import commonStyles from "components/layout/commonStyles";
import clsx from "clsx";
import wizardStyles from "./wizardStyles";

const useStyles = makeStyles((theme) => ({
  ...commonStyles(theme),
  ...wizardStyles(theme),
}));

export const PauseTree: FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const pauses = useSelector(availablePausesSelector);
  const [showPauseDialog, setShowPauseDialog] = useState<Pause>();
  const currentStep = useSelector(currentStepSelector);

  const responseSelected = useSelector(selectedExitResponseSelector);
  const disabled = responseSelected && responseSelected.show_pauses === false;

  const collapsed = useSelector(
    (state) => displayDataSelector(state).pausesCollapsed
  );

  const onClickCollapseOptions = () => dispatch(toggleCollapsePauses());

  const mainPanelSelected =
    !disabled && currentStep?.path === EXPERIENCE_URLS.pause_options;

  const isMechanics = currentStep?.path.indexOf(WIZARD_URLS.mechanics) !== -1;
  const isSettings = currentStep?.path.indexOf(WIZARD_URLS.settings) !== -1;

  const handleClose = () => {
    setShowPauseDialog(undefined);
  };

  return (
    <TreeWrapper
      header={
        <MainTreeCard
          title="Pauses"
          number={pauses.length}
          disabled={disabled}
          collapsed={disabled || collapsed}
          onClick={onClickCollapseOptions}
          onCardClick={
            isMechanics
              ? undefined
              : () =>
                  dispatch(
                    stepNavigate({ path: EXPERIENCE_URLS.pause_options })
                  )
          }
        />
      }
    >
      {disabled || collapsed
        ? null
        : Object.keys(pauses).map((slug: string) => {
            const pause = pauses[slug];
            if (!mainPanelSelected && !pause.active) return null;
            return (
              <Grid
                item
                key={pause.slug}
                className={clsx(
                  pause.slug.length < 10 ? classes.secondarySmall : null
                )}
              >
                <SecondaryTreeCard
                  description={pause.display_title}
                  active={pause.active}
                  selected={
                    currentStep?.path === MECHANICS_URLS.pause_mechanics
                  }
                  onClickSelect={
                    isMechanics
                      ? () =>
                          dispatch(
                            stepNavigate({
                              path: MECHANICS_URLS.pause_mechanics,
                            })
                          )
                      : undefined
                  }
                  leftIcon={
                    <PauseActivityIcon
                      slug={pause.slug}
                      pause={pause}
                      isMechanics={isMechanics}
                      isSettings={isSettings}
                    />
                  }
                  rightIcon={
                    currentStep?.path === EXPERIENCE_URLS.pause_options ? (
                      <>
                        <Tooltip title="Edit" placement="right" arrow>
                          <img
                            src={EditIcon}
                            alt="delete"
                            className={classes.hoverPointer}
                            onClick={() => setShowPauseDialog(pause)}
                          />
                        </Tooltip>
                      </>
                    ) : (
                      <></>
                    ) // If the right icon is undefined, we experience weird vertical spacing issues between cards!
                  }
                />
              </Grid>
            );
          })}
      <PauseDialog
        pause={showPauseDialog}
        open={Boolean(showPauseDialog)}
        handleClose={handleClose}
      />
    </TreeWrapper>
  );
};

interface PauseDialogProps {
  pause?: Pause;
  open: boolean;
  handleClose: CallableFunction;
}

export const PauseDialog: FC<PauseDialogProps> = ({
  pause,
  open,
  handleClose,
}) => {
  const dispatch = useDispatch();
  const [displayTitle, setDisplayTitle] = useState("");
  const currentEngineSlug = useSelector(currentEngineSlugSelector);

  useEffect(() => {
    if (pause?.display_title) setDisplayTitle(pause.display_title);
  }, [pause?.display_title]);

  if (!pause) return <></>; // Hidden when pause is false

  return (
    <Dialog
      open={Boolean(open)}
      onClose={() => handleClose()}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">Edit Pause</DialogTitle>
      <DialogContent>
        <DialogContentText>
          This pause is for {pause?.pause_term_display_name}.
        </DialogContentText>
        <TextField
          variant="outlined"
          autoFocus
          id="name"
          label="Display Name"
          type="text"
          fullWidth
          InputLabelProps={{ shrink: true }}
          value={displayTitle}
          onChange={(e) => setDisplayTitle(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleClose()}>Cancel</Button>
        <Button
          onClick={() => {
            dispatch(
              updatePause.Action({
                display_title: displayTitle,
                engine: currentEngineSlug,
                slug: pause?.slug,
              })
            );
            handleClose();
          }}
          color="primary"
          variant="contained"
        >
          Save Changes
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export interface PauseActivityIconProps {
  slug: string;
  pause: Intermediate;
  isMechanics: boolean;
  isSettings: boolean;
}

export const PauseActivityIcon: React.FC<PauseActivityIconProps> = ({
  pause,
  isMechanics,
  isSettings,
  slug,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const UpdatePause = useSelector(updatePause.Selector);
  const isUpdatingPause = useSelector(updatePause.isFetchingSelector);
  const currentEngineSlug = useSelector(currentEngineSlugSelector);
  const selectedExitResponse = useSelector(
    selectedExitResponseSelector
  ) as ExitSurveyResponse;

  let showActive;
  if (selectedExitResponse) {
    showActive = selectedExitResponse.show_pauses;
  } else {
    showActive = pause.active;
  }
  const loading = isUpdatingPause && UpdatePause.input.slug === slug;
  if (loading) return <Loader size={16} />;

  return (
    <Tooltip
      title={showActive ? "Activated" : "Deactivated"}
      placement="left"
      arrow
    >
      <img
        src={showActive ? PurpleCheck : GrayCheck}
        className={classes.hoverPointer}
        onClick={() =>
          isMechanics || isSettings
            ? null
            : dispatch(
                updatePause.Action({
                  slug,
                  engine: currentEngineSlug,
                  active: !pause.active,
                })
              )
        }
        alt="activate_or_deactivate"
      />
    </Tooltip>
  );
};

export default PauseTree;
