import { useHistory } from "react-router-dom";
import { USER_SETTINGS_URLS } from "../../wizard/slice/urls";

// Material UI
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";

// images
import AccountGraphic from "./img/accountGraphic.svg";
import PasswordGraphic from "./img/passwordGraphic.svg";

// styles
import { makeStyles } from "@material-ui/core/styles";
import commonStyles from "../../../components/layout/commonStyles";
import styles from "./userSettingsStyles";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  ...commonStyles(theme),
  ...styles(theme),
}));

export default function UserSettingsSidebar() {
  const classes = useStyles();
  const history = useHistory();

  return (
    <div className={classes.lgPadding}>
      <Grid container direction="column" spacing={1}>
        <UserSettingsButton
          title="Account"
          subtitle="Modify your plan, profile details, and email"
          icon={AccountGraphic}
          active={USER_SETTINGS_URLS.account === history.location.pathname}
        />
        <UserSettingsButton
          title="Password"
          subtitle="Change your password"
          icon={PasswordGraphic}
          active={USER_SETTINGS_URLS.password === history.location.pathname}
        />
      </Grid>
    </div>
  );
}

interface UserSettingsButtonProps {
  title: string;
  subtitle: string;
  icon: string;
  onClick?: CallableFunction;
  active: Boolean;
}

export const UserSettingsButton: React.FC<UserSettingsButtonProps> = ({
  title,
  subtitle,
  icon,
  active,
}) => {
  const classes = useStyles();
  const history = useHistory();

  const handleClick = () => {
    history.push(`/user-settings/${title.toLowerCase()}`);
  };

  return (
    <Grid item>
      <Button
        fullWidth
        className={
          active ? classes.userSettingsButtonActive : classes.userSettingsButton
        }
        onClick={() => handleClick()}
      >
        <Grid container alignItems="center" spacing={2}>
          <Grid item xs={2}>
            <img src={icon} alt={title} />
          </Grid>
          <Grid item xs={10} className={classes.alignLeft}>
            <Grid container direction="column">
              <Grid item>
                <Typography variant="h6" className={classes.purpleGradient}>
                  {title}
                </Typography>
              </Grid>
              <Grid item>
                <Typography
                  variant="body2"
                  className={clsx(classes.gray, classes.zeroMargin)}
                >
                  {subtitle}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Button>
    </Grid>
  );
};
