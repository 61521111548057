import Typography from "@material-ui/core/Typography";
import styles from "./footerStyles";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";

const useStyles = makeStyles(styles);

export default function MiniFooter() {
  const classes = useStyles();

  return (
    <div className={classes.miniFooterContainer}>
      <Grid container justifyContent="center">
        <Grid item xs={11}>
          <Divider variant="middle" className={classes.miniDivider} />
        </Grid>
      </Grid>
      <Typography variant="body2" className={classes.copyright}>
        &copy; RetentionEngine {new Date().getFullYear()}. All rights reserved.
      </Typography>
    </div>
  );
}
