import React from "react";
import styled from "@emotion/styled";
import AcceptOfferButton from "../../components/AcceptOfferButton";
import Footer from "../../components/Footer";

// Material UI
import Link from "@material-ui/core/Link";

/*
These are the assets from the mobile mockup, but we are using the desktop SVGs below 
import CancelGraphic from './CancelGraphic.svg';
import SuccessGraphic from './SuccessGraphic.svg';
*/
import { ThemeAttributeProps } from "../../theme";
import { useTheme } from "emotion-theming";
import { getFontColor } from "features/offboarding/utils";

export interface EndScreenProps {
  state?: "paused" | "saved" | "cancel" | "lost";
  type?: string;
  title?: string;
  text?: string;
  cancel?: boolean;
  onClick?(): void;
  button_text?: string;
  confirm_cancel?: boolean;
  callUpdate?(data?: any): void;
  saved_screen?: boolean;
  pause_term?: string;
  confirm_cancel_image?: string;
  customer_saved_image?: string;
  customer_lost_image?: string;
  show_customer_saved_image?: boolean;
  show_customer_lost_image?: boolean;
  single_page_preview?: boolean;
  is_embedded?: boolean;
  show_cancel_bundle?: boolean;
  bundle_cancel_disclaimer?: string;
  show_cancel_alternative_action?: boolean;
  cancel_alternative_action_text?: string;
  cancel_alternative_action_url?: string;
  back_home_button?: string;
  force_cancel_button_text?: string;
  embedded_experience_refresh_page_text?: string;
  is_prepaid?: boolean;
  cancel_after_error?: boolean;
  website_url?: string;
}

const FullPageWhite = styled.div`
  height: 100vh;
  width: 100%;
  text-align: center;
  @media (min-width: 481px) and (max-width: 768px) {
    margin-bottom: 5rem;
  }
  display: flex;
  flex-flow: column;
`;

const CenterCard = styled.div`
  margin: 5rem auto 1rem;
  border-radius: 20px;
  @media (max-width: 768px) {
    margin: 4rem auto 0;
  }
  flex: 0 1 auto;
`;

const CenterGraphic = styled.div`
  width: auto;
  max-height: 30vh;
  margin: auto;
  @media (max-width: 768px) {
    max-width: 85%;
  }
`;

const TextWrapper = styled.div`
  width: 40%;
  margin: 3rem auto 2.5rem;
  @media (max-width: 480px) {
    width: 80%;
    margin: 5rem auto 1.5rem;
  }
  @media (min-width: 481px) and (max-width: 768px) {
    width: 80%;
    margin: 10rem auto 1.5rem;
  }
  flex: 0 1 auto;
`;

const MultipleSubsDisclaimerText = styled.h2`
  font-family: ${(props: any) => props.theme.font};
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  text-align: center;
  color: #e13137;
  margin-bottom: 12px;
  margin-top: 0;
`;

const Title = styled.h1`
  font-family: ${(props: any) => props.theme.font};
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 54px;
  text-align: center;
  color: #080f18;
  @media (max-width: 768px) {
    font-size: 22px;
    margin: 0;
  }
`;

const Text = styled.div`
  font-family: ${(props: any) => props.theme.font};
  font-style: normal;
  font-weight: 300;
  font-size: 24px;
  line-height: 36px;
  text-align: center;
  color: #080f18;
  mix-blend-mode: normal;
  opacity: 0.6;
  @media (max-width: 768px) {
    font-size: 20px;
    line-height: 18px;
  }
`;

const AcceptOfferWrapper = styled.div`
  flex: 1 1 auto;
  font-family: ${(props: any) => props.theme.font};
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  margin-bottom: 16rem;
`;

const FooterContainer = styled.div`
  // this causes some weird designs to show up in storybook but
  // it actually works in prod to make out footer stick to the bottom
  position: sticky;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 20;
`;

const EndScreenImage = styled.img`
  max-height: 250px;
  max-width: 30vw;

  @media (max-width: 768px) {
    max-width: 300px;
  }
`;

const EndScreen: React.FC<EndScreenProps> = (props: EndScreenProps) => {
  const [confirmBundleCancelChecked, setConfirmBundleCancelChecked] =
    React.useState(props.confirm_cancel && props.show_cancel_bundle);
  const updateConfirmBundleCancelChecked = () =>
    setConfirmBundleCancelChecked(!confirmBundleCancelChecked);

  let localImage = null;
  try {
    localImage = localStorage.getItem("previewImage");
  } catch (err) {
    console.warn(err);
  }

  if (props.single_page_preview === true && localImage) {
    props.confirm_cancel_image = localImage || props.confirm_cancel_image;
    props.customer_lost_image = localImage || props.customer_lost_image;
    props.customer_saved_image = localImage || props.customer_saved_image;
  }

  let OptionalFooter = <Footer />;
  if (props.confirm_cancel && props.callUpdate) {
    OptionalFooter = (
      <Footer
        onClickBack={
          props.cancel_after_error
            ? () =>
                (window.location.href =
                  props.website_url || "https://bellwethr.com")
            : (e) => {
                if (props.callUpdate) props.callUpdate("_back");
              }
        }
        backButtonText={
          props.cancel_after_error ? props.back_home_button : undefined
        }
      />
    );
  } else if (props.saved_screen) {
    OptionalFooter = (
      <Footer
        thanksButtonText={props.saved_screen && props.force_cancel_button_text}
        onClickContinue={
          props.saved_screen && props.force_cancel_button_text
            ? (e) => {
                if (props.callUpdate) props.callUpdate("continue");
              }
            : undefined
        }
        hide_carrets={props.saved_screen}
      />
    );
  }

  let ConfirmCancelImage =
    props.confirm_cancel && props.confirm_cancel_image ? (
      <CenterGraphic>
        <EndScreenImage
          alt="confirm cancel image"
          src={props.confirm_cancel_image}
          className="end-screen-image"
        />
      </CenterGraphic>
    ) : null;

  let CustomerLostImage =
    props.cancel && props.show_customer_lost_image ? (
      props.customer_lost_image ? (
        <CenterGraphic>
          <EndScreenImage
            alt="sorry to see you leave"
            src={props.customer_lost_image}
            className="end-screen-image"
          />
        </CenterGraphic>
      ) : (
        <CenterGraphic style={{ height: 240 }}>
          <Canceled />
        </CenterGraphic>
      )
    ) : null;

  let CustomerSavedImage =
    props.saved_screen && props.show_customer_saved_image ? (
      props.customer_saved_image ? (
        <CenterGraphic>
          <EndScreenImage
            alt="glad you stayed"
            src={props.customer_saved_image}
            className="end-screen-image"
          />
        </CenterGraphic>
      ) : (
        <CenterGraphic style={{ height: 240 }}>
          <Saved />
        </CenterGraphic>
      )
    ) : null;

  let BundleSubscriptionCheckbox =
    props.confirm_cancel && props.show_cancel_bundle ? (
      <TextWrapper
        style={{ marginTop: "0" }}
        className="cancel-bundle-disclaimer-text"
      >
        <MultipleSubsDisclaimerText>
          {props.bundle_cancel_disclaimer}
        </MultipleSubsDisclaimerText>

        <div>
          <input
            style={{ height: "20px", width: "20px" }}
            type="checkbox"
            id="cancel_all_subscriptions_in_bundle"
            checked={confirmBundleCancelChecked}
            onChange={updateConfirmBundleCancelChecked}
          />
        </div>
      </TextWrapper>
    ) : null;

  let CancelAlternative =
    props.show_cancel_alternative_action &&
    props.cancel_alternative_action_text ? (
      <TextWrapper>
        <Text style={{ fontSize: "1.3em", textDecoration: "underline" }}>
          <Link
            href={props.cancel_alternative_action_url}
            target="_blank"
            rel="noopener"
          >
            {props.cancel_alternative_action_text}
          </Link>
        </Text>
      </TextWrapper>
    ) : null;

  const isIframe = window !== window.top;

  return (
    <FullPageWhite className="end-screen-background">
      <CenterCard>
        {ConfirmCancelImage}
        {CustomerLostImage}
        {CustomerSavedImage}
      </CenterCard>
      <TextWrapper>
        <Title className="end-screen-title">{props.title}</Title>
        {props.text ? (
          <Text className="end-screen-description">{props.text}</Text>
        ) : null}
      </TextWrapper>
      {BundleSubscriptionCheckbox}
      <AcceptOfferWrapper className="end-screen-confirm-wrapper">
        {CancelAlternative}
        {isIframe &&
        props.is_embedded &&
        (props.cancel || props.saved_screen) ? (
          <span className="end-screen-refresh-page-text">
            {props.embedded_experience_refresh_page_text}
          </span>
        ) : (
          <AcceptOfferButton
            buttonText={props.button_text}
            onClick={(e) => {
              if (
                BundleSubscriptionCheckbox !== null &&
                props.callUpdate &&
                confirmBundleCancelChecked
              )
                props.callUpdate("cancel_all");
              else if (props.onClick) props.onClick();
            }}
          />
        )}
      </AcceptOfferWrapper>
      <FooterContainer>{OptionalFooter}</FooterContainer>
    </FullPageWhite>
  );
};

export const EndScreenPaused: React.FunctionComponent<EndScreenProps> = (
  props
) => {
  const theme: ThemeAttributeProps = useTheme();
  return (
    <EndScreen
      title={theme.pause_success_headline}
      text={theme.pause_success_subtext}
      button_text={theme.back_home_button}
      onClick={() =>
        (window.location.href =
          theme.pause_claimed_redirect_url || theme.website_url)
      }
      callUpdate={props.callUpdate}
      saved_screen
      type="pause"
      show_customer_saved_image={theme.show_customer_saved_image}
      customer_saved_image={
        theme.show_customer_saved_image ? theme.customer_saved_image : ""
      }
      single_page_preview={props.single_page_preview}
      is_embedded={theme && theme.is_embedded}
      show_cancel_bundle={theme.show_cancel_bundle}
      force_cancel_button_text={
        theme.show_force_cancel_button
          ? theme.force_cancel_button_text
          : undefined
      }
      embedded_experience_refresh_page_text={
        theme.embedded_experience_refresh_page_text
      }
    />
  );
};

export const EndScreenSaved: React.FunctionComponent<EndScreenProps> = (
  props
) => {
  const theme: ThemeAttributeProps = useTheme();

  return (
    <EndScreen
      title={props.title}
      text={undefined}
      button_text={
        props.button_text ? props.button_text : theme.back_home_button
      }
      onClick={() =>
        (window.location.href =
          theme.resolution_claimed_redirect_url || theme.website_url)
      }
      callUpdate={props.callUpdate}
      saved_screen
      show_customer_saved_image={theme.show_customer_saved_image}
      customer_saved_image={
        theme.show_customer_saved_image ? theme.customer_saved_image : ""
      }
      single_page_preview={props.single_page_preview}
      is_embedded={theme && theme.is_embedded}
      show_cancel_bundle={theme.show_cancel_bundle}
      force_cancel_button_text={
        theme.show_force_cancel_button
          ? theme.force_cancel_button_text
          : undefined
      }
      embedded_experience_refresh_page_text={
        theme.embedded_experience_refresh_page_text
      }
    />
  );
};

export const EndScreenCancel: React.FC<EndScreenProps> = (props) => {
  const theme: ThemeAttributeProps = useTheme();
  const ConfirmCancel = theme.confirm_cancel_button;
  const BundleCancelDisclaimer = theme.bundle_subscription_disclaimer;
  return (
    <EndScreen
      title={
        props.cancel_after_error
          ? theme.cancel_after_error_heading
          : theme.confirm_cancel_heading
      }
      text={
        props.cancel_after_error
          ? theme.cancel_after_error_description
          : theme.confirm_cancel_description
      }
      confirm_cancel_image={theme.confirm_cancel_image}
      button_text={ConfirmCancel}
      confirm_cancel
      callUpdate={props.callUpdate}
      onClick={props.onClick}
      single_page_preview={props.single_page_preview}
      show_cancel_bundle={theme.show_cancel_bundle}
      bundle_cancel_disclaimer={BundleCancelDisclaimer}
      show_cancel_alternative_action={theme.show_cancel_alternative_action}
      cancel_alternative_action_text={theme.cancel_alternative_action_text}
      cancel_alternative_action_url={theme.cancel_alternative_action_url}
      cancel_after_error={props.cancel_after_error}
      website_url={theme.website_url}
      back_home_button={
        props.cancel_after_error ? theme.back_home_button : undefined
      }
      embedded_experience_refresh_page_text={
        theme.embedded_experience_refresh_page_text
      }
    />
  );
};

export const EndScreenLost: React.FC<EndScreenProps> = (props) => {
  const theme: ThemeAttributeProps = useTheme();
  const CancellationConfirmed = theme.customer_lost_headline;

  return (
    <EndScreen
      title={CancellationConfirmed}
      text={
        props.is_prepaid
          ? theme.customer_lost_description_for_prepaid
          : theme.customer_lost_description
      }
      button_text={theme.customer_lost_button_text}
      onClick={() =>
        (window.location.href =
          theme.customer_canceled_redirect_url || theme.website_url)
      }
      show_customer_lost_image={theme.show_customer_lost_image}
      customer_lost_image={
        theme.show_customer_lost_image ? theme.customer_lost_image : ""
      }
      cancel
      single_page_preview={props.single_page_preview}
      is_embedded={theme && theme.is_embedded}
      embedded_experience_refresh_page_text={
        theme.embedded_experience_refresh_page_text
      }
    />
  );
};

export const EndScreenSupportPhone: React.FC<EndScreenProps> = (props) => {
  const theme: ThemeAttributeProps = useTheme();
  return (
    <EndScreen
      title={theme.contact_support_for_cancel_heading}
      text={theme.contact_support_for_cancel_description}
      cancel
      button_text={theme.customer_lost_button_text}
      onClick={() => (window.location.href = theme.website_url)}
      is_embedded={theme && theme.is_embedded}
      embedded_experience_refresh_page_text={
        theme.embedded_experience_refresh_page_text
      }
    />
  );
};

export default EndScreen;

const Canceled: React.FC = () => {
  const theme: ThemeAttributeProps = useTheme();
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 271 271"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="experience-svg"
    >
      <path
        d="M220.188 271H50.8125C22.7471 271 0 248.253 0 220.188V50.8125C0 22.7471 22.7471 0 50.8125 0H220.188C248.253 0 271 22.7471 271 50.8125V220.188C271 248.253 248.253 271 220.188 271Z"
        fill={
          theme.secondary_color === theme.primary_color
            ? getFontColor(theme.primary_color)
            : theme.secondary_color
        }
      />
      <path
        d="M135.5 68C98.2653 68 68 98.2653 68 135.5C68 172.735 98.2653 203 135.5 203C172.735 203 203 172.735 203 135.5C203 98.2653 172.735 68 135.5 68ZM96.1222 115.815C96.1222 113.512 98.0375 111.597 100.341 111.597C102.644 111.597 104.56 113.512 104.56 115.815C104.56 118.912 107.091 121.443 110.188 121.443C113.284 121.443 115.815 118.912 115.815 115.815C115.815 113.512 117.731 111.597 120.034 111.597C122.337 111.597 124.253 113.512 124.253 115.815C124.253 123.578 117.95 129.881 110.188 129.881C102.425 129.881 96.1222 123.578 96.1222 115.815ZM153.784 170.659H117.224C114.119 170.659 111.597 168.136 111.597 165.031C111.597 161.926 114.111 159.403 117.224 159.403H153.784C156.889 159.403 159.412 161.926 159.412 165.031C159.412 168.136 156.889 170.659 153.784 170.659ZM160.812 129.872C153.05 129.872 146.747 123.569 146.747 115.807C146.747 113.503 148.662 111.588 150.966 111.588C153.269 111.588 155.185 113.503 155.185 115.807C155.185 118.903 157.716 121.435 160.812 121.435C163.909 121.435 166.44 118.903 166.44 115.807C166.44 113.503 168.356 111.588 170.659 111.588C172.963 111.588 174.878 113.503 174.878 115.807C174.878 123.578 168.575 129.872 160.812 129.872Z"
        fill={theme.primary_color}
      />
    </svg>
  );
};
const Saved: React.FC = () => {
  const theme: ThemeAttributeProps = useTheme();
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 271 271"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="experience-svg"
    >
      <path
        d="M220.188 271H50.8125C22.7471 271 0 248.253 0 220.188V50.8125C0 22.7471 22.7471 0 50.8125 0H220.188C248.253 0 271 22.7471 271 50.8125V220.188C271 248.253 248.253 271 220.188 271Z"
        fill={
          theme.secondary_color === theme.primary_color
            ? getFontColor(theme.primary_color)
            : theme.secondary_color
        }
      />
      <path
        d="M72.163 162L54.7867 202.735C53.2861 206.256 53.9631 210.181 56.5537 212.98C58.3793 214.951 60.8171 216 63.349 216C64.4109 216 65.4911 215.815 66.5469 215.435L93 205.927L72.163 162Z"
        fill={theme.primary_color}
      />
      <path
        d="M88.3918 124L77 150.969L101.443 203L122 195.539L88.3918 124Z"
        fill={theme.primary_color}
      />
      <path
        d="M204.863 87.048C202.293 87.4154 200.509 89.7892 200.878 92.351C201.012 93.2945 201.122 94.211 201.22 95.1154C202.337 105.455 200.999 113.293 197.137 119.002C192.936 125.21 185.147 128.852 177.755 128.062C176.028 127.877 174.327 127.451 172.702 126.818C172.999 126.306 173.275 125.783 173.527 125.249C174.886 122.379 175.45 119.075 175.23 115.795C175.061 113.293 174.437 110.807 173.354 108.536C171.195 104.005 167.558 100.908 163.113 99.8131C158.209 98.606 152.802 101.857 151.059 107.06C149.921 110.456 150.221 114.339 151.95 118.602C153.386 122.146 155.489 125.344 158.079 128.061C155.74 129.305 152.99 130.208 149.966 130.684C147.403 131.088 145.653 133.487 146.058 136.044C146.423 138.354 148.421 140 150.693 140C150.936 140 151.184 139.982 151.431 139.943C157.046 139.058 162.063 137.009 166.045 134.088C169.353 135.834 172.983 136.978 176.754 137.381C177.781 137.491 178.806 137.544 179.832 137.544C189.712 137.544 199.318 132.532 204.925 124.245C210.488 116.022 212.206 105.155 210.179 91.0231C209.811 88.4613 207.428 86.6806 204.863 87.048ZM159.971 110.031C160.162 109.462 160.689 109.019 160.947 108.936C163.129 109.516 164.338 111.449 164.868 112.561C166.171 115.294 166.234 118.704 165.03 121.248C164.981 121.352 164.93 121.456 164.877 121.558C163.087 119.683 161.634 117.491 160.661 115.089C159.807 112.982 159.569 111.233 159.971 110.031Z"
        fill={theme.primary_color}
      />
      <path
        d="M179 155.501C179 152.462 176.538 150 173.501 150C170.462 150 168 152.462 168 155.501C168 158.538 170.462 161 173.501 161C176.538 161 179 158.538 179 155.501Z"
        fill={theme.primary_color}
      />
      <path
        d="M188 91.5C188 88.4624 185.538 86 182.499 86C179.462 86 177 88.4624 177 91.5C177 94.5376 179.462 97 182.499 97C185.538 97 188 94.5376 188 91.5Z"
        fill={theme.primary_color}
      />
      <path
        d="M200 83C207.168 83 213 76.9444 213 69.5006C213 62.0556 207.169 56 200 56C192.831 56 187 62.0556 187 69.5006C187 76.9444 192.832 83 200 83Z"
        fill={theme.primary_color}
      />
      <path
        d="M138 112C138 108.686 135.538 106 132.499 106C129.462 106 127 108.686 127 112C127 115.314 129.462 118 132.499 118C135.538 118 138 115.314 138 112Z"
        fill={theme.primary_color}
      />
      <path
        d="M168 68.9994C168 65.686 165.537 63 162.5 63C159.462 63 157 65.686 157 68.9994C157 72.314 159.462 75 162.5 75C165.537 75 168 72.314 168 68.9994Z"
        fill={theme.primary_color}
      />
      <path
        d="M130.458 88.1274H132.542V90.3733C132.542 92.9288 134.538 95 137 95C139.462 95 141.458 92.9288 141.458 90.3733V88.1274H143.542C146.004 88.1274 148 86.055 148 83.5006C148 80.945 146.004 78.8739 143.542 78.8739H141.458V76.6267C141.458 74.0724 139.462 72 137 72C134.538 72 132.542 74.0724 132.542 76.6267V78.8739H130.458C127.996 78.8739 126 80.945 126 83.5006C126 86.055 127.996 88.1274 130.458 88.1274Z"
        fill={theme.primary_color}
      />
      <path
        d="M101.15 98.5423C99.3977 96.6055 96.4298 96.4754 94.5201 98.2511C92.6103 100.028 92.4832 103.038 94.2342 104.975L96.0743 107.009L93.7259 112.532L131.787 193L163.507 181.565L164.85 183.05C165.776 184.073 167.041 184.591 168.309 184.591C169.443 184.591 170.578 184.177 171.48 183.34C173.39 181.563 173.517 178.553 171.766 176.617L101.15 98.5423Z"
        fill={theme.primary_color}
      />
    </svg>
  );
};
