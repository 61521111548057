import { history } from "app/history";
import { generatePath, Route, Router, Switch } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

// Material UI
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Tooltip from "@material-ui/core/Tooltip";
import Button from "@material-ui/core/Button";

// components
import { EXPERIENCE_ROOT, EXPERIENCE_URLS } from "../slice/urls";
import { ExperienceIconOrder, ExperienceIcons } from "../slice/tour";
import ExitSurveyPanel from "./ExitSurveyPanel";
import StylePanel from "./StylePanel";
import BuilderSidebarWrapper from "../BuilderSidebarWrapper";
import TreatmentLibraryPanel from "./TreatmentLibraryPanel";
import PausePanel from "./PausePanel";
import ExperienceTextsPanel from "./ExperienceTextsPanel";
import CssEditorPanel from "./CssEditorPanel";
import CancelledPanel from "./CancelledPanel";
import TreatmentFormPanel from "./TreatmentFormPanel";
import Loader from "../../../components/loading/Loader";

import {
  currentEngineSlugSelector,
  currentStepPathSelector,
  navigateTo,
} from "../slice/wizardSlice";

//styles
import { makeStyles } from "@material-ui/core/styles";
import commonStyles from "../../../components/layout/commonStyles";
import clsx from "clsx";
import wizardStyles from "../wizardStyles";
import FinalExitPanel from "./FinalExitPanel";

const useStyles = makeStyles((theme) => ({
  ...commonStyles(theme),
  ...wizardStyles(theme),
}));

export const ExperienceContainer = () => {
  const classes = useStyles();
  const currentStepPath = useSelector(currentStepPathSelector);
  const engine = useSelector(currentEngineSlugSelector);
  const dispatch = useDispatch();
  if (!currentStepPath) {
    return <Loader padding />;
  }

  return (
    <BuilderSidebarWrapper>
      <Grid
        container
        justifyContent="flex-start"
        className={classes.fullHeight}
      >
        <Grid
          item
          className={clsx(classes.fullHeight, classes.sidebarNavigation)}
        >
          <Grid
            container
            direction="column"
            alignItems="center"
            style={{ paddingTop: "0.5em" }}
          >
            {ExperienceIconOrder.map((name: string) => {
              const icon = ExperienceIcons[name];
              return (
                <Grid item key={icon.alt}>
                  <Button
                    onClick={() =>
                      dispatch(
                        navigateTo(generatePath(icon.target, { engine }))
                      )
                    }
                    className={classes.sidebarNavIcon}
                  >
                    <Tooltip title={icon.title || ""} placement="left" arrow>
                      <img
                        src={
                          currentStepPath.indexOf(icon.target) !== -1
                            ? icon.selected
                            : icon.normal
                        }
                        alt={icon.alt}
                      />
                    </Tooltip>
                  </Button>
                </Grid>
              );
            })}
          </Grid>
        </Grid>
        <Grid item className={clsx(classes.fullHeight, classes.flexTrue)}>
          <Paper
            square
            className={clsx(classes.sidebarContent, classes.scrollY)}
          >
            <Router history={history}>
              <Switch>
                <Route
                  exact
                  component={ExitSurveyPanel}
                  path={EXPERIENCE_URLS.exit_survey}
                />

                <Route
                  exact
                  component={TreatmentLibraryPanel}
                  path={EXPERIENCE_URLS.treatments}
                />
                <Route
                  exact
                  component={TreatmentFormPanel}
                  path={EXPERIENCE_URLS.add_treatment}
                />
                <Route
                  exact
                  component={TreatmentFormPanel}
                  path={EXPERIENCE_URLS.duplicate_treatment}
                />
                <Route
                  exact
                  component={TreatmentFormPanel}
                  path={EXPERIENCE_URLS.edit_treatment}
                />
                <Route
                  exact
                  component={PausePanel}
                  path={EXPERIENCE_URLS.pause_options}
                />
                <Route
                  exact
                  component={ExperienceTextsPanel}
                  path={EXPERIENCE_URLS.experience_texts}
                />

                <Route
                  exact
                  component={CancelledPanel}
                  path={EXPERIENCE_URLS.cancelled}
                />
                <Route
                  exact
                  component={FinalExitPanel}
                  path={EXPERIENCE_URLS.exit}
                />
                <Route
                  exact
                  component={StylePanel}
                  path={EXPERIENCE_URLS.style}
                />
                <Route
                  exact
                  component={CssEditorPanel}
                  path={EXPERIENCE_URLS.css_editor}
                />

                <Route
                  exact
                  component={ExitSurveyPanel}
                  path={EXPERIENCE_ROOT}
                />
              </Switch>
            </Router>
          </Paper>
        </Grid>
      </Grid>
    </BuilderSidebarWrapper>
  );
};
