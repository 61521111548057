import React, { useState } from "react";

// Material UI
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import InputBase from "@material-ui/core/InputBase";

//images
import GrayX from "../img/grayX.svg";
import SquarePurpleCheck from "../img/squarePurpleCheck.svg";

// styles
import { makeStyles } from "@material-ui/core/styles";
import commonStyles from "components/layout/commonStyles";
import wizardStyles from "features/wizard/wizardStyles";

const useStyles = makeStyles((theme) => ({
  ...commonStyles(theme),
  ...wizardStyles(theme),
}));

type EditableTreeCardProps = {
  onClickCancel: () => void;
  reason_text?: string;
  onClickSave: (reason_text: string) => void;
};

export const EditableTreeCard: React.FC<EditableTreeCardProps> = ({
  onClickCancel,
  reason_text,
  onClickSave,
}) => {
  const classes = useStyles();
  const field = React.useRef();
  const [text, setText] = useState(reason_text || "");

  return (
    <Card className={classes.emptyTreeCard}>
      <Grid container alignItems="center" spacing={1}>
        <Grid item xs={10}>
          <InputBase
            className={classes.optionTextField}
            value={text}
            ref={field}
            onChange={(e) => setText(e.target.value)}
          />
        </Grid>
        <Grid item xs={1}>
          <img src={GrayX} alt="delete_icon" onClick={() => onClickCancel()} />
        </Grid>
        <Grid item xs={1}>
          <img
            src={SquarePurpleCheck}
            alt="add_icon"
            onClick={() => onClickSave(text)}
          />
        </Grid>
      </Grid>
    </Card>
  );
};

export default EditableTreeCard;
