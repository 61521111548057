import React, { FC, useEffect } from "react";
import { RouteProps } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { logoutUser, userSelector } from "./features/user/userSlice";
import { Redirect, Route } from "react-router-dom";
import Loader from "./components/loading/Loader";
import { navigateTo } from "./features/wizard/slice/wizardSlice";
import toast from "react-hot-toast";

export const VerifiedUserRoute: FC<RouteProps> = ({ component, ...rest }) => {
  const { auth, isFetching, isError } = useSelector(userSelector);
  const Component = component as React.ElementType;
  const dispatch = useDispatch();
  const email = auth.user?.email;

  useEffect(() => {
    // Also do this again on all logged-in-routes, but dont depend on stored_user from localStorage
    const stored = localStorage.getItem("RE_auth_user");

    if (!isFetching && !isError) {
      if (!stored) {
        console.log("No user cookie detected, you are not logged in!");
        dispatch(logoutUser());
        dispatch(navigateTo("/login"));
        toast.error("You are not logged in!");
      }
    }
  }, [email, dispatch, isFetching, isError]);

  return (
    <Route
      {...rest}
      render={(props) => {
        if (isFetching)
          return <Loader padding reason="Checking your profile..." />;
        else if (email) return <Component {...props} />;
        else if (auth.failed || isError) {
          console.log("You have no profile, logging you out");
          return <Redirect to="/logout" />;
        }
      }}
    />
  );
};
