import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";

// components
import { UpdateEngineFields } from "../slice/types";
import PanelHeader from "components/wizard/PanelHeader";
import LinkGeneratorDialog from "components/wizard/settings/LinkGeneratorDialog";
import { AttentionBubble } from "components/wizard/AttentionBubble";
import ContextVariableList from "components/wizard/ContextVariableList";

import {
  wizardEngineSelector,
  currentEngineSlugSelector,
  updateEngine,
  engineStyleSelector,
} from "../slice/wizardSlice";

// Material UI
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import FormGroup from "@material-ui/core/FormGroup";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";

// styles
import { makeStyles } from "@material-ui/core/styles";
import commonStyles from "../../../components/layout/commonStyles";
import wizardStyles from "../wizardStyles";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  ...commonStyles(theme),
  ...wizardStyles(theme),
}));

export default function AdvancedSettingsPanel() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { control, handleSubmit } = useForm();
  const currentEngineSlug = useSelector(currentEngineSlugSelector);
  const currentEngine = useSelector(wizardEngineSelector);
  const styling = useSelector(engineStyleSelector);
  const isFetchingUpdateEngine = useSelector(updateEngine.isFetchingSelector);

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleCloseDialog = () => {
    setOpen(false);
  };

  const onSubmit = handleSubmit((data) => {
    const updateSlug =
      data.slug !== currentEngineSlug ? { updateSlug: data.slug } : {};
    dispatch(
      updateEngine.Action({
        ...data,
        ...updateSlug,
        slug: currentEngineSlug,
      } as UpdateEngineFields)
    );
  });

  return (
    <div className={classes.mdPadding}>
      <PanelHeader title="Advanced" caption="Settings" />
      <Divider className={classes.headingDivider} />
      <Typography variant="h6" className={classes.purpleGradient}>
        RetentionEngine Link Generator
      </Typography>
      <Typography variant="body2" className={classes.gray}>
        Need to send a link to RetentionEngine directly to a customer? Use this
        form to create a link.
      </Typography>
      <Button className={classes.panelButton} onClick={handleClickOpen}>
        Open Link Generator
      </Button>
      <LinkGeneratorDialog
        open={open}
        currentEngineSlug={currentEngineSlug}
        handleCloseDialog={() => handleCloseDialog()}
      />
      <form onSubmit={onSubmit}>
        <Typography variant="h6" className={classes.panelSubheader}>
          Cancellation API Call URL
        </Typography>
        <Typography variant="body2" className={classes.gray}>
          You can <span className={classes.bold}>optionally</span> provide an
          API endpoint that should be called when customer cancels their account
        </Typography>
        <ContextVariableList
          contextVariables={styling.context_vars}
          textType="URL"
          isUrl
        />

        <FormGroup>
          <Controller
            control={control}
            name="custom_subscription_url"
            defaultValue={currentEngine.custom_subscription_url}
            render={({ field }) => (
              <TextField
                {...field}
                InputLabelProps={{ shrink: true }}
                label="255 CHARACTERS MAX"
                variant="outlined"
                type="url"
              />
            )}
          />
        </FormGroup>
        <FormControlLabel
          control={
            <Switch
              checked={currentEngine.replace_payment_engine}
              name="replace_payment_engine"
              onChange={() =>
                dispatch(
                  updateEngine.Action({
                    replace_payment_engine:
                      !currentEngine.replace_payment_engine,
                    slug: currentEngineSlug,
                  })
                )
              }
            />
          }
          label="Replace Payment Engine Cancellation"
          className={classes.zeroPadding}
        />
        <Typography
          variant="body2"
          className={clsx(classes.gray, classes.zeroMargin)}
        >
          Turn on if your custom api will replace the payment engine
          cancellation.
        </Typography>

        {currentEngine.allows_cancel_bundle ? (
          <>
            <Typography variant="h6" className={classes.panelSubheader}>
              Bundle Subscriptions
            </Typography>
            <FormControlLabel
              control={
                <Switch
                  checked={currentEngine.show_cancel_bundle}
                  name="show_cancel_bundle"
                  onChange={() =>
                    dispatch(
                      updateEngine.Action({
                        show_cancel_bundle: !currentEngine.show_cancel_bundle,
                        slug: currentEngineSlug,
                      })
                    )
                  }
                />
              }
              label="Allow Bundle Subscriptions Cancellation"
              className={classes.zeroPadding}
            />
            <Typography
              variant="body2"
              className={clsx(classes.gray, classes.zeroMargin)}
            >
              Turn on if you wish to allow customers to cancel a bundle made up
              of multiple subscriptions.
            </Typography>
          </>
        ) : null}

        {currentEngine.payment_engine === "RECHARGE" ? (
          <>
            <Typography variant="h6" className={classes.panelSubheader}>
              Address Discounts
            </Typography>
            <FormControlLabel
              control={
                <Switch
                  checked={currentEngine.apply_discount_to_address}
                  name="apply_discount_to_address"
                  onChange={() =>
                    dispatch(
                      updateEngine.Action({
                        apply_discount_to_address:
                          !currentEngine.apply_discount_to_address,
                        slug: currentEngineSlug,
                      })
                    )
                  }
                />
              }
              label="Apply discounts to address"
              className={classes.zeroPadding}
            />
            <Typography
              variant="body2"
              className={clsx(classes.gray, classes.zeroMargin)}
            >
              Turn on if you wish to apply discounts to the customers address.{" "}
              <span className={classes.warningText}>
                Strongly recommended for bundle subscriptions
              </span>
            </Typography>
          </>
        ) : null}

        <Typography variant="h6" className={classes.panelSubheader}>
          Conditional End Screen Redirect
        </Typography>
        <Typography variant="body2" className={classes.gray}>
          Add dynamic redirect links for each experience outcome.
        </Typography>
        <AttentionBubble text="Conditional redirects that are left empty in this section will make use of the default redirect link." />

        <Typography variant="h6" className={classes.panelSubheader}>
          Resolution Claimed Redirect URL
        </Typography>
        <Typography variant="body2" className={classes.gray}>
          Once an offer is claimed, this is where the customer will be
          redirected.
        </Typography>
        <ContextVariableList
          contextVariables={styling.context_vars}
          textType="URL"
          isUrl
        />
        <FormGroup>
          <Controller
            control={control}
            name="resolution_claimed_redirect_url"
            defaultValue={currentEngine.resolution_claimed_redirect_url}
            render={({ field }) => (
              <TextField
                {...field}
                InputLabelProps={{ shrink: true }}
                label="255 CHARACTERS MAX"
                variant="outlined"
                type="url"
              />
            )}
          />
        </FormGroup>

        <Typography variant="h6" className={classes.panelSubheader}>
          Pause Claimed Redirect URL
        </Typography>
        <Typography variant="body2" className={classes.gray}>
          Once a pause is claimed, this is where the customer will be
          redirected.
        </Typography>
        <ContextVariableList
          contextVariables={styling.context_vars}
          textType="URL"
          isUrl
        />
        <FormGroup>
          <Controller
            control={control}
            name="pause_claimed_redirect_url"
            defaultValue={currentEngine.pause_claimed_redirect_url}
            render={({ field }) => (
              <TextField
                {...field}
                InputLabelProps={{ shrink: true }}
                label="255 CHARACTERS MAX"
                variant="outlined"
                type="url"
              />
            )}
          />
        </FormGroup>

        <Typography variant="h6" className={classes.panelSubheader}>
          Customer Canceled Redirect URL
        </Typography>
        <Typography variant="body2" className={classes.gray}>
          Once a customer has canceled, this is where the customer will be
          redirected.
        </Typography>
        <ContextVariableList
          contextVariables={styling.context_vars}
          textType="URL"
          isUrl
        />
        <FormGroup>
          <Controller
            control={control}
            name="customer_canceled_redirect_url"
            defaultValue={currentEngine.customer_canceled_redirect_url}
            render={({ field }) => (
              <TextField
                {...field}
                InputLabelProps={{ shrink: true }}
                label="255 CHARACTERS MAX"
                variant="outlined"
                type="url"
              />
            )}
          />
        </FormGroup>

        <Typography variant="h6" className={classes.panelSubheader}>
          Company URL Name
        </Typography>
        <Typography variant="body2" className={classes.gray}>
          Edit the company name for the engine URL.
          <br />
          Example:
          <br />
          <span className={classes.underline}>
            https://app.bellwethr.com/offboard/experience/company-name-3555688611
          </span>
        </Typography>
        <FormGroup>
          <Controller
            control={control}
            name="slug"
            defaultValue={currentEngine.slug}
            render={({ field }) => (
              <TextField
                {...field}
                InputLabelProps={{ shrink: true }}
                label="100 CHARACTERS MAX"
                variant="outlined"
              />
            )}
          />
        </FormGroup>
        <AttentionBubble
          text={`
                1. This field only accepts lowercase letters, numbers, and
                hyphens.
                
                2. If you change this field you need to update your code snippet
                too.`}
        />
        <Button
          type="submit"
          className={clsx(classes.panelButton, classes.marginTop)}
          disabled={isFetchingUpdateEngine}
        >
          Save Changes
        </Button>
      </form>
    </div>
  );
}
