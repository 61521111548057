import { useSelector, useDispatch } from "react-redux";
import {
  displayDataSelector,
  openPreviewStep,
  closePreview,
} from "features/wizard/slice/wizardSlice";
import { States } from "features/offboarding/containers/StateViewRouter/StateViewRouter";

// images
import OpenPreview from "features/wizard/img/previewLeftArrowGray.svg";
import ClosePreview from "features/wizard/img/previewRightArrowPurple.svg";

// Material UI
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Tooltip from "@material-ui/core/Tooltip";

// styles
import { makeStyles } from "@material-ui/core/styles";
import commonStyles from "components/layout/commonStyles";
import wizardStyles from "features/wizard/wizardStyles";

const useStyles = makeStyles((theme) => ({
  ...commonStyles(theme),
  ...wizardStyles(theme),
}));

interface FormSectionHeaderProps {
  title: string;
  showPreview?: boolean;
  headerInPreview?: boolean;
  previewStep?: States;
  extraPreviewProps?: object;
}

export const FormSectionHeader: React.FC<FormSectionHeaderProps> = ({
  title,
  showPreview,
  headerInPreview,
  previewStep,
  extraPreviewProps,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const showPreviewStep = useSelector(displayDataSelector).showPreviewStep;
  const inPreview =
    headerInPreview !== null ? headerInPreview : showPreviewStep;
  const step = !previewStep ? States.REASONS : previewStep;
  const previewProps = extraPreviewProps ? extraPreviewProps : {};

  return (
    <Grid container alignItems="baseline" justifyContent="space-between">
      <Grid item>
        <Typography variant="h6" className={classes.panelSubheader}>
          {title}
        </Typography>
      </Grid>
      {showPreview ? (
        <Grid item>
          <Tooltip
            title={inPreview ? "Close Preview" : "Open Preview"}
            placement="left"
            arrow
          >
            <img
              src={inPreview ? ClosePreview : OpenPreview}
              alt="toggle_preview"
              onClick={() =>
                dispatch(
                  inPreview
                    ? closePreview()
                    : openPreviewStep({ step, ...previewProps })
                )
              }
            />
          </Tooltip>
        </Grid>
      ) : null}
    </Grid>
  );
};

export default FormSectionHeader;
