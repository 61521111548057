import { useSelector } from "react-redux";
import { displayDataSelector } from "features/wizard/slice/wizardSlice";

// images
import OpenPreview from "features/wizard/img/previewLeftArrowGray.svg";
import ClosePreview from "features/wizard/img/previewRightArrowPurple.svg";

// Material UI
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Tooltip from "@material-ui/core/Tooltip";

// styles
import { makeStyles } from "@material-ui/core/styles";
import commonStyles from "components/layout/commonStyles";
import wizardStyles from "features/wizard/wizardStyles";

const useStyles = makeStyles((theme) => ({
  ...commonStyles(theme),
  ...wizardStyles(theme),
}));

interface PanelHeaderProps {
  title: string;
  caption?: string;
  onClick?: CallableFunction;
  headerInPreview?: boolean;
}

export const PanelHeader: React.FC<PanelHeaderProps> = ({
  title,
  caption,
  onClick,
  headerInPreview,
}) => {
  const classes = useStyles();
  const showPreviewStep = useSelector(displayDataSelector).showPreviewStep;
  const inPreview =
    headerInPreview !== null ? headerInPreview : showPreviewStep;

  return (
    <Grid container alignItems="center" justifyContent="space-between">
      <Grid item>
        <Typography variant="h5" className={classes.panelHeader}>
          {title}
        </Typography>
        {caption ? (
          <Typography variant="caption" className={classes.panelCaption}>
            {caption}
          </Typography>
        ) : null}
      </Grid>
      {onClick ? (
        <Grid item>
          <Tooltip
            title={inPreview ? "Close Preview" : "Open Preview"}
            placement="left"
            arrow
          >
            <img
              src={inPreview ? ClosePreview : OpenPreview}
              alt="open_preview"
              onClick={() => onClick()}
            />
          </Tooltip>
        </Grid>
      ) : null}
    </Grid>
  );
};

export default PanelHeader;
