import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import { mergeErrors } from "app/forms";

// components
import Loader from "components/loading/Loader";
import PanelHeader from "components/wizard/PanelHeader";
import { NotificationPeriod, NotificationSettings } from "../slice/types";
import { authUserSelector } from "features/user/userSlice";
import { AttentionBubble } from "components/wizard/AttentionBubble";

import {
  currentEngineSlugSelector,
  getNotificationSettings,
  notificationSettingsSelector,
  previewNotificationEmail,
  updateNotificationSettings,
  wizardEngineSelector,
  wizardSelector,
} from "../slice/wizardSlice";

// Material UI
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import Switch from "@material-ui/core/Switch";
import Alert from "@material-ui/lab/Alert";

// icons
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import Send from "@material-ui/icons/Send";

// styles
import { makeStyles } from "@material-ui/core/styles";
import commonStyles from "../../../components/layout/commonStyles";
import wizardStyles from "../wizardStyles";
import clsx from "clsx";
import toast from "react-hot-toast";

const useStyles = makeStyles((theme) => ({
  ...commonStyles(theme),
  ...wizardStyles(theme),
}));

function validateEmail(email: string) {
  const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return re.test(String(email).toLowerCase());
}

export default function NotificationSettingsPanel() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const currentEngineSlug = useSelector(currentEngineSlugSelector);
  const engine = useSelector(wizardEngineSelector);
  const notificationSettings = useSelector(notificationSettingsSelector);
  const user = useSelector(authUserSelector);
  const [email, setEmail] = useState("");
  const [teamEmails, setTeamEmails] = useState<string[]>([]);
  const disableStatisticsNotificationsPeriod = engine.payment_engine === "NONE";
  const disabledPreviewCustomerNotifications =
    engine.payment_engine === "RETEXTION";

  const { updateNotificationSettingsErrors, getNotificationSettingsStatus } =
    useSelector(wizardSelector);

  const fields = notificationSettings.field_descriptions || {};
  const fieldNamesClient = Object.keys(fields).filter((key) =>
    key.includes("_client_")
  );
  const fieldNamesCustomer = Object.keys(fields).filter((key) =>
    key.includes("_customer_")
  );

  const [period, setPeriod] = useState<NotificationPeriod>(
    NotificationPeriod.INSTANT
  );

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmitEmails = () => {
    dispatch(
      updateNotificationSettings({
        team_notification_emails: teamEmails,
        slug: currentEngineSlug,
      } as NotificationSettings)
    );
  };

  const {
    handleSubmit,
    control,
    formState: { errors: frontendErrors },
  } = useForm();

  const onSubmit = handleSubmit((data) => {
    dispatch(
      updateNotificationSettings({
        ...data,
        notification_period: period,
        slug: currentEngineSlug,
      } as NotificationSettings)
    );
  });

  useEffect(() => {
    if (Array.isArray(notificationSettings.team_notification_emails))
      setTeamEmails(notificationSettings.team_notification_emails);
  }, [notificationSettings.team_notification_emails]);

  useEffect(() => {
    if (currentEngineSlug) dispatch(getNotificationSettings(currentEngineSlug));
  }, [currentEngineSlug, dispatch]);

  useEffect(() => {
    if (notificationSettings.notification_period)
      setPeriod(notificationSettings.notification_period);
  }, [notificationSettings.notification_period]);

  const errors = mergeErrors(frontendErrors, updateNotificationSettingsErrors);

  if (
    getNotificationSettingsStatus === "FETCHING" ||
    getNotificationSettingsStatus === "NONE"
  ) {
    return <Loader padding reason="Fetching notification settings..." />;
  }

  return (
    <div className={classes.mdPadding}>
      <PanelHeader title="Notification Settings" caption="Settings" />
      <Divider className={classes.headingDivider} />
      <form onSubmit={onSubmit}>
        <Typography variant="h6" className={classes.purpleGradient}>
          Company Email Notifications
        </Typography>
        <Typography variant="body2" className={classes.gray}>
          Who will receive internal notifications about this RetentionEngine.
        </Typography>
        <Button className={classes.panelButton} onClick={handleClickOpen}>
          Edit List
        </Button>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="form-dialog-title"
          fullWidth
          maxWidth={"sm"}
        >
          <DialogTitle id="form-dialog-title">
            Team Notification Emails
          </DialogTitle>
          <DialogContent>
            <List dense>
              {teamEmails.map((teamEmail, index) => (
                <ListItem key={teamEmail}>
                  <ListItemText primary={teamEmail} />
                  <ListItemSecondaryAction>
                    <Tooltip title="Remove email">
                      <IconButton
                        onClick={() =>
                          setTeamEmails((old) =>
                            old.filter((e) => e !== teamEmail)
                          )
                        }
                      >
                        <RemoveIcon />
                      </IconButton>
                    </Tooltip>
                  </ListItemSecondaryAction>
                </ListItem>
              ))}
            </List>
            <Divider />
            <Grid container alignItems="center" spacing={2}>
              <Grid item xs={11}>
                <TextField
                  autoFocus
                  variant="outlined"
                  id="email"
                  label="Email Address"
                  type="email"
                  value={email}
                  fullWidth
                  onChange={(e) => setEmail(e.target.value)}
                />
              </Grid>
              <Grid item xs={1}>
                <Tooltip title="Add email">
                  <AddIcon
                    onClick={() => {
                      if (validateEmail(email)) {
                        setTeamEmails([...teamEmails, email]);
                        setEmail("");
                      } else {
                        toast.error("Not a valid email");
                      }
                    }}
                  />
                </Tooltip>
              </Grid>
            </Grid>
            {errors.get("team_notification_emails") && (
              <Alert severity="error">
                {errors.get("team_notification_emails")}
              </Alert>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Close</Button>
            <Button
              color="primary"
              variant="contained"
              onClick={handleSubmitEmails}
            >
              Save Email Settings
            </Button>
          </DialogActions>
        </Dialog>
        <Typography variant="h6" className={classes.panelSubheader}>
          Statistics Notification Period
        </Typography>
        <Typography variant="body2" className={classes.gray}>
          Select when you want your experience notifications to be sent.
        </Typography>
        <AttentionBubble
          text="All engines without
                a payment integration get instant notifications"
        />
        <Grid container alignItems="center">
          <Grid item xs={12} md={6}>
            <FormControl variant="outlined" className={classes.zeroMargin}>
              <Select
                id="period"
                defaultValue={period}
                fullWidth
                className={classes.gray}
                disabled={disableStatisticsNotificationsPeriod}
                onChange={(e) =>
                  setPeriod(e.target.value as NotificationPeriod)
                }
              >
                {Object.keys(NotificationPeriod).map((p) => (
                  <MenuItem key={p} value={p}>
                    {p}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControlLabel
              control={
                <Switch
                  checked={notificationSettings.instant_gift_notifications}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    dispatch(
                      updateNotificationSettings({
                        instant_gift_notifications: e.target.checked,
                        slug: currentEngineSlug,
                      })
                    )
                  }
                  name="instant_gift_notifications"
                />
              }
              label="Instant Gift Notifications"
            />
          </Grid>
        </Grid>
        <Typography variant="h6" className={classes.panelSubheader}>
          Email Sender and Reply Email
        </Typography>
        <FormGroup>
          <Controller
            control={control}
            name="customer_email_sender"
            rules={{ required: true }}
            defaultValue={notificationSettings.customer_email_sender}
            render={({ field }) => (
              <TextField
                {...field}
                InputLabelProps={{ shrink: true }}
                label="300 CHARACTERS MAX"
                variant="outlined"
                error={Boolean(errors.get("customer_email_sender"))}
                helperText={errors.get("customer_email_sender")}
              />
            )}
          />
        </FormGroup>
        <Typography variant="h6" className={clsx(classes.purpleGradient)}>
          Company Name for Email
        </Typography>
        <FormGroup>
          <Controller
            control={control}
            name="customer_email_signature"
            rules={{ required: true }}
            defaultValue={notificationSettings.customer_email_signature}
            render={({ field }) => (
              <TextField
                {...field}
                InputLabelProps={{ shrink: true }}
                label="300 CHARACTERS MAX"
                variant="outlined"
                error={Boolean(errors.get("customer_email_signature"))}
                helperText={errors.get("customer_email_signature")}
              />
            )}
          />
        </FormGroup>

        {!disabledPreviewCustomerNotifications && (
          <>
            <Typography variant="h6" className={classes.panelSubheader}>
              Customer Notifications
            </Typography>
            <FormControl component="fieldset">
              {fieldNamesCustomer.map((name) => {
                return (
                  <Grid container alignItems="center" key={name}>
                    <Grid item xs={10}>
                      <FormControlLabel
                        label={fields[name]}
                        key={name}
                        control={
                          <Controller
                            control={control}
                            name={name as keyof NotificationSettings}
                            defaultValue={notificationSettings[name]}
                            render={({ field }) => (
                              <Checkbox checked={field.value} {...field} />
                            )}
                          />
                        }
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <Tooltip title="Send Test Email" placement="top" arrow>
                        <IconButton
                          onClick={() =>
                            dispatch(
                              previewNotificationEmail.Action({
                                slug: currentEngineSlug,
                                email_type: name,
                                send_to_email: user.email,
                              })
                            )
                          }
                        >
                          <Send />
                        </IconButton>
                      </Tooltip>
                    </Grid>
                  </Grid>
                );
              })}
            </FormControl>
          </>
        )}
        {period !== "INSTANT" ? (
          <>
            <Typography variant="h6" className={classes.panelSubheader}>
              Client Notifications
            </Typography>
            <AttentionBubble text="All engines without a payment integration get all client notifications" />
            <FormControl component="fieldset">
              {fieldNamesClient.map((name) => {
                return (
                  <Grid container alignItems="center">
                    <Grid item xs={10}>
                      <FormControlLabel
                        label={fields[name]}
                        key={name}
                        control={
                          <Controller
                            control={control}
                            name={name as keyof NotificationSettings}
                            defaultValue={notificationSettings[name]}
                            render={({ field }) => (
                              <Checkbox checked={field.value} {...field} />
                            )}
                          />
                        }
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <Tooltip title="Send Test Email" placement="right" arrow>
                        <IconButton
                          onClick={() =>
                            dispatch(
                              previewNotificationEmail.Action({
                                slug: currentEngineSlug,
                                email_type: name,
                                send_to_email: user.email,
                              })
                            )
                          }
                        >
                          <Send />
                        </IconButton>
                      </Tooltip>
                    </Grid>
                  </Grid>
                );
              })}
            </FormControl>
          </>
        ) : null}
        <Button
          type="submit"
          className={clsx(classes.panelButton, classes.marginTop)}
        >
          Save Changes
        </Button>
      </form>
    </div>
  );
}
