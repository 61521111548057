import React from "react";
import styled from "@emotion/styled";
import { useTheme } from "emotion-theming";
import { ThemeAttributeProps } from "features/offboarding/theme";

interface FooterProps {
  backButtonText?: string;
  thanksButtonText?: string;
  onClickContinue?(e: any): void;
  onClickBack?(e: any): void;
  hide_carrets?: boolean;
}

/**
 * Wrapper for the footer. Responsible for sticking the footer to the bottom of
 * the viewport.
 */
const FooterWrapper = styled.div`
  position: -webkit-sticky;
  position: sticky;

  bottom: 0px;
  left: 0px;
  right: 0px;
  z-index: 20;
  overflow: hidden;

  height: 4rem;
  box-shadow: 0 -0.1rem 0.5rem #0006;
  background: ${(props: any) => props.theme.secondary_color};

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 768px) {
    height: 4.375rem;
    flex-direction: column-reverse;
    justify-content: center;
  }
`;

/**
 * Wrapper for the NavigationButtons in the Footer.
 */
const NavigationButtonsWrapper = styled.div`
  text-align: center;
  @media (max-width: 768px) {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
`;

/**
 * An individual navigation button
 */
const NavigationButton = styled.div`
  display: inline-block;
  padding: 0 1rem;
  margin-left: auto;
  margin-right: auto;

  font-family: ${(props: any) => props.theme.font};
  font-style: normal;
  font-weight: 500;
  color: ${(props: any) => props.theme.secondary_font_color};
  font-size: 16px;

  &:hover {
    background: ${(props: any) => props.theme.secondary_color};
    color: ${(props: any) => props.theme.secondary_font_color};
    cursor: pointer;
  }
`;

/**
 * Hidden div to help properly align content
 */
const HiddenDiv = styled.div`
  opacity: 0;
  @media (max-width: 768px) {
    display: none;
  }
`;

/**
 * Icon for the up arrow.
 */
const CarretUp = styled.i`
  border: solid ${(props: any) => props.theme.secondary_font_color};
  border-width: 0 4px 4px 0;
  display: inline-block;
  padding: 3px;
  transform: translateY(2px) rotate(-135deg);
  -webkit-transform: translateY(2px) rotate(-135deg);
`;

/**
 * Icon for the down arrow.
 */
const CarretDown = styled(CarretUp)`
  transform: translateY(-2px) rotate(45deg);
  -webkit-transform: translateY(-3px), rotate(45deg);
`;

/**
 * Wrapper for the Powered by RetentionEngine link.
 */
const PoweredByLinkWrapper = styled.div`
  display: inline-block;
  padding: 0 1rem;
  font-family: ${(props: any) => props.theme.font};
  font-style: normal;
  color: ${(props: any) => props.theme.secondary_font_color};
  font-size: 13px;

  a {
    color: ${(props: any) => props.theme.secondary_font_color};
  }
  &:hover {
    cursor: pointer;
  }

  @media (max-width: 768px) {
    margin-top: 16px;
    font-size: 12px;
  }
`;

/**
 * Complete Powered by RetentionEngine Link.
 */
const PoweredByLink = () => {
  return (
    <PoweredByLinkWrapper className="footer-nav-button">
      {"Powered by "}
      <a
        href="https://www.retentionengine.com/"
        target="_blank"
        rel="noopener noreferrer"
      >
        Bellwethr
      </a>
    </PoweredByLinkWrapper>
  );
};

/**
 * Footer at the bottom of every view. Hold forward and backward navigation, as
 * well as link to RetentionEngine.com.
 */
const Footer: React.FC<FooterProps> = (props) => {
  const theme: ThemeAttributeProps = useTheme();
  const ContinueToCancel = theme.continue_to_cancel;
  const GoBack = theme.go_back_button;

  return (
    <FooterWrapper className="footer-wrapper">
      {theme.hide_powered_by_bellwethr_link ? <div /> : <PoweredByLink />}
      <NavigationButtonsWrapper className="footer-nav-button-container">
        {props.onClickBack ? (
          <NavigationButton
            onClick={props.onClickBack}
            className="footer-nav-button"
          >
            {!props.hide_carrets && <CarretUp />}{" "}
            {props.backButtonText || GoBack}
          </NavigationButton>
        ) : (
          ""
        )}
        {props.onClickContinue ? (
          <NavigationButton
            onClick={props.onClickContinue}
            className="footer-nav-button"
          >
            {props.thanksButtonText || ContinueToCancel}{" "}
            {!props.hide_carrets && <CarretDown />}
          </NavigationButton>
        ) : (
          ""
        )}
      </NavigationButtonsWrapper>
      {theme.hide_powered_by_bellwethr_link ? (
        <div />
      ) : (
        <HiddenDiv>
          <PoweredByLink />
        </HiddenDiv>
      )}
    </FooterWrapper>
  );
};

export default Footer;
