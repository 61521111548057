import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { mergeErrors } from "app/forms";

// components
import { EngineStyle } from "../slice/types";
import DeleteEngineDialog from "components/wizard/settings/DeleteEngineDialog";
import Loader from "components/loading/Loader";
import NameAndWebsiteChangeForm from "components/wizard/settings/NameAndWebsiteChangeForm";
import PanelHeader from "components/wizard/PanelHeader";

import {
  wizardEngineSelector,
  currentEngineSlugSelector,
  engineStyleSelector,
  getEngineStyle,
  updateEngineStyle,
  updateEngine,
  getStaticOffboardValues,
  staticValuesSelector,
} from "../slice/wizardSlice";

// Material UI
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormGroup from "@material-ui/core/FormGroup";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";

// images
import TrashIcon from "../img/trashIconWhite.svg";

// styles
import { makeStyles } from "@material-ui/core/styles";
import commonStyles from "../../../components/layout/commonStyles";
import wizardStyles from "../wizardStyles";

const useStyles = makeStyles((theme) => ({
  ...commonStyles(theme),
  ...wizardStyles(theme),
}));

export default function YourEnginePanel() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const currentEngine = useSelector(wizardEngineSelector);
  const currentEngineSlug = useSelector(currentEngineSlugSelector);
  const updateEngineStyleErrors = useSelector(
    updateEngineStyle.ExtraErrorsSelector
  );
  const isStyleSaving = useSelector(updateEngineStyle.isFetchingSelector);
  const isEngineSaving = useSelector(updateEngine.isFetchingSelector);

  const style = useSelector(engineStyleSelector);
  const ready = useSelector(getEngineStyle.isReadySelector);

  const [open, setOpen] = useState(false);
  const [domain, setDomain] = useState(currentEngine.payment_api_domain);
  const [apiKey, setApiKey] = useState(currentEngine.payment_api_key);
  const [secret, setSecret] = useState(currentEngine.payment_api_secret);

  const loadingLanguages = useSelector(
    getStaticOffboardValues.isFetchingSelector
  );
  const staticValues = useSelector(staticValuesSelector);

  const {
    handleSubmit,
    control,
    formState: { errors: frontendErrors },
  } = useForm();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChangeLocale = (locale_code: string) => {
    dispatch(
      updateEngine.Action({
        locale_code,
        slug: currentEngineSlug,
      })
    );
  };

  const handleSaveAPIChanges = () => {
    dispatch(
      updateEngine.Action({
        payment_api_key: apiKey,
        payment_api_domain: domain,
        payment_api_secret: secret,
        slug: currentEngineSlug,
      })
    );
  };

  const onSubmit = handleSubmit((data) => {
    dispatch(updateEngineStyle.Reset());
    dispatch(
      updateEngineStyle.Action({
        ...data,
        slug: currentEngineSlug,
      } as EngineStyle)
    );
  });

  useEffect(() => {
    if (!staticValues) dispatch(getStaticOffboardValues.Action(null));
    dispatch(getEngineStyle.Action(currentEngineSlug));
  }, [dispatch, currentEngineSlug, staticValues]);

  const errors = mergeErrors(frontendErrors, updateEngineStyleErrors);

  if (loadingLanguages || !ready || !currentEngine) {
    return <Loader padding reason="Fetching language options..." />;
  } else {
    return (
      <div className={classes.mdPadding}>
        <PanelHeader title="Your Engine" caption="Settings" />
        <Divider className={classes.headingDivider} />
        <NameAndWebsiteChangeForm currentEngine={currentEngine} />

        <form onSubmit={onSubmit}>
          <Typography variant="h6" className={classes.panelSubheader}>
            RetentionEngine Tab Title*
          </Typography>
          <Typography variant="body2" className={classes.gray}>
            Edit the browser tab title shown to customers when the engine is
            open.
          </Typography>
          <FormGroup>
            <Controller
              control={control}
              name="experience_tab_title"
              rules={{ required: true }}
              defaultValue={style.experience_tab_title}
              render={({ field }) => (
                <TextField
                  {...field}
                  InputLabelProps={{ shrink: true }}
                  label="255 CHARACTERS MAX"
                  variant="outlined"
                  error={Boolean(errors.get("experience_tab_title"))}
                  helperText={errors.get("experience_tab_title")}
                />
              )}
            />
          </FormGroup>
          <Button
            type="submit"
            className={classes.panelButton}
            disabled={isStyleSaving}
          >
            Save Tab Title
          </Button>

          <Typography variant="h6" className={classes.panelSubheader}>
            Payment Integration API Keys
          </Typography>
          <Typography variant="body2" className={classes.gray}>
            Your engine is integrated with {currentEngine.payment_engine}.
          </Typography>
          {currentEngine.payment_api_domain ? (
            <FormGroup>
              <TextField
                name="payment_api_domain"
                InputLabelProps={{ shrink: true }}
                label="Payment API Domain"
                variant="outlined"
                value={domain}
                onChange={(e) => setDomain(e.target.value)}
              />
            </FormGroup>
          ) : null}
          {currentEngine.payment_api_key ? (
            <FormGroup>
              <TextField
                name="payment_api_key"
                InputLabelProps={{ shrink: true }}
                label="Payment API Key"
                variant="outlined"
                value={apiKey}
                type="password"
                onChange={(e) => setApiKey(e.target.value)}
              />
            </FormGroup>
          ) : null}
          {currentEngine.payment_api_secret ? (
            <FormGroup>
              <TextField
                name="payment_api_secret"
                InputLabelProps={{ shrink: true }}
                label="Payment API Secret"
                variant="outlined"
                type="password"
                value={secret}
                onChange={(e) => setSecret(e.target.value)}
              />
            </FormGroup>
          ) : null}
          {currentEngine.payment_api_domain ||
          currentEngine.payment_api_key ||
          currentEngine.payment_api_secret ? (
            <Button
              type="submit"
              className={classes.panelButton}
              disabled={isEngineSaving}
              onClick={handleSaveAPIChanges}
            >
              Save API Changes
            </Button>
          ) : null}

          <Typography variant="h6" className={classes.panelSubheader}>
            Locale
          </Typography>
          <Typography variant="body2" className={classes.gray}>
            The format used for date, time and currency.
          </Typography>
          <FormControl variant="outlined" className={classes.zeroMargin}>
            <Select
              id="locale_select"
              className={classes.gray}
              value={currentEngine.locale_code || ""}
              disabled={isEngineSaving}
              onChange={(e) => handleChangeLocale(e.target.value as string)}
            >
              {staticValues &&
                Object.keys(staticValues.locales).map((l) => (
                  <MenuItem key={l} value={l}>
                    {staticValues.locales[l]}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </form>

        <Typography variant="h6" className={classes.panelSubheader}>
          Delete this RetentionEngine
        </Typography>
        <Typography variant="body2" className={classes.gray}>
          <span className={classes.bold}>Note:</span> This action{" "}
          <span className={classes.warningText}>cannot</span> be undone.
        </Typography>
        <Button
          className={classes.lightPurpleButton}
          startIcon={<img src={TrashIcon} alt="delete" />}
          onClick={handleClickOpen}
        >
          Delete Engine
        </Button>
        <DeleteEngineDialog
          open={open}
          currentEngine={currentEngine}
          handleClose={handleClose}
        />
      </div>
    );
  }
}
