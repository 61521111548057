import { useDispatch, useSelector } from "react-redux";
import { generatePath, useHistory } from "react-router-dom";

// components
import MainTreeCard from "components/wizard/experience/MainTreeCard";
import SecondaryTreeCard from "components/wizard/experience/SecondaryTreeCard";
import { EXPERIENCE_URLS, MECHANICS_URLS, WIZARD_URLS } from "./slice/urls";
import { ExitSurveyResponse, Intermediate } from "./slice/types";
import Loader from "../../components/loading/Loader";
import TreeWrapper from "../../components/wizard/experience/TreeWrapper";

import {
  archiveTreatment,
  availableTreatmentsSelector,
  currentEngineSlugSelector,
  currentSlugSelector,
  currentStepSelector,
  deleteTreatment,
  displayDataSelector,
  selectedExitResponseSelector,
  stepNavigate,
  toggleCollapseTreatments,
  updateTreatment,
} from "./slice/wizardSlice";

// images
import PurpleCheck from "./img/purpleCheck.svg";
import GrayCheck from "../../components/wizard/img/grayCheck.svg";
import AddIcon from "../wizard/img/purpleAddIcon.svg";
import TrashIcon from "../../components/wizard/img/trashIcon.svg";
import ArchiveIcon from "./img/archivePurpleGraphic.svg";

// Material UI
import Grid from "@material-ui/core/Grid";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";

// styles
import { makeStyles } from "@material-ui/core/styles";
import commonStyles from "components/layout/commonStyles";
import clsx from "clsx";
import wizardStyles from "./wizardStyles";

const useStyles = makeStyles((theme) => ({
  ...commonStyles(theme),
  ...wizardStyles(theme),
}));

export function TreatmentTree() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const availableTreatments = useSelector(availableTreatmentsSelector);
  const currentStep = useSelector(currentStepSelector);
  const currentSlug = useSelector(currentSlugSelector);
  const currentEngineSlug = useSelector(currentEngineSlugSelector);
  const isArchivingTreatment = useSelector(
    archiveTreatment.isFetchingSelector
  ) as boolean;

  const collapsed = useSelector(
    (state) => displayDataSelector(state).treatmentsCollapsed
  );

  const onClickCollapseOptions = () => dispatch(toggleCollapseTreatments());

  const mainPanelSelected = currentStep?.path === EXPERIENCE_URLS.treatments;

  const isMechanics = currentStep?.path.indexOf(WIZARD_URLS.mechanics) !== -1;
  const isSettings = currentStep?.path.indexOf(WIZARD_URLS.settings) !== -1;

  const selectedExitResponse = useSelector(
    selectedExitResponseSelector
  ) as ExitSurveyResponse;

  const showTreatmentSlugs = Object.keys(availableTreatments)
    .filter(
      (slug: string) => mainPanelSelected || availableTreatments[slug].active
    )
    .filter((slug: string) => availableTreatments[slug].archived === false);

  const activeTreatmentSlugs = Object.keys(availableTreatments)
    .filter((slug: string) => availableTreatments[slug].active)
    .filter((slug: string) => availableTreatments[slug].archived === false);

  return (
    <TreeWrapper
      header={
        <MainTreeCard
          title="Treatments"
          number={activeTreatmentSlugs.length}
          collapsed={collapsed}
          onClick={onClickCollapseOptions}
          onCardClick={
            isMechanics
              ? undefined
              : () =>
                  dispatch(stepNavigate({ path: EXPERIENCE_URLS.treatments }))
          }
        />
      }
      after={
        !isMechanics && !isSettings ? (
          <Tooltip title="Add Treatment" arrow>
            <IconButton
              aria-label="add treatment"
              onClick={() =>
                history.push(
                  generatePath(EXPERIENCE_URLS.add_treatment, {
                    engine: currentEngineSlug,
                  })
                )
              }
            >
              <img src={AddIcon} alt="add" />
            </IconButton>
          </Tooltip>
        ) : undefined
      }
    >
      {collapsed
        ? null
        : showTreatmentSlugs.map((slug) => (
            <Grid
              item
              key={slug}
              className={clsx(slug.length < 10 ? classes.secondarySmall : null)}
            >
              <SecondaryTreeCard
                description={availableTreatments[slug].name}
                active={
                  selectedExitResponse
                    ? selectedExitResponse.offer_ids.includes(
                        `${availableTreatments[slug].id}`
                      )
                    : availableTreatments[slug].active
                }
                selected={
                  slug === currentSlug &&
                  currentStep?.path === MECHANICS_URLS.treatment_mechanics
                }
                onClickSelect={
                  isMechanics
                    ? () =>
                        dispatch(
                          stepNavigate({
                            path: MECHANICS_URLS.treatment_mechanics,
                            slug,
                          })
                        )
                    : undefined
                }
                leftIcon={
                  <TreatmentActivityIcon
                    slug={slug}
                    treatment={availableTreatments[slug]}
                    isMechanics={isMechanics}
                    isSettings={isSettings}
                  />
                }
                rightIcon={
                  currentStep?.path === EXPERIENCE_URLS.treatments ? (
                    availableTreatments[slug].can_delete ? (
                      <Tooltip title="Delete Treatment" placement="right" arrow>
                        <img
                          src={TrashIcon}
                          alt="delete"
                          className={classes.hoverPointer}
                          onClick={() =>
                            dispatch(
                              deleteTreatment.Action({
                                slug,
                                engine: currentEngineSlug,
                              })
                            )
                          }
                        />
                      </Tooltip>
                    ) : (
                      <Tooltip
                        title="Archive Treatment"
                        placement="right"
                        arrow
                      >
                        <img
                          src={ArchiveIcon}
                          alt="delete"
                          className={classes.hoverPointer}
                          onClick={() =>
                            !isArchivingTreatment &&
                            dispatch(
                              archiveTreatment.Action({
                                slug,
                                engine: currentEngineSlug,
                                archived: true,
                              })
                            )
                          }
                        />
                      </Tooltip>
                    )
                  ) : (
                    <></>
                  )
                }
              />
            </Grid>
          ))}
    </TreeWrapper>
  );
}

export interface TreatmentActivityIconProps {
  slug: string;
  treatment: Intermediate;
  isMechanics: boolean;
  isSettings: boolean;
}

export const TreatmentActivityIcon: React.FC<TreatmentActivityIconProps> = ({
  treatment,
  isMechanics,
  isSettings,
  slug,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const UpdateTreatment = useSelector(updateTreatment.Selector);
  const isUpdatingTreatment = useSelector(updateTreatment.isFetchingSelector);
  const currentEngineSlug = useSelector(currentEngineSlugSelector);
  const selectedExitResponse = useSelector(
    selectedExitResponseSelector
  ) as ExitSurveyResponse;

  let showActive;
  if (selectedExitResponse) {
    showActive = selectedExitResponse.offer_ids.includes(`${treatment.id}`);
  } else {
    showActive = treatment.active;
  }
  const loading = isUpdatingTreatment && UpdateTreatment.input?.slug === slug;
  if (loading) return <Loader size={16} />;
  return (
    <Tooltip
      title={treatment.active ? "Activated" : "Deactivated"}
      placement="left"
      arrow
    >
      <img
        src={showActive ? PurpleCheck : GrayCheck}
        className={classes.hoverPointer}
        alt="activate_or_deactivate"
        onClick={
          isMechanics || isUpdatingTreatment || isSettings
            ? undefined
            : () =>
                dispatch(
                  updateTreatment.Action({
                    slug,
                    offboard_slug: currentEngineSlug,
                    active: !treatment.active,
                  })
                )
        }
      />
    </Tooltip>
  );
};

export default TreatmentTree;
