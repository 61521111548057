export const WIZARD_URL_PREFIX = "/wizard";
export const SETUP_URL_PREFIX = "/setup";
export const ONBOARDING_URL_PREFIX = "/onboarding";
export const USER_SETTINGS_URL_PREFIX = "/user-settings";
export const EXPERIENCE_ROOT = WIZARD_URL_PREFIX + "/:engine/elements";
export const MECHANICS_ROOT = WIZARD_URL_PREFIX + "/:engine/flow";
export const SETTINGS_ROOT = WIZARD_URL_PREFIX + "/:engine/settings";
export const ACTIVATE_ROOT = WIZARD_URL_PREFIX + "/:engine/activate-engine";

export interface URLMap {
  [key: string]: string;
}

export const ONBOARDING_URLS: URLMap = {
  root: `${ONBOARDING_URL_PREFIX}`,
  company_signup: `${ONBOARDING_URL_PREFIX}/company-signup`,
};

export const USER_SETTINGS_URLS: URLMap = {
  root: `${USER_SETTINGS_URL_PREFIX}`,
  account: `${USER_SETTINGS_URL_PREFIX}/account`,
  password: `${USER_SETTINGS_URL_PREFIX}/password`,
};

export const SETUP_URLS: URLMap = {
  root: `${SETUP_URL_PREFIX}`,
  platform_options: `${SETUP_URL_PREFIX}/platform-options`,
  /* NOT INTEGRATED YET
  name_your_engine: `${SETUP_ROOT}/name-your-engine`,
  start_or_discover: `${SETUP_ROOT}/start-or-discover`,
  select_from_templates: `${SETUP_ROOT}/select-from-templates`
  */
};

export const EXPERIENCE_URLS: URLMap = {
  root: `${EXPERIENCE_ROOT}`,
  engine_integration: `${EXPERIENCE_ROOT}/engine-integration`,
  exit_survey: `${EXPERIENCE_ROOT}/exit-survey`,
  pause_options: `${EXPERIENCE_ROOT}/pause-options`,
  experience_texts: `${EXPERIENCE_ROOT}/experience-texts`,
  treatments: `${EXPERIENCE_ROOT}/treatments`,
  add_treatment: `${EXPERIENCE_ROOT}/treatments/add`,
  duplicate_treatment: `${EXPERIENCE_ROOT}/treatments/add/:duplicate`,
  edit_treatment: `${EXPERIENCE_ROOT}/treatments/:slug`,
  cancelled: `${EXPERIENCE_ROOT}/cancelled`,
  exit: `${EXPERIENCE_ROOT}/final-exit`,
  style: `${EXPERIENCE_ROOT}/style`,
  css_editor: `${EXPERIENCE_ROOT}/css-editor`,
};

export const MECHANICS_URLS: URLMap = {
  root: `${MECHANICS_ROOT}`,
  exit_survey_response: `${MECHANICS_ROOT}/exit-survey/:slug`,
  pause_mechanics: `${MECHANICS_ROOT}/pause`,
  treatment_mechanics: `${MECHANICS_ROOT}/treatments/:slug`,
};

export const SETTINGS_URLS: URLMap = {
  root: `${SETTINGS_ROOT}`,
  your_engine: `${SETTINGS_ROOT}/your-engine`,
  notification_settings: `${SETTINGS_ROOT}/notification-settings`,
  gaming_prevention: `${SETTINGS_ROOT}/gaming-prevention`,
  announcement_banner: `${SETTINGS_ROOT}/announcement-banner`,
  advanced: `${SETTINGS_ROOT}/advanced`,
  user_access_management: `${SETTINGS_ROOT}/user-access-management`,
};

export const ACTIVATE_URLS: URLMap = {
  root: `${ACTIVATE_ROOT}`,
  activate_engine: `${ACTIVATE_ROOT}`,
};

export const WIZARD_URLS: { [key: string]: string } = {
  root: `${WIZARD_URL_PREFIX}`,
  experience: `${EXPERIENCE_ROOT}`,
  mechanics: `${MECHANICS_ROOT}`,
  settings: `${SETTINGS_ROOT}`,
  preview: `${WIZARD_URL_PREFIX}/:engine/preview`,
  ...EXPERIENCE_URLS,
  ...MECHANICS_URLS,
  ...SETTINGS_URLS,
  ...ACTIVATE_URLS,
};

export const TEST_URL = `/offboard/experience/:engine/?is_preview=on`;

export const ALL_URLS: URLMap = {
  ...ONBOARDING_URLS,
  ...SETUP_URLS,
  ...WIZARD_URLS,
  root: "/",
};
