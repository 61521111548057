import Grid from "@material-ui/core/Grid";
import {ArrowDropDown, ArrowDropUp} from "@material-ui/icons";
import {makeStyles} from "@material-ui/core/styles";
import commonStyles from "../../layout/commonStyles";
import styles from "./graphStyles";


const useStyles = makeStyles((theme) => ({
    ...commonStyles(theme),
    ...styles(theme),
}));

export interface GraphCardMetricsProps {
    mainMetric: number;
    increase?: {diff: number; pct: number;}
    fmt?: (v: number) => string;
}

export const GraphCardMetrics = (props: GraphCardMetricsProps) => {
    const classes = useStyles();

    let fmt = (props.fmt !== undefined) ? props.fmt : (v: number) => v.toString();
    let decimalFormatter = (n: number) => (n < 100) ? Math.round(n * 10) / 10 : Math.round(n);
    let pctFmt = (n: number) => `${decimalFormatter(n)}%`

    return (
        <>
            <Grid container direction="column" item>
                <Grid item>
                    <span className={classes.graphMetricMain}>{fmt(props.mainMetric)}</span>
                </Grid>
                {props.increase && (
                    <Grid container direction="row" item style={{maxWidth: "fit-content", paddingTop: "5px"}}>
                        <span>
                            {
                                (props.increase.diff > 0) ?
                                    <span className={classes.graphMetricPositive}>+</span>
                                    : (props.increase.diff < 0) ?
                                        <span className={classes.graphMetricNegative}>-</span>
                                        : <span> </span>
                            }
                            {fmt(Math.abs(props.increase.diff))}
                        </span>
                        <span style={{paddingLeft: "20px"}}/>
                        <span>
                            {
                                (props.increase.pct > 0)?
                                <span className={classes.graphMetricPositive}>
                                    <ArrowDropUp viewBox="8 8 8 10" style={{width: "10px", height: "10px", paddingRight: "2px"}}/>
                                </span>
                                : (props.increase.pct < 0)?
                                <span className={classes.graphMetricNegative}>
                                    <ArrowDropDown viewBox="8 9 8 10" style={{width: "10px", height: "10px",
                                        paddingRight: "2px"}}/>
                                </span>
                                : <span/>
                            }
                            {pctFmt((props.increase.pct >= 0)? props.increase.pct : -1 * props.increase.pct)}
                        </span>
                    </Grid>
                )}
            </Grid>
        </>
    )
}