import React from "react";
import ErrorScreen from "../../views/ErrorScreen";
import * as Sentry from "@sentry/react";

interface ErrorBoundaryProps {}
interface ErrorBoundaryState {
  hasError: boolean;
}

export default class ErrorBoundary extends React.Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
    this.toggleHasError = this.toggleHasError.bind(this);
  }

  componentDidCatch(error: any) {
    this.setState({ hasError: true });
    Sentry.captureException(error);
  }

  toggleHasError(newErrorState: boolean) {
    this.setState({ hasError: newErrorState });
  }

  render() {
    if (this.state.hasError) {
      return <ErrorScreen />;
    }
    return this.props.children;
  }
}
