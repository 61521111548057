import React from 'react';
import styled from '@emotion/styled';
import { Center } from '../AlignItems';

interface AnnouncementBannerProps {
  announcementText: string;
}

const AnnouncementBannerWrapper = styled.div`
  width: 100%;
  padding-top: 0.4rem;
  padding-bottom: 0.4rem;

  background: ${(props: any) => props.theme.secondary_color};
  color: ${(props: any) => props.theme.secondary_font_color};
  font-family: ${(props: any) => props.theme.font};
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

  @media (max-width: 768px) {
    font-size: 14px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }
`;

const AnnouncementBanner: React.FunctionComponent<AnnouncementBannerProps> = props => {
  return (
    <AnnouncementBannerWrapper className="announcement-banner">
      <Center horizontal vertical>
        {props.announcementText}
      </Center>
    </AnnouncementBannerWrapper>
  );
};

export default AnnouncementBanner;
