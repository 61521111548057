import {AxisTickProps} from "@nivo/axes"
import {ResponsiveLine, Serie, SliceTooltip} from "@nivo/line";
import {ChartProps} from "./chartProps";
import {useOrdinalColorScale} from "@nivo/colors";

export interface LineChartProps extends ChartProps {
    type: "line";
    data: Serie[];
}

const CustomXTick = (tick: AxisTickProps<string>) => {
    return (
        <g transform={`translate(${tick.x},${tick.y - 5})`}>
            <line opacity="1" x1="-30" x2="0" y1="5" y2="5" stroke="#dddddd" strokeWidth="1"/>
            <text
                textAnchor="left"
                dominantBaseline="middle"
                x={-30}
                style={{
                    fill: '#333',
                    fontSize: 10,
                }}
            >
                {(tick.format)? tick.format(tick.value) : tick.value}
            </text>
        </g>
    )
}


const CustomTooltip: SliceTooltip = ({slice}) => {
    let xVal = Math.min(...slice.points.map((point) => {
      return (typeof(point.data.x) === "number")? point.data.x
          : (point.data.x as Date).getTime();
    }));
    let xFormatted = Object.assign({}, ...slice.points.map((point) => {
      return {[(typeof(point.data.x) === "number")? point.data.x
          : (point.data.x as Date).getTime()]: point.data.xFormatted};
    }))[xVal]
    return (
      <div
        style={{
          background: "white",
          padding: "9px 12px",
          border: "1px solid #ccc",
        }}
      >

        <div>{xFormatted}</div>
        {slice.points.filter(
          (point) => {
            var samePoints = slice.points.filter((_point) => (_point.serieId === point.serieId));
            var smallestPoint = samePoints[samePoints.length - 1];
            return (point.id === smallestPoint.id);
          }
        ).sort(
          (point1, point2) => {
            return point1.y - point2.y;
          }
        ).map(
          (point) => (
            <div
              key={point.id}
              style={{
                color: point.serieColor,
                padding: "3px 0",
              }}
            >
              <strong>{point.serieId}</strong> {point.data.yFormatted}
            </div>
          )
        ).slice(0, 5)}
      </div>
    );
}


export const LineChart = (props: LineChartProps) => {
    let lineColorConfig = props.colors || {scheme: "category10"};
    let lineColor = useOrdinalColorScale(lineColorConfig, "id");

    let yFmt = (props.yFmt !== undefined) ? props.yFmt : (v: number) => v.toString();

    return (
        <ResponsiveLine
            data={props.data}
            colors={lineColor}
            xFormat={(value) => {
                if (value instanceof Date)
                    return value.toISOString().split('T')[0];
                return value.toString();
            }}
            yFormat={(value) => {
                if ((typeof (value) === "number"))
                    return yFmt(value);
                return value.toString();
            }}
            xScale={{
                type: 'time',
                format: '%Y-%m-%d',
                useUTC: false,
                precision: 'day',
            }}
            yScale={{
                type: 'linear',
                stacked: false,
            }}
            axisLeft={{
                format: yFmt,
                renderTick: CustomXTick,
            }}
            axisBottom={{
                format: '%b %d',
                tickValues: 'every 60 days',
            }}
            pointSize={0}
            pointBorderColor={{
                from: 'color',
                modifiers: [['darker', 0.3]],
            }}
            margin={{top: 10, right: 20, bottom: 22, left: 30}}
            enableGridX={false}
            enableSlices={"x"}
            sliceTooltip={CustomTooltip}
        />

    );
}