import { useEffect } from "react";
import {
  matchPath,
  Route,
  Router,
  Switch,
  useLocation,
} from "react-router-dom";
import { history } from "app/history";
import { useDispatch, useSelector } from "react-redux";

import {
  availableEnginesSelector,
  wizardEngineSelector,
  currentEngineSlugSelector,
  currentSlugSelector,
  currentStepPathSelector,
  exitSurveyResponsesEnabledSlugsSelector,
  getEngines,
  treatmentsEnabledSlugsSelector,
  pausesEnabledSlugsSelector,
  setWizardEngine,
  setWizardSlug,
  setWizardStepPath,
  stepNavigate,
  getEngine,
  staticValuesSelector,
  getStaticOffboardValues,
} from "./slice/wizardSlice";

// components
import WizardLayout from "features/wizard/WizardLayout";
import { MechanicsContainer } from "./mechanics";
import { ExperienceContainer } from "./experience";
import { WizardSettingsContainer } from "./settings";
import { ActivateContainer } from "./activate";
import {
  EXPERIENCE_URLS,
  MECHANICS_URLS,
  SETTINGS_URLS,
  SETUP_URLS,
  WIZARD_URLS,
} from "./slice/urls";
import Loader from "../../components/loading/Loader";
import WizardPreviewContainer from "./preview/WizardPreviewContainer";
import { userSelector } from "../user/userSlice";

interface WizardParams {
  engine?: string;
  slug?: string;
}

export const WizardContainer = () => {
  let location = useLocation();
  const staticValues = useSelector(staticValuesSelector);
  const exitResponses = useSelector(exitSurveyResponsesEnabledSlugsSelector);
  const pauses = useSelector(pausesEnabledSlugsSelector);
  const treatments = useSelector(treatmentsEnabledSlugsSelector);
  const currentStepPath = useSelector(currentStepPathSelector);
  const reduxSlug = useSelector(currentSlugSelector);
  const reduxEngineSlug = useSelector(currentEngineSlugSelector);
  const reduxEngine = useSelector(wizardEngineSelector);
  const availableEngines = useSelector(availableEnginesSelector);
  const ready = useSelector(getEngines.isReadySelector);
  const enginesRequested = useSelector(getEngines.isRequestedSelector);
  const { auth } = useSelector(userSelector);
  const isStaff = auth?.user?.is_staff;
  const getEngineStarted = useSelector(getEngine.isRequestedSelector);

  const dispatch = useDispatch();

  const matchSteps = Object.keys(WIZARD_URLS)
    .map((stepName: string) => {
      const stepPath = WIZARD_URLS[stepName];
      return matchPath(location.pathname, { path: stepPath });
    })
    .filter((result) => result !== null);

  const step = matchSteps.length ? matchSteps[matchSteps.length - 1] : null;

  useEffect(() => {
    /* On every page load, check the URL to see if redux state is different */
    if (step) {
      const params = step?.params as WizardParams;
      if (step.path && step.path !== currentStepPath) {
        console.debug(
          "Read Step from url path...",
          step?.path,
          currentStepPath
        );
        dispatch(setWizardStepPath(step?.path));
      }
      if (params.engine && params.engine !== reduxEngineSlug) {
        if (params.engine === ":engine") {
          if (step && Object.keys(availableEngines)) {
            dispatch(
              stepNavigate({
                path: step.path,
                engine: Object.keys(availableEngines)[0],
              })
            );
          }
        } else {
          console.debug("Read Engine from url path...", params.engine);
          dispatch(setWizardEngine(params.engine));
        }
      }
      if (params.slug && params.slug !== reduxSlug) {
        console.debug("Read Slug from url path...", params.slug, reduxSlug);
        dispatch(setWizardSlug(params.slug));
      }
    }
    // eslint-disable-next-line
  }, [step, availableEngines.length]);

  useEffect(() => {
    if (!enginesRequested) dispatch(getEngines.Action(null));
  }, [dispatch, enginesRequested]);

  useEffect(() => {
    // No engines setup, must redirect to create engine
    if (ready && step) {
      const engineSlugs = Object.keys(availableEngines);
      const params = step?.params as WizardParams;
      const viewClientAsStaff =
        isStaff && params.engine && !engineSlugs.includes(params.engine);

      if (engineSlugs.length === 0) {
        dispatch(stepNavigate({ path: SETUP_URLS.platform_options }));
      } else if (viewClientAsStaff) {
        if (!getEngineStarted && params.engine) {
          dispatch(getEngine.Action(params.engine));
        }
      } else if (!reduxEngine) {
        dispatch(
          stepNavigate({
            path: step.path,
            engine: engineSlugs[0],
          })
        );
      } else {
        if (step.path === WIZARD_URLS.experience)
          dispatch(stepNavigate({ path: EXPERIENCE_URLS.exit_survey }));

        if (step.path === WIZARD_URLS.settings)
          dispatch(
            stepNavigate({
              path: SETTINGS_URLS.your_engine,
            })
          );

        if (step.path === WIZARD_URLS.mechanics)
          dispatch(
            stepNavigate({
              path: MECHANICS_URLS.exit_survey_response,
              slug: exitResponses[0],
            })
          );

        if (location.pathname.endsWith(":slug")) {
          if (
            step.path === WIZARD_URLS.mechanics ||
            step.path === MECHANICS_URLS.exit_survey_response
          )
            dispatch(
              stepNavigate({
                path: MECHANICS_URLS.exit_survey_response,
                slug: exitResponses[0],
              })
            );

          if (step.path === MECHANICS_URLS.pause_mechanics)
            dispatch(
              stepNavigate({
                path: MECHANICS_URLS.pause_mechanics,
                slug: pauses[0],
              })
            );

          if (step.path === MECHANICS_URLS.treatment_mechanics)
            dispatch(
              stepNavigate({
                path: MECHANICS_URLS.treatment_mechanics,
                slug: treatments[0] || "add",
              })
            );
        }
      }
    }
    // eslint-disable-next-line
  }, [availableEngines, step?.path, ready]);

  useEffect(() => {
    if (ready && step) {
      const engineSlugs = Object.keys(availableEngines);
      console.debug(
        "Wizard loaded -",
        engineSlugs.length,
        "engines, current =",
        reduxEngineSlug,
        "step =",
        step.path
      );
    }
    // eslint-disable-next-line
  }, [availableEngines, ready]);

  useEffect(() => {
    if (!staticValues) dispatch(getStaticOffboardValues.Action(null));
    // eslint-disable-next-line
  }, []);

  if (!reduxEngineSlug) {
    return <Loader reason="Setting up your engines..." padding />;
  }

  if (!reduxEngine) {
    return <Loader reason="Loading your engines..." padding />;
  }

  return (
    <WizardLayout>
      <Router history={history}>
        <Switch>
          {` Any URL with a slug must be listed here, 
          for the slug to be accessible via useParams`}
          <Route
            exact
            component={ExperienceContainer}
            path={EXPERIENCE_URLS.edit_treatment}
          />
          <Route
            exact
            component={MechanicsContainer}
            path={MECHANICS_URLS.treatment_mechanics}
          />
          <Route
            exact
            component={MechanicsContainer}
            path={MECHANICS_URLS.exit_survey_response}
          />
          <Route
            component={WizardPreviewContainer}
            path={WIZARD_URLS.preview}
          />

          {`THESE ROUTES MUST COME LAST - it is not exact
          and matches the full experience`}
          <Route
            component={ExperienceContainer}
            path={WIZARD_URLS.experience}
          />
          <Route component={MechanicsContainer} path={WIZARD_URLS.mechanics} />
          <Route
            component={WizardSettingsContainer}
            path={WIZARD_URLS.settings}
          />
          <Route
            component={ActivateContainer}
            path={WIZARD_URLS.activate_engine}
          />
        </Switch>
      </Router>
    </WizardLayout>
  );
};

export default WizardContainer;
