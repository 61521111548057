import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import ReactFlow, {
  FlowElement,
  Position,
  ReactFlowProvider,
} from "react-flow-renderer";

// components
import ExitSurveyTree from "./ExitSurveyTree";
import TreatmentTree from "./TreatmentTree";
import PauseTree from "./PauseTree";
import { EXPERIENCE_URLS } from "./slice/urls";
import { clearFormState } from "../user/userSlice";

import {
  wizardEngineSelector,
  currentStepSelector,
  displayDataSelector,
  selectedExitResponseSelector,
  createExitSurveyResponse,
  deleteExitSurveyResponse,
  createIntermediate,
  deleteTreatment,
} from "./slice/wizardSlice";

// styles
import { makeStyles } from "@material-ui/core/styles";
import commonStyles from "components/layout/commonStyles";
import clsx from "clsx";
import wizardStyles from "./wizardStyles";
import "./builderStyle.css";

const useStyles = makeStyles((theme) => ({
  ...commonStyles(theme),
  ...wizardStyles(theme),
}));

export default function BuilderView() {
  const { draggedItem } = useSelector(displayDataSelector);
  const classes = useStyles();

  return (
    <div className={clsx(classes.fullHeight, classes.relative)}>
      <BuilderFlowContainer />
      {draggedItem ? (
        <div className={clsx(classes.fullHeight, classes.dropzoneOverlay)} />
      ) : null}
    </div>
  );
}

const flowNodeStyle = {
  background: "#FFF0",
  border: "none",
  minWidth: "30em",
  minHeight: "10em",
  boxShadow: "none",
};

export function BuilderFlowContainer() {
  const dispatch = useDispatch();
  const classes = useStyles();
  const reactFlowWrapper = React.useRef<HTMLDivElement>(null);
  const [reactFlowInstance, setReactFlowInstance] = useState(null);
  const responseSelected = useSelector(selectedExitResponseSelector);
  const displayData = useSelector(displayDataSelector);
  const currentStep = useSelector(currentStepSelector);
  const engine = useSelector(wizardEngineSelector);

  const createExitSurveyResponseStatus = useSelector(
    createExitSurveyResponse.isFetchingSelector
  );

  const deleteExitSurveyResponseStatus = useSelector(
    deleteExitSurveyResponse.isFetchingSelector
  );

  const createTreatmentStatus = useSelector(
    createIntermediate.isFetchingSelector
  );

  const deleteTreatmentStatus = useSelector(deleteTreatment.isFetchingSelector);


  const ExitSurveyNode = {
    id: "exit_survey",
    type: "input",
    data: { label: <ExitSurveyTree /> },
    position: { x: 0, y: 0 },
    style: flowNodeStyle,
  };

  const PauseNode = {
    id: "pause",
    type: "output",
    data: {
      label: <PauseTree />,
    },
    position: {
      x: 200,
      y: 550,
    },
    style: flowNodeStyle,
  };

  const PauseEdge = {
    id: "pause_edge",
    source: "exit_survey",
    target: "pause",
    animated: true,
  };

  const TreatmentNode = {
    id: "treatment",
    sourcePosition: "top" as Position,
    data: {
      label: <TreatmentTree />,
    },
    position: { x: -200, y: 550 },
    style: flowNodeStyle,
  };

  const TreatmentEdge = {
    id: "treatment_edge",
    source: "exit_survey",
    target: "treatment",
    animated: true,
  };

  const [elements, setElements] = useState([
    ExitSurveyNode,
    PauseNode,
    PauseEdge,
    TreatmentNode,
    TreatmentEdge,
  ] as FlowElement[]);

  // On component load
  useEffect(() => {
    return () => {
      dispatch(clearFormState());
    };
  }, [dispatch]);

  const onLoad = (_reactFlowInstance: any) => {
    _reactFlowInstance.fitView();
    setReactFlowInstance(_reactFlowInstance);
    window.addEventListener("resize", () => {
      _reactFlowInstance.fitView();
    });
  };

  const reactFlowBounds =
    reactFlowWrapper.current !== null
      ? reactFlowWrapper.current.getBoundingClientRect()
      : {
          top: 0,
          left: 0,
        };

  React.useEffect(() => {
    if (reactFlowInstance !== null) {
      // @ts-ignore
      setTimeout(() => {
        if (reactFlowInstance === null) return;
        // @ts-ignore
        reactFlowInstance.fitView({ padding: 0.15 });
      }, 10);
    }
  }, [
    displayData,
    elements,
    currentStep,
    engine,
    reactFlowInstance,
    createExitSurveyResponseStatus,
    deleteExitSurveyResponseStatus,
    createTreatmentStatus,
    deleteTreatmentStatus,
    reactFlowBounds.left,
    reactFlowBounds.top,
  ]);

  React.useEffect(() => {
    const STEP_ONE = [ExitSurveyNode];
    const STEP_TWO_PAUSE = [PauseNode];
    const STEP_TWO_OFFER = [TreatmentNode];
    const ALL_EDGES = [TreatmentEdge, PauseEdge];
    let showElems: FlowElement[] = [];
    if (currentStep?.path === EXPERIENCE_URLS.exit_survey) showElems = STEP_ONE;
    else if (currentStep?.path === EXPERIENCE_URLS.pause_options)
      showElems = STEP_TWO_PAUSE;
    else if (currentStep?.path === EXPERIENCE_URLS.treatments)
      showElems = STEP_TWO_OFFER;
    else {
      showElems = showElems.concat(STEP_ONE);
      showElems = showElems.concat(STEP_TWO_PAUSE);
      showElems = showElems.concat(STEP_TWO_OFFER);
      showElems = showElems.concat(ALL_EDGES);
    }

    setElements(showElems);
  }, [reactFlowInstance, responseSelected, currentStep]); // eslint-disable-line react-hooks/exhaustive-deps

  const flowOptions = {
    nodesConnectable: false,
    nodesDraggable: false,
    zoomOnScroll: false,
    panOnScroll: false,
    zoomOnDoubleClick: false,
    paneMoveable: false,
    onLoad,
  };

  return (
    <ReactFlowProvider>
      <div className={classes.builderView} ref={reactFlowWrapper}>
        <ReactFlow elements={elements} {...flowOptions} />
      </div>
    </ReactFlowProvider>
  );
}
