import {Theme} from "@material-ui/core";

export default function graphStyles(theme: Theme) {
    return {
        graphCard: {
            border: "2px solid #c2c2c266",
            boxShadow: "none",
            margin: "1.5em 0 0em",
            borderRadius: 10,
            overflow: "visible",
        },
        graphContainer: {
            height: 330,
        },
        graphItem: {
            color: "#787878",
            textAlign: "left" as "left",
            paddingLeft: "20px",
            paddingRight: "20px",
            paddingTop: "20px",
        },
        graphMetricMain: {
            fontSize: "4.25rem",
            fontWeight: 100,
            lineHeight: "4rem",
            height: "4rem",
            paddingRight: "20px",
            minWidth: "50px",
        },
        graphMetricSubContainer: {
            maxWidth: "fit-content",
            textAlign: "right" as "right",
        },
        graphMetricPositive: {
            color: "#5DD47F",
        },
        graphMetricNegative: {
            color: "#C75A5A",
        },
    }
}