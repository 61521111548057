import { useDispatch, useSelector } from "react-redux";

// components
import { ExitSurveyResponse } from "../slice/types";
import PanelHeader from "components/wizard/PanelHeader";

import {
  availableTreatmentsSelector,
  selectedExitResponseSelector,
  treatmentsEnabledSlugsSelector,
  updateExitSurveyResponse,
  wizardEngineSelector,
} from "../slice/wizardSlice";

import { OffboardEngine } from "../slice/types";

// Material UI
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import FormGroup from "@material-ui/core/FormGroup";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import Checkbox from "@material-ui/core/Checkbox";

// styles
import { makeStyles } from "@material-ui/core/styles";
import commonStyles from "../../../components/layout/commonStyles";
import wizardStyles from "../wizardStyles";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  ...commonStyles(theme),
  ...wizardStyles(theme),
}));

export default function ResponseLogicPanel() {
  const classes = useStyles();
  const dispatch = useDispatch();

  const availableTreatments = useSelector(availableTreatmentsSelector);
  const enabledTreatmentsSlugs = useSelector(treatmentsEnabledSlugsSelector);
  const selectedResponse = useSelector(
    selectedExitResponseSelector
  ) as ExitSurveyResponse;
  const currentEngine = useSelector(wizardEngineSelector) as OffboardEngine;

  const exitSurveyUpdate = (updateData: object) => {
    dispatch(
      updateExitSurveyResponse.Action({
        slug: selectedResponse.slug,
        offboard_slug: currentEngine.slug,
        ...updateData,
      })
    );
  };

  const handleToggleTreatment = (treatmentSlug: string) => {
    const treatment = availableTreatments[treatmentSlug];
    const oID = `${treatment.id}`;
    const wasEnabled = selectedResponse.offer_ids.includes(oID);
    let offer_ids;
    if (wasEnabled) {
      offer_ids = selectedResponse.offer_ids.filter((id) => id !== oID);
    } else {
      offer_ids = selectedResponse.offer_ids.concat(oID);
    }

    exitSurveyUpdate({ offer_ids });
  };

  const handleTogglePauses = () => {
    const data = { show_pauses: !selectedResponse.show_pauses };
    exitSurveyUpdate(data);
  };

  const handleToggleAdditionalComments = () => {
    const data = {
      allow_additional_comment_on_reason_select: !selectedResponse.allow_additional_comment_on_reason_select,
    };
    exitSurveyUpdate(data);
  };

  if (!selectedResponse?.slug) {
    return (
      <div className={classes.mdPadding}>
        <Typography variant="h3">Loading...</Typography>
      </div>
    );
  }

  return (
    <div className={classes.mdPadding}>
      <PanelHeader title="Conditional Logic" caption="Exit Survey Response" />
      <Divider className={classes.headingDivider} />
      <Typography variant="body2" className={classes.gray}>
        Select the options you would like to show for the Exit Survey response
        <span className={clsx(classes.purple, classes.bold)}>
          &nbsp;{selectedResponse.reason_text}
        </span>
      </Typography>

      <Typography variant="h6" className={classes.panelSubheader}>
        Treatment Outcomes For This Response
      </Typography>
      <FormControl component="fieldset">
        <FormGroup>
          {enabledTreatmentsSlugs.map((treatmentSlug: string) => (
            <FormControlLabel
              control={
                selectedResponse.active ? (
                  <Checkbox
                    checked={
                      selectedResponse.offer_ids.indexOf(
                        `${availableTreatments[treatmentSlug].id}`
                      ) !== -1
                    }
                    onChange={() => handleToggleTreatment(treatmentSlug)}
                    name={treatmentSlug}
                  />
                ) : (
                  <Checkbox
                    checked={
                      selectedResponse.offer_ids.indexOf(
                        `${availableTreatments[treatmentSlug].id}`
                      ) !== -1
                    }
                    onChange={() => handleToggleTreatment(treatmentSlug)}
                    name={treatmentSlug}
                    disabled
                  />
                )
              }
              key={treatmentSlug}
              label={availableTreatments[treatmentSlug].name}
            />
          ))}
        </FormGroup>
      </FormControl>
      <hr />
      <FormControlLabel
        control={
          <Switch
            checked={selectedResponse.show_pauses}
            onChange={() => handleTogglePauses()}
            name="pause_outcomes"
            color="primary"
          />
        }
        label="Pause Outcomes"
        labelPlacement="start"
        className={classes.zeroMargin}
      />

      {currentEngine.allow_additional_comment ? (
        <FormControlLabel
          control={
            <Switch
              checked={
                selectedResponse.allow_additional_comment_on_reason_select
              }
              onChange={() => handleToggleAdditionalComments()}
              name="allow_additional_comment_on_reason_select"
              color="primary"
            />
          }
          label="Show Additional Comments for Response"
          labelPlacement="start"
          className={classes.zeroMargin}
        />
      ) : null}
    </div>
  );
}
