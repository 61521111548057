import { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

// Material UI
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import Typography from "@material-ui/core/Typography";

//components
import DashboardNavBar from "components/dashboard/DashboardNavBar";
import Loader from "components/loading/Loader";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import IconButton from "@material-ui/core/IconButton";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import List from "@material-ui/core/List";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import ChevronRight from "@material-ui/icons/ChevronRight";
import {
  FormatedGraphData,
  SurvivalAnalysisGraph,
} from "../../components/dashboard/SurvivalAnalysisGraph";

import {
  getSurvivalAnalysisData,
  survivalAnalysisSelector,
} from "../wizard/slice/wizardSlice";

// styles
import clsx from "clsx";
import styles from "./dashboardStyles";
import commonStyles from "components/layout/commonStyles";
import { makeStyles } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/core";
import { darkTheme, lightTheme } from "MUITheme";
import { SurvivalAnalysis as SurvivalAnalysisType } from "../wizard/slice/types";
import {
  MonthData,
  SurvivalAnalysisLegend,
} from "../../components/dashboard/SurvivalAnalysisLegend";

const useStyles = makeStyles((theme) => ({
  ...commonStyles(theme),
  ...styles(theme),
}));

export default function SurvivalAnalysis() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const params = useParams<{ engine: string }>();
  const [currentRiskType, setCurrentRiskType] = useState("survival");
  const [currentCategory, setCurrentCategory] = useState("baseline");
  const [currentAudiences, setCurrentAudiences] = useState([
    "baseline",
  ] as string[]);
  const [currentMonthData, setCurrentMonthData] = useState({
    includeAllTimeAverage: true,
  } as MonthData);
  const [displayForecastsHelp, setDisplayForecastsHelp] = useState(false);

  const survivalAnalysisData: SurvivalAnalysisType = useSelector(
    survivalAnalysisSelector
  );
  const [currentGraphData, setCurrentGraphData] = useState(
    [] as FormatedGraphData[]
  );

  const getFormattedGraphData = useCallback(
    (graphCohort: string) => {
      // graphIds will hold the idx of each cohort in graphData
      let graphData: FormatedGraphData[] = [];
      let graphIds: { [index: string]: number } = {};

      // Gets all groups matching graph type (survival vs ltv) and graph cohort
      let graphGroups = survivalAnalysisData.graph_types[
        currentRiskType
      ].groups.filter((value) => value.group_id.graph_cohort === graphCohort);

      // Handles filtering out months
      let minMonthDate = currentMonthData.monthRange?.minMonth;
      let maxMonthDate = currentMonthData.monthRange?.maxMonth;
      let monthRangeIsUnset = currentMonthData.monthRange === undefined;
      let isValidMonthStr = (monthStr: string) => {
        if (monthStr === "default") {
          if (currentMonthData.includeAllTimeAverage) return true;
          return monthRangeIsUnset;
        }
        let monthDate = new Date(monthStr);
        let monthDateIsInvalid = isNaN(monthDate.getDate());

        if (monthDateIsInvalid || monthRangeIsUnset) return false;
        return (
          minMonthDate &&
          maxMonthDate &&
          monthDate >= minMonthDate &&
          monthDate <= maxMonthDate
        );
      };

      graphGroups.forEach((graphGroup) => {
        // Sets up for looping through each data point
        let rawGraphData = graphGroup.graph_data.data;
        let monthStr = graphGroup.group_id.month;
        if (!isValidMonthStr(monthStr)) return;
        let xVals = rawGraphData.x;
        let yVals = rawGraphData.y;
        let colVals = rawGraphData.col_val ? rawGraphData.col_val : [];
        // Changes the raw data format into the graph format
        xVals.forEach((xVal: number, idx: number) => {
          let yVal = yVals[idx];
          let cohortName = "baseline";
          // Changes cohortName to the cohort name if colVals exists
          if (idx < colVals.length)
            cohortName =
              typeof colVals[idx] === "number"
                ? colVals[idx].toString()
                : '"' + colVals[idx].toString() + '"';
          let graphId =
            monthStr === "default" ? cohortName : `${cohortName} [${monthStr}]`;
          // Creates an entry in graphData on first pass for each cohort
          if (!(graphId in graphIds)) {
            graphIds[graphId] = Object.keys(graphIds).length;
            graphData[graphIds[graphId]] = {
              id: graphId,
              cohort: cohortName,
              month: monthStr,
              data: [],
            };
          }
          // Appends an (x, y) pair to the data
          graphData[graphIds[graphId]].data = [
            ...graphData[graphIds[graphId]].data,
            {
              x: xVal,
              y: yVal,
            },
          ];
        });
      });

      return graphData;
    },
    [currentMonthData, currentRiskType, survivalAnalysisData.graph_types]
  );

  useEffect(() => {
    // Formats the backend graph data
    if (
      Object.keys(survivalAnalysisData).length === 0 ||
      Object.keys(survivalAnalysisData.graph_types).length === 0
    )
      return;

    let graphData: FormatedGraphData[] = getFormattedGraphData("baseline");

    if (currentCategory !== "baseline")
      graphData = [...graphData, ...getFormattedGraphData(currentCategory)];

    setCurrentGraphData(graphData);
  }, [
    currentRiskType,
    currentCategory,
    currentAudiences,
    currentMonthData,
    survivalAnalysisData,
    getFormattedGraphData,
  ]);

  useEffect(() => {
    if (
      !survivalAnalysisData.offboard_slug ||
      survivalAnalysisData.offboard_slug !== params.engine
    ) {
      dispatch(getSurvivalAnalysisData.Action(params.engine));
    }
  }, [dispatch, params.engine, survivalAnalysisData.offboard_slug]);

  const isLoading = useSelector(getSurvivalAnalysisData.isFetchingSelector);
  const forecastHelperTexts: string[] = [
    "These graphs are forecasts of the risk of churn across segments of your customers",
    " You can use these graphs to identify weaker and stronger segments",
    "When the probability is lower than 50%, it means the segment is most likely to have an active subscription",
    " When the probability raises above 50%, it means the segment is most likely to churn",
    " Set treatments inside of your RetentionEngine that address these segments in order to incrementally reduce churn",
  ];

  if (isLoading || !survivalAnalysisData.offboard_slug)
    return (
      <div style={{ margin: "0 3em" }}>
        <ThemeProvider theme={darkTheme}>
          <DashboardNavBar />
        </ThemeProvider>
        <ThemeProvider theme={lightTheme}>
          <Loader reason="Fetching Risk Forecasts" />
        </ThemeProvider>
      </div>
    );

  const currentGraphHasData =
    Object.keys(survivalAnalysisData.graph_types[currentRiskType]?.groups || [])
      .length > 0;
  return (
    <div style={{ margin: "0 3em" }}>
      <ThemeProvider theme={darkTheme}>
        <DashboardNavBar />
      </ThemeProvider>
      <ThemeProvider theme={lightTheme}>
        <Grid
          container
          alignItems="center"
          justifyContent="space-between"
          style={{ paddingTop: "2em" }}
        >
          <Grid item>
            <Typography variant="h5" className={clsx(classes.gray)}>
              Risk Forecasts{" "}
              <IconButton onClick={() => setDisplayForecastsHelp(true)}>
                <HelpOutlineIcon />
              </IconButton>
            </Typography>
            <Typography
              variant="body1"
              className={clsx(
                classes.gray,
                classes.zeroMargin,
                classes.alignLeft
              )}
            >
              {currentGraphHasData
                ? survivalAnalysisData.graph_types[currentRiskType].groups.find(
                    (value) => value.group_id.graph_cohort === currentCategory
                  )?.graph_data.display_name
                : ""}
            </Typography>
          </Grid>
        </Grid>

        <div>
          <Grid container spacing={1} alignItems="stretch">
            {/* Risk Chart */}
            <Grid item xs={currentGraphHasData ? 9 : 12}>
              <Card className={classes.barChartCard}>
                <div style={{ height: 450 }}>
                  {currentGraphHasData ? (
                    <SurvivalAnalysisGraph
                      data={currentGraphData}
                      selectedAudiences={currentAudiences}
                      x_axis_type={currentRiskType}
                    />
                  ) : (
                    <>
                      <Typography variant="h5" className={classes.mdPadding}>
                        No Risk Forecast Data Available
                      </Typography>
                    </>
                  )}
                </div>
              </Card>
            </Grid>
            {/* Risk Chart Options */}
            {currentGraphHasData && (
              <Grid item xs={3} alignItems="center">
                <Card
                  className={classes.barChartCard}
                  style={{ minHeight: 450 }}
                >
                  <SurvivalAnalysisLegend
                    data={survivalAnalysisData}
                    onRiskTypeChange={setCurrentRiskType}
                    onCategoryChange={setCurrentCategory}
                    onAudiencesChange={setCurrentAudiences}
                    onMonthDataChange={setCurrentMonthData}
                  />
                </Card>
              </Grid>
            )}
          </Grid>
        </div>
      </ThemeProvider>
      <Dialog open={displayForecastsHelp} fullWidth maxWidth="lg">
        <DialogTitle id="form-dialog-title">
          Interpreting Your Risk Forecast Graphs
        </DialogTitle>
        <DialogContent>
          <List>
            {forecastHelperTexts.map((val, idx) => (
              <ListItem key={idx}>
                <ListItemIcon>
                  <ChevronRight />
                </ListItemIcon>
                <ListItemText primary={val} />
              </ListItem>
            ))}
          </List>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDisplayForecastsHelp(false)}>Close</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
