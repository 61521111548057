import React, { FC } from "react";
import { makeStyles } from "@material-ui/core/styles";
import commonStyles from "../../layout/commonStyles";
import wizardStyles from "../../../features/wizard/wizardStyles";
import clsx from "clsx";
import Grid from "@material-ui/core/Grid";

const useStyles = makeStyles((theme) => ({
  ...commonStyles(theme),
  ...wizardStyles(theme),
}));

export interface TreeWrapperProps {
  header: JSX.Element;
  after?: JSX.Element;
}

const TreeWrapper: FC<TreeWrapperProps> = ({ children, header, after }) => {
  const classes = useStyles();

  return (
    <div className={clsx(classes.treeWrapper)}>
      <Grid container direction="column" justifyContent="center">
        <Grid item className={classes.mainCardItem}>
          {header}
        </Grid>

        <Grid item container direction="column" justifyContent="flex-end">
          <div
            className={
              Array.isArray(children) && children.length > 7
                ? classes.bigTreeChildWrapper
                : undefined
            }
          >
            {children}
          </div>
        </Grid>
      </Grid>
      {after}
    </div>
  );
};

export default TreeWrapper;
