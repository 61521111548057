import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { mergeErrors } from "app/forms";

// Material UI
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import Dialog from "@material-ui/core/Dialog";

// components
import HeroLayout from "components/layout/hero/HeroLayout";
import Loader from "components/loading/Loader";
import { SETUP_URLS } from "../slice/urls";
import { UpdateEngineFields, PlatformIntegrationOption } from "../slice/types";

import {
  createEngine,
  getOAuthURL,
  getStaticOffboardValues,
  navigateTo,
  staticValuesSelector,
} from "../slice/wizardSlice";

import {
  clearFormState,
  clientSelector,
  userSelector,
} from "../../user/userSlice";

// styles
import styles from "./setupStyles";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import commonStyles from "components/layout/commonStyles";
import toast from "react-hot-toast";

const useStyles = makeStyles((theme) => ({
  ...styles(theme),
  ...commonStyles(theme),
}));

export default function SubscriptionOptions() {
  const dispatch = useDispatch();
  const classes = useStyles();
  const history = useHistory();
  const client = useSelector(clientSelector);
  const [chosenPlatform, setChosenPlatform] =
    useState<PlatformIntegrationOption | null>(null);

  const isError = useSelector(createEngine.ErrorSelector);
  const isFetching = useSelector(createEngine.isFetchingSelector);
  const ready = useSelector(createEngine.isReadySelector);

  const {
    handleSubmit,
    control,
    watch,
    formState: { errors: frontendErrors },
  } = useForm();
  const website_url = watch("website_url");

  const { auth } = useSelector(userSelector);

  const integrationsLoading = useSelector(
    getStaticOffboardValues.isFetchingSelector
  );
  const staticValues = useSelector(staticValuesSelector);

  const hasInput = website_url?.startsWith("http");

  const errors = mergeErrors(
    frontendErrors,
    useSelector(createEngine.ExtraErrorsSelector)
  );

  const choosePlatform = (platform: PlatformIntegrationOption | null) => {
    setChosenPlatform(platform);
    if (platform?.key === "STRIPE") {
      dispatch(getOAuthURL.Action({ website_url }));
    }
  };

  const onSubmit = handleSubmit(
    ({
      website_url,
      payment_api_key,
      payment_api_secret,
      payment_api_domain,
    }) => {
      dispatch(
        createEngine.Action({
          website_url,
          payment_engine: chosenPlatform?.key,
          payment_api_key,
          payment_api_secret,
          payment_api_domain,
        } as UpdateEngineFields)
      );
    }
  );

  // On component load
  useEffect(() => {
    if (!staticValues) dispatch(getStaticOffboardValues.Action(null));
  }, [dispatch, staticValues]);

  // after ajax response
  useEffect(() => {
    if (ready) {
      dispatch(clearFormState());
      history.push(SETUP_URLS.engine_integration);
    }
  }, [dispatch, history, ready]);

  // after ajax response
  useEffect(() => {
    if (auth.user && client && !client.active) {
      dispatch(clearFormState());
      dispatch(navigateTo("/logout"));
      toast.error(
        "Your account is inactive. Please contact support for assistance."
      );
    }
  }, [dispatch, history, client, auth?.user]);

  const getSlice = (start: number, end: number) =>
    Object.keys(staticValues.integration_options || {})
      .slice(start, end)
      .map((key) => ({ key, ...staticValues.integration_options[key] }));

  if (integrationsLoading || !staticValues) {
    return <Loader padding reason="Fetching integration options..." />;
  } else {
    const apiFields = [
      "payment_api_key",
      "payment_api_secret",
      "payment_api_domain",
    ].filter(
      (name: string) =>
        chosenPlatform !== null &&
        chosenPlatform[name as keyof PlatformIntegrationOption]
    );

    return (
      <HeroLayout>
        <Typography variant="h1">
          Welcome, {auth?.user?.email ? `${auth?.user?.email}!` : ""}
        </Typography>
        <Typography variant="body1">
          Let's get your new RetentionEngine account set up. Tell us a little
          bit about your business.
        </Typography>
        <Grid container justifyContent="center">
          <Grid item xs={11} sm={10} lg={9} xl={8}>
            <form onSubmit={onSubmit}>
              <Card className={classes.mainCard}>
                <Typography variant="h3" className={classes.purpleGradient}>
                  Setup
                </Typography>

                <Typography
                  variant="body1"
                  className={clsx(classes.bold, classes.purpleGradient)}
                >
                  What's Your Website URL?*
                </Typography>
                <Typography variant="body2" className={classes.gray}>
                  This is where your customers will be redirected to at the end
                  of their cancellation experience.
                  <b> This field is required.</b>
                </Typography>
                <Controller
                  control={control}
                  name="website_url"
                  rules={{ required: true }}
                  defaultValue=""
                  render={({ field }) => (
                    <TextField
                      {...field}
                      id="website_url"
                      variant="outlined"
                      error={Boolean(errors.get("website_url"))}
                      helperText={errors.get("website_url")}
                      placeholder="E.g. https://www.bellwethr.com/savedcustomer"
                      style={{ width: "50%" }}
                    />
                  )}
                />

                <Typography
                  variant="body1"
                  className={clsx(classes.bold, classes.purpleGradient)}
                >
                  Subscription Integration
                </Typography>
                <Typography variant="body1">
                  What subscription management platform do you use?
                </Typography>
                <Grid container spacing={3}>
                  <CardRow
                    platforms={getSlice(0, 3)}
                    choosePlatform={choosePlatform}
                    enabled={hasInput}
                  />
                  <CardRow
                    platforms={getSlice(3, 7)}
                    choosePlatform={choosePlatform}
                    enabled={hasInput}
                  />
                  <CardRow
                    platforms={getSlice(7, 10)}
                    choosePlatform={choosePlatform}
                    enabled={hasInput}
                  />
                  <CardRow
                    platforms={getSlice(10, 14)}
                    choosePlatform={choosePlatform}
                    enabled={hasInput}
                  />
                </Grid>
              </Card>

              <Dialog open={Boolean(chosenPlatform)}>
                <Grid
                  container
                  justifyContent="center"
                  className={classes.platformDialog}
                >
                  <Grid item xs={12}>
                    <Typography variant="h5">
                      {chosenPlatform?.integration_name} is ready to integrate!
                    </Typography>
                  </Grid>
                  {apiFields.length ? (
                    apiFields.map((name: string) => (
                      <Grid item key={name} xs={12}>
                        <Controller
                          control={control}
                          name={`${name}` as const}
                          rules={{ required: true }}
                          defaultValue=""
                          render={({ field }) => (
                            <TextField
                              {...field}
                              fullWidth
                              id={name}
                              variant="outlined"
                              label={
                                chosenPlatform
                                  ? chosenPlatform[
                                      name as keyof PlatformIntegrationOption
                                    ]
                                  : null
                              }
                              error={Boolean(errors.get(name))}
                              helperText={errors.get(name)}
                            />
                          )}
                        />
                      </Grid>
                    ))
                  ) : (
                    <Typography variant="body2" className={classes.mdPadding}>
                      {chosenPlatform?.key === "STRIPE"
                        ? "Sending you to Stripe to finish setup..."
                        : "No configuration required!"}
                    </Typography>
                  )}
                  <Grid item xs={12}>
                    {isError ? (
                      <div className={classes.errorMessage}>{isError}</div>
                    ) : null}
                  </Grid>
                  <Grid item xs={12}>
                    {isFetching || chosenPlatform?.key === "STRIPE" ? (
                      <Loader reason="Creating your engine!" />
                    ) : (
                      <>
                        <Button onClick={() => choosePlatform(null)}>
                          Cancel
                        </Button>
                        <Button
                          color="primary"
                          variant="contained"
                          type="submit"
                          onClick={onSubmit}
                        >
                          Next &rarr;
                        </Button>
                      </>
                    )}
                  </Grid>
                </Grid>
              </Dialog>
            </form>
          </Grid>
        </Grid>
      </HeroLayout>
    );
  }
}

export interface PlatformCardProps {
  choosePlatform: (platform: PlatformIntegrationOption) => void;
  platform: PlatformIntegrationOption;
  enabled: boolean;
}
export interface PlatformCardRowProps {
  choosePlatform: (platform: PlatformIntegrationOption) => void;
  platforms: PlatformIntegrationOption[];
  enabled: boolean;
}

const CardRow: React.FC<PlatformCardRowProps> = ({
  platforms,
  choosePlatform,
  enabled,
}) => {
  return (
    <Grid container item xs={12} justifyContent="center" spacing={3}>
      {platforms.map((obj) => (
        <PlatformCard
          key={obj.integration_name}
          choosePlatform={choosePlatform}
          platform={obj}
          enabled={enabled}
        />
      ))}
    </Grid>
  );
};

const PlatformCard: React.FC<PlatformCardProps> = ({
  platform,
  choosePlatform,
  enabled,
}) => {
  const classes = useStyles();
  const { image_url, integration_name } = platform;

  return (
    <Grid item md={3} xs={6}>
      <Card className={classes.miniCard}>
        <CardMedia
          className={classes.logo}
          image={image_url}
          title={integration_name}
        />
        <CardContent>
          <Button
            disabled={!enabled}
            onClick={() => (enabled ? choosePlatform(platform) : null)}
          >
            Connect &rarr;
          </Button>

          <Typography
            variant="body2"
            className={clsx(classes.bold, classes.gray)}
          >
            {platform.integration_name}
          </Typography>
        </CardContent>
      </Card>
    </Grid>
  );
};
