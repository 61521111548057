import React, { useEffect, useState } from "react";
import { generatePath, Link, useHistory } from "react-router-dom";
import { userSelector } from "../../features/user/userSlice";
import { useDispatch, useSelector } from "react-redux";

// Material UI
import Typography from "@material-ui/core/Typography";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Avatar from "@material-ui/core/Avatar";
import Grid from "@material-ui/core/Grid";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import ListSubheader from "@material-ui/core/ListSubheader";
import InputBase from "@material-ui/core/InputBase";
import Table from "@material-ui/core/Table";
import TableRow from "@material-ui/core/TableRow";
import TableHead from "@material-ui/core/TableHead";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import TableCell from "@material-ui/core/TableCell";
import TextField from "@material-ui/core/TextField";
import Tooltip from "@material-ui/core/Tooltip";

// components
import { DASHBOARD_URLS } from "features/dashboard/urls";
import { PrimarySelect } from "components/dashboard/input/SelectBoxes";
import Loader from "../loading/Loader";

import {
  OffboardEngine,
  OffboardSearchResult,
} from "../../features/wizard/slice/types";

import {
  EXPERIENCE_URLS,
  SETUP_URLS,
  TEST_URL,
  USER_SETTINGS_URLS,
  WIZARD_URLS,
} from "../../features/wizard/slice/urls";

import {
  adminSearchEngine,
  currentEngineSlugSelector,
  getEngine,
  getEngines,
  navigateTo,
  setWizardEngine,
  stepNavigate,
  wizardSelector,
} from "features/wizard/slice/wizardSlice";

// images
import Logo from "components/logo/REMagnetLogo.svg";
import EditGraphicGray from "./img/editGraphicGray.svg";
import EditGraphicWhite from "./img/editGraphicWhite.svg";
import DashboardGraphicGray from "./img/dashboardGraphicGray.svg";
import DashboardGraphicWhite from "./img/dashboardGraphicWhite.svg";
import PreviewGraphicGray from "./img/eyeGraphicGray.svg";
import PreviewGraphicWhite from "./img/eyeGraphicWhite.svg";
import CreateWhite from "./img/addIconWhite.svg";
import CreateGray from "./img/addIconGray.svg";

// icons
import Accessibility from "@material-ui/icons/Accessibility";
import Search from "@material-ui/icons/Search";
import DescriptionIcon from "@material-ui/icons/Description";

// styles
import styles from "./headerStyles";
import clsx from "clsx";
import commonStyles from "components/layout/commonStyles";

import {
  createStyles,
  makeStyles,
  Theme,
  withStyles,
} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  ...styles(theme),
  ...commonStyles(theme),
  AutomationLogNumber: {
    margin: 0,
    padding: 0,
    fontSize: "small",
    color: theme.palette.text.primary,
  },
}));

const NavigationSelect = withStyles((theme: Theme) =>
  createStyles({
    root: {
      "label + &": {
        marginTop: theme.spacing(3),
      },
    },
    input: {
      padding: "5px 26px 5px 12px",
      transition: theme.transitions.create(["border-color", "box-shadow"]),

      background: "#442F86",
      border: "3px solid rgba(70, 68, 164, 0.2)",
      borderRadius: "5px",
      color: "#FBFBFB",
      fontFamily: "Poppins",
      fontWeight: 500,
      fontSize: "14px",

      "&:focus": {
        border: "3px solid rgba(70, 68, 164, 0.2)",
        borderRadius: "5px",
        boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
        color: "#FBFBFB",
        background: "#442F86",
      },
    },
  })
)(InputBase);

type PrivateNavBarProps = {
  extraNavigation?: JSX.Element;
};

export const EnginePicker: React.FC = () => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();

  const { auth } = useSelector(userSelector);
  const isStaff = auth?.user?.is_staff;
  const isDashboard = history.location.pathname.startsWith("/dashboard");
  const currentEngineSlug = useSelector(currentEngineSlugSelector);

  const [clientEngineModal, setClientEngineModal] = useState(false);

  const engines = useSelector(wizardSelector).availableEngines;
  const engine: OffboardEngine = engines[currentEngineSlug];

  const searchResults = useSelector(adminSearchEngine.ReplySelector);
  const searchPending = useSelector(adminSearchEngine.isFetchingSelector);

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    if (event.target.value === "create_engine") {
      history.push(SETUP_URLS.platform_options);
    } else if (isDashboard) {
      history.push(
        generatePath(DASHBOARD_URLS.overview, {
          engine: event.target.value as string,
        })
      );
    } else {
      dispatch(
        stepNavigate({
          path: WIZARD_URLS.root,
          engine: event.target.value as string,
        })
      );
    }
  };

  const adminSelectSearchResult = (result: OffboardSearchResult) => {
    setClientEngineModal(false);
    dispatch(getEngine.Action(result.slug));
    dispatch(setWizardEngine(result.slug));
    const path = generatePath(DASHBOARD_URLS.overview, { engine: result.slug });
    history.push(path);
  };

  let clients: { [k: string]: OffboardEngine["client"] } = {};
  Object.values(engines).forEach((val: any) => {
    clients[val.client.slug] = val.client;
  });

  return (
    <div>
      <Select
        id="engine"
        input={<PrimarySelect />}
        value={currentEngineSlug}
        onChange={handleChange}
        className={classes.engineSelect}
      >
        {Object.values(clients).map((client) => {
          const subheadingKeyword = "subheading";
          const engKeys = [subheadingKeyword].concat(Object.keys(engines));
          const isUnderClient = (slug: string) =>
            slug === subheadingKeyword ||
            engines[slug].client.slug === client.slug;

          return engKeys.map((slug) => {
            if (!isUnderClient(slug)) return null;
            return slug === subheadingKeyword ? (
              <ListSubheader style={{ lineHeight: "25px" }}>
                {client.organization_name.toUpperCase()}
              </ListSubheader>
            ) : (
              <MenuItem key={slug} value={slug}>
                <Typography
                  variant="body1"
                  className={classes.navBarMenuItemText}
                >
                  {engines[slug].name}
                </Typography>
              </MenuItem>
            );
          });
        })}

        <MenuItem value="create_engine">
          <Grid container alignItems="center" justifyContent="flex-start">
            <Grid item>
              <img
                src={isDashboard ? CreateWhite : CreateGray}
                alt="create_new_engine"
              />
            </Grid>
            <Grid item>
              <Typography
                variant="body1"
                className={classes.navBarMenuItemText}
              >
                Create New Engine
              </Typography>
            </Grid>
          </Grid>
        </MenuItem>
      </Select>

      {isStaff ? (
        <Tooltip title="Admin: Search for Client Engine">
          <IconButton onClick={() => setClientEngineModal(true)}>
            <Search />
          </IconButton>
        </Tooltip>
      ) : null}

      {isStaff ? (
        <Typography className={classes.AutomationLogNumber}>
          (Automation: {engine?.automation_slug})
        </Typography>
      ) : null}
      <Dialog open={clientEngineModal} fullWidth maxWidth="lg">
        <DialogTitle id="form-dialog-title">Client Engine Search</DialogTitle>
        <DialogContent>
          <TextField
            fullWidth
            variant="outlined"
            placeholder="Search client name, website or engine name/slug"
            onChange={(e) => {
              dispatch(adminSearchEngine.Action(e.target.value));
            }}
          />
          {searchResults && searchResults.length ? (
            <Table>
              <TableHead>
                <TableCell>Name</TableCell>
                <TableCell>Client</TableCell>
                <TableCell>Slug</TableCell>
                <TableCell>Payment</TableCell>
                <TableCell>Updated</TableCell>
                <TableCell></TableCell>
              </TableHead>
              {searchResults.map((result: OffboardSearchResult) => (
                <TableRow key={result.slug}>
                  <TableCell>{result.name}</TableCell>
                  <TableCell>{result.client}</TableCell>
                  <TableCell>{result.slug}</TableCell>
                  <TableCell>{result.payment_engine}</TableCell>
                  <TableCell>{result.updated}</TableCell>
                  <TableCell>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => adminSelectSearchResult(result)}
                    >
                      Load Engine
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </Table>
          ) : searchPending ? (
            <Loader />
          ) : (
            <Typography variant="h6">No results</Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setClientEngineModal(false)}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export const PrivateNavBar: React.FC<PrivateNavBarProps> = ({
  extraNavigation,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();

  const { auth } = useSelector(userSelector);
  const isDashboard = history.location.pathname.startsWith("/dashboard");
  const isWizard = history.location.pathname.startsWith("/wizard");
  const isPreview = history.location.pathname.includes("/preview");

  const currentEngineSlug = useSelector(currentEngineSlugSelector);

  const enginesRequested = useSelector(getEngines.isRequestedSelector);
  const viewPage = isDashboard ? "dashboard" : isPreview ? "preview" : "edit";

  const initials =
    auth.user.first_name && auth.user.last_name
      ? auth.user.first_name[0] + auth.user.last_name[0]
      : "RE";

  const showDashboardNavBar =
    history.location.pathname.startsWith("/dashboard");

  // On component load
  useEffect(() => {
    if (!enginesRequested) dispatch(getEngines.Action(null));
  }, [dispatch, enginesRequested]);

  return (
    <div className={classes.root}>
      <AppBar
        position="static"
        className={showDashboardNavBar ? classes.appBar : classes.privateAppBar}
      >
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={() => history.push(DASHBOARD_URLS.root)}
            className={classes.privateLogo}
          >
            <img src={Logo} alt="logo" />
          </IconButton>
          <Typography
            variant="h6"
            className={
              showDashboardNavBar
                ? clsx(classes.title, classes.offWhite)
                : clsx(classes.title, classes.purpleGradient)
            }
          >
            <span className={classes.headingMain}>Retention</span>
            <span className={classes.headingSub}>Engine</span>
          </Typography>
          {currentEngineSlug && (isDashboard || isWizard) ? (
            <EnginePicker />
          ) : null}

          <div className={classes.grow} />

          <Select
            className={classes.navBarSelectView}
            input={<NavigationSelect />}
            value={viewPage}
            onChange={(e) => {
              if (e.target.value === "edit") {
                dispatch(
                  navigateTo(
                    generatePath(EXPERIENCE_URLS.root, {
                      engine: currentEngineSlug || ":engine",
                    })
                  )
                );
              }
              if (e.target.value === "dashboard") {
                dispatch(
                  navigateTo(
                    generatePath(DASHBOARD_URLS.overview, {
                      engine: currentEngineSlug || ":engine",
                    })
                  )
                );
              }
              if (e.target.value === "preview") {
                dispatch(
                  navigateTo(
                    generatePath(WIZARD_URLS.preview, {
                      engine: currentEngineSlug || ":engine",
                    })
                  )
                );
              }
              if (e.target.value === "test") {
                const testURL = generatePath(TEST_URL, {
                  engine: currentEngineSlug || ":engine",
                });
                window.open(testURL, "_blank");
              }
              if (e.target.value === "docs") {
                window.open("http://docs.bellwethr.com/", "_blank");
              }
            }}
          >
            <MenuItem value="edit">
              <Grid
                container
                alignItems="center"
                justifyContent="flex-start"
                spacing={1}
              >
                <Grid item>
                  <img
                    src={
                      viewPage === "edit" ? EditGraphicWhite : EditGraphicGray
                    }
                    alt="edit"
                  />
                </Grid>
                <Grid item>
                  <Typography
                    variant="body1"
                    className={classes.navBarMenuItemText}
                  >
                    Edit
                  </Typography>
                </Grid>
              </Grid>
            </MenuItem>
            <MenuItem value="dashboard">
              <Grid
                container
                alignItems="center"
                justifyContent="flex-start"
                spacing={1}
              >
                <Grid item>
                  <img
                    src={
                      viewPage === "dashboard"
                        ? DashboardGraphicWhite
                        : DashboardGraphicGray
                    }
                    alt="dashboard"
                  />
                </Grid>
                <Grid item>
                  <Typography
                    variant="body1"
                    className={classes.navBarMenuItemText}
                  >
                    Dashboard
                  </Typography>
                </Grid>
              </Grid>
            </MenuItem>
            <MenuItem value="preview">
              <Grid
                container
                alignItems="center"
                justifyContent="flex-start"
                spacing={1}
              >
                <Grid item>
                  <img
                    src={
                      viewPage === "preview"
                        ? PreviewGraphicWhite
                        : PreviewGraphicGray
                    }
                    alt="dashboard"
                  />
                </Grid>
                <Grid item>
                  <Typography
                    variant="body1"
                    className={classes.navBarMenuItemText}
                  >
                    Preview
                  </Typography>
                </Grid>
              </Grid>
            </MenuItem>
            <MenuItem value="test">
              <Grid
                container
                alignItems="center"
                justifyContent="flex-start"
                spacing={1}
              >
                <Grid item>
                  <Accessibility />
                </Grid>
                <Grid item>
                  <Typography
                    variant="body1"
                    className={classes.navBarMenuItemText}
                  >
                    Test
                  </Typography>
                </Grid>
              </Grid>
            </MenuItem>
            <MenuItem value="docs">
              <Grid
                container
                alignItems="center"
                justifyContent="flex-start"
                spacing={1}
              >
                <Grid item>
                  <DescriptionIcon />
                </Grid>
                <Grid item>
                  <Typography
                    variant="body1"
                    className={classes.navBarMenuItemText}
                  >
                    Documentation
                  </Typography>
                </Grid>
              </Grid>
            </MenuItem>
          </Select>

          <Link
            to={USER_SETTINGS_URLS.account}
            style={{ textDecoration: "none" }}
          >
            <Avatar
              className={clsx(classes.darkPurpleGradient, classes.offWhite)}
            >
              {initials}
            </Avatar>
          </Link>
        </Toolbar>
        <Grid container justifyContent="center">
          {extraNavigation}
        </Grid>
      </AppBar>
    </div>
  );
};
export default PrivateNavBar;
