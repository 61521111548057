import InputBase from "@material-ui/core/InputBase";
import { createStyles, Theme, withStyles } from "@material-ui/core/styles";

const inputStyles = {
  padding: "5px 8px",

  background: "rgba(255, 255, 255, 0.18)",
  border: "0.5px solid #D2D2D2",
  borderRadius: "5px",
  fontFamily: "Poppins",
  fontSize: "14px",
};

const focusStyles = {
  borderRadius: "5px",
  boxShadow: "none",
  background: "rgba(255, 255, 255, 0.18)",
};

export const PrimarySelect = withStyles((theme: Theme) =>
  createStyles({
    root: {
      "label + &": {
        marginTop: theme.spacing(3),
      },
    },
    input: {
      ...inputStyles,
      transition: theme.transitions.create(["border-color", "box-shadow"]),
      color: window.location.pathname.startsWith("/dashboard")
        ? "#F1E5F7"
        : "#787878",

      "&:focus": {
        ...focusStyles,
        color: window.location.pathname.startsWith("/dashboard")
          ? "#F1E5F7"
          : "#787878",
      },
    },
  })
)(InputBase);

export const SecondarySelect = withStyles((theme: Theme) =>
  createStyles({
    root: {
      "label + &": {
        marginTop: theme.spacing(3),
      },
    },
    input: {
      ...inputStyles,
      transition: theme.transitions.create(["border-color", "box-shadow"]),
      color: "#787878",

      "&:focus": {
        ...focusStyles,
        color: "#787878",
      },
    },
  })
)(InputBase);
