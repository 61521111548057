import { Theme } from "@material-ui/core";

export default function setupStyles(theme: Theme) {
  return {
    // COMPANY SIGNUP
    radialGradient: {
      zIndex: 0,
      position: "absolute" as "absolute",
      width: "100%",
      height: "100vh",
      top: "45%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      background:
        "radial-gradient(50% 50% at 50% 50%, rgba(249, 246, 226, 0.1) 0%, rgba(246, 203, 241, 0) 100%)",
    },
    backgroundSprinkles: {
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      position: "absolute" as "absolute",
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      zIndex: 2
    },
    // CHECK EMAIL
    backgroundMailSVG: {
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      position: "absolute" as "absolute",
      width: "500px",
      height: "500px",
      right: 0,
      top: "10vh",
      backgroundPosition: "right" as "right",
    },
    // BILLING
    billingButtonWrapper: {
      marginTop: "auto",
      paddingBottom: "2rem",
    },
    billingCard: {
      boxShadow:
        "0px 10px 100px rgba(62, 62, 62, 0.07), 0px 5px 12px rgba(62, 62, 62, 0.035)",
      borderRadius: "10px",
      height: "100%",
      width: "100%",
      display: "flex" as "flex",
      flexDirection: "column" as "column",
    },
    title: {
      margin: "0 -1em",
    },
  };
}
