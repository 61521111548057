import { useState } from "react";

// Material UI
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Chip from "@material-ui/core/Chip";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreRounded from "@material-ui/icons/ExpandMoreRounded";

// Styles
import commonStyles from "components/layout/commonStyles";
import clsx from "clsx";
import wizardStyles from "features/wizard/wizardStyles";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Divider from "@material-ui/core/Divider";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";

const useStyles = makeStyles((theme) => ({
  ...commonStyles(theme),
  ...wizardStyles(theme),
}));

interface ContextVariableProps {
  contextVariables: string[];
  textType?: string;
  isUrl?: boolean;
}

export const ContextVariableList: React.FC<ContextVariableProps> = ({
  contextVariables,
  textType,
  isUrl,
}) => {
  const classes = useStyles();
  const [expanded, setExpanded] = useState<string | false>(false);

  const handleChange =
    (panel: string) => (_event: any, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  const handleClickAway = () => {
    setExpanded(false);
  };

  if (!contextVariables || contextVariables.length < 1) return null;
  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <Accordion
        expanded={expanded === "variablesPanel"}
        onChange={handleChange("variablesPanel")}
        square={false}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreRounded />}
          aria-controls="variablesPanelbh-content"
          id="variablesPanelbh-header"
        >
          <Typography variant="subtitle2" className={classes.gray}>
            {isUrl ? "Add user context to URL" : "Add custom messaging"}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography variant="caption" className={classes.gray}>
            Include user specific variables{" "}
            {textType ? ` in the ${textType} ` : ""}
            by sandwiching any of the below in double curly braces eg. "
            {`{{ customer_email }}`}"
            <Divider />
            {contextVariables.map((contextVariable: String, index: Number) => (
              <Chip
                key={index.toString()}
                className={clsx(classes.contextChip)}
                label={contextVariable}
                size="small"
                variant="outlined"
              />
            ))}
          </Typography>
          <Typography></Typography>
        </AccordionDetails>
      </Accordion>
    </ClickAwayListener>
  );
};

export default ContextVariableList;
