import { URLMap } from "../wizard/slice/urls";

export const DASHBOARD_URL_PREFIX = "/dashboard";
export const DASHBOARD_URL_WITH_SLUG = `${DASHBOARD_URL_PREFIX}/:engine`;

export const DASHBOARD_URLS: URLMap = {
  root: `${DASHBOARD_URL_PREFIX}`,
  overview: `${DASHBOARD_URL_WITH_SLUG}/overview`,
  exit_survey: `${DASHBOARD_URL_WITH_SLUG}/exit-survey`,
  experiences: `${DASHBOARD_URL_WITH_SLUG}/experiences`,
  risk_forecasts: `${DASHBOARD_URL_WITH_SLUG}/risk-forecasts`,
};
