import { Theme } from "@material-ui/core";

export default function WizardPreviewContainerStyles(theme: Theme) {
  return {
    previewSelectContainer: {
      position: "absolute" as "absolute",
      top: 25,
      left: 10,
      zIndex: 399,
      padding: "0 8px",
      textAlign: "left" as "left",
      background: "#FFFC",
    },
    previewSelectTitle: {
      fontSize: 14,
      fontWeight: 500,
      margin: "0 0.5em 0 0",
      display: "inline-block",
    },
    previewSelectItemText: {
      margin: 0,
      padding: "0 0.5em",
      fontFamily: "Poppins",
      fontSize: 14,
    },
  };
}
