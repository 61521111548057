import { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
import {
  signupUser,
  userSelector,
  clearFormState,
  SignupFields,
  setEmail,
} from "./userSlice";
import { useHistory } from "react-router-dom";
import toast from "react-hot-toast";
import { mergeErrors } from "../../app/forms";

// Material UI
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import FormGroup from "@material-ui/core/FormGroup";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Checkbox from "@material-ui/core/Checkbox";

// components
import TransparentTextField from "../../components/input/TransparentTextField";
import ExternalLayout from "components/layout/external/ExternalLayout";

// styles
import styles from "./userStyles";
import commonStyles from "../../components/layout/commonStyles";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  ...styles(theme),
  ...commonStyles(theme),
}));

export default function SignUp() {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [termsAndConditions, setTermsAndConditions] = useState(false);

  const {
    handleSubmit,
    control,
    formState: { errors: frontendErrors },
  } = useForm();
  const history = useHistory();

  const {
    isFetching,
    isSuccess,
    isError,
    errorMessage,
    backendFieldErrors,
    loggedIn,
  } = useSelector(userSelector);

  const errors = mergeErrors(frontendErrors, backendFieldErrors);

  // Submit data here
  const onSubmit = handleSubmit((data) => {
    if (!termsAndConditions)
      return toast.error("Please agree to terms and conditions to proceed.");
    const signupFields = data as SignupFields;
    dispatch(setEmail(data.email));
    dispatch(signupUser(signupFields));
  });

  // On component load
  useEffect(() => {
    if (loggedIn) history.push("/dashboard");

    return () => {
      dispatch(clearFormState());
    };
  }, [loggedIn]); // eslint-disable-line react-hooks/exhaustive-deps

  // after ajax response
  useEffect(() => {
    if (isSuccess) {
      dispatch(clearFormState());
      history.push("/check-email");
    }

    if (isError) {
      toast.error(errorMessage);
    }
  }, [isSuccess, isError, dispatch, errorMessage, history]);

  return (
    <ExternalLayout footerFull>
      <Grid container justifyContent="center" alignItems="center">
        <Grid item xs={10} md={5}>
          <Typography variant="h1">You're on your way to...</Typography>
          <Typography variant="body1">Retaining more customers</Typography>
          <Typography variant="body1">Retaining more revenue</Typography>
          <Typography variant="body1">Forecasting churn</Typography>
          <Typography variant="body1">Reactivating former customers</Typography>
        </Grid>
        <Grid item xs={10} md={5}>
          <form onSubmit={onSubmit} method="POST">
            <Card>
              <Typography variant="h1">First, let's set you up</Typography>
              <Typography variant="body1">
                We suggest using the email address you use at work.
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <FormGroup>
                    <Controller
                      control={control}
                      name="first_name"
                      rules={{ required: true }}
                      defaultValue=""
                      render={({ field }) => (
                        <TransparentTextField
                          {...field}
                          error={Boolean(errors.get("first_name"))}
                          helperText={errors.get("first_name")}
                          label="FIRST NAME"
                          variant="outlined"
                        />
                      )}
                    />
                  </FormGroup>
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormGroup>
                    <Controller
                      control={control}
                      name="last_name"
                      rules={{ required: true }}
                      defaultValue=""
                      render={({ field }) => (
                        <TransparentTextField
                          {...field}
                          error={Boolean(errors.get("last_name"))}
                          helperText={errors.get("last_name")}
                          label="LAST NAME"
                          variant="outlined"
                        />
                      )}
                    />
                  </FormGroup>
                </Grid>
              </Grid>
              <FormGroup>
                <Controller
                  control={control}
                  name="email"
                  rules={{ required: true }}
                  defaultValue=""
                  render={({ field }) => (
                    <TransparentTextField
                      {...field}
                      error={Boolean(errors.get("email"))}
                      helperText={errors.get("email")}
                      label="YOUR EMAIL"
                      variant="outlined"
                    />
                  )}
                />
              </FormGroup>
              <FormGroup>
                <Controller
                  control={control}
                  name="password1"
                  rules={{ required: true }}
                  defaultValue=""
                  render={({ field }) => (
                    <TransparentTextField
                      {...field}
                      error={Boolean(errors.get("password1"))}
                      helperText={errors.get("password1")}
                      label="PASSWORD"
                      variant="outlined"
                      type="password"
                    />
                  )}
                />
              </FormGroup>
              <FormGroup>
                <Controller
                  control={control}
                  name="password2"
                  rules={{ required: true }}
                  defaultValue=""
                  render={({ field }) => (
                    <TransparentTextField
                      {...field}
                      error={Boolean(errors.get("password2"))}
                      helperText={errors.get("password2")}
                      label="CONFIRM PASSWORD"
                      variant="outlined"
                      type="password"
                    />
                  )}
                />
              </FormGroup>
              <Grid item>
                <Typography variant="body1">
                  <Checkbox
                    checked={termsAndConditions}
                    onChange={() => setTermsAndConditions(!termsAndConditions)}
                    name="terms and contitions checkbox"
                    color="default"
                    disabled={isFetching}
                  />
                  By checking this box you agree to Bellwethr’s{" "}
                  <a
                    href="https://www.bellwethr.com/privacy-policy"
                    target="_blank"
                    rel="noreferrer"
                    className={classes.offWhite}
                  >
                    privacy policy
                  </a>{" "}
                  and{" "}
                  <a
                    href="https://www.bellwethr.com/terms-conditions"
                    target="_blank"
                    rel="noreferrer"
                    className={classes.offWhite}
                  >
                    terms and conditions
                  </a>
                  .
                </Typography>
              </Grid>

              {isError ? (
                <div className={classes.errorMessage}>{errorMessage}</div>
              ) : null}

              <Button
                color="primary"
                type="submit"
                variant="contained"
                disabled={isFetching}
              >
                Create Account
              </Button>
            </Card>
          </form>
        </Grid>
      </Grid>
    </ExternalLayout>
  );
}
