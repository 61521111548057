import { FormEvent, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import { mergeErrors } from "app/forms";

// Material UI
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import FormGroup from "@material-ui/core/FormGroup";
import IconButton from "@material-ui/core/IconButton";

// components
import Loader from "components/loading/Loader";
import ColorCard from "components/wizard/experience/ColorCard";
import { States } from "../../offboarding/containers/StateViewRouter/StateViewRouter";
import PanelHeader from "components/wizard/PanelHeader";

import {
  wizardEngineSelector,
  currentEngineSlugSelector,
  engineStyleSelector,
  getEngineStyle,
  openPreviewStep,
  updateEngineStyle,
  updatePreviewStyle,
  closePreview,
  displayDataSelector,
  removeMedia,
  getStaticOffboardValues,
  staticValuesSelector,
} from "../slice/wizardSlice";

// icons
import Cancel from "@material-ui/icons/Cancel";

// styles
import { makeStyles } from "@material-ui/core/styles";
import commonStyles from "../../../components/layout/commonStyles";
import wizardStyles from "../wizardStyles";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  ...commonStyles(theme),
  ...wizardStyles(theme),
}));

export default function StylePanel() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const {
    control,
    formState: { errors: frontendErrors },
    getValues,
    clearErrors,
  } = useForm();

  const [primaryColor, setPrimaryColor] = useState<string>("");
  const [secondaryColor, setSecondaryColor] = useState<string>("");
  const logoRef = useRef<HTMLInputElement | null>(null);
  const [logo, setLogo] = useState<File>();

  const currentEngine = useSelector(wizardEngineSelector);
  const currentEngineSlug = useSelector(currentEngineSlugSelector);
  const style = useSelector(engineStyleSelector);
  const engineStylesReady = useSelector(getEngineStyle.isReadySelector);
  const updateEngineStyleErrors = useSelector(
    updateEngineStyle.ExtraErrorsSelector
  );
  const isFetchingEngineStyle = useSelector(
    updateEngineStyle.isFetchingSelector
  );
  const [font, setFont] = useState("");
  const inPreview = useSelector(displayDataSelector).showPreviewStep;
  const getFontsStatus = useSelector(
    getStaticOffboardValues.isFetchingSelector
  );
  const staticValues = useSelector(staticValuesSelector);

  const onSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const { custom_font_url, custom_font_family } = getValues();
    let customFont = {};
    if (font === "Custom Font") {
      customFont = {
        custom_font_family,
        custom_font_url,
      };
    }
    dispatch(
      updateEngineStyle.Action({
        slug: currentEngineSlug,
        primary_color: primaryColor,
        secondary_color: secondaryColor,
        font: font,
        ...customFont,
        ...(logo?.name ? { logo } : null),
      })
    );
    return false;
  };

  const errors = mergeErrors(frontendErrors, updateEngineStyleErrors);

  useEffect(() => {
    if (currentEngineSlug) {
      dispatch(getEngineStyle.Action(currentEngineSlug));
      dispatch(openPreviewStep({ step: States.REASONS }));
    }
    if (!staticValues) dispatch(getStaticOffboardValues.Action(null));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentEngineSlug]);

  useEffect(() => {
    if (engineStylesReady) {
      clearErrors();
      setPrimaryColor(style.primary_color);
      setSecondaryColor(style.secondary_color);
      setFont(style.font);
    }
  }, [engineStylesReady, style, clearErrors]);

  if (getFontsStatus || !engineStylesReady || !currentEngine || !primaryColor) {
    return <Loader padding reason="Fetching your engine styles..." />;
  }

  return (
    <div className={classes.mdPadding}>
      <PanelHeader
        title="Style"
        onClick={() =>
          dispatch(
            inPreview
              ? closePreview()
              : openPreviewStep({ step: States.REASONS })
          )
        }
      />

      <Divider className={classes.headingDivider} />
      <form onSubmit={onSubmit}>
        <Typography variant="h6" className={classes.purpleGradient}>
          Company Logo
        </Typography>
        <Typography variant="body2" className={classes.gray}>
          Personalize the image that appears on the top.
        </Typography>

        <Grid container alignItems="center" spacing={2}>
          <Grid item>
            <input
              accept={
                staticValues
                  ? staticValues.allowed_image_types.join(",")
                  : "image/*"
              }
              style={{ display: "none" }}
              type="file"
              ref={logoRef}
              onChange={(e) => {
                const newLogo = e.target.files ? e.target?.files[0] : undefined;
                if (
                  staticValues &&
                  newLogo &&
                  newLogo.size > staticValues.max_image_size
                )
                  return alert(
                    `Max file size is ${
                      staticValues.max_image_size / 1024 / 1024
                    }MB`
                  );
                setLogo(newLogo);
                dispatch(
                  updatePreviewStyle({
                    logo: newLogo ? URL.createObjectURL(newLogo) : null,
                  })
                );
              }}
            />
          </Grid>
          <Grid item xs={12} lg={3}>
            <Button
              className={classes.panelButton}
              onClick={() => logoRef.current && logoRef.current.click()}
            >
              Choose File
            </Button>
          </Grid>

          <Grid item xs={10} md={6}>
            <TextField
              value={logo?.name || style?.logo || "no image selected"}
              variant="outlined"
              size="small"
              disabled
            />
          </Grid>

          {style?.logo ? (
            <Grid item xs={2} xl={1}>
              <IconButton
                onClick={() => {
                  setLogo(undefined);
                  dispatch(
                    removeMedia.Action({
                      slug: currentEngineSlug,
                      media_type: "logo",
                    })
                  );
                }}
              >
                <Cancel />
              </IconButton>
            </Grid>
          ) : null}
        </Grid>

        <Typography variant="h6" className={classes.panelSubheader}>
          Primary Color
        </Typography>

        <ColorCard
          stateColor={primaryColor}
          onColorChange={(color: string) => {
            setPrimaryColor(color);
            dispatch(updatePreviewStyle({ primary_color: color }));
          }}
        />

        <Typography variant="h6" className={classes.panelSubheader}>
          Secondary Color
        </Typography>

        <ColorCard
          stateColor={secondaryColor}
          onColorChange={(color: string) => {
            setSecondaryColor(color);
            dispatch(updatePreviewStyle({ secondary_color: color }));
          }}
        />

        <Typography variant="h6" className={classes.panelSubheader}>
          Font
        </Typography>
        <FormControl variant="outlined" className={classes.zeroMargin}>
          <Select
            value={font}
            onChange={(e) => {
              setFont(e.target.value as string);
              dispatch(updatePreviewStyle({ font: e.target.value }));
            }}
            className={classes.gray}
          >
            {staticValues &&
              staticValues.fonts.map((font: string) => (
                <MenuItem key={font} value={font}>
                  {font}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
        {font === "Custom Font" ? (
          <FormGroup>
            <Controller
              control={control}
              name="custom_font_family"
              rules={{ required: true }}
              defaultValue={style.custom_font_family}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Custom Font Family"
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                  error={Boolean(errors.get("custom_font_family"))}
                  helperText={errors.get("custom_font_family")}
                />
              )}
            />
            <Controller
              control={control}
              name="custom_font_url"
              rules={{ required: true }}
              defaultValue={style.custom_font_url}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Custom Font URL"
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                  error={Boolean(errors.get("custom_font_url"))}
                  helperText={errors.get("custom_font_url")}
                />
              )}
            />
          </FormGroup>
        ) : null}

        <br />

        <Button
          type="submit"
          className={clsx(classes.panelButton, classes.marginTop)}
          disabled={isFetchingEngineStyle}
        >
          Save Changes
        </Button>
      </form>
    </div>
  );
}
