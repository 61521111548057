import React from "react";
import { ThemeAttributeProps } from "../../theme";
import { useTheme } from "emotion-theming";
import { getFontColor } from "features/offboarding/utils";

const ErrorLogo: React.FC = () => {
  const theme: ThemeAttributeProps = useTheme();
  return (
    <svg
      width="271"
      height="271"
      viewBox="0 0 271 271"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="experience-svg"
    >
      <path
        d="M220.188 271H50.8125C22.7471 271 0 248.253 0 220.188V50.8125C0 22.7471 22.7471 0 50.8125 0H220.188C248.253 0 271 22.7471 271 50.8125V220.188C271 248.253 248.253 271 220.188 271Z"
        fill={
          theme.secondary_color === theme.primary_color
            ? getFontColor(theme.primary_color)
            : theme.primary_color
        }
      />
      <path
        d="M136.5 54C91.5629 54 55 90.5629 55 135.5C55 180.437 91.5629 217 136.5 217C181.437 217 218 180.437 218 135.5C218 90.5629 181.437 54 136.5 54ZM95.75 115.125C95.75 109.512 100.324 104.938 105.938 104.938C111.551 104.938 116.125 109.512 116.125 115.125C116.125 120.738 111.551 125.312 105.938 125.312C100.324 125.312 95.75 120.738 95.75 115.125ZM163.864 165.696L112.926 186.071C112.305 186.315 111.663 186.438 111.031 186.438C109.014 186.438 107.099 185.225 106.304 183.239C105.255 180.631 106.528 177.666 109.147 176.627L160.084 156.252C162.692 155.203 165.657 156.476 166.696 159.084C167.735 161.692 166.472 164.646 163.864 165.696ZM167.062 125.312C161.449 125.312 156.875 120.738 156.875 115.125C156.875 109.512 161.449 104.938 167.062 104.938C172.676 104.938 177.25 109.512 177.25 115.125C177.25 120.738 172.676 125.312 167.062 125.312Z"
        fill={theme.secondary_color}
      />
    </svg>
  );
};

export { ErrorLogo };
