import { useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
import {
  loginUser,
  userSelector,
  clearFormState,
  getProfile,
} from "./userSlice";
import { Link, useHistory } from "react-router-dom";
import toast from "react-hot-toast";
import { mergeErrors } from "app/forms";

// Material UI
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import FormGroup from "@material-ui/core/FormGroup";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";

// components
import TransparentTextField from "components/input/TransparentTextField";
import ExternalLayout from "components/layout/external/ExternalLayout";
import Loader from "components/loading/Loader";
import { ONBOARDING_URLS } from "../wizard/slice/urls";
import { DASHBOARD_URLS } from "features/dashboard/urls";

// styles
import styles from "./userStyles";
import { makeStyles } from "@material-ui/core/styles";
import commonStyles from "components/layout/commonStyles";

const useStyles = makeStyles((theme) => ({
  ...styles(theme),
  ...commonStyles(theme),
}));

export default function Login() {
  const dispatch = useDispatch();
  const {
    handleSubmit,
    control,
    formState: { errors: frontendErrors },
  } = useForm();
  const history = useHistory();
  const classes = useStyles();

  const {
    isFetching,
    isSuccess,
    isError,
    errorMessage,
    backendFieldErrors,
    auth,
    loggedIn,
  } = useSelector(userSelector);

  const onSubmit = handleSubmit(({ email, password }) =>
    dispatch(loginUser.Action({ email, password }))
  );

  const errors = mergeErrors(frontendErrors, backendFieldErrors);

  // On component load
  useEffect(() => {
    return () => {
      dispatch(clearFormState());
    };
  }, [dispatch]);

  // after ajax response
  useEffect(() => {
    if (isSuccess || (auth && auth.user?.email)) {
      dispatch(clearFormState());
      dispatch(getProfile());

      console.info("User logged in! Redirecting...");
      toast.success("Logged in!");

      // TODO: If this is the user's first time logging in,
      // push to company signup, if not push to dashboard.
      if (auth.user.client === null)
        history.push(ONBOARDING_URLS.company_signup);
      else history.push(DASHBOARD_URLS.root);
    }

    if (isError) {
      toast.error(errorMessage || "An error occurred");
    }
  }, [isSuccess, isError, auth.user, errorMessage, history, dispatch]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <ExternalLayout footerFull>
      <Grid container justifyContent="center">
        <Grid item xs={10} md={6} lg={4}>
          <form onSubmit={onSubmit}>
            <Card>
              <Typography variant="h1">Welcome Back</Typography>
              <Typography variant="body1">
                {loggedIn ? (
                  <>You are logged in as {auth.user.email}</>
                ) : (
                  "Login to your account here"
                )}
              </Typography>
              <FormGroup>
                <Controller
                  control={control}
                  name="email"
                  rules={{ required: true }}
                  defaultValue=""
                  render={({ field }) => (
                    <TransparentTextField
                      {...field}
                      error={Boolean(errors.get("email"))}
                      helperText={errors.get("email")}
                      label="YOUR EMAIL"
                      variant="outlined"
                    />
                  )}
                />
              </FormGroup>
              <FormGroup>
                <Controller
                  control={control}
                  name="password"
                  rules={{ required: true }}
                  defaultValue=""
                  render={({ field }) => (
                    <TransparentTextField
                      {...field}
                      error={Boolean(errors.get("password"))}
                      helperText={errors.get("password")}
                      label="YOUR PASSWORD"
                      variant="outlined"
                      type="password"
                    />
                  )}
                />
                <Link
                  className={classes.forgotPasswordLink}
                  to="/forgot-password"
                >
                  Forgot password?
                </Link>
              </FormGroup>

              {isError ? (
                <div className={classes.errorMessage}>{errorMessage}</div>
              ) : null}

              {isFetching ? (
                <Loader />
              ) : (
                <Button
                  color="primary"
                  onClick={onSubmit}
                  variant="contained"
                  disabled={isFetching}
                  type="submit"
                >
                  Login
                </Button>
              )}
            </Card>
          </form>
        </Grid>
      </Grid>
    </ExternalLayout>
  );
}
