import { useState } from "react";
import { Color, ColorResult, SketchPicker } from "react-color";

// images
import PaintBucket from "features/wizard/img/paintBucketIcon.svg";

// Material UI
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import TextField from "@material-ui/core/TextField";

// styles
import { makeStyles } from "@material-ui/core/styles";
import commonStyles from "../../../components/layout/commonStyles";
import wizardStyles from "features/wizard/wizardStyles";

const useStyles = makeStyles((theme) => ({
  ...commonStyles(theme),
  ...wizardStyles(theme),
}));

interface ColorCardProps {
  stateColor: string;
  onColorChange: (color: string) => void;
}

export const ColorCard: React.FC<ColorCardProps> = ({
  stateColor,
  onColorChange,
}) => {
  const classes = useStyles();
  const [displayColorPicker, setDisplayColorPicker] = useState(false);
  const [color, setColor] = useState<Color>(stateColor);

  const handleClick = () => {
    setDisplayColorPicker(true);
  };

  const handleClose = () => {
    setDisplayColorPicker(false);
  };

  const handleChange = (c: ColorResult) => {
    setColor(c.hex);
    onColorChange(c.hex);
  };

  return (
    <Grid item md={9} lg={8} xl={6}>
      <Card className={classes.colorCard}>
        <Grid container alignItems="center" justifyContent="space-evenly">
          <Grid item xs={1}>
            <img
              src={PaintBucket}
              alt="paint_bucket"
              style={{ marginTop: "0.4em" }}
            />
          </Grid>
          <Grid item xs={7}>
            <TextField variant="outlined" size="small" value={color} />
          </Grid>
          <Grid item xs={2}>
            <div
              className={classes.swatch}
              onClick={handleClick}
              style={{ marginTop: "0.4em" }}
            >
              <div
                style={{
                  width: "20px",
                  height: "20px",
                  borderRadius: "2px",
                  background: color as string,
                }}
              />
            </div>
            {displayColorPicker ? (
              <div className={classes.popover}>
                <div className={classes.cover} onClick={handleClose} />
                <SketchPicker color={color} onChange={handleChange} />
              </div>
            ) : null}
          </Grid>
        </Grid>
      </Card>
    </Grid>
  );
};

export default ColorCard;
