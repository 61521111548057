import { history } from "app/history";
import { useDispatch, useSelector } from "react-redux";
import { generatePath, Route, Router, Switch } from "react-router-dom";

// Material UI
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Tooltip from "@material-ui/core/Tooltip";
import Button from "@material-ui/core/Button";

// components
import { SETTINGS_ROOT, SETTINGS_URLS } from "../slice/urls";
import { SettingsIconOrder, SettingsIcons } from "../slice/tour";
import BuilderSidebarWrapper from "../BuilderSidebarWrapper";
import YourEnginePanel from "./YourEnginePanel";
import NotificationSettingsPanel from "./NotificationSettingsPanel";
import GamingPreventionPanel from "./GamingPreventionPanel";
import AnnouncementBannerPanel from "./AnnouncementBannerPanel";
import AdvancedSettingsPanel from "./AdvancedSettingsPanel";
import UserAccessManagement from "./UserAccessManagement";

import {
  currentEngineSlugSelector,
  currentStepPathSelector,
  navigateTo,
} from "../slice/wizardSlice";

//styles
import { makeStyles } from "@material-ui/core/styles";
import commonStyles from "../../../components/layout/commonStyles";
import clsx from "clsx";
import wizardStyles from "../wizardStyles";

const useStyles = makeStyles((theme) => ({
  ...commonStyles(theme),
  ...wizardStyles(theme),
}));

export const WizardSettingsContainer = () => {
  const classes = useStyles();
  const currentStepPath = useSelector(currentStepPathSelector);
  const engine = useSelector(currentEngineSlugSelector);
  const dispatch = useDispatch();

  return (
    <BuilderSidebarWrapper>
      <Grid
        container
        justifyContent="flex-start"
        className={classes.fullHeight}
      >
        <Grid
          item
          className={clsx(classes.fullHeight, classes.sidebarNavigation)}
        >
          <Grid
            container
            direction="column"
            alignItems="center"
            style={{ paddingTop: "0.5em" }}
          >
            {SettingsIconOrder.map((name: string) => {
              const icon = SettingsIcons[name];
              return (
                <Grid item key={icon.alt}>
                  <Button
                    onClick={() =>
                      dispatch(
                        navigateTo(generatePath(icon.target, { engine }))
                      )
                    }
                    className={classes.sidebarNavIcon}
                  >
                    <Tooltip title={icon.title || ""} placement="left" arrow>
                      <img
                        src={
                          currentStepPath === icon.target
                            ? icon.selected
                            : icon.normal
                        }
                        alt={icon.alt}
                      />
                    </Tooltip>
                  </Button>
                </Grid>
              );
            })}
          </Grid>
        </Grid>
        <Grid item className={clsx(classes.fullHeight, classes.flexTrue)}>
          <Paper
            square
            className={clsx(classes.sidebarContent, classes.scrollY)}
          >
            <Router history={history}>
              <Switch>
                <Route
                  exact
                  component={YourEnginePanel}
                  path={SETTINGS_URLS.your_engine}
                />
                <Route
                  exact
                  component={NotificationSettingsPanel}
                  path={SETTINGS_URLS.notification_settings}
                />
                <Route
                  exact
                  component={GamingPreventionPanel}
                  path={SETTINGS_URLS.gaming_prevention}
                />
                <Route
                  exact
                  component={AnnouncementBannerPanel}
                  path={SETTINGS_URLS.announcement_banner}
                />
                <Route
                  exact
                  component={AdvancedSettingsPanel}
                  path={SETTINGS_URLS.advanced}
                />
                <Route
                  exact
                  component={UserAccessManagement}
                  path={SETTINGS_URLS.user_access_management}
                />
                <Route exact component={YourEnginePanel} path={SETTINGS_ROOT} />
              </Switch>
            </Router>
          </Paper>
        </Grid>
      </Grid>
    </BuilderSidebarWrapper>
  );
};
