import React from "react";
import styled from "@emotion/styled";
import { compareIgnoringCase } from "features/offboarding/utils";

interface HorizontalCardSelectableProps {
  name: string;
  subTitle?: string;
  imageUrl: string;
  newPriceText: string;
  previousPriceText: string | null;
  selected: boolean;
  onClick?(event: any): void;
}

const Card = styled.div<{
  selected?: boolean;
}>`
  display: flex;
  flex: 0 100%;
  height: 130px;
  max-width: 300px;
  min-width: 270px;
  border-radius: 10px;
  margin: 20px 40px;

  background: ${(props: any) => props.theme.primary_color};
  font-family: ${(props: any) => props.theme.font};

  border: ${(props: any) =>
    compareIgnoringCase(props.theme.primary_color, "#FFFFFF")
      ? compareIgnoringCase(props.theme.secondary_color, "#FFFFFF")
        ? "1px solid #000"
        : `1px solid ${props.theme.secondary_color}`
      : ""};
  
  :hover {
    cursor: pointer;
    box-shadow: 0 0 34px green;
  }
  box-shadow: ${(props: any) => (props.selected ? "0 0 34px green" : "0")};
`;

const ImageHolder = styled.img`
  border-radius: 10px;
  display:inline-block;
  width: 50%;
`;

const TextContent = styled.div`
  margin: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 50%;
`;

const TitleText = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  font-size: 14px;
  color: ${(props: any) => props.theme.font_color};
`;
const DescriptionText = styled.div`
  display: -webkit-box;
  font-size: 14px;
  color: ${(props: any) => props.theme.font_color};
`;

const HorizontalCardSelectable: React.FunctionComponent<
  HorizontalCardSelectableProps
> = ({ name, subTitle, imageUrl, newPriceText, previousPriceText, onClick, selected }) => {
  return (
    <Card selected={selected} onClick={onClick}>
      <ImageHolder src={imageUrl} alt={`${name} image`} />
      <TextContent>
        <TitleText style={{fontWeight: "bold"}}>{name}</TitleText>
        { subTitle && <TitleText>{subTitle}</TitleText> }
        <div>
          <DescriptionText>
            {previousPriceText && <DescriptionText><s>{previousPriceText}</s></DescriptionText>}
          </DescriptionText>
          <DescriptionText>{newPriceText}</DescriptionText>
        </div>
      </TextContent>
    </Card>
  );
};

export default HorizontalCardSelectable;
