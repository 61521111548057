import React from "react";
import styled from "@emotion/styled";
import AcceptOfferButton from "../../components/AcceptOfferButton";
import { ErrorLogo } from "./ErrorLogo";
import { ThemeAttributeProps } from "../../theme";
import { useTheme } from "emotion-theming";

export interface ErrorScreenProps {
  error_text?: string;
  support_email?: string;
  onClick?(): void;
  is_embedded?: boolean | undefined;
}

interface ErrorScreenFormatProps extends ErrorScreenProps {
  back_home_button: string;
  error_heading: string;
  default_error_description: string;
}

const FullPageWhite = styled.div`
  height: 100%;
  width: 100%;
  text-align: center;
  @media (min-width: 481px) and (max-width: 768px) {
    margin-bottom: 5rem;
  }
`;

const CenterCard = styled.div`
  margin: 5rem auto 1rem;
  border-radius: 20px;
  @media (max-width: 768px) {
    margin: 4rem auto 0;
  }
`;

const CenterGraphic = styled.div`
  width: 20rem;
  max-height: 30vh;
  margin: auto;
  @media (max-width: 768px) {
    max-width: 85%;
  }
`;

const TextWrapper = styled.div`
  width: 40%;
  margin: 5rem auto 2.5rem;
  @media (max-width: 480px) {
    width: 80%;
    margin: 5rem auto 1.5rem;
  }
  @media (min-width: 481px) and (max-width: 768px) {
    width: 80%;
    margin: 10rem auto 1.5rem;
  }
`;

const Title = styled.h1`
  font-family: ${(props: any) => props.theme.font};
  font-style: normal;
  font-weight: 900;
  font-size: 30px;
  line-height: 54px;
  text-align: center;
  color: #080f18;
  @media (max-width: 768px) {
    font-size: 22px;
  }
`;

const Text = styled.div`
  font-family: ${(props: any) => props.theme.font};
  font-style: normal;
  font-weight: 300;
  font-size: 24px;
  line-height: 36px;
  text-align: center;
  color: #000000;
  mix-blend-mode: normal;
  opacity: 0.6;
  @media (max-width: 768px) {
    font-size: 16px;
    line-height: 18px;
  }
`;

const ErrorScreenFormat: React.FC<ErrorScreenFormatProps> = (
  props: ErrorScreenFormatProps
) => {
  const emailText = props.support_email ? (
    <>
      <br />
      <a href={"mailto:" + props.support_email}>{props.support_email}</a>
    </>
  ) : (
    <></>
  );
  return (
    <FullPageWhite>
      <CenterCard>
        <CenterGraphic>
          <ErrorLogo />
        </CenterGraphic>
      </CenterCard>
      <TextWrapper>
        <Title>{props.error_heading}</Title>
        <Text>
          {props.error_text ? (
            <div dangerouslySetInnerHTML={{ __html: props.error_text }} />
          ) : (
            <>
              {props.default_error_description}
              {emailText}
            </>
          )}
        </Text>
      </TextWrapper>
      {props.is_embedded ? undefined : (
        <AcceptOfferButton
          buttonText={props.back_home_button}
          onClick={props.onClick}
        />
      )}
    </FullPageWhite>
  );
};

export const ErrorScreen: React.FC<ErrorScreenProps> = (props) => {
  const theme: ThemeAttributeProps = useTheme();
  return (
    <ErrorScreenFormat
      error_text={props.error_text}
      onClick={() => (window.location.href = theme.website_url)}
      support_email={theme.support_email}
      is_embedded={theme && theme.is_embedded}
      error_heading={theme.error_heading}
      default_error_description={theme.default_error_description}
      back_home_button={theme.back_home_button}
    />
  );
};
