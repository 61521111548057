import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { generatePath, useHistory } from "react-router-dom";

// components
import PanelHeader from "components/wizard/PanelHeader";
import { EXPERIENCE_URLS } from "../slice/urls";

import {
  availableTreatmentsSelector,
  currentEngineSlugSelector,
  treatmentsArchivedSlugsSelector,
  treatmentsNotArchivedSlugsSelector,
  unarchiveTreatment,
  updateTreatment,
} from "../slice/wizardSlice";

// icons
import UnarchiveIcon from "@material-ui/icons/UnarchiveOutlined";
import Duplicate from "@material-ui/icons/FileCopyOutlined";

// images
import EditNote from "../img/editNoteIcon.svg";

// Material UI
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Switch from "@material-ui/core/Switch";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Tooltip from "@material-ui/core/Tooltip";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import IconButton from "@material-ui/core/IconButton";

// styles
import { makeStyles } from "@material-ui/core/styles";
import commonStyles from "components/layout/commonStyles";
import wizardStyles from "../wizardStyles";

const useStyles = makeStyles((theme) => ({
  ...commonStyles(theme),
  ...wizardStyles(theme),
}));

export default function TreatmentLibraryPanel() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const treatments = useSelector(availableTreatmentsSelector);
  const archived = useSelector(treatmentsArchivedSlugsSelector);
  const notArchived = useSelector(treatmentsNotArchivedSlugsSelector);
  const history = useHistory();

  const [showArchived, setShowArchived] = useState(false);

  const currentEngineSlug = useSelector(currentEngineSlugSelector);
  const isUpdatingTreatment = useSelector(
    updateTreatment.isFetchingSelector
  ) as boolean;

  return (
    <div className={classes.mdPadding}>
      <PanelHeader
        title="Treatment Library"
        caption="All Available Treatments"
      />

      <Divider className={classes.headingDivider} />
      <Typography variant="h6" className={classes.purpleGradient}>
        Displayed Treatment Options
      </Typography>
      <Typography variant="body2" className={classes.gray}>
        You can choose which treatment options are active.
      </Typography>
      <List aria-label="not archived treatments">
        {notArchived.map((slug: string) => (
          <ListItem className={classes.zeroPadding} key={slug}>
            <ListItemIcon>
              <Tooltip
                title={
                  treatments[slug].active
                    ? "Deactivate This Treatment"
                    : "Activate This Treatment"
                }
                placement="left"
              >
                <Checkbox
                  checked={treatments[slug].active}
                  name={treatments[slug].name}
                  disabled={isUpdatingTreatment}
                  onChange={() =>
                    !isUpdatingTreatment &&
                    dispatch(
                      updateTreatment.Action({
                        slug,
                        offboard_slug: currentEngineSlug,
                        active: !treatments[slug].active,
                      })
                    )
                  }
                />
              </Tooltip>
            </ListItemIcon>
            <ListItemText
              primary={treatments[slug].name}
              style={{ paddingRight: 100 }}
            />
            <ListItemSecondaryAction>
              <Tooltip title="Duplicate Treatment" placement="top" arrow>
                <IconButton
                  onClick={() =>
                    history.push(
                      generatePath(EXPERIENCE_URLS.duplicate_treatment, {
                        engine: currentEngineSlug,
                        duplicate: slug,
                      })
                    )
                  }
                >
                  <Duplicate />
                </IconButton>
              </Tooltip>
              <Tooltip title="Edit This Treatment" placement="top" arrow>
                <IconButton
                  onClick={() =>
                    history.push(
                      generatePath(EXPERIENCE_URLS.edit_treatment, {
                        engine: currentEngineSlug,
                        slug: slug,
                      })
                    )
                  }
                >
                  <img src={EditNote} alt="edit_treatment" />
                </IconButton>
              </Tooltip>
            </ListItemSecondaryAction>
          </ListItem>
        ))}
      </List>
      <br />
      {archived.length ? (
        <FormControlLabel
          control={
            <Switch
              checked={showArchived}
              onChange={() => setShowArchived(!showArchived)}
              name="show_archived"
            />
          }
          label="Show Archived"
        />
      ) : null}

      {showArchived ? (
        <List aria-label="archived treatments">
          {archived.map((slug: string) => (
            <ListItem key={slug}>
              <ListItemIcon
                onClick={() =>
                  !isUpdatingTreatment &&
                  dispatch(
                    unarchiveTreatment.Action({
                      slug,
                      engine: currentEngineSlug,
                      archived: false,
                    })
                  )
                }
              >
                <Tooltip
                  title="Unarchive This Treatment"
                  placement="right"
                  arrow
                >
                  <UnarchiveIcon className={classes.hoverPointer} />
                </Tooltip>
              </ListItemIcon>
              <ListItemText primary={treatments[slug].name} />
            </ListItem>
          ))}
        </List>
      ) : null}
    </div>
  );
}
