import {ChartProps} from "./chartProps";
import {useOrdinalColorScale} from "@nivo/colors";
import {OrdinalColorScaleConfig} from "@nivo/colors/dist/types/ordinalColorScale";
import {ResponsiveSankey} from "@nivo/sankey";

type BarItem = { [key: string]: number };
export type BarItems = { [key: string]: BarItem };

export interface SankeyChartProps extends ChartProps {
    type: "sankey";
    data: BarItems;
    sourceOrder?: string[];
    targetOrder?: string[];
    sourceColors?: OrdinalColorScaleConfig;
    targetColors?: OrdinalColorScaleConfig;
    limit?: number;
    // yFmt?: (y: number) => string;
}

export const SankeyChart = (props: SankeyChartProps) => {
    let defaultColorConfig = (props.colors || {scheme: "category10"}) as OrdinalColorScaleConfig;

    // let yFmt = (props.yFmt !== undefined) ? props.yFmt : (v: number) => v.toString();

    // Sets colors
    let sourceColorConfig: OrdinalColorScaleConfig = props.sourceColors || defaultColorConfig;
    let targetColorConfig: OrdinalColorScaleConfig = props.targetColors || defaultColorConfig;
    let sourceColors = useOrdinalColorScale(sourceColorConfig, "id");
    let targetColors = useOrdinalColorScale(targetColorConfig, "id");

    // Prepares for ordering
    let barSizes: { [key: string]: number } = {};  // AKA sourceSizes
    let barItemSizes: { [key: string]: number } = {};  // AKA targetSizes
    Object.entries(props.data).forEach(([source, bar]) => {
        barSizes[source] = 0;
        Object.entries(bar).forEach(([key, barItemSize]) => {
            barSizes[source] += barItemSize;
            if (barItemSizes[key] === undefined)
                barItemSizes[key] = barItemSize;
            else
                barItemSizes[key] += barItemSize;
        });
    });

    // Creates nodes and links
    let nodes: {id: string, nodeColor: string}[] = [];
    let links: { source: string, target: string, value: number }[] = [];
    let sourceIds = new Set(),
        targetIds = new Set();
    Object.entries(props.data)
        // Orders by props.sourceOrder, if defined, else by total size
        .sort(([source1], [source2]) => (
            (props.sourceOrder === undefined)?
                barSizes[source2] - barSizes[source1]
                : props.sourceOrder.indexOf(source1) - props.sourceOrder.indexOf(source2)
        ))
        // Limit to top 10
        .filter((value, idx) => idx < 10)
        .forEach((([source, barItem]) => {
            // Creates source node
            sourceIds.add(source);
            let sourceNode = nodes.find(_node => _node.id === source);
            if (sourceNode === undefined)
                nodes = [...nodes, {id: source, nodeColor: sourceColors({id: source})}];

            Object.entries(barItem)
                // Orders by props.targetOrder, if defined, else by total size
                .sort(([target1], [target2]) => (
                    (props.targetOrder === undefined)?
                        barItemSizes[target2] - barItemSizes[target1]
                        : props.targetOrder.indexOf(target1) - props.targetOrder.indexOf(target2)
                ))
                .forEach(([target, value]) => {
                    // Creates link
                    links = [...links, {source: source, target: target, value: value}]

                    // Creates Target Node
                    targetIds.add(target);
                    let targetNode = nodes.find(_node => _node.id === target);
                    if (targetNode === undefined)
                        nodes = [...nodes, {id: target, nodeColor: targetColors({id: target})}];
                });
        }));

    return (
        <ResponsiveSankey
            data={{
                nodes: nodes,
                links: links,
            }}
            margin={{top: 5, right: 0, bottom: 5, left: 0}}
            colors={(node: {nodeColor: string}) => node.nodeColor}
            sort="input"
            nodeHoverOthersOpacity={0.05}
            linkHoverOthersOpacity={0.05}
            enableLinkGradient
        />
    )
}