import React from "react";
import styled from "@emotion/styled";
import { compareIgnoringCase } from "features/offboarding/utils";

interface AcceptOfferButtonProps {
  buttonText?: string;
  onClick?(event: any): void;
}

const AcceptOfferButtonWrapper = styled.div`
  max-height: 200px;
  max-width: 280px;
  min-height: 20px;
  min-width: 140px;
  margin: auto;
  padding: 10px;
  text-align: center;
  text-overflow: ellipses;

  overflow-y: hidden;

  @media (max-width: 768px) {
    width: 200px;
    font-size: 16px;
    width: 15rem;
    margin-top: 12px;
  }

  @media (max-height: 620px) {
    margin-bottom: 4rem;
  }

  background: ${(props: any) => props.theme.primary_color};
  font-family: ${(props: any) => props.theme.font};
  font-style: normal;
  display: inline-block;
  border: ${(props: any) =>
    compareIgnoringCase(props.theme.primary_color, "#FFFFFF")
      ? compareIgnoringCase(props.theme.secondary_color, "#FFFFFF")
        ? "1px solid #000"
        : `1px solid ${props.theme.secondary_color}`
      : ""};

  color: ${(props: any) => props.theme.font_color};
  border-radius: 10px;

  :hover {
    background: ${(props: any) => props.theme.secondary_color};
    color: ${(props: any) => props.theme.secondary_font_color};
    cursor: pointer;

    div {
      // this div is the inner part of the button and should also change on hover
      background: ${(props: any) => props.theme.secondary_color};
      color: ${(props: any) => props.theme.secondary_font_color};
    }
  }

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const AcceptOfferButton: React.FunctionComponent<AcceptOfferButtonProps> = (
  props
) => {
  return (
    <AcceptOfferButtonWrapper
      onClick={props.onClick}
      className="accept-button"
      role="button"
    >
      {props.buttonText || "Accept Offer"}
    </AcceptOfferButtonWrapper>
  );
};

export default AcceptOfferButton;
