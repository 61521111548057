import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";

import {
  closePreview,
  currentEngineSlugSelector,
  displayDataSelector,
  engineStyleSelector,
  getEngineStyle,
  openPreviewStep,
  updateEngineStyle,
} from "../slice/wizardSlice";

// Material UI
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import FormGroup from "@material-ui/core/FormGroup";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";

// components
import { EngineStyle } from "../slice/types";
import Loader from "components/loading/Loader";
import { mergeErrors } from "../../../app/forms";
import { States } from "features/offboarding/containers/StateViewRouter/StateViewRouter";
import PanelHeader from "components/wizard/PanelHeader";

// styles
import { makeStyles } from "@material-ui/core/styles";
import commonStyles from "../../../components/layout/commonStyles";
import wizardStyles from "../wizardStyles";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  ...commonStyles(theme),
  ...wizardStyles(theme),
}));

export default function AnnouncementBannerPanel() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const style = useSelector(engineStyleSelector);
  const currentEngineSlug = useSelector(currentEngineSlugSelector);
  const ready = useSelector(getEngineStyle.isReadySelector);
  const backendErrors = useSelector(updateEngineStyle.ExtraErrorsSelector);
  const inPreview = useSelector(displayDataSelector).showPreviewStep;
  const isFetchingUpdateStyle = useSelector(
    updateEngineStyle.isFetchingSelector
  );

  const {
    handleSubmit,
    control,
    formState: { errors: frontendErrors },
  } = useForm();

  const [banner, setBanner] = useState(style.announcement_banner_active);

  const onSubmit = handleSubmit((data) => {
    dispatch(
      updateEngineStyle.Action({
        ...data,
        announcement_banner_active: banner,
        slug: currentEngineSlug,
      } as EngineStyle)
    );
  });

  const errors = mergeErrors(frontendErrors, backendErrors);

  useEffect(() => {
    dispatch(getEngineStyle.Action(currentEngineSlug));
  }, [dispatch, currentEngineSlug]);

  useEffect(() => {
    if (currentEngineSlug) {
      dispatch(
        openPreviewStep({
          step: States.REASONS,
        })
      );
    }
  }, [dispatch, currentEngineSlug]);

  if (!ready) return <Loader padding reason="Fetching engine styles..." />;

  return (
    <div className={classes.mdPadding}>
      <form onSubmit={onSubmit}>
        <PanelHeader
          title="Announcement Banner"
          caption="Settings"
          onClick={() =>
            dispatch(
              inPreview
                ? closePreview()
                : openPreviewStep({ step: States.REASONS })
            )
          }
        />

        <Divider className={classes.headingDivider} />
        <FormControlLabel
          control={
            <Switch
              checked={banner}
              name="announcement_banner_active"
              onChange={() => setBanner(!banner)}
            />
          }
          labelPlacement="start"
          label="Announcement Banner"
          className={classes.zeroMargin}
        />
        <Typography variant="body2" className={classes.gray}>
          Make special announcements to your customers with a banner fixed at
          the top of your cancellation experience. Displays on the Exit Survey
          screen only.
        </Typography>
        <FormGroup>
          <Controller
            control={control}
            name="announcement_banner_text"
            defaultValue={style.announcement_banner_text}
            render={({ field }) => (
              <TextField
                {...field}
                InputLabelProps={{ shrink: true }}
                label="300 CHARACTERS MAX"
                variant="outlined"
                disabled={!banner}
                error={Boolean(errors.get("announcement_banner_text"))}
                helperText={errors.get("announcement_banner_text")}
              />
            )}
          />
        </FormGroup>

        <Button
          type="submit"
          className={clsx(classes.panelButton, classes.marginTop)}
          disabled={isFetchingUpdateStyle}
        >
          Save Changes
        </Button>
      </form>
    </div>
  );
}
