import { useEffect } from "react";
import { mergeErrors } from "app/forms";
import { useHistory } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";

// Material UI
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import FormGroup from "@material-ui/core/FormGroup";

// components
import { clearFormState, createClient, userSelector } from "../user/userSlice";
import TransparentTextField from "../../components/input/TransparentTextField";
import ExternalLayout from "../../components/layout/external/ExternalLayout";
import { SETUP_URLS } from "../wizard/slice/urls";

// images
import Sprinkles from "./img/sprinklesGraphic.svg";

// styles
import styles from "./onboardingStyles";
import { makeStyles } from "@material-ui/core/styles";
import commonStyles from "components/layout/commonStyles";

const useStyles = makeStyles((theme) => ({
  ...styles(theme),
  ...commonStyles(theme),
}));

export default function CompanySignUp() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const { auth } = useSelector(userSelector);

  const {
    handleSubmit,
    control,
    formState: { errors: frontendErrors },
  } = useForm();

  const { isSuccess, isError, errorMessage, backendFieldErrors } =
    useSelector(userSelector);

  const errors = mergeErrors(frontendErrors, backendFieldErrors);

  const onSubmit = handleSubmit(({ organization_name }) =>
    dispatch(createClient({ organization_name }))
  );

  // after ajax response
  useEffect(() => {
    if (isSuccess || auth.user?.client !== null) {
      dispatch(clearFormState());
      history.push(SETUP_URLS.platform_options);
    }
  }, [isSuccess, isError, dispatch, history]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <div
        className={classes.backgroundSprinkles}
        style={{ backgroundImage: `url(${Sprinkles})` }}
      />
      <ExternalLayout footerFull>
        <Grid container justifyContent="center">
          <div className={classes.radialGradient} />
          <Grid item xs={8} md={6} lg={4}>
            <form onSubmit={onSubmit} method="POST">
              <Card>
                <Typography variant="h1">
                  Tell us the name of your company
                </Typography>
                <FormGroup className={classes.marginTop}>
                  <Controller
                    control={control}
                    name="organization_name"
                    defaultValue=""
                    rules={{ required: true }}
                    render={({ field }) => (
                      <TransparentTextField
                        {...field}
                        error={Boolean(errors.get("organization_name"))}
                        helperText={errors.get("organization_name")}
                        label="COMPANY NAME"
                        variant="outlined"
                      />
                    )}
                  />
                </FormGroup>
                {isError ? (
                  <div className={classes.errorMessage}>{errorMessage}</div>
                ) : null}
                <Button color="primary" type="submit" variant="contained">
                  Create
                </Button>
              </Card>
            </form>
          </Grid>
        </Grid>
      </ExternalLayout>
    </>
  );
}
