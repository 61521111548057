import { FC } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { mergeErrors } from "app/forms";

// components
import { updateEngine } from "features/wizard/slice/wizardSlice";

import {
  OffboardEngine,
  UpdateEngineFields,
} from "features/wizard/slice/types";

// Material UI
import Typography from "@material-ui/core/Typography";
import FormGroup from "@material-ui/core/FormGroup";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";

// styles
import { makeStyles } from "@material-ui/core/styles";
import commonStyles from "components/layout/commonStyles";
import wizardStyles from "features/wizard/wizardStyles";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  ...commonStyles(theme),
  ...wizardStyles(theme),
}));

interface NameAndWebsiteChangeFormProps {
  currentEngine: OffboardEngine;
}

export const NameAndWebsiteChangeForm: FC<NameAndWebsiteChangeFormProps> = ({
  currentEngine,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const isFetchingUpdateEngine = useSelector(updateEngine.isFetchingSelector);

  const {
    handleSubmit,
    control,
    formState: { errors: frontendErrors },
  } = useForm();

  const onSubmit = handleSubmit((data) => {
    dispatch(
      updateEngine.Action({
        ...data,
        slug: currentEngine.slug,
      } as UpdateEngineFields)
    );
  });

  const errors = mergeErrors(
    frontendErrors,
    useSelector(updateEngine.ExtraErrorsSelector)
  );

  return (
    <form onSubmit={onSubmit}>
      <Typography
        variant="h6"
        className={clsx(classes.purpleGradient, classes.marginTop)}
      >
        RetentionEngine Name*
      </Typography>
      <Typography variant="body2" className={classes.gray}>
        Name used to internally identify your RetentionEngine.
      </Typography>
      <FormGroup>
        <Controller
          control={control}
          name="name"
          rules={{ required: true }}
          defaultValue={currentEngine.name}
          render={({ field }) => (
            <TextField
              {...field}
              InputLabelProps={{ shrink: true }}
              label="255 CHARACTERS MAX"
              variant="outlined"
              error={Boolean(errors.get("name"))}
              helperText={errors.get("name")}
            />
          )}
        />
      </FormGroup>
      <Typography variant="h6" className={clsx(classes.purpleGradient)}>
        Website URL*
      </Typography>
      <Typography variant="body2" className={classes.gray}>
        This is where your customers will be redirected to at the end of their
        cancellation experience.
      </Typography>
      <FormGroup>
        <Controller
          control={control}
          name="website_url"
          rules={{ required: true }}
          defaultValue={currentEngine.website_url}
          render={({ field }) => (
            <TextField
              {...field}
              InputLabelProps={{ shrink: true }}
              label="255 CHARACTERS MAX"
              variant="outlined"
              error={Boolean(errors.get("website_url"))}
              helperText={errors.get("website_url")}
              type="url"
            />
          )}
        />
      </FormGroup>
      <Button
        type="submit"
        className={classes.panelButton}
        disabled={isFetchingUpdateEngine}
      >
        Save Name and Website
      </Button>
    </form>
  );
};

export default NameAndWebsiteChangeForm;
