import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";

// components
import Loader from "../../../components/loading/Loader";
import { mergeErrors } from "../../../app/forms";
import { States } from "../../offboarding/containers/StateViewRouter/StateViewRouter";
import PanelHeader from "components/wizard/PanelHeader";
import FormSectionHeader from "components/wizard/FormSectionHeader";

import {
  currentEngineSlugSelector,
  openPreviewStep,
  displayDataSelector,
  closePreview,
  updateEngineStyle,
  engineStyleSelector,
  getEngineStyle,
} from "../slice/wizardSlice";

// Material UI
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import FormGroup from "@material-ui/core/FormGroup";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";

// styles
import { makeStyles } from "@material-ui/core/styles";
import commonStyles from "../../../components/layout/commonStyles";
import wizardStyles from "../wizardStyles";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  ...commonStyles(theme),
  ...wizardStyles(theme),
}));

const ExperienceTextsPanel = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const currentEngineSlug = useSelector(currentEngineSlugSelector);
  const inPreview = useSelector(displayDataSelector).showPreviewStep;
  const additionalCommentsPreview =
    inPreview && inPreview.step === States.ADDITIONAL_COMMENTS;

  const inErrorPreview = inPreview && inPreview.step === States.INVALID;
  const style = useSelector(engineStyleSelector);
  const isFetchingStyle = useSelector(updateEngineStyle.isFetchingSelector);
  const styleReady = useSelector(getEngineStyle.isReadySelector);

  const {
    handleSubmit,
    control,
    formState: { errors: frontendErrors },
  } = useForm();

  const errors = mergeErrors(
    frontendErrors,
    useSelector(updateEngineStyle.ExtraErrorsSelector)
  );

  const onSubmit = handleSubmit((data: any) => {
    dispatch(
      updateEngineStyle.Action({
        ...data,
        slug: currentEngineSlug,
      })
    );
  });

  useEffect(() => {
    if (currentEngineSlug) {
      dispatch(getEngineStyle.Action(currentEngineSlug));
      dispatch(
        openPreviewStep({
          step: States.ADDITIONAL_COMMENTS,
        })
      );
    }
  }, [dispatch, currentEngineSlug]);

  if (!styleReady)
    return <Loader padding reason="Fetching experience data..." />;

  return (
    <div className={classes.mdPadding}>
      <PanelHeader
        title="Experience Texts"
        caption="Editable texts that appear on multiple pages in the experience"
        onClick={() =>
          dispatch(
            additionalCommentsPreview
              ? closePreview()
              : openPreviewStep({ step: States.ADDITIONAL_COMMENTS })
          )
        }
        headerInPreview={additionalCommentsPreview}
      />

      <Divider className={classes.headingDivider} />
      <form onSubmit={onSubmit}>
        <Typography variant="h6" className={classes.purpleGradient}>
          Button Texts
        </Typography>
        <Typography variant="body2" className={classes.gray}>
          These appear on the various buttons within the experience.
        </Typography>
        <FormGroup>
          <Controller
            control={control}
            name="go_back_button"
            defaultValue={style.go_back_button}
            render={({ field }) => (
              <TextField
                {...field}
                InputLabelProps={{ shrink: true }}
                label="GO BACK"
                variant="outlined"
                error={Boolean(errors.get("go_back_button"))}
                helperText={errors.get("go_back_button")}
              />
            )}
          />
          <Controller
            control={control}
            name="continue_to_cancel"
            defaultValue={style.continue_to_cancel}
            render={({ field }) => (
              <TextField
                {...field}
                InputLabelProps={{ shrink: true }}
                label="CONTINUE TO CANCEL"
                variant="outlined"
                error={Boolean(errors.get("continue_to_cancel"))}
                helperText={errors.get("continue_to_cancel")}
              />
            )}
          />
          <Controller
            control={control}
            name="submit_button"
            defaultValue={style.submit_button}
            render={({ field }) => (
              <TextField
                {...field}
                InputLabelProps={{ shrink: true }}
                label="SUBMIT"
                variant="outlined"
                error={Boolean(errors.get("submit_button"))}
                helperText={errors.get("submit_button")}
              />
            )}
          />
        </FormGroup>
        <Button
          type="submit"
          className={clsx(classes.panelButton, classes.marginTop)}
          disabled={isFetchingStyle}
        >
          Save Changes
        </Button>
      </form>

      <Divider className={classes.headingDivider} />
      <form onSubmit={onSubmit}>
        <FormSectionHeader
          title="Error Page Text"
          showPreview
          headerInPreview={inErrorPreview}
          previewStep={States.INVALID}
        />
        <Typography variant="body2" className={classes.gray}>
          Texts that appear when an error occurs during an experience.
        </Typography>
        <FormGroup>
          <Controller
            control={control}
            name="error_heading"
            defaultValue={style.error_heading}
            render={({ field }) => (
              <TextField
                {...field}
                InputLabelProps={{ shrink: true }}
                label="ERROR PAGE HEADING"
                variant="outlined"
                error={Boolean(errors.get("error_heading"))}
                helperText={errors.get("error_heading")}
              />
            )}
          />
          <Controller
            control={control}
            name="default_error_description"
            defaultValue={style.default_error_description}
            render={({ field }) => (
              <TextField
                {...field}
                InputLabelProps={{ shrink: true }}
                label="DEFAULT ERROR DESCRIPTION"
                variant="outlined"
                error={Boolean(errors.get("default_error_description"))}
                helperText={errors.get("default_error_description")}
              />
            )}
          />
          <Controller
            control={control}
            name="already_cancelled_description"
            defaultValue={style.already_cancelled_description}
            render={({ field }) => (
              <TextField
                {...field}
                InputLabelProps={{ shrink: true }}
                label="SUBSCRIPTION ALREADY CANCELLED TEXT"
                variant="outlined"
                error={Boolean(errors.get("already_cancelled_description"))}
                helperText={errors.get("already_cancelled_description")}
              />
            )}
          />
        </FormGroup>
        <Button
          type="submit"
          className={clsx(classes.panelButton, classes.marginTop)}
          disabled={isFetchingStyle}
        >
          Save Changes
        </Button>
      </form>

      <Divider className={classes.headingDivider} />
      <form onSubmit={onSubmit}>
        <FormSectionHeader title="Embedded Experience Refresh Page Text" />
        <Typography variant="body2" className={classes.gray}>
          Text that appears at the end of an embedded experience telling a user
          to refresh the page in order to see changes.
        </Typography>
        <FormGroup>
          <Controller
            control={control}
            name="embedded_experience_refresh_page_text"
            defaultValue={style.embedded_experience_refresh_page_text}
            render={({ field }) => (
              <TextField
                {...field}
                InputLabelProps={{ shrink: true }}
                label="Refresh Page Text"
                variant="outlined"
                error={Boolean(
                  errors.get("embedded_experience_refresh_page_text")
                )}
                helperText={errors.get("embedded_experience_refresh_page_text")}
              />
            )}
          />
        </FormGroup>
        <Button
          type="submit"
          className={clsx(classes.panelButton, classes.marginTop)}
          disabled={isFetchingStyle}
        >
          Save Changes
        </Button>
      </form>
    </div>
  );
};
export default ExperienceTextsPanel;
