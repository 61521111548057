import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { clientSelector, userSelector } from "../../features/user/userSlice";

// Material UI
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";

// images
import Logo from "components/logo/REMagnetLogo.svg";

// styles
import styles from "./headerStyles";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import { ONBOARDING_URLS } from "../../features/wizard/slice/urls";

const useStyles = makeStyles(styles);

export default function PublicNavBar() {
  const classes = useStyles();
  const history = useHistory();

  const { loggedIn } = useSelector(userSelector);
  const client = useSelector(clientSelector);
  const nextDestination = loggedIn
    ? client?.organization_name
      ? "/dashboard"
      : ONBOARDING_URLS.company_signup
    : "/";

  let cornerContent;
  if (loggedIn) {
    cornerContent = (
      <Button
        variant="contained"
        color="secondary"
        onClick={() => history.push("/logout")}
      >
        Log Out
      </Button>
    );
  } else {
    cornerContent = (
      <Button
        variant="contained"
        color="secondary"
        onClick={() => history.push("/login")}
      >
        Login
      </Button>
    );
  }

  return (
    <div className={classes.root}>
      <AppBar position="static" className={classes.appBar}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={() => history.push(nextDestination)}
          >
            <img src={Logo} alt="logo" />
          </IconButton>
          <Typography
            variant="h6"
            className={clsx(classes.title, classes.grow)}
          >
            <span className={classes.headingMain}>Retention</span>
            <span className={classes.headingSub}>Engine</span>
          </Typography>

          {cornerContent}
        </Toolbar>
      </AppBar>
    </div>
  );
}
