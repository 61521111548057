import React from "react";

// Material UI
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import Typography from "@material-ui/core/Typography";

// styles
import { makeStyles } from "@material-ui/core/styles";
import commonStyles from "components/layout/commonStyles";
import wizardStyles from "features/wizard/wizardStyles";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  ...commonStyles(theme),
  ...wizardStyles(theme),
}));

type SecondaryTreeCardProps = {
  description: string;
  active?: boolean;
  selected?: boolean;
  leftIcon?: JSX.Element;
  rightIcon?: JSX.Element;
  onClickSelect?: CallableFunction;
};

export const SecondaryTreeCard: React.FC<SecondaryTreeCardProps> = ({
  description,
  active,
  selected,
  leftIcon,
  rightIcon,
  onClickSelect,
}) => {
  const classes = useStyles();

  return (
    <Card
      className={
        selected
          ? clsx(classes.secondaryTreeCard, classes.selectedOptionCard)
          : classes.secondaryTreeCard
      }
      onClick={onClickSelect ? () => onClickSelect() : undefined}
    >
      <Grid container alignItems="center" spacing={1}>
        <Grid item xs={1}>
          {leftIcon}
        </Grid>
        <Grid item xs={10}>
          <div className={classes.secondaryFontWrapper}>
          <Typography
            variant="body2"
            className={
              !active
                ? clsx(classes.midGray, classes.alignLeft)
                : classes.alignLeft
            }
          >
            {description}
          </Typography>
          </div>
        </Grid>
        <Grid item xs={1}>
          {rightIcon}
        </Grid>
      </Grid>
    </Card>
  );
};

export default SecondaryTreeCard;
