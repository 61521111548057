import { useSelector } from "react-redux";

// Material UI
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import Typography from "@material-ui/core/Typography";

//components
import DashboardNavBar from "components/dashboard/DashboardNavBar";
import { TopOffersPie } from "components/dashboard/TopOffersPie";
import { TopPausesPie } from "components/dashboard/TopPausesPie";
import TopOffersTable from "components/dashboard/TopOffersTable";
import TopPausesTable from "components/dashboard/TopPausesTable";
import Loader from "components/loading/Loader";

import {
  dashboardStatsDataSelector,
  getDashboardStatistics,
} from "features/wizard/slice/wizardSlice";

// styles
import styles from "./dashboardStyles";
import { makeStyles } from "@material-ui/core/styles";
import commonStyles from "components/layout/commonStyles";
import { ThemeProvider } from "@material-ui/core";
import { darkTheme, lightTheme } from "MUITheme";
import {BarItems, HorizontalBarChart} from "components/graphs/HorizontalBarChart";
import React, {useCallback, useMemo} from "react";
import {ExitSurveyData, TopIntermediateStatistics} from "../wizard/slice/types";
import {SankeyChart} from "components/graphs/SankeyChart";
import {PctCompactFmt} from "../wizard/utils/Format";
import graphStyles from "../../components/graphs/card/graphStyles";

const useStyles = makeStyles((theme) => ({
  ...commonStyles(theme),
  ...styles(theme),
  ...graphStyles(theme),
}));

type t_strfmt = (str: string) => string | undefined;

export default function ExitSurveyDash() {
  const classes = useStyles();

  // Loads up the Exit Survey Data
  const exitSurveyData = useSelector(
    dashboardStatsDataSelector
  ).exit_survey as ExitSurveyData;

  // Used to load data for the sankey and bar graphs
  let loadBarData = useCallback((cat_1: string, cat_2: string, cat_1_fmt?: t_strfmt, cat_2_fmt?: t_strfmt) => {
    if (exitSurveyData === undefined) return {};

    // The params cat_1 and cat_2 may not be in the proper order as cat_stats.cat_1,
    // cat_stats.cat_2, so if we find they are reversed, we'll need to flip their values
    // in `barItems` (below) as well.
    let reversed = false;
    let catStats = exitSurveyData.cat_overlap.find(
      (cat_stats) => {
        // Proper order
        if ((cat_stats.cat_1 === cat_1) && (cat_stats.cat_2 === cat_2))
          return true;
        // Reversed order
        if ((cat_stats.cat_2 === cat_1) && (cat_stats.cat_1 === cat_2)) {
          reversed = true;
          return true;
        }
        // Not found
        return false;
      }
    )
    if (catStats === undefined) return {};

    // Preps the data to be in a format accepted by the graphs
    let barItems: BarItems = {};
    catStats.val_stats.forEach((val_stats) => {
      // Gets each value's name
      let v1_name = (!reversed)? val_stats.cat_val_1: val_stats.cat_val_2;
      let v2_name = (!reversed)? val_stats.cat_val_2: val_stats.cat_val_1;
      // Formats the string if specified
      v1_name = ((cat_1_fmt === undefined)? v1_name : cat_1_fmt(v1_name)) as string;
      v2_name = ((cat_2_fmt === undefined)? v2_name : cat_2_fmt(v2_name)) as string;
      // Creates the barItem if missing in barItems for v1_name
      barItems[v1_name] = (barItems[v1_name] || {});
      // Sets the number of experiences shared between the category values
      barItems[v1_name][v2_name] = val_stats.n_unique_experiences;
    });

    return barItems;
  }, [exitSurveyData])

  // Used to load data for the pie charts
  let loadPieData = useCallback((cat_name: string) => {
    if (exitSurveyData === undefined) return [];

    // Gets the overall data associated with the category
    let catData = exitSurveyData.overall.cat[cat_name];
    if (catData === undefined) return [];

    // Preps the data in a format used by the pie charts
    let data: TopIntermediateStatistics[] = [];
    Object.entries(catData).forEach(([cat_val, stats]) => {
      // Appends a data point
      data = [...data, {
        name: cat_val,
        shown: stats.n_unique_experiences.sum,
        selected: stats.n_saved_by_this.sum,
        percent_selected: PctCompactFmt(stats.n_saved_by_this.sum / stats.n_unique_experiences.sum),
      }]
    })

    return data;
  }, [exitSurveyData]);

  // *** Save Data *** //
  let responseSaveBarData = useMemo(() => {
    return loadBarData(
      "reason_text",
      "state",
      undefined,
      (str) => (
        ((str === "ABANDONED") || (str === "SAVED"))? "Saved" : "Lost"
      ),
    );
  }, [loadBarData]);

  // *** Product Data *** //
  let productResponseBarData = useMemo(() => {
    return loadBarData("plan_before", "reason_text");
  }, [loadBarData]);

  // *** Pie Chart Data *** //
  let cancelTreatmentPerformanceData = useMemo(() => {
    return loadPieData("chosen_offer_name");
  }, [loadPieData]);

  let pauseOptionPerformanceData = useMemo(() => {
    return loadPieData("shown_pauses_names");
  }, [loadPieData]);

  const isLoading = useSelector(getDashboardStatistics.isFetchingSelector);

  if (isLoading)
    return (
      <div style={{ margin: "0 3em" }}>
        <ThemeProvider theme={darkTheme}>
          <DashboardNavBar />
        </ThemeProvider>
        <ThemeProvider theme={lightTheme}>
          <Loader reason="Fetching Exit Survey Statistics" />
        </ThemeProvider>
      </div>
    );
  if ((exitSurveyData === undefined) || (exitSurveyData.overall.stats.n_all_experiences.sum === 0))
    return (
      <div style={{ margin: "0 3em" }}>
        <ThemeProvider theme={darkTheme}>
          <DashboardNavBar />
        </ThemeProvider>
        <ThemeProvider theme={lightTheme}>
            <div
              style={{
                width: "100%",
                textAlign: "center",
              }}
            >
                <Typography variant="h6" className={classes.purpleGradient}>
                  No Data Found. Try another Date Range.
                </Typography>
            </div>
        </ThemeProvider>
      </div>
    );

  return (
    <div style={{ margin: "0 3em" }}>
      <ThemeProvider theme={darkTheme}>
        <DashboardNavBar />
      </ThemeProvider>
      <ThemeProvider theme={lightTheme}>
        {/* Graph Container */}
        <Grid container direction="row" spacing={3} alignItems="stretch">

          {/* Exit Survey Response */}
          <Grid item xs={12}>
            <Card className={classes.barChartCard}>
              {/* Header */}
              <Typography className={classes.barChartTitle} variant="h5">
                Exit Survey Response
              </Typography>

              {/* Graphs */}
              <Grid container direction="row">
                <Grid item xs={6} className={classes.barChartContainer}>
                  <HorizontalBarChart barItems={responseSaveBarData} order={["Lost", "Saved"]} colors={["#C75A5A", "#5DD47F"]}/>
                </Grid>
                <Grid item xs={6} className={classes.barChartContainer}>
                  <SankeyChart barItems={responseSaveBarData} targetOrder={["Lost", "Saved"]} targetColors={["#C75A5A", "#5DD47F"]}/>
                </Grid>
              </Grid>
            </Card>
          </Grid>

          {/* Product to Response */}
          <Grid item xs={12}>
            <Card className={classes.barChartCard}>
              {/* Header */}
              <Typography className={classes.barChartTitle} variant="h5">
                SKU-Based Churn
              </Typography>

              {/* Graphs */}
              <Grid container direction="row">
                <Grid item xs={6} className={classes.barChartContainer}>
                  <HorizontalBarChart barItems={productResponseBarData}/>
                </Grid>
                <Grid item xs={6} className={classes.barChartContainer}>
                  <SankeyChart barItems={productResponseBarData}/>
                </Grid>
              </Grid>
            </Card>
          </Grid>

          {/* Cancel Treatment Performance */}
          <Grid item xs={6}>
            <Card className={classes.barChartCard}>
              <Typography className={classes.barChartTitle} variant="h5">
                Cancel Treatment Performance
              </Typography>
              <div className={classes.barChartContainer}>
                <TopOffersPie data={cancelTreatmentPerformanceData}/>
              </div>
              <Card className={classes.tableCard}>
                <TopOffersTable data={cancelTreatmentPerformanceData}/>
              </Card>
            </Card>
          </Grid>

          {/* Pause Option Performance */}
          <Grid item xs={6}>
            <Card className={classes.barChartCard}>
              <Typography className={classes.barChartTitle} variant="h5">
                Pause Option Performance
              </Typography>
              <div className={classes.barChartContainer}>
                <TopPausesPie data={pauseOptionPerformanceData}/>
              </div>
              <Card className={classes.tableCard}>
                <TopPausesTable data={pauseOptionPerformanceData}/>
              </Card>
            </Card>
          </Grid>

        </Grid>
      </ThemeProvider>
    </div>
  );
}
