import React from "react";
import { DynamicThemeProvider } from "../../theme";
import OfferView, { OfferViewProps } from "../../views/OfferView/OfferView";
import {
  EndScreenProps,
  EndScreenPaused,
  EndScreenSaved,
  EndScreenCancel,
  EndScreenLost,
  EndScreenSupportPhone,
} from "../../views/EndScreen/EndScreen";
import OtherReasonScreen from "../../views/OtherReasonScreen";

import MultipleProductsSwap, {
  SwapProductInterface,
} from "../../views/MultipleProductsSwap/MultipleProductsSwap";

import ExitSurveyScreen, {
  IReason,
} from "../../views/ExitSurveyScreen/ExitSurveyScreen";
import GetStarted from "../../views/GetStarted";
import ErrorScreen from "../../views/ErrorScreen";
import { ThemeAttributeProps } from "../../theme";
import { useTransition, animated } from "react-spring";
import ErrorBoundary from "../ErrorBoundary";
import PauseScreen, { IPause } from "../../views/PauseScreen/PauseScreen";
import Loader from "../../components/Loader";
import AdditionalComments from "../../views/AdditionalComments";

/*
The StateViewRouter is used to navigate between STATE screens with an animation
In the future, if new STATES are added to the API / data flow, then they need to
be mapped below, so the prop types exist and can be mounted appropriately
*/

export enum States {
  START = "START",
  REASONS = "REASONS",
  ADDITIONAL_COMMENTS = "ADDITIONAL_COMMENTS",
  OTHER_REASON = "OTHER_REASON",
  OFFER = "OFFER",
  PAUSES = "PAUSES",
  CANCEL = "CANCEL",
  CANCEL_AFTER_ERROR = "CANCEL_AFTER_ERROR",
  CANCEL_AFTER_SAVE = "CANCEL_AFTER_SAVE",
  SAVED = "SAVED",
  LOST = "LOST",
  INVALID = "INVALID",
  ALREADY_CANCELLED = "ALREADY_CANCELLED",
  MULTI_PRODUCT_SWAP_EXISTING = "MULTI_PRODUCT_SWAP_EXISTING",
  MULTI_PRODUCT_SWAP_NEW = "MULTI_PRODUCT_SWAP_NEW",
}

export interface MainProps {
  theme?: ThemeAttributeProps; // This is only used to mount theme as props, optional
  state?: States;
  reasons: IReason[];
  reason?: string;
  intermediate?: OfferViewProps;
  pauses?: IPause[];
  saved?: EndScreenProps;
  host?: string;
  endpoint?: string;
  callUpdate(data?: any): void;
  error_text?: string;
  isLoading: boolean;
  stub: string;
  edit_styling_offer_image?: string;
  edit_styling?: boolean;
  single_page_preview?: boolean;
  is_prepaid?: boolean;
  additional_comments_reason_text?: string;
  minHeight?: string;
  existing_swap_products_data?: SwapProductInterface;
  new_swap_products_data?: SwapProductInterface;
}

export interface IViewList {
  [key: string]: any;
}

interface IAnimationFrame {
  key: string;
  item: any;
  props: any;
}

const BaseViewRouter: React.FC<MainProps> = (props: MainProps) => {
  // All but 3 screens have the animation; this is a mapping of the screens that do have the animation
  const VIEWS: IViewList = {
    [States.START]: (
      <GetStarted onGetStartedClicked={() => props.callUpdate("continue")} />
    ),

    [States.REASONS]: (
      <ExitSurveyScreen
        title={(props.theme && props.theme.exit_survey_heading) || ""}
        reasons={props.reasons}
        selected={props.reason}
        onReasonChange={(input: string) => props.callUpdate(input)}
        website_url={props.theme && props.theme.website_url}
        theme={props.theme}
      />
    ),

    [States.ADDITIONAL_COMMENTS]: (
      <AdditionalComments
        callUpdate={props.callUpdate}
        reason_text={props.additional_comments_reason_text}
      />
    ),

    [States.OTHER_REASON]: (
      <OtherReasonScreen
        onReasonChange={(input: string) => props.callUpdate(input)}
      />
    ),

    [States.OFFER]: props.intermediate ? (
      <OfferView
        {...props.intermediate}
        callUpdate={props.callUpdate}
        website_url={props.theme && props.theme.website_url}
      />
    ) : null,

    [States.PAUSES]: props.pauses ? (
      <PauseScreen
        onPauseChange={props.callUpdate}
        title="Did you know you could also pause your account?"
        pauseOptions={props.pauses}
        callUpdate={props.callUpdate}
        pauseDisclaimerText={props.theme && props.theme.pause_disclaimer_text}
        theme={props.theme}
      />
    ) : null,

    [States.CANCEL]: (
      <EndScreenCancel
        callUpdate={props.callUpdate}
        onClick={() => props.callUpdate("continue")}
        single_page_preview={props.single_page_preview}
      />
    ),

    [States.CANCEL_AFTER_SAVE]: (
      <EndScreenCancel
        callUpdate={props.callUpdate}
        onClick={() => props.callUpdate("continue")}
        single_page_preview={props.single_page_preview}
      />
    ),

    [States.CANCEL_AFTER_ERROR]: (
      <EndScreenCancel
        callUpdate={props.callUpdate}
        onClick={() => props.callUpdate("continue")}
        single_page_preview={props.single_page_preview}
        cancel_after_error={true}
      />
    ),

    [States.MULTI_PRODUCT_SWAP_EXISTING]: (
      <MultipleProductsSwap
        callUpdate={props.callUpdate}
        swapProductsData={props.existing_swap_products_data!}
      />
    ),

    [States.MULTI_PRODUCT_SWAP_NEW]: (
      <MultipleProductsSwap
        callUpdate={props.callUpdate}
        swapProductsData={props.new_swap_products_data!}
      />
    ),
  };

  const transitions = useTransition(props.state, (item) => item, {
    from: {
      opacity: 0,
      transform: "scale(0.5) translateY(50%)",
    },
    enter: {
      opacity: 1,
      transform: "scale(1) translateY(0)",
    },
    leave: {
      opacity: 0,
      transform: "scale(0.5) translateY(-50%)",
    },
  });

  if (props.state && props.state in VIEWS) {
    return (
      <>
        {transitions.map((frame: IAnimationFrame) => (
          <animated.div
            key={frame.key}
            style={{
              ...frame.props,
              position: "absolute",
              top: 0,
              width: "100%",
              minHeight: props.minHeight || "100vh",
              display: "flex",
              alignItems: "stretch",
              flexDirection: "column",
            }}
          >
            {VIEWS[frame.item]}
          </animated.div>
        ))}
      </>
    );
  }

  if (
    props.state === States.SAVED &&
    props.saved &&
    props.saved.type?.toLowerCase() === "pause"
  )
    return (
      <EndScreenPaused
        callUpdate={props.callUpdate}
        single_page_preview={props.single_page_preview}
        {...props.saved}
      />
    );

  if (props.state === States.SAVED)
    return (
      <EndScreenSaved
        callUpdate={props.callUpdate}
        single_page_preview={props.single_page_preview}
        {...props.saved}
      />
    );

  if (props.state === States.LOST && props.theme?.contact_support_for_cancel) {
    return <EndScreenSupportPhone />;
  }

  if (props.state === States.LOST) {
    return (
      <EndScreenLost
        single_page_preview={props.single_page_preview}
        is_prepaid={props.is_prepaid}
      />
    );
  }

  // Covers invalid states
  return <ErrorScreen error_text={props.error_text} />;
};

const StateViewRouter: React.FC<MainProps> = (props: MainProps) => {
  const [cssDiv, setCssDiv] = React.useState<HTMLStyleElement | null>(null);

  React.useEffect(() => {
    if (props.theme && cssDiv) {
      let customCSS = "";
      if (props.theme.custom_font_url) customCSS += props.theme.custom_font_url;
      if (props.theme.custom_css) customCSS += props.theme.custom_css;
      cssDiv.innerHTML = customCSS;
    }
  }, [
    props.theme,
    props.theme?.custom_css,
    props.theme?.custom_font_url,
    cssDiv,
  ]);

  React.useEffect(() => {
    let styleDiv: HTMLStyleElement = document.createElement("style");
    styleDiv.id = "customStyling";
    styleDiv.type = "text/css";
    document.getElementsByTagName("head")[0].appendChild(styleDiv);
    setCssDiv(styleDiv);
  }, []);

  return (
    <DynamicThemeProvider theme={props.theme}>
      <ErrorBoundary>
        <Loader loading={props.isLoading} />
        <BaseViewRouter {...props} />
      </ErrorBoundary>
    </DynamicThemeProvider>
  );
};

export default StateViewRouter;
