import {ChartProps} from "./chartProps";
import {useOrdinalColorScale} from "@nivo/colors";
import {CustomTooltip} from "../../dashboard/CustomTooltip";
import {OrdinalColorScaleConfig} from "@nivo/colors/dist/types/ordinalColorScale";
import {ResponsiveBar} from "@nivo/bar";
import {Tooltip} from "@material-ui/core";

type BarItem = { [key: string]: number };
export type BarItems = { [key: string]: BarItem };

export interface HorizontalBarChartProps extends ChartProps {
    type: "hBar";
    data: BarItems;
    order?: string[];
    limit?: number;
}

export const HorizontalBarChart = (props: HorizontalBarChartProps) => {
    let colorConfig = (props.colors || {scheme: "category10"}) as OrdinalColorScaleConfig;
    let colors = useOrdinalColorScale(colorConfig, "id");

    // let yFmt = (props.yFmt !== undefined) ? props.yFmt : (v: number) => v.toString();

    // Orders by bar size, then sets to BarDatum type
    let sizeOfBar = (bar: BarItem) => Object.values(bar).reduce((a, b) => a + b, 0);
    const data = Array.from(Object.entries(props.data))
        .sort(([k1, bar1], [k2, bar2]) => {
            return sizeOfBar(bar1) - sizeOfBar(bar2);
        })
        // Limits to top 10
        .filter((value, idx) => idx >= Object.keys(props.data).length - (props.limit || 10))
        .map(([key, bar]) => ({_key: key, ...bar}));

    // Used for ordering the bar items (the categories of each bar)
    let barKeys: string[];
    if (props.order !== undefined)
        barKeys = props.order;
    else {
        // Prepares for ordering
        let barItemSizes: { [key: string]: number } = {};
        Object.values(props.data).forEach((bar) => {
            Object.entries(bar).forEach(([key, barItemSize]) => {
                if (barItemSizes[key] === undefined)
                    barItemSizes[key] = barItemSize;
                else
                    barItemSizes[key] += barItemSize;
            });
        });
        // Orders by total bar item size
        barKeys = Object.keys(barItemSizes).sort((key1, key2) => (
            barItemSizes[key2] - barItemSizes[key1]
        ))
    }

    return (
        <ResponsiveBar
            data={data}
            keys={barKeys}
            indexBy="_key"
            layout="horizontal"
            margin={{top: 0, right: 0, bottom: -15, left: 0}}
            padding={0.7}
            valueScale={{type: "linear"}}
            indexScale={{type: "band", round: true}}
            colors={colors}
            enableLabel={false}
            enableGridY={false}
            axisTop={null}
            axisBottom={null}
            axisLeft={{
                renderTick: (tick) => {
                    const SUMMARY_LENGTH = 60;
                    return (
                        <g transform={`translate(${tick.x},${tick.y})`}>
                            <Tooltip title={tick.value} aria-label={tick.value}>
                                <text
                                    textAnchor="start"
                                    dominantBaseline="middle"
                                    dy={({
                                        // Manually positions the yTick based on number of bars shown
                                        // Designed when the height of the graph is 300
                                        "1": -36,
                                        "2": -26,
                                        "3": -21,
                                        "4": -18,
                                        "5": -17,
                                        "6": -14,
                                        "7": -14,
                                        "8": -14,
                                        "9": -13,
                                        "10": -13,
                                    } as {[key: string]: number})[data.length.toString()] || 0}
                                    style={{
                                        fill: "#333",
                                        fontSize: 12,
                                    }}
                                >
                                    {tick.value.slice(0, SUMMARY_LENGTH) +
                                        (tick.value.length >= SUMMARY_LENGTH ? "..." : "")}
                                </text>
                            </Tooltip>
                        </g>
                    );
                },
            }}
            tooltip={(props) => {
                return <CustomTooltip datum={props}/>;
            }}
            legends={(barKeys.length > 4)? [] : [
                {
                    dataFrom: "keys",
                    anchor: "bottom-right",
                    direction: "row",
                    justify: false,
                    translateY: 20,
                    itemsSpacing: 2,
                    itemWidth: 100,
                    itemHeight: 20,
                    itemDirection: "left-to-right",
                    itemOpacity: 0.85,
                    symbolSize: 20,
                    effects: [
                        {
                            on: "hover",
                            style: {
                                itemOpacity: 1,
                            },
                        },
                    ],
                },
            ]}
        />
    );
}