import { FC, useRef, useState } from "react";
import toast from "react-hot-toast";

// Material UI
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import InputAdornment from "@material-ui/core/InputAdornment";

// icons
import Copy from "@material-ui/icons/FileCopyOutlined";

// styles
import { makeStyles } from "@material-ui/core/styles";
import commonStyles from "components/layout/commonStyles";

const useStyles = makeStyles((theme) => ({
  ...commonStyles(theme),
}));

interface LinkGeneratorProps {
  open: boolean;
  currentEngineSlug: string;
  handleCloseDialog: CallableFunction;
}

export const LinkGeneratorDialog: FC<LinkGeneratorProps> = ({
  open,
  currentEngineSlug,
  handleCloseDialog,
}) => {
  const classes = useStyles();
  const [subId, setSubID] = useState("");
  const [email, setEmail] = useState("");
  const [plan, setPlan] = useState("");
  const [renewal, setRenewal] = useState("");
  const [price, setPrice] = useState("");
  const [activeSubs, setActiveSubs] = useState("");
  const [numOrders, setNumOrders] = useState("");
  const [ltr, setLtr] = useState("");
  const codeText = useRef<HTMLTextAreaElement | null>(null);

  const handleClose = () => {
    setSubID("");
    setEmail("");
    setPlan("");
    setRenewal("");
    setPrice("");
    setActiveSubs("");
    setNumOrders("");
    setLtr("");
  };

  const linkRaw = [
    "https://app.bellwethr.com/offboard/experience/",
    currentEngineSlug,
    "?",
    subId ? `&subscription_id=${subId}` : "",
    email ? `&client_email=${email}` : "",
    plan ? `&current_plan=${plan}` : "",
    price ? `&monthly_price=${price}` : "",
    numOrders ? `&num_orders=${numOrders}` : "",
    activeSubs ? `&num_subscriptions=${activeSubs}` : "",
    renewal ? `&next_renewal_date=${renewal}` : "",
  ].join("");

  const copyToClipboard = () => {
    if (codeText.current) {
      codeText.current.select();
      document.execCommand("copy");
      toast.success("Copied to clipboard!");
    }
  };
  return (
    <Dialog
      open={open}
      onClose={() => {
        handleClose();
        handleCloseDialog();
      }}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">
        RetentionEngine Link Builder
      </DialogTitle>
      <DialogContent>
        <code className={classes.panelSubheader}>
          http://app.bellwethr.com/offboard/experience/
          <br />
          {currentEngineSlug}
          {subId ? `?subscription_id=${subId}` : null}
          <br />
          {email ? `&client_email=${email}` : null}
          {plan ? `&current_plan=${plan}` : null}
          <br />
          {price ? `&monthly_price=${price}` : null}
          {numOrders ? `&num_orders=${numOrders}` : null}
          {activeSubs ? `&num_subscriptions=${activeSubs}` : null}
          <br />
          {renewal ? `&next_renewal_date=${renewal}` : null}
        </code>
        <textarea
          value={linkRaw}
          ref={codeText}
          style={{ height: 1, width: 1, opacity: 0 }}
          readOnly
        />
        <Button
          className={classes.copyButton}
          startIcon={<Copy />}
          onClick={copyToClipboard}
        >
          Copy
        </Button>
        <DialogContentText>
          Enter variables for the URL parameters:
        </DialogContentText>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TextField
              value={subId}
              onChange={(e) => setSubID(e.target.value)}
              autoFocus
              id="subscription_id"
              label="Subscription ID"
              type="text"
              fullWidth
              required
              variant="outlined"
              InputLabelProps={{ shrink: true }}
              helperText="Please make sure this field is filled in."
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              id="client_email"
              label="Client Email"
              type="email"
              fullWidth
              variant="outlined"
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              value={plan}
              onChange={(e) => setPlan(e.target.value)}
              id="current_plan"
              label="Current Plan"
              type="text"
              fullWidth
              variant="outlined"
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              value={price}
              onChange={(e) => setPrice(e.target.value)}
              id="monthly_price"
              label="Monthly Price"
              type="number"
              fullWidth
              variant="outlined"
              InputLabelProps={{ shrink: true }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">$</InputAdornment>
                ),
              }}
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              value={activeSubs}
              onChange={(e) => setActiveSubs(e.target.value)}
              id="active_subscriptions"
              label="Active Subscriptions"
              type="number"
              fullWidth
              variant="outlined"
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              value={numOrders}
              onChange={(e) => setNumOrders(e.target.value)}
              id="num_orders"
              label="Total Number of Orders"
              type="number"
              fullWidth
              variant="outlined"
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              value={ltr}
              onChange={(e) => setLtr(e.target.value)}
              id="lifetime_revenue"
              label="Lifetime Revenue"
              type="number"
              fullWidth
              variant="outlined"
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              value={renewal}
              onChange={(e) => setRenewal(e.target.value)}
              id="next_renewal"
              label="Next Renewal"
              type="text"
              fullWidth
              variant="outlined"
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            handleClose();
            handleCloseDialog();
          }}
          color="primary"
        >
          Exit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default LinkGeneratorDialog;
