import { Toaster } from "react-hot-toast";
import clsx from "clsx";

// Material UI
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";

//components
import UserSettingsSidebar from "./UserSettingsSidebar";
import PrivateNavBar from "components/header/PrivateNavBar";

// styles
import commonStyles from "components/layout/commonStyles";
import { makeStyles } from "@material-ui/core/styles";
import styles from "./userSettingsStyles";

const useStyles = makeStyles((theme) => ({
  ...commonStyles(theme),
  ...styles(theme),
}));

export interface UserSettingsLayoutProps {}

export const UserSettingsLayout: React.FC<UserSettingsLayoutProps> = ({
  children,
}) => {
  const classes = useStyles();

  return (
    <div>
      <PrivateNavBar />
      <Grid
        container
        style={{ height: "100%", position: "absolute" }}
        alignItems="stretch"
        className={classes.alignLeft}
      >
        <Grid item sm={5} lg={4} xl={3}>
          <Paper square className={clsx(classes.alignLeft, classes.fullHeight)}>
            <UserSettingsSidebar />
          </Paper>
        </Grid>
        <Grid item sm={7} lg={8} xl={9} className={classes.mdPadding}>
          {children}
        </Grid>
      </Grid>
      <Toaster position="bottom-center" />
    </div>
  );
};

export default UserSettingsLayout;
