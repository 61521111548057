import { Theme } from "@material-ui/core";

export default function userStyles(theme: Theme) {
  return {
    registerLink: {
      color: "white",
      margin: "2rem auto 0 auto",
      fontSize: "small",
      display: "block",
      width: "fit-content",
      alignSelf: "center",
    },
    forgotPasswordLink: {
      color: "white",
      marginBottom: "1rem",
      fontSize: "small",
      display: "block",
      width: "fit-content",
      alignSelf: "flex-end",
    },
  };
}
