import { Route, Router, Switch } from "react-router-dom";
import { history } from "app/history";
import { USER_SETTINGS_URLS } from "features/wizard/slice/urls";
import Account from "./Account";
import Password from "./Password";

export const UserSettingsContainer = () => {
  return (
    <Router history={history}>
      <Switch>
        <Route exact component={Account} path={USER_SETTINGS_URLS.account} />
        <Route exact component={Password} path={USER_SETTINGS_URLS.password} />
      </Switch>
    </Router>
  );
};

export default UserSettingsContainer;
