import React from "react";
import styled from "@emotion/styled";
import Layout, { BottomWhite } from "../../components/Layout/Layout";
import Footer from "../../components/Footer";
import ResponseButton from "../../components/ResponseButton";
import { ThemeAttributeProps } from "../../theme";

export interface IPause {
  id: number;
  display_title: string;
  headline?: string;
  resume_message?: string;
}

export interface PauseScreenProps {
  title?: string;
  pauseOptions: IPause[];
  selected?: string;
  onPauseChange(id: string): void;
  bottomTitle?: string;
  callUpdate(action: string): void;
  pauseDisclaimerText?: string;
  theme?: ThemeAttributeProps;
}

const PausesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: auto;
`;

const BottomTitle = styled.div`
  font-family: ${(props: any) => props.theme.font};
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  color: #080f18;
  margin-bottom: 2rem;
  margin-left: auto;
  margin-right: auto;

  @media (max-width: 768px) {
    font-size: 20px;
  }
`;

const PauseDisclaimer = styled.div`
  font-family: ${(props: any) => props.theme.font};
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 26px;
  color: #000;
  margin-top: 2rem;
  margin-bottom: 2rem;
  margin-left: auto;
  margin-right: auto;
`;

const BottomWhiteWrapper = styled(BottomWhite)`
  padding-top: 4rem;
  display: block;
`;

interface PauseScreenState {
  selected?: string;
}

class PauseScreen extends React.Component<PauseScreenProps, PauseScreenState> {
  constructor(props: PauseScreenProps) {
    super(props);
    this.state = {
      selected: props.selected,
    };
  }

  pickPause(id: string) {
    this.props.onPauseChange(id);
    this.setState({ selected: id });
  }

  render() {
    return (
      <>
        <Layout
          title={(this.props.theme && this.props.theme.pause_headline) || ""}
        >
          <BottomWhiteWrapper className="bottom-content-container">
            <PausesWrapper>
              <BottomTitle className="pause-bottom-title">
                {this.props.theme && this.props.theme.pause_select_text}
              </BottomTitle>
              {this.props.pauseOptions.map((pause: IPause, index: number) => (
                <ResponseButton
                  key={index}
                  onClick={() => this.pickPause(pause.id.toString())}
                  buttonText={pause.display_title}
                  buttonAdditionalText={
                    pause.resume_message ? pause.resume_message : ""
                  }
                />
              ))}
              {this.props.pauseDisclaimerText ? (
                <PauseDisclaimer className="pause-disclaimer-text">
                  {this.props.pauseDisclaimerText}
                </PauseDisclaimer>
              ) : (
                ""
              )}
            </PausesWrapper>
          </BottomWhiteWrapper>
        </Layout>
        <Footer
          onClickContinue={() => this.props.callUpdate("continue")}
          onClickBack={() => this.props.callUpdate("_back")}
        />
      </>
    );
  }
}

export default PauseScreen;
