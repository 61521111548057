import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { userSelector, clearFormState, logoutUser } from "./userSlice";
import { useHistory } from "react-router-dom";

// Material UI
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import Typography from "@material-ui/core/Typography";

// components
import ExternalLayout from "components/layout/external/ExternalLayout";
import Loader from "../../components/loading/Loader";

export default function Logout() {
  const dispatch = useDispatch();
  const history = useHistory();

  const { isFetching, loggedIn, auth } = useSelector(userSelector);

  // On component load
  useEffect(() => {
    if (!isFetching && loggedIn) {
      dispatch(clearFormState());
      dispatch(logoutUser());
      setTimeout(() => {
        if (window.location.pathname === "/logout") history.push("/");
      }, 3000);
    }
  }, [dispatch, loggedIn, isFetching, history]);

  return (
    <ExternalLayout footerFull>
      <Grid container justifyContent="center">
        <Grid item xs={10} md={6} lg={4}>
          <Card>
            <Typography variant="body1">
              {loggedIn ? (
                <>
                  You are logged in as {auth.user.email}...
                  <br />
                  Logging you out....
                </>
              ) : (
                "You are not logged in!"
              )}
            </Typography>
            {isFetching ? <Loader /> : null}
          </Card>
        </Grid>
      </Grid>
    </ExternalLayout>
  );
}
