import { Theme } from "@material-ui/core";

export default function dashboardStyles(theme: Theme) {
  return {
    overviewHeaderCard: {
      backgroundColor: "rgba(255,255,255,0.05)",
      borderRadius: "20px 20px 0 0",
      padding: "2em",
      height: "182px",
      boxShadow: "none",
    },
    overviewInfoCard: {
      height: "150px",
      backgroundColor: "rgba(255,255,255,0.2)",
      padding: "1em",
    },
    infoCardText: {
        fontSize: "4.25rem",
        fontWeight: 100,
        lineHeight: "4rem",
        height: "4rem",
        paddingRight: "20px",
        minWidth: "50px",
    },
    overviewChartArea: {
      height: "400px",
    },
    barChartCard: {
      border: "2px solid #c2c2c266",
      boxShadow: "none",
      margin: "1.5em 0 0em",
      borderRadius: 10,
      overflow: "visible",
    },
    barChartContainer: {
      height: 330,
    },
    barChartTitle: {
      color: "#787878",
      textAlign: "left" as "left",
      padding: "1em",
    },
    dashboardNavHeader: {
      color: "#FBFBFB",
      margin: "0 1em",
    },
    dashboardNavButtonInactive: {
      background: "#8E509F",
      borderRadius: "10px",
      color: "#F4F4F4",
    },
    dashboardNavButtonActive: {
      background: "#FBFBFB",
      borderRadius: "10px",
      color: "#787878",
    },
    tableCard: {
      margin: "1em",
      border: "2px solid #c2c2c266",
      borderRadius: "12px",
      boxShadow: "none",
    },
    dialogTextfield: {
      margin: "0 1em",
    },
    survivalSelect: {
      color: "#787878",
      position: "relative" as "relative",
      // marginRight: theme.spacing(2),
      marginLeft: 0,
      marginBottom: 0,
      width: "100%",
      // [theme.breakpoints.up("sm")]: {
      //   marginLeft: theme.spacing(3),
      //   width: "auto",
      // },
    },
    inputLabel: {
      fontSize: "8px",
    },
  };
}
