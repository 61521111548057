import { FC, useState } from "react";

// Material UI
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TablePagination from "@material-ui/core/TablePagination";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";

// components
import { ExperienceStat, OffboardEngine } from "features/wizard/slice/types";
import { ExperienceDetailContent } from "./ExperienceDetailContent";

interface ExperiencesTableProps {
  data: ExperienceStat[];
  currentEngine: OffboardEngine;
}

export const ExperiencesTable: FC<ExperiencesTableProps> = ({
  data,
  currentEngine,
}) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const start = page * rowsPerPage;

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const [open, setOpen] = useState(false);
  const [alid, setAlid] = useState("");

  const handleClickOpen = (alid: string) => {
    setOpen(true);
    setAlid(alid);
  };

  const handleClose = () => {
    setOpen(false);
    setAlid("");
  };

  return (
    <div>
      <Table aria-label="experiences table">
        <TableHead>
          <TableRow>
            <TableCell>Date</TableCell>
            <TableCell align="left">Email</TableCell>
            <TableCell align="left">Orders</TableCell>
            <TableCell align="left">Response</TableCell>
            <TableCell align="left">Treatment Shown</TableCell>
            <TableCell align="left">Outcome</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.slice(start, start + rowsPerPage).map((row) => (
            <TableRow
              key={row.id}
              style={
                row.test_experience ? { textDecoration: "line-through" } : {}
              }
            >
              <TableCell component="th" scope="row">
                {row.timestamp}
              </TableCell>
              <TableCell align="left">{row.customer_email}</TableCell>
              <TableCell align="left">{row.num_orders || ""}</TableCell>
              <TableCell align="left">{row.user_reason}</TableCell>
              <TableCell align="left">{row.offer_shown}</TableCell>
              <TableCell
                align="left"
                style={
                  ["Cancelled", "Error"].includes(row.outcome)
                    ? { color: "#C75A5A" }
                    : row.outcome.toLocaleLowerCase().startsWith("saved")
                    ? { color: "#5DD47F" }
                    : undefined
                }
              >
                <span
                  style={{ cursor: "pointer" }}
                  onClick={() => handleClickOpen(row.alid)}
                >
                  {row.outcome}
                </span>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={data.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Experience #{alid}</DialogTitle>
        <DialogContent>
          <ExperienceDetailContent currentEngine={currentEngine} alid={alid} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ExperiencesTable;
