import { FC, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { mergeErrors } from "app/forms";
import {
  OffboardEngine,
  DeleteEngineFields,
} from "features/wizard/slice/types";

import { deleteEngine } from "features/wizard/slice/wizardSlice";

// Material UI
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import FormGroup from "@material-ui/core/FormGroup";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";

interface DeleteEngineProps {
  open: boolean;
  currentEngine: OffboardEngine;
  handleClose: CallableFunction;
}

export const DeleteEngineDialog: FC<DeleteEngineProps> = ({
  open,
  currentEngine,
  handleClose,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const error_list = useSelector(deleteEngine.ExtraErrorsSelector);
  const ready = useSelector(deleteEngine.isReadySelector);

  const {
    handleSubmit,
    control,
    formState: { errors: frontendErrors },
  } = useForm();

  const onSubmit = handleSubmit((data) => {
    dispatch(
      deleteEngine.Action({
        data,
        slug: currentEngine.slug,
      } as DeleteEngineFields)
    );
    handleClose();
  });

  const errors = mergeErrors(frontendErrors, error_list);

  // after ajax response
  useEffect(() => {
    if (ready) {
      history.push("/dashboard");
    }
  }, [history, ready]);

  return (
    <Dialog
      open={open}
      onClose={() => handleClose()}
      aria-labelledby="delete-engine-title"
    >
      <form onSubmit={onSubmit}>
        <DialogTitle id="delete-engine-title">Delete Engine</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete <b>{currentEngine.name}</b>? Enter
            your password to confirm.
          </DialogContentText>
          <FormGroup>
            <Controller
              control={control}
              name="password"
              rules={{ required: true }}
              defaultValue=""
              render={({ field }) => (
                <TextField
                  {...field}
                  InputLabelProps={{ shrink: true }}
                  label="PASSWORD"
                  variant="outlined"
                  autoFocus
                  type="password"
                  error={Boolean(errors.get("password"))}
                  helperText={errors.get("password")}
                />
              )}
            />
          </FormGroup>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleClose()} color="secondary">
            Cancel
          </Button>
          <Button type="submit" color="primary">
            Delete
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default DeleteEngineDialog;
