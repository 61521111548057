import { FC, useEffect, useState } from "react";
import StateViewRouter from "../../offboarding/containers/StateViewRouter";
import { useDispatch, useSelector } from "react-redux";
import { generatePath, useHistory, useParams } from "react-router-dom";

// Material UI
import Typography from "@material-ui/core/Typography";
import Select from "@material-ui/core/Select";
import Grid from "@material-ui/core/Grid";
import MenuItem from "@material-ui/core/MenuItem";
import Card from "@material-ui/core/Card";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";

// components
import { EndScreenProps } from "../../offboarding/views/EndScreen/EndScreen";
import { EXPERIENCE_URLS, WIZARD_URLS } from "../slice/urls";
import { States } from "../../offboarding/containers/StateViewRouter/StateViewRouter";
import Loader from "../../../components/loading/Loader";
import { OfferViewProps } from "../../offboarding/views/OfferView/OfferView";
import { PrimarySelect } from "components/dashboard/input/SelectBoxes";
import { ThemeAttributeProps } from "features/offboarding/theme";

import {
  availablePausesSelector,
  availableTreatmentsSelector,
  currentEngineSlugSelector,
  displayDataSelector,
  engineStyleSelector,
  exitSurveyResponsesEnabledSlugsSelector,
  exitSurveyResponsesSelector,
  getEngineStyle,
  openPreviewStep,
  pausesEnabledSlugsSelector,
  navigateTo,
  availableEnginesSelector,
  getEngines,
  wizardEngineSelector,
} from "../slice/wizardSlice";

// images
import CreateGray from "components/header/img/addIconGray.svg";

// icons
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";

// styles
import commonStyles from "../../../components/layout/commonStyles";
import styles from "./wizardPreviewContainerStyles";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import wizardStyles from "../wizardStyles";
import { getFontColor } from "../../offboarding/utils";
import { OffboardEngine, Intermediate } from "../slice/types";
import multiSwapLineItems from "../multiSwapLineItems";
interface PreviewURLParams {
  slug?: string;
}

const useStyles = makeStyles((theme) => ({
  ...styles(theme),
  ...commonStyles(theme),
  ...wizardStyles(theme),
}));

const WizardPreviewContainer: FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const classes = useStyles();
  const params = useParams<PreviewURLParams>();
  const [showControls, setShowControls] = useState(false);
  const engine = useSelector(wizardEngineSelector) as OffboardEngine;
  const engineSlug = useSelector(currentEngineSlugSelector);
  const engineStyle = useSelector(engineStyleSelector);
  const engineStyleReady = useSelector(getEngineStyle.isReadySelector);
  const { showPreviewStep } = useSelector(displayDataSelector);
  const engineSlugs = useSelector((state) =>
    Object.keys(availableEnginesSelector(state))
  );
  const reasons = useSelector((state) => {
    const allResponses = exitSurveyResponsesSelector(state);
    return exitSurveyResponsesEnabledSlugsSelector(state).map(
      (slug) => allResponses[slug]
    );
  });
  const pauses = useSelector((state) => {
    const allPauses = availablePausesSelector(state);
    return pausesEnabledSlugsSelector(state).map((slug) => allPauses[slug]);
  });

  const treatments = useSelector(availableTreatmentsSelector);

  const showTreatment =
    showPreviewStep?.treatment ||
    (params.slug ? treatments[params.slug] : null);

  const savedByPause =
    showPreviewStep?.saved?.type === "pause" ? { type: "pause" } : {};

  const extraStyleData = showPreviewStep?.style
    ? { ...showPreviewStep.style }
    : {};

  useEffect(() => {
    if (engineSlug !== ":engine") dispatch(getEngineStyle.Action(engineSlug));
  }, [engineSlug, dispatch]);

  useEffect(() => {
    if (engineSlug === ":engine" && engineSlugs.length === 0)
      dispatch(getEngines.Action(null));
  }, [engineSlug, engineSlugs.length, dispatch]);

  useEffect(() => {
    if (engineSlug === ":engine" && engineSlugs.length) {
      dispatch(
        navigateTo(
          generatePath(WIZARD_URLS.preview, { engine: engineSlugs[0] })
        )
      );
    }
  }, [engineSlug, engineSlugs, engineSlugs.length, dispatch]);

  if (!engineStyleReady) {
    return <Loader padding reason={"Styling experience preview..."} />;
  }
  if (!reasons.length) {
    return <Loader padding reason={"Fetching preview survey questions..."} />;
  }
  const previewStyle = {
    ...engineStyle,
    ...extraStyleData,
    font_color: getFontColor(engineStyle.primary_color),
    secondary_font_color: getFontColor(engineStyle.secondary_color),

    // Offboard object theme attributes
    show_cancel_bundle: engine.show_cancel_bundle,
    contact_support_for_cancel: engine.contact_support_for_cancel,
    website_url: engine.website_url,
    support_email: engine.support_email,
  } as ThemeAttributeProps;

  if (engineStyle.show_customer_saved_image === "NO_IMAGE") {
    previewStyle.show_customer_saved_image = false; // Offboarding expereince wants this to be a boolean
    previewStyle.customer_saved_image = undefined;
  } else {
    previewStyle.show_customer_saved_image = true; // Offboarding expereince wants this to be a boolean
  }

  if (engineStyle.show_customer_saved_image !== "CUSTOM") {
    previewStyle.customer_saved_image = undefined;
  }

  if (engineStyle.show_customer_lost_image === "NO_IMAGE") {
    previewStyle.show_customer_lost_image = false; // Offboarding expereince wants this to be a boolean
  } else {
    previewStyle.show_customer_lost_image = true; // Offboarding expereince wants this to be a boolean
  }

  if (engineStyle.show_customer_lost_image !== "CUSTOM") {
    previewStyle.customer_lost_image = undefined;
  }

  if (
    engine.allow_california_immediate_cancel ||
    engine.allow_global_immediate_cancel
  ) {
    previewStyle.immediate_cancel_reason_id = 1;
  }

  const offerProps = {
    ...showTreatment,
    callUpdate: () => {},
  } as OfferViewProps;

  const allowTreatments =
    showPreviewStep && showPreviewStep.step === States.OFFER;

  const sampleTreatment = Object.values(treatments)[0] as Intermediate;

  return (
    <Grid container style={{ minHeight: "100%" }}>
      <Grid item xs={12}>
        <div
          style={{
            height: "100%",
            width: "100%",
            position: "relative",
          }}
          className={classes.scrollY}
        >
          <Card className={clsx(classes.previewSelectContainer)}>
            <Tooltip
              title={`${showControls ? "Hide" : "Show"} Preview Controls`}
            >
              <IconButton onClick={() => setShowControls(!showControls)}>
                {showControls ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </Tooltip>
            {showControls ? (
              <>
                <Typography
                  variant="body2"
                  className={clsx(
                    classes.previewSelectTitle,
                    classes.purpleGradient
                  )}
                >
                  Experience Preview:
                </Typography>
                <Select
                  input={<PrimarySelect />}
                  value={showPreviewStep?.step || States.REASONS}
                  variant="outlined"
                  onChange={(e) =>
                    dispatch(
                      openPreviewStep({
                        step: e.target.value as States,
                        treatment: showTreatment,
                      })
                    )
                  }
                >
                  {Object.keys(States).map((name) => {
                    return (
                      <MenuItem key={name} value={name}>
                        <Typography
                          variant="body1"
                          className={classes.previewSelectItemText}
                        >
                          {name}
                        </Typography>
                      </MenuItem>
                    );
                  })}
                </Select>
                &nbsp;
                {allowTreatments ? (
                  <Select
                    input={<PrimarySelect />}
                    value={params.slug}
                    variant="outlined"
                    onChange={(e) =>
                      history.push(
                        generatePath(EXPERIENCE_URLS.edit_treatment, {
                          engine: engineSlug,
                          slug: e.target.value as string,
                        })
                      )
                    }
                  >
                    {Object.keys(treatments).map((slug) => {
                      return (
                        <MenuItem key={slug} value={slug}>
                          <Typography
                            variant="body1"
                            className={classes.previewSelectItemText}
                          >
                            {treatments[slug].name}
                          </Typography>
                        </MenuItem>
                      );
                    })}
                    <MenuItem value="add">
                      <Grid
                        container
                        alignItems="center"
                        justifyContent="flex-start"
                      >
                        <Grid item>
                          <img src={CreateGray} alt="create_new_treatment" />
                        </Grid>
                        <Grid item>
                          <Typography
                            variant="body1"
                            className={classes.previewSelectItemText}
                          >
                            Create New
                          </Typography>
                        </Grid>
                      </Grid>
                    </MenuItem>
                  </Select>
                ) : null}
              </>
            ) : null}
          </Card>
          <StateViewRouter
            theme={previewStyle}
            state={showPreviewStep?.step || States.REASONS}
            callUpdate={console.log}
            isLoading={false}
            reasons={reasons}
            pauses={pauses}
            single_page_preview={true}
            intermediate={offerProps}
            saved={
              {
                title: showTreatment
                  ? showTreatment.offer_claimed_heading
                  : sampleTreatment?.offer_claimed_heading,
                button_text: showTreatment?.offer_claimed_button_text,
                ...savedByPause,
              } as EndScreenProps
            }
            stub={engineSlug}
            minHeight="100%"
            existing_swap_products_data={{
              line_items: multiSwapLineItems,
              headline: showTreatment?.display_title || "",
              button_text: showTreatment?.button_text || "",
            }}
            new_swap_products_data={{
              line_items: multiSwapLineItems,
              headline: showTreatment?.secondary_display_title || "",
              button_text: showTreatment?.secondary_button_text || "",
            }}
            is_prepaid={Boolean(showPreviewStep?.is_prepaid)}
          />
        </div>
      </Grid>
    </Grid>
  );
};

export default WizardPreviewContainer;
