import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

// components
import UserSettingsLayout from "./UserSettingsLayout";
import Loader from "components/loading/Loader";

import {
  clientSelector,
  clientSettingsReadySelector,
  clientSettingsSelector,
  getClient,
  getClientSettings,
  updateClient,
} from "../userSlice";

// Material UI
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import Typography from "@material-ui/core/Typography";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
import Input from "@material-ui/core/Input";
import InputBase from "@material-ui/core/InputBase";

// images
import DefaultLogo from "./img/defaultCompanyLogo.svg";
import NewPage from "./img/goToNewPageGraphic.svg";

// styles
import commonStyles from "components/layout/commonStyles";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import styles from "./userSettingsStyles";

const useStyles = makeStyles((theme) => ({
  ...commonStyles(theme),
  ...styles(theme),
}));

export default function Account() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const timeSettings = useSelector(clientSettingsSelector);
  const clientSettingsReady = useSelector(clientSettingsReadySelector);
  const client = useSelector(clientSelector);
  const [orgName, setOrgName] = useState(client.organization_name);
  const [edit, setEdit] = useState(false);

  useEffect(() => {
    dispatch(getClientSettings());
    dispatch(getClient());
  }, [dispatch]);

  if (!clientSettingsReady)
    return <Loader padding reason="Loading your settings..." />;

  return (
    <UserSettingsLayout>
      <Typography
        variant="h4"
        className={clsx(classes.purpleGradient, classes.bold)}
      >
        Account
      </Typography>
      <Grid container>
        <Grid item xs={12} lg={9}>
          <Card className={clsx(classes.borderCard)}>
            <Grid container alignItems="center" spacing={2}>
              <Grid item xs={3} lg={2}>
                <img src={DefaultLogo} alt="default_logo" />
              </Grid>
              <Grid item xs={9} container direction="column">
                <Typography
                  variant="body1"
                  className={clsx(
                    classes.gray,
                    classes.bold,
                    classes.zeroMargin
                  )}
                >
                  Organization Name
                </Typography>
                {edit ? (
                  <>
                    <Input
                      defaultValue={orgName}
                      className={classes.orgName}
                      onChange={(e) => setOrgName(e.target.value)}
                    />
                    <span
                      style={{
                        textDecoration: "underline",
                        color: "blue",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        dispatch(
                          updateClient.Action({ organization_name: orgName })
                        );
                        setEdit(false);
                      }}
                    >
                      Save
                    </span>
                  </>
                ) : (
                  <>
                    <InputBase
                      defaultValue={orgName}
                      className={classes.orgName}
                      disabled
                    />
                    <span
                      className={classes.underlinedPurpleLink}
                      onClick={() => setEdit(true)}
                    >
                      Edit
                    </span>
                  </>
                )}
              </Grid>
            </Grid>
          </Card>
        </Grid>
      </Grid>
      <Typography
        variant="h6"
        className={clsx(classes.purpleGradient, classes.marginTop)}
      >
        Timezone Setting
      </Typography>
      <Typography variant="body2" className={classes.gray}>
        Select the timezone you want to use
      </Typography>
      <FormControl variant="outlined" className={classes.zeroMargin}>
        <Select
          id="font"
          defaultValue={client.timezone}
          className={classes.gray}
          onChange={(e) =>
            dispatch(
              updateClient.Action({
                timezone: e.target.value,
              })
            )
          }
        >
          {Object.keys(timeSettings.timezones).map((tz) => (
            <MenuItem value={tz} key={tz}>
              {tz} - {timeSettings.timezones[tz]}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <Grid
        container
        alignItems="center"
        className={classes.marginTop}
        spacing={1}
      >
        <Grid item>
          <Typography variant="h6" className={classes.purpleGradient}>
            Terms and Conditions
          </Typography>
        </Grid>
        <Grid item>
          <a
            href="https://bellwethragents.s3.amazonaws.com/pdf/Bellwethr_Terms&Conditions.pdf"
            target="_blank"
            rel="noreferrer"
          >
            <img src={NewPage} alt="terms_and_conditions" />
          </a>
        </Grid>
      </Grid>
      <Typography variant="body2" className={classes.gray}>
        Last updated December 2019
      </Typography>

      <Grid
        container
        alignItems="center"
        className={classes.marginTop}
        spacing={1}
      >
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            onClick={() => (window.location.pathname = "/logout")}
          >
            Logout
          </Button>
        </Grid>
      </Grid>
    </UserSettingsLayout>
  );
}
