import { Theme } from "@material-ui/core";

export default function heroLayoutStyles(theme: Theme) {
  return {
    purpleBackground: {
      background:
        "linear-gradient(180deg, #341241 0%, #4F2260 53.8%, #6D3781 76.2%, #9D68B0 99.97%)",
      width: "100%",
      height: 400,
      zIndex: 0,
      position: "absolute" as "absolute",
      top: 0,
    },
  };
}
