import { FC, useEffect, useState } from "react";
import { Link, generatePath } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

// Material UI
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";

// styles
import { makeStyles } from "@material-ui/core/styles";
import wizardStyles from "features/wizard/wizardStyles";
import commonStyles from "components/layout/commonStyles";

import {
  SingleExperienceDetail,
  OffboardEngine,
  ContextNameData,
} from "features/wizard/slice/types";
import { EXPERIENCE_URLS } from "features/wizard/slice/urls";
import Loader from "components/loading/Loader";
import {
  getSingleExperienceDetails,
  toggleIsTestExperience,
} from "features/wizard/slice/wizardSlice";

interface ExperienceDetailProps {
  currentEngine: OffboardEngine;
  alid: string;
}

const useStyles = makeStyles((theme) => ({
  ...commonStyles(theme),
  ...wizardStyles(theme),
}));

export const ExperienceDetailContent: FC<ExperienceDetailProps> = ({
  currentEngine,
  alid,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [isTestExperience, setTestExperience] = useState(false);

  // onload
  useEffect(() => {
    if (alid) {
      dispatch(
        getSingleExperienceDetails.Action({
          offboardSlug: currentEngine.slug,
          alid,
        })
      );
    }
  }, [dispatch, currentEngine.slug, alid]);

  const data: SingleExperienceDetail = useSelector(
    getSingleExperienceDetails.ReplySelector
  );
  const isLoading = useSelector(getSingleExperienceDetails.isFetchingSelector);

  const updatedTestExperienceData: { test_experience: boolean } = useSelector(
    toggleIsTestExperience.ReplySelector
  );

  // after request
  useEffect(() => {
    if (data) setTestExperience(data.test_experience);
  }, [dispatch, isLoading, data]);

  useEffect(() => {
    if (updatedTestExperienceData)
      setTestExperience(updatedTestExperienceData.test_experience);
  }, [updatedTestExperienceData]);

  const contextVariableValues = Object.entries(
    data?.context_variables_values || {}
  );

  const bulkSusbcriptions =
    data && data.bulk_subscriptions ? data.bulk_subscriptions.join(", ") : "";

  const defaultDisplayFields: string[] = [
    "created",
    "customer_id",
    "subscription_id",
    "customer_email",
    "plan_before",
    "user_reason",
    "option_selected",
    "action",
    "outcome",
  ];

  const contextNamesKeys = Object.keys(
    currentEngine.conditionals_data.context_names
  );
  const contextNamesObj: ContextNameData = {
    ...currentEngine.conditionals_data.context_names,
  };

  const formatTitle = (text: string) => {
    let textArray = text.split("_");
    textArray = textArray.map(
      (val: string) => val.charAt(0).toUpperCase() + val.slice(1)
    );
    return textArray.join(" ");
  };

  return !isLoading && data ? (
    <div>
      {data.error_occurred ? (
        <>
          <Typography variant="body1" className={classes.warningText}>
            During this experience the following error occured:
          </Typography>
          <Typography variant="body2" className={classes.gray}>
            {data.error_message}
          </Typography>
        </>
      ) : null}

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h6">Details</Typography>
        </Grid>
        {Object.entries(data).map(([key, val]) =>
          defaultDisplayFields.includes(key) ? (
            <Grid item xs={6} key={key}>
              <TextField
                value={val}
                disabled
                label={formatTitle(key)}
                type="text"
                fullWidth
                variant="outlined"
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
          ) : null
        )}

        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Switch
                checked={isTestExperience}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  dispatch(
                    toggleIsTestExperience.Action({
                      is_test: e.target.checked,
                      offboardSlug: currentEngine.slug,
                      alid,
                    })
                  )
                }
                name="is_test_experience"
              />
            }
            label="Is Test Experience"
          />
        </Grid>

        {contextVariableValues.length ? (
          <Grid item xs={12}>
            <Typography variant="h6">Context Variables</Typography>
          </Grid>
        ) : null}
        {contextVariableValues.map(([key, val]) =>
          contextNamesKeys.includes(key) ? (
            <Grid item xs={6} key={key}>
              <TextField
                value={val}
                disabled
                label={contextNamesObj[key].human_name}
                type="text"
                fullWidth
                variant="outlined"
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
          ) : null
        )}

        {bulkSusbcriptions ? (
          <Grid item xs={12}>
            <Typography variant="h6">Bulk Subscription IDs</Typography>
            <TextField
              value={bulkSusbcriptions}
              disabled
              label="Bulk Subscriptions"
              type="text"
              fullWidth
              variant="outlined"
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
        ) : null}

        {data.additional_comment ? (
          <Grid item xs={12}>
            <Typography variant="h6">Additional Comments</Typography>
            <Typography variant="body2" className={classes.gray}>
              {data.additional_comment}
            </Typography>
          </Grid>
        ) : null}

        {data.offers_shown.length ? (
          <Grid item xs={12}>
            <Typography variant="h6">Treatments Shown</Typography>
            <Table aria-label="experience-offers-shown">
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell align="left">Type</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.offers_shown.map((row, idx) => (
                  <TableRow key={idx}>
                    <TableCell component="th" scope="row">
                      {row.name}
                    </TableCell>
                    <TableCell align="left">{formatTitle(row.type)}</TableCell>
                    <TableCell align="left">
                      <Link
                        to={generatePath(
                          row.type === "PAUSE"
                            ? EXPERIENCE_URLS.pause_options
                            : EXPERIENCE_URLS.treatments,
                          {
                            engine: currentEngine.slug,
                          }
                        )}
                        style={{ textDecoration: "none" }}
                        target="_blank"
                      >
                        View Details
                      </Link>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Grid>
        ) : null}

        {data.other_experiences.length ? (
          <Grid item xs={12}>
            <Typography variant="h6">
              {data.other_experiences.length} Other Experiences{" "}
            </Typography>
            <Table aria-label="experience-previous-experiences">
              <TableHead>
                <TableRow>
                  <TableCell align="left">Timestamp</TableCell>
                  <TableCell align="left">Exit Survey Response</TableCell>
                  <TableCell align="left">Treatment Shown</TableCell>
                  <TableCell align="left">Outcome</TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {data.other_experiences.map((row, idx) => (
                  <TableRow key={idx}>
                    <TableCell component="th" scope="row">
                      {row.created}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {row.reason}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {row.chosen_offer}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {row.outcome}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Grid>
        ) : null}
      </Grid>
    </div>
  ) : (
    <Loader />
  );
};

export default ExperienceDetailContent;
