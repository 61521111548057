// Material UI
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import Typography from "@material-ui/core/Typography";

// images
import ExpandDownArrow from "../img/expandDownArrow.svg";
import ExpandUpArrow from "../img/expandUpArrow.svg";

// styles
import { makeStyles } from "@material-ui/core/styles";
import commonStyles from "components/layout/commonStyles";
import wizardStyles from "features/wizard/wizardStyles";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  ...commonStyles(theme),
  ...wizardStyles(theme),
}));

interface MainTreeCardProps {
  title: string;
  number: number;
  disabled?: boolean;
  collapsed?: boolean;
  selected?: boolean;
  onClick: CallableFunction;
  onCardClick?: CallableFunction;
};

export const MainTreeCard: React.FC<MainTreeCardProps> = ({
  title,
  number,
  disabled,
  collapsed,
  selected,
  onClick,
  onCardClick,
}) => {
  const classes = useStyles();

  return (
    <Card
      className={clsx(
        classes.mainTreeCard,
        selected ? classes.mainTreeCardSelected : null,
        disabled ? classes.mainTreeCardDisabled : null
      )}
      onClick={onCardClick ? () => onCardClick() : undefined}
    >
      <Grid container justifyContent="space-between">
        <Grid item>
          <Typography variant="h6">{title}</Typography>
        </Grid>
        <Grid item>
          <Card className={classes.numberCard}>
            <Typography variant="h6" className={classes.lightGray}>
              {number}
            </Typography>
          </Card>
        </Grid>
      </Grid>
      <img
        className={classes.expandOptions}
        src={collapsed ? ExpandDownArrow : ExpandUpArrow}
        onClick={() => onClick()}
        alt="expand_collapse_icon"
      />
    </Card>
  );
};

export default MainTreeCard;
