

// Adapted from stackoverflow: https://stackoverflow.com/a/9462382
function CompactFmt(num: number, digits: number, fmt?: (n: number) => string) {
    let _fmt = (fmt)? fmt : (n: number) => n.toString();
    const lookup = [
      {value: 1, symbol: ""},
      {value: 1e3, symbol: "k"},
      {value: 1e6, symbol: "M"},
      {value: 1e9, symbol: "B"},
      {value: 1e12, symbol: "T"}
    ];
    var item = lookup.slice().reverse().find(function (item) {
      return num >= item.value;
    });
    // const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;  // Used to trim the 0s
    // return item ? (num / item.value).toFixed(digits).replace(rx, "$1") + item.symbol : "0";
    return (item)?
        _fmt(Number.parseFloat((num / item.value).toFixed(digits))) + item.symbol
        : _fmt(Number.parseFloat((num).toFixed(digits)));
}

export function DecimalCompactFmt(n: number) {
    return CompactFmt(n, 1);
}
export function PctCompactFmt(n: number) {
    return `${DecimalCompactFmt(n * 100)}%`;
}
export function DollarFmt(n: number) {
    let dollarFormatter = new Intl.NumberFormat(
        'en-US',
        {style: 'currency', currency: 'USD'}
    );
    return dollarFormatter.format(n);
}
export function DollarCompactFmt(n: number) {
    /*
      0            => $0
      0-9.99       => $1.23
      10-999.99    => $123
      1k-99.99k    => $87.2k
      100k-999.99k => $739k
    */
    let nDigits =
        (n < 10)? 2
        : (n < 1000)? 0
        : 1;

    if (n === 0) return "$0";
    if (nDigits === 0)
      return CompactFmt(n, 0, (_n) => {
        let dollarStr = DollarFmt(_n);
        return dollarStr.substring(0, dollarStr.length - 3)
      });
    if (nDigits === 1)
      return CompactFmt(n, 1, (_n) => {
        let dollarStr = DollarFmt(_n);
        if (_n >= 100) return dollarStr.substring(0, dollarStr.length - 3);
        return dollarStr.substring(0, dollarStr.length - 1)
      });
    return CompactFmt(n, 2, DollarFmt);
}