import { FC, useState } from "react";
import { useSelector } from "react-redux";
import moment from "moment";

// components
import { IntermediateConditionalFormProps } from "./TreatmentPanel";
import { IntermediateConditional, ContextNameData } from "../slice/types";
import { formatDateString } from "app/utils";

import {
  availableTreatmentsSelector,
  currentSlugSelector,
  wizardEngineSelector,
} from "../slice/wizardSlice";

// Material UI
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputBase from "@material-ui/core/InputBase";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";

// images
import DeleteIcon from "../../../components/wizard/img/grayX.svg";

// styles
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import commonStyles from "../../../components/layout/commonStyles";
import styles from "features/dashboard/dashboardStyles";
import wizardStyles from "../wizardStyles";

const useStyles = makeStyles((theme) => ({
  ...commonStyles(theme),
  ...wizardStyles(theme),
  ...styles(theme),
}));

export const ConditionalForm: FC<IntermediateConditionalFormProps> = ({
  index,
  conditional,
  conditionalType,
  onChange,
  onRemove,
}) => {
  const classes = useStyles();
  const selectedTreatmentID = useSelector(currentSlugSelector);
  const selectedTreatment = useSelector(
    (state) => availableTreatmentsSelector(state)[selectedTreatmentID]
  );

  const [formData, setFormData] = useState<IntermediateConditional>(
    conditional
  );

  const engine = useSelector(wizardEngineSelector);

  const sendChangesToParent = (addData: any) => {
    const newData = {
      ...formData,
      ...addData,
    } as IntermediateConditional;

    setFormData(newData);
    onChange(newData, index);
  };

  const handleChangeContextName = (name: string) => {
    sendChangesToParent({ context_name: name });
  };
  const handleChangeCondition = (name: string) => {
    sendChangesToParent({ condition: name });
  };
  const handleChangeValue = (index: number, value: string) => {
    const newValues = [...conditional.values];
    newValues[index] = value;
    sendChangesToParent({ values: newValues });
  };
  const removeValue = (index: number) => {
    const newValues = [...conditional.values];
    newValues.splice(index, 1);
    sendChangesToParent({ values: newValues });
  };
  const addValue = () => {
    const newValues = [...conditional.values, ""];
    sendChangesToParent({ values: newValues });
  };

  const context_names: ContextNameData = engine.conditionals_data.context_names;
  const conditions = engine.conditionals_data.conditions;
  const manyValues =
    conditional.condition === "in" || conditional.condition === "not in";
  const hiddenContextNames = ["customer_first_name", "customer_last_name"];

  const allowedConditionsForDataType: { [k: string]: string[]} = 
    engine.conditionals_data.allowed_conditions_for_data_type;
  const conditionalsForContext = allowedConditionsForDataType[
    context_names[formData.context_name].data_type
  ];

  return (
    <>
      <Grid container alignItems="center" justifyContent="space-between">
        <Grid item xs={10}>
          <Typography
            variant="h6"
            className={clsx(classes.panelSubheader, classes.marginBottom)}
          >
            Show "{selectedTreatment ? selectedTreatment.name : "pause"}" to
            users whose...
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Button onClick={onRemove}>
            <img src={DeleteIcon} alt="remove_condition" />
          </Button>
        </Grid>
      </Grid>
      <Grid container alignItems="center" spacing={2}>
        <Grid item xs={12}>
          <FormControl variant="outlined">
            <Select
              id="context_name"
              className={clsx(classes.gray, classes.zeroMargin)}
              value={formData.context_name}
              onChange={(e) =>
                handleChangeContextName(e.target.value as string)
              }
            >
              {Object.keys(context_names).map((name) =>
                hiddenContextNames.includes(name) ? null : (
                  <MenuItem key={name} value={name}>
                    {context_names[name].human_name}
                  </MenuItem>
                )
              )}
            </Select>
          </FormControl>
        </Grid>
        <Grid container item xs={12} lg={10} xl={6}>
          <Grid item xs={8}>
            <FormControl variant="outlined">
              <Select
                id="condition"
                className={clsx(classes.gray, classes.zeroMargin)}
                value={formData.condition}
                onChange={(e) =>
                  handleChangeCondition(e.target.value as string)
                }
              >
                {Object.keys(conditions).map((name) => (
                  conditionalsForContext.includes(name) ?
                    <MenuItem key={name} value={name}>
                      {conditions[name]}
                    </MenuItem> :
                    null
                ))}
              </Select>
            </FormControl>
          </Grid>
          {formData.values.map((value = "100", index) => (
            <>
              {index > 0 ? <Grid item xs={8} key={index} /> : null}
              {conditionalType === "date" ?
                <Grid container alignItems="flex-start" key={index}>
                  <TextField
                    id={`date-${index}`}
                    label="Date"
                    type="date"
                    variant="outlined"
                    value={moment(value).format("YYYY-MM-DD")}
                    onChange={(e) =>
                      handleChangeValue(index, formatDateString(moment(e.target.value)))
                    }
                    InputLabelProps={{ shrink: true }}
                  />
                  <Button onClick={() => removeValue(index)} className={classes.marginTop}>
                    <img src={DeleteIcon} alt="remove_value" />
                  </Button>
                </Grid>:
                <Grid item xs={3}>
                  <InputBase
                    className={clsx(classes.inputBorder, classes.zeroMargin)}
                    value={value}
                    onChange={(e) => handleChangeValue(index, e.target.value)}
                  />
                </Grid>
              }
            
              <Grid item xs={1}>
                {formData.values.length > 1 && conditionalType !== "date" ? (
                  <Button onClick={() => removeValue(index)}>
                    <img src={DeleteIcon} alt="remove_value" />
                  </Button>
                ) : null}
              </Grid>
            </>
          ))}
          <Grid item xs={8} />
          <Grid item xs={4}>
            {manyValues ? <Button onClick={addValue}>Add</Button> : null}
          </Grid>
        </Grid>
      </Grid>

      <Divider className={classes.marginTop} variant="middle" />
    </>
  );
};
