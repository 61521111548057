import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";

// components
import Loader from "../../../components/loading/Loader";
import { mergeErrors } from "../../../app/forms";
import { States } from "../../offboarding/containers/StateViewRouter/StateViewRouter";
import PanelHeader from "components/wizard/PanelHeader";
import FormSectionHeader from "components/wizard/FormSectionHeader";

import {
  currentEngineSlugSelector,
  openPreviewStep,
  displayDataSelector,
  closePreview,
  updateEngineStyle,
  engineStyleSelector,
  getEngineStyle,
} from "../slice/wizardSlice";

// Material UI
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import FormGroup from "@material-ui/core/FormGroup";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";

// styles
import { makeStyles } from "@material-ui/core/styles";
import commonStyles from "../../../components/layout/commonStyles";
import wizardStyles from "../wizardStyles";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  ...commonStyles(theme),
  ...wizardStyles(theme),
}));

export default function PausePanel() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const currentEngineSlug = useSelector(currentEngineSlugSelector);
  const inPreview = useSelector(displayDataSelector).showPreviewStep;
  const pauseScreenPreview = inPreview && inPreview.step === States.PAUSES;
  const savedByPausePreview = inPreview && inPreview.step === States.SAVED;
  const style = useSelector(engineStyleSelector);
  const isFetchingStyle = useSelector(updateEngineStyle.isFetchingSelector);
  const styleReady = useSelector(getEngineStyle.isReadySelector);

  const {
    handleSubmit,
    control,
    formState: { errors: frontendErrors },
  } = useForm();

  const errors = mergeErrors(
    frontendErrors,
    useSelector(updateEngineStyle.ExtraErrorsSelector)
  );

  useEffect(() => {
    dispatch(getEngineStyle.Action(currentEngineSlug));
  }, [currentEngineSlug, dispatch]);

  const onSubmit = handleSubmit((data: any) => {
    dispatch(
      updateEngineStyle.Action({
        ...data,
        slug: currentEngineSlug,
      })
    );
  });

  if (!styleReady)
    return <Loader padding reason="Fetching pause screen data..." />;

  return (
    <div className={classes.mdPadding}>
      <PanelHeader
        title="Pause Library"
        caption="All available pauses"
        onClick={() =>
          dispatch(
            pauseScreenPreview
              ? closePreview()
              : openPreviewStep({ step: States.PAUSES })
          )
        }
        headerInPreview={pauseScreenPreview}
      />

      <Divider className={classes.headingDivider} />
      <form onSubmit={onSubmit}>
        <Typography variant="h6" className={classes.purpleGradient}>
          Pause Screen
        </Typography>
        <Typography variant="body2" className={classes.gray}>
          The screen shown when a user is prompted to select a pause as a
          treatment.
        </Typography>
        <FormGroup>
          <Controller
            control={control}
            name="pause_headline"
            rules={{ required: true }}
            defaultValue={style.pause_headline}
            render={({ field }) => (
              <TextField
                {...field}
                InputLabelProps={{ shrink: true }}
                label="HEADLINE"
                variant="outlined"
                error={Boolean(errors.get("pause_headline"))}
                helperText={errors.get("pause_headline")}
              />
            )}
          />
          <Controller
            control={control}
            name="pause_select_text"
            rules={{ required: true }}
            defaultValue={style.pause_select_text}
            render={({ field }) => (
              <TextField
                {...field}
                InputLabelProps={{ shrink: true }}
                label="SELECT A PAUSE PROMPT"
                variant="outlined"
                error={Boolean(errors.get("pause_select_text"))}
                helperText={errors.get("pause_select_text")}
              />
            )}
          />
          <Controller
            control={control}
            name="pause_disclaimer_text"
            defaultValue={style.pause_disclaimer_text}
            render={({ field }) => (
              <TextField
                {...field}
                InputLabelProps={{ shrink: true }}
                label="DISCLAIMER TEXT"
                variant="outlined"
                error={Boolean(errors.get("pause_disclaimer_text"))}
                helperText={errors.get("pause_disclaimer_text")}
              />
            )}
          />
        </FormGroup>

        <Typography
          variant="h6"
          className={classes.purpleGradient}
        ></Typography>

        <FormSectionHeader
          title="Pause Success"
          showPreview
          headerInPreview={savedByPausePreview}
          previewStep={States.SAVED}
          extraPreviewProps={{ saved: { type: "pause" } }}
        />
        <Typography variant="body2" className={classes.gray}>
          The screen displayed when a user selects one of the pause options.
        </Typography>
        <FormGroup>
          <Controller
            control={control}
            name="pause_success_headline"
            defaultValue={style.pause_success_headline}
            render={({ field }) => (
              <TextField
                {...field}
                InputLabelProps={{ shrink: true }}
                label="HEADLINE"
                variant="outlined"
                error={Boolean(errors.get("pause_success_headline"))}
                helperText={errors.get("pause_success_headline")}
              />
            )}
          />
          <Controller
            control={control}
            name="pause_success_subtext"
            defaultValue={style.pause_success_subtext}
            render={({ field }) => (
              <TextField
                {...field}
                InputLabelProps={{ shrink: true }}
                label="SUBTEXT"
                variant="outlined"
                error={Boolean(errors.get("pause_success_subtext"))}
                helperText={errors.get("pause_success_subtext")}
              />
            )}
          />
        </FormGroup>
        <Button
          type="submit"
          className={clsx(classes.panelButton, classes.marginTop)}
          disabled={isFetchingStyle}
        >
          Save Changes
        </Button>
      </form>
      <Typography variant="h6" className={classes.panelSubheader}>
        Displayed Pause Options
      </Typography>
      <Typography variant="body2" className={classes.gray}>
        You can choose which pause options are active by toggling the checkboxes
        on the left preview panel.
      </Typography>
    </div>
  );
}
