import React from "react";
import { Toaster } from "react-hot-toast";

// styles
import { darkTheme, lightTheme } from "MUITheme";
import { makeStyles, ThemeProvider } from "@material-ui/core/styles";
import styles from "./heroLayoutStyles";
import footerStyles from "components/footer/footerStyles";
import commonStyles from "../commonStyles";

// components
import MiniFooter from "components/footer/MiniFooter";
import PublicNavBar from "components/header/PublicNavBar";

// Material UI
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";

type HeroLayoutProps = {
  titleContent?: JSX.Element;
};

const useStyles = makeStyles((theme) => ({
  ...styles(theme),
  ...commonStyles(theme),
  ...footerStyles(theme),
}));

export const HeroLayout: React.FC<HeroLayoutProps> = ({
  children,
  titleContent,
}) => {
  const classes = useStyles();

  return (
    <ThemeProvider theme={darkTheme}>
      <Toaster position="top-center" />
      <Paper square className={classes.grayBackground}>
        <Paper square className={classes.purpleBackground} />
        <Grid
          container
          justifyContent="center"
          direction="column"
          className={classes.mainContent}
        >
          <Grid item>
            <PublicNavBar />
          </Grid>
          <Grid item>{titleContent}</Grid>

          <ThemeProvider theme={lightTheme}>
            <Grid item className={classes.middleContainer}>
              {children}
            </Grid>
            <Grid item className={classes.miniFooterContainer}>
              <MiniFooter />
            </Grid>
          </ThemeProvider>
        </Grid>
      </Paper>
    </ThemeProvider>
  );
};

export default HeroLayout;
