import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";

import {
  availableTreatmentsSelector,
  currentSlugSelector,
  updateTreatment,
  wizardEngineSelector,
} from "../slice/wizardSlice";

// Material UI
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import FormControl from "@material-ui/core/FormControl";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";

//components
import { IntermediateConditional, ContextNameData, ConditionalDataType } from "../slice/types";

//images
import AddIcon from "../img/purpleAddIcon.svg";

// styles
import { makeStyles } from "@material-ui/core/styles";
import commonStyles from "components/layout/commonStyles";
import clsx from "clsx";
import wizardStyles from "../wizardStyles";
import { ConditionalForm } from "./ConditionalForm";

const useStyles = makeStyles((theme) => ({
  ...commonStyles(theme),
  ...wizardStyles(theme),
}));

export default function TreatmentPanel() {
  const classes = useStyles();
  const selectedTreatmentID = useSelector(currentSlugSelector);
  const availableTreatments = useSelector(availableTreatmentsSelector);
  const selectedTreatment = availableTreatments[selectedTreatmentID];

  const engine = useSelector(wizardEngineSelector);
  const dispatch = useDispatch();

  const [toggleConditionals, setToggleConditionals] = useState<boolean>(false);
  const [currentConditionals, setCurrentConditionals] = useState<
    IntermediateConditional[]
  >([]);

  const isFetchingUpdateTreatment = useSelector(
    updateTreatment.isFetchingSelector
  );

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const show = (event.target as HTMLInputElement).value === "conditions";
    setToggleConditionals(show);
    if (show && !currentConditionals?.length) addConditional();
    if (!show)
      dispatch(
        updateTreatment.Action({
          offboard_slug: engine.slug,
          slug: selectedTreatment.slug,
          conditionals: [],
        })
      );
  };

  useEffect(() => {
    if (selectedTreatment) {
      setToggleConditionals(selectedTreatment.conditionals?.length >= 1);
      setCurrentConditionals(
        selectedTreatment.conditionals as IntermediateConditional[]
      );
    }
  }, [selectedTreatment, selectedTreatment?.conditionals, selectedTreatmentID]);

  const context_names: ContextNameData = engine?.conditionals_data?.context_names;
  const allowed_context_names = Object.keys(context_names).filter(name => !['customer_first_name', 'customer_last_name'].includes(name))
  const conditions = engine?.conditionals_data?.conditions;

  const saveConditionals = () => {
    dispatch(
      updateTreatment.Action({
        offboard_slug: engine.slug,
        slug: selectedTreatment.slug,
        conditionals: currentConditionals,
      })
    );
  };

  const addConditional = () => {
    setCurrentConditionals((current) =>
      (current || []).concat({
        context_type: "CONTEXT",
        context_name: allowed_context_names[0],
        condition: Object.keys(conditions)[0],
        values: [""],
        id: Math.random(),
      } as IntermediateConditional)
    );
  };
  const removeConditional = (index: number) => {
    setCurrentConditionals((current) => {
      return Array.from(current.filter((c, idx) => idx !== index));
    });
  };
  const handleConditionalUpdate = (
    updated: IntermediateConditional,
    index: number
  ) => {
    const newData = Array.from(currentConditionals);
    newData[index] = updated;
    setCurrentConditionals(newData);
  };

  if (!Object.keys(availableTreatments).length)
    return (
      <div className={classes.mdPadding}>
        <Typography variant="h5" className={classes.panelHeader}>
          Treatment Rules
        </Typography>
        <Typography variant="caption" className={classes.panelCaption}>
          No Treatments Available
        </Typography>
      </div>
    );

  if (!selectedTreatment) return <>Loading...</>;

  return (
    <div className={classes.mdPadding}>
      <Typography variant="h5" className={classes.panelHeader}>
        Treatment Rules
      </Typography>
      <Typography variant="caption" className={classes.panelCaption}>
        For {selectedTreatment.name}
      </Typography>
      <Divider className={classes.headingDivider} />
      <Typography variant="h6" className={classes.panelSubheader}>
        Rules
      </Typography>
      <FormControl component="fieldset">
        <RadioGroup
          aria-label="activate_treatment"
          name="activate_treatment"
          value={toggleConditionals}
          onChange={handleChange}
        >
          <FormControlLabel
            value="everyone"
            checked={!toggleConditionals}
            control={<Radio />}
            label="Activate For Everyone"
          />
          <FormControlLabel
            value="conditions"
            checked={toggleConditionals}
            control={<Radio />}
            label="Activate With Conditions"
          />
        </RadioGroup>
      </FormControl>
      {toggleConditionals ? (
        <>
          {currentConditionals.map((c, idx) => (
            <ConditionalForm
              key={`${selectedTreatment?.id}-${c.context_name}-${c.condition}-${idx}`}
              index={idx}
              conditional={c}
              conditionalType={context_names[c.context_name].data_type}
              onChange={handleConditionalUpdate}
              onRemove={() => removeConditional(idx)}
            />
          ))}
          <Grid container alignItems="center">
            <Grid item>
              <IconButton
                aria-label="add option"
                className={classes.zeroPadding}
                onClick={addConditional}
              >
                <img src={AddIcon} alt="add" />
              </IconButton>
            </Grid>
            <Grid item>
              <Typography variant="body1">
                &nbsp; Add another user type
              </Typography>
            </Grid>
          </Grid>
          <Grid container alignItems="flex-start">
            <Button
              className={clsx(classes.blueButton, classes.marginTop)}
              onClick={saveConditionals}
              disabled={isFetchingUpdateTreatment}
            >
              Save Rules
            </Button>
          </Grid>
        </>
      ) : null}

      <Grid container>
        <Typography variant="body2" className={classes.gray}>
          Need help with Rules?
          <a
            href="https://docs.bellwethr.com/docs/cancel-resolutions-getting-started-1"
            className={classes.textLink}
            target="_blank"
            rel="noreferrer"
          >
            <span className={classes.purple}>&nbsp; See our docs</span>
          </a>
        </Typography>
      </Grid>
    </div>
  );
}

export interface IntermediateConditionalFormProps {
  index: number;
  conditional: IntermediateConditional;
  conditionalType: ConditionalDataType;
  onChange: (updated: IntermediateConditional, index: number) => void;
  onRemove: () => void;
}
