import Typography from "@material-ui/core/Typography";

export interface GraphCardGroupInfoProps {
    by: "day" | "week" | "month" | "all";
}

export const GraphCardGroupInfo = (props: GraphCardGroupInfoProps) => {
    return (
        <Typography variant="subtitle2" style={{fontWeight: 300}}>
            {{
                "day": "By Day",
                "week": "By Week",
                "month": "By Month",
                "all": "Total",
            }[props.by]}
        </Typography>
    )
}