import { Theme } from "@material-ui/core";

export default function setupStyles(theme: Theme) {
  return {
    mainCard: {
      padding: "2em",
      background: "#FDFDFD",
      boxShadow:
        "0px 10px 100px rgba(62, 62, 62, 0.07), 0px 5px 12px rgba(62, 62, 62, 0.035)",
      borderRadius: "10px",
      margin: "1em 0",
    },
    templateCard: {
      height: "15em",
      background: "rgba(196, 196, 196, 0.2)",
      border: "1px solid #D2D2D2",
      boxSizing: "border-box" as "border-box",
      boxShadow: "none",
      padding: '1em 0'
    },
    templateCardGraphic: {
      marginTop: '1em'
    },
    chosenTemplateCard: {
      background: "rgba(246, 223, 255, 0.2)",
      border: "1px solid #8E509F",
    },
    creditImage: {
      width: '100%',
      height: '100%',
      objectFit: 'cover' as 'cover'
    },
    
    // ENGINE INTEGRATION
    subTextLink: {
      textDecoration: "none",
      fontSize: "12px",
      display: "block",
      margin: "2em 0 0",
      color: "#8E509F",
    },
    // PLATFORM OPTIONS
    miniCard: {
      background: "rgba(196, 196, 196, 0.2)",
      border: "1px solid rgba(196, 196, 196, 0.2)",
      boxSizing: "border-box" as "border-box",
      borderRadius: "5px",
      padding: "1em",
      height: "13em",
    },
    logo: {
      height: '90px',
      width: "100%",
      backgroundSize: "contain",
    },
    
    platformDialog: {
      padding: '2em',
      textAlign: 'center' as 'center'
    },
  };
}
