import { useSelector } from "react-redux";

// Material UI
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import Typography from "@material-ui/core/Typography";

//components
import OverviewInfoCard from "components/dashboard/OverviewInfoCard";
import DashboardNavBar from "components/dashboard/DashboardNavBar";
import Loader from "components/loading/Loader";
import {GraphCard} from "components/graphs/card/GraphCard";

import {
  dashboardStatsDataSelector,
  getDashboardStatistics,
} from "../wizard/slice/wizardSlice";
import {OverviewStats} from "../wizard/slice/types";
import {DecimalCompactFmt, DollarCompactFmt, PctCompactFmt} from "../wizard/utils/Format";

// styles
import styles from "./dashboardStyles";
import { makeStyles } from "@material-ui/core/styles";
import commonStyles from "components/layout/commonStyles";
import { ThemeProvider } from "@material-ui/core";
import { darkTheme, lightTheme } from "MUITheme";

const useStyles = makeStyles((theme) => ({
  ...commonStyles(theme),
  ...styles(theme),
}));

export default function Overview() {
  const classes = useStyles();
  const OverviewStats = useSelector(dashboardStatsDataSelector).overview as OverviewStats;
  const isLoading = useSelector(getDashboardStatistics.isFetchingSelector);

  if (isLoading)
    return (
      <div style={{ margin: "0 3em" }}>
        <ThemeProvider theme={darkTheme}>
          <DashboardNavBar />
        </ThemeProvider>
        <ThemeProvider theme={lightTheme}>
          <Loader reason="Fetching Statistics" />
        </ThemeProvider>
      </div>
    );
  if (OverviewStats === undefined || OverviewStats.overall.stats.n_unique_experiences.sum === 0)
    return (
      <div style={{ margin: "0 3em" }}>
        <ThemeProvider theme={darkTheme}>
          <DashboardNavBar />
        </ThemeProvider>
        <ThemeProvider theme={lightTheme}>
            <div
              style={{
                width: "100%",
                textAlign: "center",
              }}
            >
                <Typography variant="h6" className={classes.purpleGradient}>
                  No Data Found. Try another Date Range.
                </Typography>
            </div>
        </ThemeProvider>
      </div>
    );

  // TODO: Clean up graph data prep
  let saveGraphData = OverviewStats.dates.map(({date, stats}) => ({
    "x": date,
    "y": stats.save_rate,
  }));
  let mrrSavedGraphData = OverviewStats.dates.map(({date, stats}) => ({
    "x": date,
    "y": stats.mrr_saved,
  }));
  let mrrSavedRateGraphData = OverviewStats.dates.map(({date, stats}) => ({
    "x": date,
    "y": (stats.mrr_saved === null)? stats.mrr_saved : (stats.mrr_saved / (stats.mrr_saved + stats.mrr_lost)),
  }));
  let totalExpGraphData = OverviewStats.dates.map(({date, stats}) => ({
    "x": date,
    "y": stats.n_unique_experiences,
  }));
  let outcomePieGraphData = Object
      .entries(OverviewStats.overall.cat.outcome)
      .filter(([name]) => name !== "Cancelled")
      .map(([name, stats]) => ({
        id: name,
        value: stats.n_unique_experiences.sum,
      }));
  let reasonTextPieGraphData = Object
      .entries(OverviewStats.overall.cat.reason_text)
      .map(([name, stats]) => ({
        id: name,
        value: stats.n_unique_experiences.sum,
      }));

  let outcomeDateGraphDataPrep: {[cat_val: string]: {x: string, y: number}[]} = {};
  OverviewStats.dates.forEach(({date, cat}) => {
    Object.entries(cat)
      .filter(([cat_name]) => cat_name === "outcome")
      .forEach(([cat_name, cat_val_stats]) => {
        Object.entries(cat_val_stats).forEach(([cat_val, cat_val_main_stats]) => {
          outcomeDateGraphDataPrep[cat_val] = [
            ...(outcomeDateGraphDataPrep[cat_val] || []),
            {x: date, y: cat_val_main_stats.n_unique_experiences},
          ];
        });
      });
  });
  let outcomeDateGraphData = Object.entries(outcomeDateGraphDataPrep).map(([cat_val, data]) => ({
    id: cat_val,
    data: data,
  })).filter(({id}) => id !== "Cancelled");

  let reasonTextDateGraphDataPrep: {[cat_val: string]: {x: string, y: number}[]} = {};
  OverviewStats.dates.forEach(({date, cat}) => {
    Object.entries(cat)
      .filter(([cat_name]) => cat_name === "reason_text")
      .forEach(([cat_name, cat_val_stats]) => {
        Object.entries(cat_val_stats).forEach(([cat_val, cat_val_main_stats]) => {
          reasonTextDateGraphDataPrep[cat_val] = [
            ...(reasonTextDateGraphDataPrep[cat_val] || []),
            {x: date, y: cat_val_main_stats.n_unique_experiences},
          ];
        });
      });
  });
  let reasonTextDateGraphData = Object.entries(reasonTextDateGraphDataPrep).map(([cat_val, data]) => ({
    id: cat_val,
    data: data,
  }));

  type graphDataType = {
    title: string,
    tooltip: string,
    fmt: (v: number) => string,
    data: { x: string, y: number }[],
    dataNotNull: { x: string, y: number }[],
  }[];
  let graphData: graphDataType = [
    {
      title: "Save Rate",
      tooltip: "The percentage of cancel experiences resulting in a saved customer",
      fmt: PctCompactFmt,
      data: saveGraphData,
    },
    {
      title: "MRR Saved",
      tooltip: "The estimated monthly recurring revenue saved by RetentionEngine",
      fmt: DollarCompactFmt,
      data: mrrSavedGraphData,
    },
    {
      title: "MRR Save Rate",
      tooltip: "The estimated monthly recurring revenue save rate by RetentionEngine",
      fmt: PctCompactFmt,
      data: mrrSavedRateGraphData,
    },
    {
      title: "Total Experiences",
      tooltip: "The quantity of customers who have clicked/requested to cancel their subscription",
      fmt: DecimalCompactFmt,
      data: totalExpGraphData,
    },
  ].map(g => ({...g, dataNotNull: g.data.filter(({y}) => y !== null)}));

  return (
    <div style={{ margin: "0 3em" }}>
      <ThemeProvider theme={darkTheme}>
        <>
          <DashboardNavBar />
          <Card className={classes.overviewHeaderCard}>
            <Grid container spacing={1}>
              <Grid item xs={3}>
                <OverviewInfoCard
                  title="Save Rate"
                  amount={PctCompactFmt(OverviewStats.overall.stats.save_rate.mean)}
                  tooltip="The percentage of cancel experiences resulting in a saved customer during the time frame selected."
                />
              </Grid>
              <Grid item xs={3}>
                <OverviewInfoCard
                  title="Experiences Delivered"
                  amount={DecimalCompactFmt(OverviewStats.overall.stats.n_unique_experiences.sum)}
                  tooltip="The total quantity of customers who have clicked/requested to cancel their subscription during the time frame selected."
                />
              </Grid>
              <Grid item xs={3}>
                <OverviewInfoCard
                  title="Unique Experiences Saved"
                  amount={DecimalCompactFmt(OverviewStats.overall.stats.n_saved.sum)}
                  tooltip="The total quantity of unique experiences by month who have been saved by RetentionEngine during the time frame selected."
                />
              </Grid>
              <Grid item xs={3}>
                <OverviewInfoCard
                  title="Estimated MRR Saved"
                  amount={DollarCompactFmt(OverviewStats.overall.stats.mrr_saved.sum) || "N/A"}
                  tooltip="The total estimated monthly recurring revenue saved by RetentionEngine during the time frame selected."
                />
              </Grid>
            </Grid>
          </Card>
        </>
      </ThemeProvider>
      <ThemeProvider theme={lightTheme}>
        <Grid container direction="row" spacing={1} style={{paddingTop: "20px"}}>
          {graphData.map(graphData => (
              <Grid item xs={3}>
                <GraphCard
                  fmt={graphData.fmt}
                  header={{text: graphData.title, tooltip: graphData.tooltip}}
                  metrics={(graphData.dataNotNull.length <= 2)? undefined : {
                        mainMetric: graphData.dataNotNull[graphData.dataNotNull.length - 1].y,
                        increase: {
                            diff: (graphData.dataNotNull[graphData.dataNotNull.length - 1].y - graphData.dataNotNull[graphData.dataNotNull.length - 2].y),
                            pct: (100 * (graphData.dataNotNull[graphData.dataNotNull.length - 1].y / graphData.dataNotNull[graphData.dataNotNull.length - 2].y)) - 100,
                        }
                  }}
                  groupBy={{by: "week"}}
                  graph={{
                    type: "line",
                    data: [{id: graphData.title, data: graphData.data}],
                  }}
                />
              </Grid>
          ))}
              <Grid item xs={3}>
                <GraphCard
                  fmt={DecimalCompactFmt}
                  header={{text: "Save Outcomes", tooltip: ""}}
                  groupBy={{by: "all"}}
                  graph={{
                    type: "halfPie",
                    data: outcomePieGraphData,
                  }}
                />
              </Grid>
              <Grid item xs={3}>
                <GraphCard
                  fmt={DecimalCompactFmt}
                  header={{text: "Save Outcome Counts", tooltip: ""}}
                  groupBy={{by: "week"}}
                  graph={{
                    type: "line",
                    data: outcomeDateGraphData,
                  }}
                />
              </Grid>
              <Grid item xs={3}>
                <GraphCard
                  fmt={DecimalCompactFmt}
                  header={{text: "Exit Survey Responses", tooltip: ""}}
                  groupBy={{by: "all"}}
                  graph={{
                    type: "halfPie",
                    data: reasonTextPieGraphData,
                  }}
                />
              </Grid>
              <Grid item xs={3}>
                <GraphCard
                  fmt={DecimalCompactFmt}
                  header={{text: "Exit Survey Counts", tooltip: ""}}
                  groupBy={{by: "week"}}
                  graph={{
                    type: "line",
                    data: reasonTextDateGraphData,
                  }}
                />
              </Grid>
        </Grid>
      </ThemeProvider>
    </div>
  );
}
