import React from "react";
import styled from "@emotion/styled";
import { getFontColor, compareIgnoringCase } from "features/offboarding/utils";

interface ResponseButtonProps {
  buttonText: string;
  buttonAdditionalText: string;
  onClick(): void;
}

const ResponseButtonDiv = styled.div`
  width: 24rem;
  height: 3.5rem;
  border-radius: 10px;
  margin-bottom: 1rem;
  overflow: hidden;

  background: ${(props: any) =>
    props.theme.primary_color === "#FFFFFF"
      ? "#F9F9F9"
      : props.theme.primary_color};
  color: ${(props: any) => props.theme.font_color};
  font-family: ${(props: any) => props.theme.font};

  border: 2px solid
    ${(props: any) =>
      compareIgnoringCase(props.theme.primary_color, "#FFFFFF")
        ? compareIgnoringCase(props.theme.secondary_color, "#FFFFFF")
          ? getFontColor(props.theme.secondary_color)
          : props.theme.secondary_color
        : props.theme.primary_color};

  :hover {
    border-color: ${(props: any) =>
      props.theme.primary_color === props.theme.secondary_color &&
      compareIgnoringCase(props.theme.secondary_color, "#FFFFFF")
        ? getFontColor(props.theme.secondary_color)
        : props.theme.secondary_color};
    background: ${(props: any) => props.theme.secondary_color};
    color: ${(props: any) => props.theme.secondary_font_color};
    cursor: pointer;
  }

  @media (max-width: 768px) {
    width: 16rem;
    height: 3.5rem;
  }

  text-align: center;
  padding-left: 6px;
  padding-right: 6px;

  display: flex;
  flex-direction: column;
  padding-top: 2px;
  padding-bottom: 2px;
  justify-content: space-evenly;
  align-items: center;
`;

const ResponseButton = (props: ResponseButtonProps) => {
  if (props.buttonAdditionalText) {
    return (
      <ResponseButtonDiv
        role="button"
        onClick={() => props.onClick()}
        className="response-button"
      >
        <div>{props.buttonText}</div>
        <div>{props.buttonAdditionalText}</div>
      </ResponseButtonDiv>
    );
  } else {
    return (
      <ResponseButtonDiv
        role="button"
        onClick={() => props.onClick()}
        className="response-button"
      >
        <div>{props.buttonText}</div>
      </ResponseButtonDiv>
    );
  }
};

export default ResponseButton;
