import React from "react";
import styled from "@emotion/styled";
import AcceptOfferButton from "../../components/AcceptOfferButton";
import Layout, { BottomWhite } from "../../components/Layout/Layout";
import Footer from "../../components/Footer";
import { MailContactButton } from "../../components/ContactButton";
import { getFontSize } from "../../utils";

export interface OfferViewProps {
  type: string;
  display_title: string;
  center?: string;
  image?: string;
  video?: string;
  contact_phone?: string;
  contact_email?: string;
  description: string;
  headline: string;
  button_text: string;
  offer_preview?: boolean;
  callUpdate(data?: any): void;
  website_url?: string;
  redirect_url?: string;
}

interface SpotlightContainerProps {
  type: "image" | "text";
}

const SpotlightContainer = styled.div<SpotlightContainerProps>`
  z-index: 10;
  position: relative;
  margin: auto;
  ${(props: any) => props.type === "text" && "margin-bottom: 3rem"};

  display: flex;
  justify-content: center;
  align-items: center;

  overflow: hidden;
  background: ${(props: any) =>
    props.theme.primary_color === "#FFFFFF" ? "#F9F9F9" : "#FFFFFF"};

  @media (min-width: 768px) {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
  }

  @media (max-width: 768px) {
    box-shadow: 0px 0px 25px ${(props: any) => props.theme.primary_color};
    border-radius: 15px;
  }
`;

const SpotlightImage = styled.img`
  max-height: 300px;
  max-width: 500px;

  @media (max-width: 768px) {
    max-height: 250px;
    max-width: 300px;
  }
`;

const SpotlightVideo = styled.video`
  max-height: 300px;
  max-width: 500px;

  @media (max-width: 768px) {
    max-height: 250px;
    max-width: 300px;
  }
`;

const SpotlightText = styled.div<{ text_length: number }>`
  display: flex;
  justify-content: center;
  align-items: center;

  height: 250px;
  width: 450px;
  padding: 20px;

  overflow-y: auto;
  overflow-x: auto;
  overflow-wrap: break-word;

  color: black;
  font-family: ${(props: any) => props.theme.font};
  font-size: ${(props: any) => getFontSize(props.text_length, "spotlight")}px;
  font-style: normal;
  font-weight: bold;
  text-align: center;

  @media (max-width: 768px) {
    max-height: 150px;
    max-width: 250px;
    font-size: 18px !important; //important to override our font size calculator
  }
`;

interface SpotlightProps {
  bgImage: string;
  bgVideo: string;
  text: string;
  onImageLoadHandler(data: any): void;
}

const Spotlight = React.forwardRef((props: SpotlightProps, ref) => {
  return (
    <SpotlightContainer
      type={props.bgImage || props.bgVideo ? "image" : "text"}
      ref={ref as unknown as React.MutableRefObject<HTMLDivElement>}
      className="spotlight-container"
    >
      {props.bgVideo ? (
        <SpotlightVideo controls autoPlay>
          <source src={props.bgVideo} type="video/mp4" />
          Your browser does not support the video tag.
        </SpotlightVideo>
      ) : props.bgImage ? (
        <SpotlightImage
          alt="offer"
          onLoad={props.onImageLoadHandler}
          src={props.bgImage}
          className="spotlight-image"
        />
      ) : (
        <SpotlightText
          onLoad={props.onImageLoadHandler}
          className="spotlight-text"
          text_length={props.text ? props.text.length : 0}
        >
          {props.text}
        </SpotlightText>
      )}{" "}
    </SpotlightContainer>
  );
});

interface SpotlightOffsetProps {
  offset: number;
  description_length: number;
  image: string;
  title_length: number;
}

const SpotlightOffset = styled.div<SpotlightOffsetProps>`
  height: ${(props: any) =>
    (props.description_length > 300
      ? 160
      : props.description_length > 180
      ? 120
      : 70) + (props.image ? 50 : 0)}px;

  ${(props: any) => (props.title_length > 30 ? "height: 190px;" : "")}
  @media (max-height: 820px) {
    height: ${(props: any) => props.offset + 20}px;
  }
  @media (max-width: 768px) {
    height: ${(props: any) => props.offset / 1.5}px;
    margin-bottom: 2rem;
  }
  ${() =>
    // for the offer creation wizard screen we add
    // extra spacing to allow all content to show
    window.location.pathname.startsWith("/wizard") ? "height: 200px;" : ""}
`;

const BottomTitle = styled.div`
  font-family: ${(props: any) => props.theme.font};
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  text-align: center;
  color: #080f18;
  margin-top: 1rem;
  width: 80%;
  @media (max-width: 768px) {
    font-size: 20px;
  }
  @media (max-width: 467px) {
    margin-top: 2rem;
  }
`;

const BottomDetails = styled.div`
  font-family: ${(props: any) => props.theme.font};
  font-style: normal;
  font-weight: normal;
  width: 70%;
  margin: 0.5rem auto 1.5rem;
  font-size: 16px;
  line-height: 29px;
  text-align: center;
  color: #080f18;

  @media (max-width: 768px) {
    margin: 0 auto;
    width: 80%;
  }
`;

const ButtonWrapper = styled.div`
  margin-top: 0.5rem;
  width: 100%;
  text-align: center;

  @media (max-height: 620px) {
    margin-bottom: 4rem;
  }
`;

const ContactWrapper = styled.div`
  max-width: 30rem;
`;

interface OfferViewContentProps {
  type: string;
  image?: string;
  video?: string;
  display_title?: string;
  description?: string;
  contact_phone?: string;
  contact_email?: string;
  button_text?: string;
  callUpdate(data?: any): void;
  website_url?: string;
  redirect_url?: string;
}

class OfferViewContent extends React.Component<OfferViewContentProps> {
  render() {
    const Title =
      this.props.image || this.props.video ? (
        <BottomTitle className="offer-bottom-title">
          {this.props.display_title}
        </BottomTitle>
      ) : null;

    const Details = (
      <BottomDetails className="offer-description">
        {this.props.description}
      </BottomDetails>
    );

    const isNotContactOffer =
      !this.props.contact_phone && !this.props.contact_email;
    let Buttons = <div />;
    if (isNotContactOffer) {
      Buttons = (
        <ButtonWrapper>
          <AcceptOfferButton
            buttonText={this.props.button_text}
            onClick={(e) => {
              if (this.props.type !== "RELEVANT_INFORMATION")
                this.props.callUpdate("claimed");
              else
                window.top.location.href =
                  this.props.redirect_url ||
                  this.props.website_url ||
                  "https://www.retentionengine.com";
            }}
          />
        </ButtonWrapper>
      );
    } else {
      Buttons = (
        <ContactWrapper>
          {this.props.contact_email && this.props.button_text ? (
            <MailContactButton
              contactValue={this.props.contact_email}
              buttonText={this.props.button_text}
            />
          ) : (
            ""
          )}
        </ContactWrapper>
      );
    }

    return (
      <>
        {Title}
        {Details}
        {Buttons}
      </>
    );
  }
}

interface OfferViewState {
  spotlightHeight: number;
}

/**
 * View for an offer.
 */
class OfferView extends React.Component<OfferViewProps, OfferViewState> {
  constructor(props: OfferViewProps) {
    super(props);

    this.state = {
      spotlightHeight: 200,
    };

    this.updateHeight = this.updateHeight.bind(this);
  }

  spotlightRef = React.createRef();

  updateHeight() {
    let currentRef = this.spotlightRef.current as unknown as HTMLDivElement;

    if (currentRef !== null) {
      this.setState({
        spotlightHeight: currentRef.clientHeight,
      });
    }
  }

  componentDidMount() {
    this.updateHeight();
    window.addEventListener("resize", this.updateHeight);
  }

  componentDidUpdate(prevProps: any) {
    if (this.props.image !== prevProps.image) {
      // reupdates the offset height when props are update to
      // ensure image is displayed well
      this.updateHeight();
      window.addEventListener("resize", this.updateHeight);
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateHeight);
  }

  render() {
    // for offer previews we are storing the image as a string inside localStorage
    let bgImage = "";
    try {
      if (localStorage.getItem("previewImage") && this.props.offer_preview)
        bgImage = `${localStorage.getItem("previewImage")}`;
    } catch (err) {
      console.warn(err);
    } finally {
      bgImage =
        bgImage.length < 1 && this.props.image ? `${this.props.image}` : "";
    }

    let bgVideo = this.props.video ? `${this.props.video}` : "";

    let OfferContent = (
      <OfferViewContent
        type={this.props.type}
        image={bgImage}
        video={bgVideo}
        display_title={this.props.display_title}
        description={this.props.description}
        contact_phone={this.props.contact_phone}
        contact_email={this.props.contact_email}
        button_text={this.props.button_text}
        callUpdate={this.props.callUpdate}
        website_url={this.props.website_url}
        redirect_url={this.props.redirect_url}
      />
    );

    let spotlightOffset = (this.state.spotlightHeight * 2) / 3;

    return (
      <>
        <Layout title={this.props.headline}>
          <Spotlight
            ref={this.spotlightRef}
            bgImage={bgImage}
            bgVideo={bgVideo}
            onImageLoadHandler={this.updateHeight}
            text={this.props.display_title}
          />
          <BottomWhite
            negativeTop={spotlightOffset}
            negativeTopMobile={10}
            className="bottom-content-container"
          >
            <SpotlightOffset
              offset={spotlightOffset}
              image={bgImage || bgVideo}
              description_length={
                this.props.description ? this.props.description.length : 0
              }
              title_length={
                this.props.display_title && (bgImage || bgVideo)
                  ? this.props.display_title.length
                  : 0
              }
            />
            {OfferContent}
            <br />
          </BottomWhite>
        </Layout>
        <Footer
          onClickContinue={(e) => this.props.callUpdate("continue")}
          onClickBack={(e) => this.props.callUpdate("_back")}
        />
      </>
    );
  }
}

export default OfferView;
