import React from "react";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";

// Material UI
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";

// components
import PrivateNavBar from "components/header/PrivateNavBar";
import { WIZARD_URLS } from "./slice/urls";
import { nextStep, stepNavigate } from "./slice/wizardSlice";

// styles
import { lightTheme } from "MUITheme";
import styles from "./wizardLayoutStyles";
import commonStyles from "../../components/layout/commonStyles";
import { makeStyles, ThemeProvider } from "@material-ui/core/styles";
import clsx from "clsx";
import wizardStyles from "./wizardStyles";
import { Toaster } from "react-hot-toast";

const useStyles = makeStyles((theme) => ({
  ...styles(theme),
  ...commonStyles(theme),
  ...wizardStyles(theme),
}));

interface WizardLayoutProps {}

interface WizardButtonProps {
  index: number;
  title: string;
  path: string;
  active?: Boolean;
  chevron?: Boolean;
  onClick?: CallableFunction;
}

export const WizardButton: React.FC<WizardButtonProps> = ({
  index,
  title,
  path,
  onClick,
  chevron,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const parts = history.location.pathname.split("/");
  const buttonPathParts = path.split("/");
  const active =
    parts.length >= 4 &&
    parts[3] === buttonPathParts[buttonPathParts.length - 1];

  return (
    <Button
      className={clsx(
        classes.headerButton,
        active ? classes.activeButton : null
      )}
      onClick={() =>
        onClick ? onClick() : dispatch(stepNavigate({ path: path }))
      }
    >
      <Grid
        container
        spacing={2}
        justifyContent="space-around"
        alignItems="center"
      >
        <Grid item>
          <Avatar
            className={clsx(classes.smallAvatar, classes.darkPurpleGradient)}
          >
            {index}
          </Avatar>
        </Grid>
        <Grid item>
          <Typography
            variant="body1"
            className={clsx(classes.bold, classes.gray)}
          >
            {title}
          </Typography>
        </Grid>
        <Grid item>
          {chevron ? (
            <Typography
              variant="h5"
              className={clsx(classes.bold, classes.gray)}
            >
              &rsaquo;
            </Typography>
          ) : null}
        </Grid>
      </Grid>
    </Button>
  );
};

export const WizardLayout: React.FC<WizardLayoutProps> = ({ children }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const WizardSteps = (
    <>
      <Grid item xs={2}></Grid>
      <Grid item xs={8}>
        <WizardButton
          index={1}
          path={WIZARD_URLS.experience}
          title="Elements"
          chevron
        />
        <WizardButton
          index={2}
          path={WIZARD_URLS.mechanics}
          title="Flow"
          chevron
        />
        <WizardButton
          index={3}
          path={WIZARD_URLS.settings}
          title="Settings"
          chevron
        />
        <WizardButton
          index={4}
          path={WIZARD_URLS.activate_engine}
          title="Activate Engine"
        />
      </Grid>
      <Grid item xs={2}>
        <>
          <Button
            onClick={() => dispatch(nextStep())}
            className={classes.blueButton}
          >
            Next
          </Button>{" "}
        </>
      </Grid>
    </>
  );

  return (
    <ThemeProvider theme={lightTheme}>
      <Toaster position="bottom-center" />
      <Paper square className={classes.grayBackground}>
        <Grid
          container
          justifyContent="center"
          direction="column"
          alignItems="stretch"
          className={clsx(classes.mainContent, classes.wizardMain)}
        >
          <Grid item className={classes.wizardNavbarWrapper}>
            <PrivateNavBar extraNavigation={WizardSteps} />
          </Grid>

          <Grid item className={classes.wizardContent}>
            {children}
          </Grid>
        </Grid>
      </Paper>
    </ThemeProvider>
  );
};

export default WizardLayout;
