import { Theme } from "@material-ui/core";

export default function accountSettingsStyles(theme: Theme) {
  return {
    borderCard: {
      border: "2px solid #D2D2D2",
      borderRadius: "12px",
      boxShadow: "none",
      padding: "2em",
      margin: "2em 0",
    },
    visuallyHidden: {
      border: 0,
      clip: "rect(0 0 0 0)",
      height: 1,
      margin: -1,
      overflow: "hidden",
      padding: 0,
      position: "absolute" as "absolute",
      top: 20,
      width: 1,
    },
    table: {
      minWidth: 750,
    },
    userSettingsButton: {
      padding: "2em",
    },
    userSettingsButtonActive: {
      padding: "2em",
      background: "#F1E5F7",
      borderRadius: "10px",
    },
    smMarginTop: {
      marginTop: "0.5em",
    },
    orgName: {
      fontSize: "30px",
      margin: 0,
      fontWeight: 500,
      color: "#442F86",
      "&:disabled": {
        color: "#442F86",
      },
      [theme.breakpoints.down("md")]: {
        fontSize: "20px",
      },
    },
    underlinedPurpleLink: {
      textDecoration: "underline",
      color: "#8E509F",
      fontWeight: 500,
      cursor: "pointer",
    },
  };
}
