import {SurvivalAnalysis} from "../../features/wizard/slice/types";
import {useEffect, useMemo, useState} from "react";
import Grid from "@material-ui/core/Grid";
import {ToggleButton, ToggleButtonGroup} from "@material-ui/lab";
import Select from "@material-ui/core/Select";
import {SecondarySelect} from "./input/SelectBoxes";
import MenuItem from "@material-ui/core/MenuItem";
import Typography from "@material-ui/core/Typography";
import {Link, Switch, Tooltip} from "@material-ui/core";
import Chip from "@material-ui/core/Chip";
import clsx from "clsx";
import {DatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns-v1";
import {ArrowRightIcon} from "@material-ui/pickers/_shared/icons/ArrowRightIcon";
import IconButton from "@material-ui/core/IconButton";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import {makeStyles} from "@material-ui/core/styles";
import commonStyles from "../layout/commonStyles";
import styles from "../../features/dashboard/dashboardStyles";
import {useOrdinalColorScale} from "@nivo/colors";

// Used in Array.filter
const isFirstOccurrence = (value: string, index: number, self: string[]) => (self.indexOf(value) === index);

const emptyOnChangeCall = (v: any) => null;

const useStyles = makeStyles((theme) => ({
    ...commonStyles(theme),
    ...styles(theme),
}));

function LegendItem(props: { children: JSX.Element[] | JSX.Element, title: string, tooltip: string, paddingTop?: string }) {
    // Shorthand to add items with a label to the legend area
    const classes = useStyles();
    return (
        <Grid item style={{paddingTop: props.paddingTop}}>
            <Grid container direction="column" spacing={0}>
                <Grid item xs={12} alignItems="flex-start" className={clsx([
                    classes.gray,
                    classes.alignLeft,
                ])} style={{paddingLeft: "0.1em"}}>
                    <Typography variant="caption" display="block" gutterBottom style={{fontSize: "14px"}}>
                        {props.title}
                        <Tooltip title={props.tooltip} placement="top">
                            <IconButton aria-label="delete" size="small" style={{paddingTop: 0}}>
                                <HelpOutlineIcon style={{fontSize: "12px"}}></HelpOutlineIcon>
                            </IconButton>
                        </Tooltip>
                    </Typography>
                </Grid>
                {props.children}
            </Grid>
        </Grid>
    )
}

interface MonthRange {
    minMonth: Date,
    maxMonth: Date,
}

export interface MonthData {
    includeAllTimeAverage: boolean,
    monthRange?: MonthRange
}

interface SurvivalAnalysisLegendProps {
    data: SurvivalAnalysis,

    onRiskTypeChange?: (riskType: string) => void,
    onCategoryChange?: (category: string) => void,
    onAudiencesChange?: (audiences: string[]) => void,
    onMonthDataChange?: (months: MonthData) => void,
}

export const SurvivalAnalysisLegend = (props: SurvivalAnalysisLegendProps) => {
    const classes = useStyles();
    // Needs to behave the same as the colors in SurvivalAnalysisGraph
    const colors = useOrdinalColorScale({scheme: "category10"}, "id");

    // Set up onChange() functions
    let onRiskTypeChange = props.onRiskTypeChange || emptyOnChangeCall;
    let onCategoryChange = props.onCategoryChange || emptyOnChangeCall;
    let onAudiencesChange = props.onAudiencesChange || emptyOnChangeCall;
    let onMonthDataChange = props.onMonthDataChange || emptyOnChangeCall;

    // Risk type
    const defaultRiskType = "survival";
    const [currentRiskType, setCurrentRiskType] = useState(defaultRiskType);
    const riskTypes = useMemo(() => {
        // Called on init
        let newRiskTypes = Object.keys(props.data.graph_types);
        setCurrentRiskType(defaultRiskType);
        return newRiskTypes
    }, [props.data]);
    const handleRiskTypeChange = (event: React.MouseEvent<HTMLElement, MouseEvent>, value: any) => {
        // Called on field change
        if (value === null) return;
        const newRiskType = value as string;
        setCurrentRiskType(newRiskType);
    };
    // Reduces graph updates
    useEffect(() => onRiskTypeChange(currentRiskType), [currentRiskType, onRiskTypeChange]);

    // Category
    const defaultCategory = "baseline";
    const [currentCategory, setCurrentCategory] = useState(defaultCategory);
    const categories = useMemo(
        () => {
            // Called on init
            setCurrentCategory(defaultCategory);
            return props.data.graph_types[currentRiskType].groups.map(
                (group) => group.group_id.graph_cohort
            ).filter(isFirstOccurrence)
        },
        [props.data.graph_types, currentRiskType]
    );
    const handleCategoryChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        // Called on field change
        const newCategory = event.target.value as string;
        setCurrentCategory(newCategory);
    };
    // Reduces graph updates
    useEffect(() => onCategoryChange(currentCategory), [currentCategory, onCategoryChange]);

    // Audiences
    const [currentAudiences, setCurrentAudiences] = useState(["baseline"]);
    const allAudiences = useMemo(
        () => {
            // Called on init
            // Always includes "baseline", gets unique col_vals
            let _allAudiences = [
                "baseline",
                ...props.data.graph_types[currentRiskType].groups.find(
                    (group) => (
                        (group.group_id.graph_cohort === currentCategory)
                        && (group.group_id.month === "default")
                    )
                )?.graph_data.data.col_val?.filter(
                    isFirstOccurrence
                ).map(
                    (audience) => (((typeof audience) === "number")) ?
                        audience.toString()
                        : '"' + audience.toString() + '"'
                ) || []
            ]
            setCurrentAudiences(_allAudiences);
            onAudiencesChange(_allAudiences);
            return _allAudiences;
        },
        [props.data.graph_types, onAudiencesChange, currentRiskType, currentCategory]
    );
    // Updates `colors` inner mapping
    allAudiences.map((audience) => colors({id: audience}));
    const handleAudienceChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        // Called on field change
        let newAudiences = event.target.value as string[];
        // Sets order
        newAudiences = allAudiences.filter(
            audience => newAudiences.includes(audience)
        );
        setCurrentAudiences(newAudiences);
        onAudiencesChange(newAudiences);
    }

    // Months
    const defaultMonthData: MonthData = {includeAllTimeAverage: true};
    const [currentMonthData, setCurrentMonthData] = useState(defaultMonthData);
    const maxMonthRange: MonthRange | null = useMemo(
        () => {
            // Called on init
            // Gets dates and removes time components, drops when month equals "default"
            let validDates = Object.values(props.data.graph_types).map(
                // Gets all months (in format "%b-%Y")
                graph_type => graph_type.groups.map(group => group.group_id.month)
            ).flat().map(
                // Changes the string to a date
                v => {
                    let d = new Date(v);
                    return new Date(d.getFullYear(), d.getMonth());
                }
            ).filter(
                // Gets rid of invalid dates (caused by some months being labeled as "default")
                v => !isNaN(v.getDate())
            );

            if (validDates.length < 1)
                return null;

            return {
                minMonth: new Date(Math.min.apply(null, validDates.map(d => d.getTime()))),
                maxMonth: new Date(Math.max.apply(null, validDates.map(d => d.getTime()))),
            };
        },
        [props.data.graph_types]
    );
    const handleMonthRangeChange = (newRange?: MonthRange) => {
        let newMonthData: MonthData = {
            includeAllTimeAverage: (newRange === undefined) || currentMonthData.includeAllTimeAverage,
            monthRange: newRange,
        };
        setCurrentMonthData(newMonthData);
        onMonthDataChange(newMonthData);
    }
    const handleMinMonthChange = (minMonth: Date | null) => {
        // Resets the time data
        if (minMonth) minMonth = new Date(minMonth.getFullYear(), minMonth.getMonth());
        handleMonthRangeChange((minMonth === null) ? undefined : {
            minMonth: minMonth,
            // Sets maxMonth to minMonth if it is undefined or less than minMonth
            maxMonth: (
                (currentMonthData.monthRange === undefined)
                || (currentMonthData.monthRange.maxMonth < minMonth)
            )?
                minMonth
                : currentMonthData.monthRange.maxMonth,
        });
    }
    const handleMaxMonthChange = (maxMonth: Date | null) => {
        // Resets the time data
        if (maxMonth) maxMonth = new Date(maxMonth.getFullYear(), maxMonth.getMonth());
        handleMonthRangeChange((maxMonth === null) ? undefined : {
            // Sets minMonth to maxMonth if it is undefined or greater than maxMonth
            minMonth: (
                (currentMonthData.monthRange === undefined)
                || (currentMonthData.monthRange.minMonth > maxMonth)
            ) ?
                maxMonth
                : currentMonthData.monthRange.minMonth,
            maxMonth: maxMonth,
        });
    }
    const handleAllTimeAverageChange = (e: React.ChangeEvent<{ checked: boolean }>) => {
        let newMonthData = {
            includeAllTimeAverage: e.target.checked,
            monthRange: currentMonthData.monthRange,
        };
        setCurrentMonthData(newMonthData);
        onMonthDataChange(newMonthData);
    }


    return (
        <Grid container direction="column" style={{width: "90%", margin: "auto", paddingTop: "1em", paddingBottom: "1em"}}>
            {/* Graph Type */}
            <LegendItem title="Risk Type"
                        tooltip="Analyze churn risk by how much the customer has spent, or how long the customer has been onboarded">
                <ToggleButtonGroup color="secondary" orientation="horizontal" value={currentRiskType}
                                   exclusive
                                   onChange={handleRiskTypeChange} size="small" style={{
                    paddingTop: 0,
                    alignItems: "center",
                    width: "100%",
                    justifyContent: "center",
                }}>
                    {riskTypes.map(
                        (graph_type: string) => {
                            return (
                                // Assumes there are only two graph types
                                <ToggleButton value={graph_type}
                                              style={{
                                                  width: "50%",
                                                  color: "#787878",
                                                  border: "0.5px solid #D2D2D2"
                                              }}>
                                    {props.data.graph_types[graph_type].display_name}
                                </ToggleButton>
                            );
                        }
                    )}
                </ToggleButtonGroup>
            </LegendItem>
            {/* Cohort Group */}
            <LegendItem title="Category"
                        tooltip="Select a category, then divide your customers into audiences in the following field">
                <Select
                    id="survival-graph"
                    input={<SecondarySelect/>}
                    value={currentCategory}
                    onChange={handleCategoryChange}
                    style={{marginTop: 0}}
                    className={classes.survivalSelect}
                >
                    {categories.map((category) => {
                        return (
                            <MenuItem key={category} value={category}>
                                <Typography
                                    variant="body1"
                                    className={classes.navBarMenuItemText}
                                >
                                    {(
                                        props.data.graph_types[currentRiskType].groups.find(
                                            (group) => (group.group_id.graph_cohort === category)
                                        )?.graph_data.display_name
                                    )}
                                </Typography>
                            </MenuItem>
                        );
                    })}
                </Select>
            </LegendItem>
            {/* Cohort Multi-Select */}
            <LegendItem title="Audiences"
                        tooltip="Compare churn risk among audiences matching the selected category. Colors correspond with the color on the graph."
                        paddingTop="1em">
                <Select
                    id="cohort-multi-selection"
                    multiple
                    input={<SecondarySelect/>}
                    value={currentAudiences}
                    onChange={handleAudienceChange}
                    renderValue={(selected) => (
                        <div style={{display: "flex", flexWrap: "wrap"}}>
                            {(selected as string[]).map((value) => (
                                <Tooltip title={value} aria-label={value}>
                                    <Chip key={value}
                                          label={(value.length > 35) ? value.substr(0, 35) + "…" : value}
                                          style={{
                                              margin: 2,
                                              backgroundColor: colors({id: value}) + "10",
                                              color: colors({id: value})
                                          }}/>
                                </Tooltip>
                            ))}
                        </div>
                    )}
                    style={{marginTop: 0, marginBottom: 0, width: "100%"}}
                >
                    {allAudiences.map((audience) => (
                        <MenuItem key={audience} value={audience}>
                            {audience}
                        </MenuItem>
                    ))}
                </Select>
            </LegendItem>
            {/* Month Multi-Select */}
            {(maxMonthRange !== null) &&
                <LegendItem title="Months"
                            tooltip="Group audiences into months. All-time average is highlighted. Fading lines correspond with age of group."
                            paddingTop="1em">
                    {/* Toggle All-Time Average Line */}
                    <Grid item xs={12}>
                        <Grid component="label" container alignItems="center" justifyContent="space-between"
                              spacing={1}>
                            <Grid item style={{fontSize: "12px"}} className={clsx([
                                classes.gray,
                                classes.alignLeft,
                            ])}>Include All-Time Average</Grid>
                            <Grid item>
                                <Switch checked={currentMonthData.includeAllTimeAverage} color="primary" size="small"
                                        disabled={(currentMonthData.monthRange === undefined)}
                                        onChange={handleAllTimeAverageChange}
                                />
                            </Grid>
                        </Grid>
                    </Grid>

                    {/* Date Pickers */}
                    <Grid item xs={12} style={{paddingTop: "1em"}}>
                        <div style={{width: "45%", display: "inline-flex"}}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <DatePicker
                                    autoOk={true}
                                    views={["year", "month"]}
                                    value={currentMonthData.monthRange?.minMonth || new Date()}
                                    format={(currentMonthData.monthRange) ? "MMM yyyy" : " "}
                                    disabled={(maxMonthRange === null)}
                                    label="Start&nbsp;&nbsp;"
                                    minDate={maxMonthRange?.minMonth}
                                    maxDate={maxMonthRange?.maxMonth}
                                    inputVariant="outlined"
                                    minDateMessage=""
                                    maxDateMessage=""
                                    size="small"
                                    InputLabelProps={{
                                        style: {
                                            marginTop: "2px",
                                        }
                                    }}
                                    InputProps={{
                                        style: {
                                            color: "#787878",
                                            fontSize: "14px",
                                            fontWeight: 500,
                                            textAlign: "center",
                                            marginBottom: "0px",
                                            marginTop: "2px",
                                        },
                                    }}
                                    onChange={handleMinMonthChange}
                                />
                            </MuiPickersUtilsProvider>
                        </div>
                        <div style={{width: "10%", display: "inline-flex"}}>
                            <ArrowRightIcon style={{margin: "auto", fill: "#787878"}}></ArrowRightIcon>
                        </div>
                        <div style={{width: "45%", display: "inline-flex"}}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <DatePicker
                                    autoOk={true}
                                    views={["year", "month"]}
                                    value={currentMonthData.monthRange?.maxMonth || new Date()}
                                    format={(currentMonthData.monthRange) ? "MMM yyyy" : " "}
                                    disabled={(maxMonthRange === null)}
                                    label="End&nbsp;&nbsp;"
                                    minDate={maxMonthRange?.minMonth}
                                    maxDate={maxMonthRange?.maxMonth}
                                    inputVariant="outlined"
                                    minDateMessage=""
                                    maxDateMessage=""
                                    size="small"
                                    InputLabelProps={{
                                        style: {
                                            marginTop: "2px",
                                        }
                                    }}
                                    InputProps={{
                                        style: {
                                            color: "#787878",
                                            fontSize: "14px",
                                            fontWeight: 500,
                                            textAlign: "center",
                                            marginBottom: "0px",
                                            marginTop: "2px",
                                        },
                                    }}
                                    onChange={handleMaxMonthChange}
                                />
                            </MuiPickersUtilsProvider>
                        </div>
                    </Grid>

                    {/* Clear Dates */}
                    <Grid item xs={12} className={classes.alignRight} style={{paddingRight: "0.1em"}}>
                        <Link
                            component="button"
                            variant="body2"
                            style={{color: "#787878", fontSize: "12px"}}
                            onClick={() => handleMonthRangeChange(undefined)}
                        >
                            Clear Dates
                        </Link>
                    </Grid>
                </LegendItem>
            }
        </Grid>

    )
}