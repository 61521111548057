import { Controller, useForm } from "react-hook-form";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { mergeErrors } from "app/forms";

// Material UI
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import FormGroup from "@material-ui/core/FormGroup";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Switch from "@material-ui/core/Switch";

// components
import Loader from "components/loading/Loader";
import { States } from "features/offboarding/containers/StateViewRouter/StateViewRouter";
import { ExitSurveyResponse, UpdateEngineFields } from "../slice/types";
import PanelHeader from "components/wizard/PanelHeader";
import ContextVariableList from "components/wizard/ContextVariableList";
import FormSectionHeader from "components/wizard/FormSectionHeader";

import {
  closePreview,
  wizardEngineSelector,
  currentEngineSlugSelector,
  displayDataSelector,
  engineStyleSelector,
  exitSurveyOtherReasonResponseSelector,
  getEngineStyle,
  openPreviewStep,
  updateEngine,
  updateEngineStyle,
  updateExitSurveyResponse,
} from "../slice/wizardSlice";

// styles
import { makeStyles } from "@material-ui/core/styles";
import commonStyles from "components/layout/commonStyles";
import wizardStyles from "../wizardStyles";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  ...commonStyles(theme),
  ...wizardStyles(theme),
}));

export default function ExitSurveyPanel() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const otherReason = useSelector(exitSurveyOtherReasonResponseSelector);
  const isFetchingUpdateReasons = useSelector(
    updateExitSurveyResponse.isFetchingSelector
  );
  const isFetchingUpdateEngineStyle = useSelector(
    updateEngineStyle.isFetchingSelector
  );

  const isFetchingUpdateEngine = useSelector(updateEngine.isFetchingSelector);

  const inPreview = useSelector(displayDataSelector).showPreviewStep;
  const inAdditionalCommentsPreview =
    inPreview && inPreview.step === States.ADDITIONAL_COMMENTS;
  const inExitSurveyPreview = inPreview && inPreview.step === States.REASONS;
  const inOtherReasonPreview =
    inPreview && inPreview.step === States.OTHER_REASON;

  const currentEngine = useSelector(wizardEngineSelector);
  const currentEngineSlug = useSelector(currentEngineSlugSelector);
  const updateEngineStyleErrors = useSelector(
    updateEngineStyle.ExtraErrorsSelector
  );
  const style = useSelector(engineStyleSelector);
  const ready = useSelector(getEngineStyle.isReadySelector);

  const {
    handleSubmit,
    control,
    formState: { errors: frontendErrorsExitSurvey },
  } = useForm();

  const {
    handleSubmit: handleUpdateEngineSubmit,
    control: updateStylingControl,
    formState: { errors: frontendErrors },
  } = useForm();

  const errors = mergeErrors(frontendErrors, updateEngineStyleErrors);
  const errorsExitSurvey = mergeErrors(
    frontendErrorsExitSurvey,
    updateEngineStyleErrors
  );

  const onSubmit = handleSubmit((data: any) => {
    dispatch(
      updateEngineStyle.Action({
        ...data,
        slug: currentEngine.slug,
      })
    );
  });

  const onUpdateEngineStyle = handleUpdateEngineSubmit((data: any) => {
    dispatch(
      updateEngineStyle.Action({
        ...data,
        slug: currentEngine.slug,
      })
    );
  });

  useEffect(() => {
    if (currentEngineSlug !== ":engine")
      dispatch(getEngineStyle.Action(currentEngineSlug));
  }, [dispatch, currentEngineSlug]);

  if (!ready || !currentEngine) {
    return <Loader reason="Fetching exit survey options..." padding />;
  }

  return (
    <div className={classes.mdPadding}>
      <PanelHeader
        title="Exit Survey"
        caption="Page Copy"
        onClick={() =>
          dispatch(
            inExitSurveyPreview
              ? closePreview()
              : openPreviewStep({ step: States.REASONS })
          )
        }
        headerInPreview={inExitSurveyPreview}
      />

      <Divider className={classes.headingDivider} />
      <form onSubmit={onSubmit}>
        <Typography variant="h6" className={classes.purpleGradient}>
          Customize Responses
        </Typography>
        <Typography variant="body2" className={classes.gray}>
          Customize the exit survey responses on the left by activating,
          deactivating, adding, or removing options.
        </Typography>
        <Typography variant="h6" className={classes.panelSubheader}>
          Page Heading*
        </Typography>
        <Typography variant="body2" className={classes.gray}>
          Personalize the greeting or question that asks your customers why they
          want to cancel.
        </Typography>
        <ContextVariableList contextVariables={style.context_vars} />

        <FormGroup>
          <Controller
            control={control}
            name="exit_survey_heading"
            rules={{ required: true }}
            defaultValue={style.exit_survey_heading}
            render={({ field }) => (
              <TextField
                {...field}
                InputLabelProps={{ shrink: true }}
                label="500 CHARACTERS MAX"
                variant="outlined"
                error={Boolean(errorsExitSurvey.get("exit_survey_heading"))}
                helperText={errorsExitSurvey.get("exit_survey_heading")}
              />
            )}
          />
        </FormGroup>

        <Button
          type="submit"
          className={clsx(classes.panelButton)}
          disabled={isFetchingUpdateEngineStyle}
        >
          Save Page Heading
        </Button>
      </form>

      <Divider className={classes.headingDivider} />
      <Typography variant="h6" className={classes.panelSubheader}></Typography>
      <FormSectionHeader
        title="Global Immediate Cancel"
        showPreview
        headerInPreview={inExitSurveyPreview}
        previewStep={States.REASONS}
      />
      <Typography variant="body2" className={classes.gray}>
        Allow all customers to be able to skip the reason response and get
        straight to the Cancel confirm screen.
      </Typography>
      <Switch
        checked={currentEngine?.allow_global_immediate_cancel}
        disabled={isFetchingUpdateEngine}
        onChange={() =>
          !isFetchingUpdateEngine &&
          dispatch(
            updateEngine.Action({
              slug: currentEngine.slug,
              allow_global_immediate_cancel:
                !currentEngine.allow_global_immediate_cancel,
            } as UpdateEngineFields)
          )
        }
        name="Global_Immediate_Cancel"
        inputProps={{ "aria-label": "global immediate cancel" }}
      />

      <Typography variant="h6" className={classes.panelSubheader}>
        California Immediate Cancel
      </Typography>
      <Typography variant="body2" className={classes.gray}>
        Allow customers only from California to be able to skip the reason
        response and get straight to the Cancel confirm screen.
      </Typography>
      <Switch
        checked={currentEngine?.allow_california_immediate_cancel}
        disabled={isFetchingUpdateEngine}
        onChange={() =>
          !isFetchingUpdateEngine &&
          dispatch(
            updateEngine.Action({
              slug: currentEngine.slug,
              allow_california_immediate_cancel:
                !currentEngine.allow_california_immediate_cancel,
            } as UpdateEngineFields)
          )
        }
        name="California_Immediate_Cancel"
        inputProps={{ "aria-label": "california immediate cancel" }}
      />

      <form onSubmit={onUpdateEngineStyle}>
        <FormGroup>
          <Controller
            control={updateStylingControl}
            name="immediate_cancel_text"
            defaultValue={style.immediate_cancel_text}
            rules={{ required: true }}
            render={({ field }) => (
              <TextField
                {...field}
                InputLabelProps={{ shrink: true }}
                label="IMMEDIATE CANCEL BUTTON TEXT"
                variant="outlined"
                error={Boolean(errors.get("immediate_cancel_text"))}
                helperText={errors.get("immediate_cancel_text")}
              />
            )}
          />
        </FormGroup>
        <Button
          type="submit"
          className={clsx(classes.panelButton)}
          disabled={isFetchingUpdateEngineStyle}
        >
          Save Cancel Button Text
        </Button>
      </form>

      <Divider className={classes.headingDivider} />

      <FormSectionHeader
        title="Other Reason Options"
        showPreview
        headerInPreview={inOtherReasonPreview}
        previewStep={States.OTHER_REASON}
      />
      <Typography variant="body2" className={classes.gray}>
        Allow customers to select "Other Reason" as an Exit Survey response and
        provide a response.
      </Typography>
      <Switch
        checked={otherReason?.active}
        disabled={isFetchingUpdateReasons}
        onChange={() =>
          !isFetchingUpdateReasons &&
          dispatch(
            updateExitSurveyResponse.Action({
              slug: otherReason.slug,
              active: !otherReason.active,
              offboard_slug: currentEngine.slug,
            } as ExitSurveyResponse)
          )
        }
        name="Other_Reason"
        inputProps={{ "aria-label": "other reason" }}
      />

      {otherReason?.active ? (
        <form onSubmit={onUpdateEngineStyle}>
          <FormGroup>
            <Controller
              control={updateStylingControl}
              name="other_reason_button"
              defaultValue={style.other_reason_button}
              rules={{ required: true }}
              render={({ field }) => (
                <TextField
                  {...field}
                  InputLabelProps={{ shrink: true }}
                  label="OTHER REASON BUTTON TEXT"
                  variant="outlined"
                  error={Boolean(errors.get("other_reason_button"))}
                  helperText={errors.get("other_reason_button")}
                />
              )}
            />

            <Controller
              control={updateStylingControl}
              name="other_reason_headline"
              defaultValue={style.other_reason_headline}
              render={({ field }) => (
                <TextField
                  {...field}
                  InputLabelProps={{ shrink: true }}
                  label="OTHER REASON PAGE HEADER"
                  variant="outlined"
                  error={Boolean(errors.get("other_reason_headline"))}
                  helperText={errors.get("other_reason_headline")}
                />
              )}
            />
            <Controller
              control={updateStylingControl}
              name="other_reason_placeholder"
              defaultValue={style.other_reason_placeholder}
              rules={{ required: true }}
              render={({ field }) => (
                <TextField
                  {...field}
                  InputLabelProps={{ shrink: true }}
                  label="OTHER REASON TEXTBOX PLACEHOLDER"
                  variant="outlined"
                  error={Boolean(errors.get("other_reason_placeholder"))}
                  helperText={errors.get("other_reason_placeholder")}
                />
              )}
            />
          </FormGroup>
          <Button
            type="submit"
            className={clsx(classes.panelButton)}
            disabled={isFetchingUpdateEngineStyle}
          >
            Save Other Reason Options
          </Button>
        </form>
      ) : null}

      <Divider className={classes.headingDivider} />

      <FormSectionHeader
        title="Additional Exit Survey Comments"
        showPreview
        headerInPreview={inAdditionalCommentsPreview}
        previewStep={States.ADDITIONAL_COMMENTS}
      />
      <Typography variant="body2" className={classes.gray}>
        Allow customers to add additional comments in the Exit Survey.
      </Typography>
      <Switch
        checked={currentEngine.allow_additional_comment}
        disabled={isFetchingUpdateEngineStyle}
        onChange={() =>
          dispatch(
            updateEngine.Action({
              allow_additional_comment: !currentEngine.allow_additional_comment,
              slug: currentEngine.slug,
            })
          )
        }
        name="allow_additional_comment"
        inputProps={{ "aria-label": "additional comments" }}
      />
      {currentEngine.allow_additional_comment ? (
        <form onSubmit={onUpdateEngineStyle}>
          <FormGroup>
            <Controller
              control={updateStylingControl}
              name="additional_comments_headline"
              defaultValue={style.additional_comments_headline}
              render={({ field }) => (
                <TextField
                  {...field}
                  InputLabelProps={{ shrink: true }}
                  label="ADDITIONAL COMMENT HEADER"
                  variant="outlined"
                  error={Boolean(errors.get("additional_comments_headline"))}
                  helperText={errors.get("additional_comments_headline")}
                />
              )}
            />
            <Controller
              control={updateStylingControl}
              name="additional_comments_placeholder"
              defaultValue={style.additional_comments_placeholder}
              render={({ field }) => (
                <TextField
                  {...field}
                  InputLabelProps={{ shrink: true }}
                  label="ADDITIONAL COMMENT PLACEHOLDER"
                  variant="outlined"
                  error={Boolean(errors.get("additional_comments_placeholder"))}
                  helperText={errors.get("additional_comments_placeholder")}
                />
              )}
            />
            <Controller
              control={updateStylingControl}
              name="additional_comments_reason_selected_text"
              defaultValue={style.additional_comments_reason_selected_text}
              render={({ field }) => (
                <TextField
                  {...field}
                  InputLabelProps={{ shrink: true }}
                  label="ADDITIONAL COMMENT REASON SELECTED"
                  variant="outlined"
                  error={Boolean(
                    errors.get("additional_comments_reason_selected_text")
                  )}
                  helperText={errors.get(
                    "additional_comments_reason_selected_text"
                  )}
                />
              )}
            />
          </FormGroup>
          <Button
            type="submit"
            className={clsx(classes.panelButton)}
            disabled={isFetchingUpdateEngineStyle}
          >
            Save Additional Comments Data
          </Button>
        </form>
      ) : null}
    </div>
  );
}
