import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import toast from "react-hot-toast";

// Images
import infoIcon from "../../wizard/img/infoIcon.svg";

// Material UI
import { Container, Switch } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Copy from "@material-ui/icons/FileCopyOutlined";

// components
import Loader from "../../../components/loading/Loader";

import {
  currentEngineSlugSelector,
  activateEngine,
  getEngine,
  getEngines,
  navigateTo,
  wizardEngineSelector,
} from "../slice/wizardSlice";

//styles
import { makeStyles } from "@material-ui/core/styles";
import commonStyles from "../../../components/layout/commonStyles";
import clsx from "clsx";
import { generatePath } from "react-router-dom";
import { DASHBOARD_URLS } from "features/dashboard/urls";

const useStyles = makeStyles((theme) => ({
  ...commonStyles(theme),
}));

export const ActivateContainer = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const engine = useSelector(wizardEngineSelector);
  const currentEngineSlug = useSelector(currentEngineSlugSelector);
  const getEngineStarted = useSelector(getEngine.isRequestedSelector);

  useEffect(() => {
    if (currentEngineSlug && !engine && !getEngineStarted)
      dispatch(getEngine.Action(currentEngineSlug));
  }, [currentEngineSlug, engine, getEngineStarted, dispatch]);

  useEffect(() => {
    if (!engine && !getEngineStarted) dispatch(getEngines.Action(null));
  }, [dispatch, currentEngineSlug, engine, getEngineStarted]);

  const formatted_embedd = engine.snippet_data.embedded_js
    ? engine.snippet_data.embedded_js?.join("\n")
    : null;

  const formatted_redirect = engine.snippet_data.snippet_code?.length
    ? engine.snippet_data.snippet_code?.join("\n")
    : null;
  const copyToClipboard = (text: string) => {
    if (text) {
      navigator.clipboard.writeText(text);
      toast.success("Copied to clipboard!");
    }
  };

  if (!engine) {
    return <Loader reason="Fetching snippets..." padding />;
  }
  return (
    <Container
      className={clsx(
        classes.marginTopMd,
        classes.marginBottomMd,
        classes.roundedCard
      )}
    >
      <>
        <Grid container justifyContent="center">
          <Grid item sm={12}>
            <Card className={classes.mdPadding}>
              <section>
                <Typography
                  variant="body1"
                  className={clsx(classes.bold, classes.purpleGradient)}
                >
                  Engine Integration with your business
                </Typography>
                {engine?.payment_engine === "RETEXTION" && (
                  <Card className={classes.mdPadding}>
                    <Card className={classes.attentionBubble}>
                      <Grid
                        container
                        alignItems="center"
                        spacing={2}
                        justifyContent="center"
                      >
                        <Grid item xs={1}>
                          <img src={infoIcon} alt="info_icon" />
                        </Grid>
                        <Grid item xs={10}>
                          <Typography variant="body2">
                            Toggling this on will make your RetentionEngine live
                          </Typography>
                        </Grid>
                      </Grid>
                    </Card>
                    <Switch
                      checked={engine.active}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        dispatch(
                          activateEngine.Action({
                            active: e.target.checked,
                            slug: currentEngineSlug,
                          })
                        )
                      }
                      name="isActivated"
                      color="primary"
                    />
                  </Card>
                )}
                {engine?.payment_engine !== "RETEXTION" && (
                  <>
                    <Typography variant="body1">Code Snippet</Typography>
                    <Typography variant="body2">
                      This snippet needs to be pasted into your code so that
                      customers are redirected to the RetentionEngine when they
                      click the "Cancel" button on your website.
                    </Typography>
                    <Card className={classes.attentionBubble}>
                      <Grid
                        container
                        alignItems="center"
                        spacing={2}
                        justifyContent="center"
                      >
                        <Grid item xs={1}>
                          <img src={infoIcon} alt="info_icon" />
                        </Grid>
                        <Grid item xs={10}>
                          <Typography
                            variant="body2"
                            className={classes.alignLeft}
                          >
                            Pasting this snippet into your code makes your
                            RetentionEngine live, you can change this in the
                            Builder later.
                          </Typography>
                        </Grid>
                      </Grid>
                    </Card>
                  </>
                )}
                {formatted_embedd ? (
                  <>
                    <Typography
                      variant="body2"
                      className={clsx(classes.bold, classes.purpleGradient)}
                    >
                      JavaScript Embed Instructions (-)
                    </Typography>
                    <Typography variant="body2">
                      <span
                        dangerouslySetInnerHTML={{
                          __html: engine.snippet_data.embedded_js_instructions,
                        }}
                      />
                    </Typography>
                    <Card className={classes.codeSnippetCard}>
                      <pre
                        className={classes.code}
                        style={{ maxWidth: "100%" }}
                      >
                        <code>{formatted_embedd}</code>
                        <textarea
                          value={formatted_embedd}
                          onChange={() => null}
                          style={{ height: 1, width: 1, opacity: 0 }}
                        />
                      </pre>
                      <Button
                        className={classes.copyButton}
                        startIcon={<Copy />}
                        onClick={() => copyToClipboard(formatted_embedd)}
                      >
                        Copy
                      </Button>
                    </Card>
                  </>
                ) : null}

                {formatted_redirect ? (
                  <>
                    <Typography
                      variant="body2"
                      className={clsx(classes.bold, classes.purpleGradient)}
                    >
                      Code Snippet Instructions (-)
                    </Typography>
                    <Typography variant="body2">
                      <span
                        dangerouslySetInnerHTML={{
                          __html: engine.snippet_data.snippet_prologue,
                        }}
                      />
                    </Typography>

                    <Card className={classes.codeSnippetCard}>
                      <pre className={classes.code}>
                        <code>{formatted_redirect}</code>
                        <textarea
                          value={formatted_redirect}
                          onChange={() => null}
                          style={{ height: 1, width: 1, opacity: 0 }}
                        />
                      </pre>
                      <Button
                        className={classes.copyButton}
                        startIcon={<Copy />}
                        onClick={() => copyToClipboard(formatted_redirect)}
                      >
                        Copy
                      </Button>
                    </Card>
                    <Typography variant="body2">
                      <span
                        dangerouslySetInnerHTML={{
                          __html: engine.snippet_data.snippet_epilogue,
                        }}
                      />
                    </Typography>
                  </>
                ) : null}
              </section>
              <Button
                color="primary"
                onClick={() =>
                  dispatch(
                    navigateTo(
                      generatePath(DASHBOARD_URLS.overview, {
                        engine: currentEngineSlug || ":engine",
                      })
                    )
                  )
                }
                variant="contained"
                className={classes.button}
              >
                Done &rarr;
              </Button>
            </Card>
          </Grid>
        </Grid>
      </>
    </Container>
  );
};
