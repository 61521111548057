import { ResponsivePie } from "@nivo/pie";
import { TopIntermediateStatistics } from "features/wizard/slice/types";
import { CustomTooltip } from "./CustomTooltip";

interface TopOffersPieProps {
  data: TopIntermediateStatistics[];
}

export const TopPausesPie = (props: TopOffersPieProps) => {
  let data = [...props.data];
  data.sort((a, b) => b.selected - a.selected);
  const newData = data
    .filter((stat) => stat.name !== "Total" && stat.selected !== 0)
    .slice(0, 5)
    .map((stat) => ({
      id: stat.name,
      value: stat.selected,
      label: stat.name,
    }));

  return (
    <ResponsivePie
      data={newData}
      margin={{ top: 10, right: 80, bottom: 40, left: -160 }}
      innerRadius={0.5}
      padAngle={0.7}
      cornerRadius={3}
      activeOuterRadiusOffset={8}
      borderWidth={1}
      colors={["#F3D2FF", "#8E509F", "#5B1E71", "#250542", "#CACACA"]}
      borderColor={{ from: "color", modifiers: [["darker", 0.2]] }}
      enableArcLabels={false}
      enableArcLinkLabels={false}
      arcLinkLabelsColor={{ from: "color", modifiers: [] }}
      tooltip={({ datum }) => <CustomTooltip datum={datum} />}
      legends={[
        {
          anchor: "right",
          direction: "column",
          justify: false,
          translateX: -30,
          translateY: 0,
          itemsSpacing: 0,
          itemDirection: "left-to-right",
          itemWidth: 150,
          itemHeight: 30,
          itemOpacity: 0.75,
          symbolSize: 14,
          symbolShape: "circle",
          symbolBorderColor: "rgba(0, 0, 0, .5)",
          effects: [
            {
              on: "hover",
              style: {
                itemBackground: "rgba(0, 0, 0, .03)",
                itemOpacity: 1,
              },
            },
          ],
        },
      ]}
    />
  );
};
