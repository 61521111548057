import React from "react";
import { externalTheme, lightTheme } from "MUITheme";
import { makeStyles, ThemeProvider } from "@material-ui/core/styles";
import styles from "./externalLayoutStyles";
import Paper from "@material-ui/core/Paper";
import PublicNavBar from "components/header/PublicNavBar";
import Footer from "components/footer/Footer";
import MiniFooter from "../../footer/MiniFooter";

// images
import imgHighCurve from "components/layout/external/img/backgroundHighPurpleOverlay.svg";
import imgLowCurve from "components/layout/external/img/backgroundLowPurpleOverlay.svg";
import { Grid } from "@material-ui/core";
import commonStyles from "../commonStyles";
import clsx from "clsx";
import { Toaster } from "react-hot-toast";

type ExternalLayoutProps = {
  lowCurve?: Boolean;
  footerFull?: Boolean;
};

const useStyles = makeStyles((theme) => ({
  ...styles(theme),
  ...commonStyles(theme),
}));

export const ExternalLayout: React.FC<ExternalLayoutProps> = ({
  children,
  lowCurve,
  footerFull,
}) => {
  const classes = useStyles();

  return (
    <ThemeProvider theme={externalTheme}>
      <Paper square className={classes.purpleBackground}>
        <PublicNavBar />
        <Toaster position="top-center" />
        <Grid
          container
          justifyContent="center"
          className={clsx(classes.mainContent, classes.externalMainShowFooter)}
        >
          <Grid item xs={12} md={11} xl={10}>
            <div className={classes.centerMiddleContainer}>{children}</div>
          </Grid>
          <div
            className={classes.curveOverlay}
            style={{
              backgroundImage: `url(${lowCurve ? imgLowCurve : imgHighCurve})`,
            }}
          />
        </Grid>
        <ThemeProvider theme={lightTheme}>
          {footerFull ? <Footer /> : <MiniFooter />}
        </ThemeProvider>
      </Paper>
    </ThemeProvider>
  );
};

export default ExternalLayout;
