import { Theme } from "@material-ui/core";

export default function headerStyles(theme: Theme) {
  return {
    root: {
      zIndex: 1000,
      position: "relative" as "relative",
    },
    grow: {
      flexGrow: 1,
    },
    appBar: {
      backgroundColor: "transparent !important",
      boxShadow: "none !important",
    },
    privateAppBar: {
      backgroundColor: "#f8f8f8",
      boxShadow: "none",
      paddingBottom: "0.5em",
      borderBottom: "2px solid #e8e8e8",
    },
    privateLogo: {
      margin: "-12px 0",
    },
    title: {
      textAlign: "left" as "left",
      fontSize: "24px",
    },
    headingMain: {
      display: "inline-block",
      fontWeight: 500,
    },
    headingSub: {
      display: "inline-block",
      fontWeight: 300,
    },
    engineSelect: {
      position: "relative" as "relative",
      marginRight: theme.spacing(2),
      marginLeft: 0,
      marginBottom: 0,
      width: "100%",
      [theme.breakpoints.up("sm")]: {
        marginLeft: theme.spacing(3),
        width: "auto",
      },
    },
    navBarSelectView: {
      marginRight: "1em",
    },
  };
}
