import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import { store } from "./app/store";
import { Provider } from "react-redux";
import * as serviceWorker from "./serviceWorker";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import { AppEnvironmentEnums } from "features/wizard/slice/types";

if (
  process.env.REACT_APP_ENVIRONMENT === AppEnvironmentEnums.PROD ||
  process.env.REACT_APP_ENVIRONMENT === AppEnvironmentEnums.STAGING
) {
  Sentry.init({
    dsn: "https://213972fa66764be99f5686288c473bde@sentry.io/5174079",
    integrations: [new BrowserTracing()],
    environment: process.env.REACT_APP_ENVIRONMENT,
  });
}

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,

  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
